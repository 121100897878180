import React, { FC } from 'react';
import { styled } from '@mui/material';

import { SelectDropdown } from '@forethought-technologies/forethought-elements';
import get from 'lodash/fp/get';
import { FieldAndValue } from 'src/types/workflowBuilderAPITypes';

interface DropdownValuesProps {
  fieldArr: Array<{ id: string; title: string }>;
  fieldId: string;
  fieldName: string;
  idFieldMap: {
    [id: string]: {
      field_options: Array<{ name: string; value: string }>;
    };
  };
  idValueArr: Array<FieldAndValue>;
  idx: number;
  setFieldValue: (name: string, value: Array<FieldAndValue>) => void;
}

const DropdownFields: FC<React.PropsWithChildren<DropdownValuesProps>> = ({
  fieldArr,
  fieldId,
  fieldName,
  idFieldMap,
  idValueArr,
  idx,
  setFieldValue,
}) => {
  return (
    <Container>
      <SelectDropdown
        aria-label='Select a Field...'
        id='dropdown-fields-select'
        isMenuSearchable
        menuMaxHeight={180}
        onChange={e => {
          if (e.target.value) {
            const updatedFieldValues = idValueArr
              .slice(0, idx)
              .concat({ id: e.target.value, value: '' })
              .concat(idValueArr.slice(idx + 1));

            setFieldValue(fieldName, updatedFieldValues);
          }
        }}
        options={fieldArr.map((customField: { id: string; title: string }) => ({
          label: customField.title,
          value: customField.id,
        }))}
        value={get([fieldId, 'id'], idFieldMap)}
      />
    </Container>
  );
};

export default DropdownFields;

const Container = styled('div')`
  flex: 1 1 50%;
`;
