import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { Box, Menu, MenuItem, useTheme } from '@mui/material';
import { IconDotsVertical, IconPencil, IconTrash } from '@tabler/icons-react';

import {
  Button,
  Dialog,
  IconButton,
  Typography,
} from '@forethought-technologies/forethought-elements';
import useSelfServeEvents from 'src/hooks/triage/useSelfServeEvents';
import { useDeleteModelMutation } from 'src/services/triage/triageApi';
import { TRIAGE_LLM_TRACKING_EVENTS } from 'src/utils/constants';
import { Routes } from 'src/utils/enums';

interface ModelDetailHeaderMenuProps {
  onShowEditModelDrawer: () => void;
}

const ModelDetailHeaderMenu = ({
  onShowEditModelDrawer,
}: ModelDetailHeaderMenuProps) => {
  const { modelId = '' } = useParams<'modelId'>();
  const emitTrackingEventCallback = useSelfServeEvents({
    modelId,
  });
  const navigate = useNavigate();
  const [deleteModelRequest] = useDeleteModelMutation();
  const { palette } = useTheme();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const deleteModel: () => Promise<void> = async () => {
    try {
      await deleteModelRequest(modelId).unwrap();
      navigate(Routes.PREDICTIONS_SETTINGS);
      emitTrackingEventCallback(
        TRIAGE_LLM_TRACKING_EVENTS.DELETE_MODEL_SUCCESS,
      );
    } catch (error) {
      console.error('Failed to delete model:', error);
    }
  };

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement>,
    actionType: 'edit' | 'delete',
  ) => {
    event.stopPropagation();
    if (actionType === 'edit') {
      emitTrackingEventCallback(TRIAGE_LLM_TRACKING_EVENTS.EDIT_MODEL_CLICKED);
      onShowEditModelDrawer();
    } else if (actionType === 'delete') {
      emitTrackingEventCallback(
        TRIAGE_LLM_TRACKING_EVENTS.DELETE_MODEL_CLICKED,
      );
      setIsDialogOpen(true);
    }
    setAnchorEl(null);
  };

  const onCloseDeleteDialog = () => {
    setIsDialogOpen(false);
  };

  return (
    <Box ml={1}>
      <IconButton
        aria-label='model menu'
        hoverBackgroundColor={palette.colors.purple[200]}
        id='model-menu-button'
        onClick={e => {
          e.stopPropagation();
          setAnchorEl(e.currentTarget);
        }}
        size='medium'
        variant='ghost'
      >
        <IconDotsVertical size={17.5} />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          horizontal: 'left',
          vertical: 'bottom',
        }}
        id='model-menu'
        MenuListProps={{
          'aria-labelledby': 'model-menu-button',
          role: 'listbox',
          sx: {
            py: 1.5,
          },
        }}
        onClose={e => {
          //Casting is needed as MUI has {} as a type for this event
          const event = e as MouseEvent;
          event?.stopPropagation && event.stopPropagation();
          setAnchorEl(null);
        }}
        open={Boolean(anchorEl)}
        PaperProps={{
          elevation: 1,
          sx: {
            border: `1px solid ${palette.colors.slate[200]}`,
            borderRadius: '4px',
            width: '300px',
          },
        }}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
      >
        <MenuItem
          onClick={e => handleMenuItemClick(e, 'edit')}
          sx={{
            alignItems: 'center',
            display: 'flex',
            padding: '6px 16px',
          }}
        >
          <Box alignItems='center' display='flex' mr={1}>
            <IconPencil size={20} />
          </Box>
          <Typography variant='font14'>Edit model</Typography>
        </MenuItem>
        <MenuItem
          onClick={e => handleMenuItemClick(e, 'delete')}
          sx={{
            alignItems: 'center',
            color: palette.error.main,
            display: 'flex',
            padding: '6px 16px',
          }}
        >
          <Box alignItems='center' display='flex' mr={1}>
            <IconTrash size={20} />
          </Box>
          <Typography variant='font14'>Delete model</Typography>
        </MenuItem>
      </Menu>
      <Dialog
        footer={
          <>
            <Button onClick={onCloseDeleteDialog} variant='ghost'>
              Cancel
            </Button>
            <Button onClick={deleteModel} variant='secondary'>
              Delete
            </Button>
          </>
        }
        hideCloseButton
        open={isDialogOpen}
        title='Delete this model?'
      >
        This model will be permanently deleted and will not be retrievable
      </Dialog>
    </Box>
  );
};

export default ModelDetailHeaderMenu;
