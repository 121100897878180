import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import { IconBolt } from '@tabler/icons-react';

import {
  Tooltip,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { ICON_SIZE } from '../constants';

interface RelatedTopicsProps {
  onTopicClick: (event: React.MouseEvent) => void;
  topicNames: string;
}
const RelatedTopics = ({ onTopicClick, topicNames }: RelatedTopicsProps) => {
  const { palette } = useTheme();
  return (
    <Box
      borderRadius={1}
      onClick={onTopicClick}
      overflow='hidden'
      px={1}
      py={0.5}
      sx={{
        '&>div': { width: '100%' },
        backgroundColor: palette.colors.slate[100],
        maxWidth: '100%',
      }}
    >
      <Tooltip tooltipContent={topicNames}>
        <Box
          alignItems='center'
          columnGap={0.5}
          display='flex'
          overflow='hidden'
        >
          <IconBolt
            color={palette.colors.green[500]}
            fill={palette.colors.green[500]}
            size={ICON_SIZE}
            strokeWidth={1}
            style={{ minWidth: `${ICON_SIZE}px` }}
          />
          <Typography color={palette.colors.grey[700]} noWrap variant='font12'>
            {topicNames}
          </Typography>
        </Box>
      </Tooltip>
    </Box>
  );
};

export default RelatedTopics;
