import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import DisabledIntentEditionModal from '../disabled-intent-edition-modal/DisabledIntentEditionModal';
import { useGetIntentsQueryWithProduct } from 'src/hooks/hooks';
import IntentForm from 'src/pages/workflow-builder/intent-form';
import { useGetBuilderQueryParams } from 'src/pages/workflow-builder-edit/hooks';
import { selectUserRole } from 'src/reducers/userReducer/userReducer';
import { useGetFeatureFlagsQuery } from 'src/services/dashboard-api';
import {
  closeGlobalIntentDrawer,
  selectGlobalIntentDrawerOptions,
  selectIntentDrawerDataByType,
  selectIsDisabledIntentEditionModalVisible,
  setGlobalToastOptions,
  setIsDisabledIntentEditionModalVisible,
} from 'src/slices/ui/uiSlice';
import { RootState } from 'src/store/rootReducer';
import { Intent } from 'src/types/workflowBuilderAPITypes';
import { Routes, UserRoles } from 'src/utils/enums';

const Intents = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { search } = useLocation();
  const userRole = useSelector(selectUserRole);
  const { initialPhrases, type = null } =
    useSelector(selectGlobalIntentDrawerOptions) ?? {};
  const isDisabledIntentEditionModalVisible = useSelector(
    selectIsDisabledIntentEditionModalVisible,
  );
  const { intent, isActive } = useSelector((state: RootState) =>
    selectIntentDrawerDataByType(state, type),
  );
  const { data: featureFlagsData } = useGetFeatureFlagsQuery();
  const { feature_flags: featureFlags = [] } = featureFlagsData ?? {};

  const {
    intentId,
    view: currentView,
    workflowId,
  } = useGetBuilderQueryParams();

  const { refetch: refetchIntents } = useGetIntentsQueryWithProduct(undefined, {
    skip: ![UserRoles.ROLE_SUPER_ADMIN, UserRoles.ROLE_ADMIN].includes(
      userRole,
    ),
  });

  const isIntentFormVisible = typeof type === 'string';
  const isSolveEmailEnabled = featureFlags.includes('solve_email_enabled');

  const isAutoflowsEnabled = featureFlags.includes('autonomous_agent_enabled');

  const onCloseDisabledIntentEditionModal = () => {
    dispatch(setIsDisabledIntentEditionModalVisible(false));
  };
  const onCloseIntentForm = () => {
    dispatch(closeGlobalIntentDrawer());
  };
  const isEditing = !!intent?.intent_id && type !== 'suggested';
  const showAppropriateToast = () => {
    if (isEditing) {
      dispatch(
        setGlobalToastOptions({
          autoHideDuration: 2000,
          title: 'Intent has been updated.',
          variant: 'main',
        }),
      );
      return;
    }

    dispatch(
      setGlobalToastOptions({
        autoHideDuration: 2000,
        title: 'New intent is added.',
        variant: 'main',
      }),
    );
  };

  return (
    <>
      <IntentForm
        initialPhrases={initialPhrases}
        intent={intent as Intent}
        isEditing={isEditing}
        isOpen={
          // Note: intent is an empty object when on the widget page, so we need to check if intent_id exists in that case
          type === 'widget'
            ? !!intent?.intent_id && isIntentFormVisible
            : !!intent && isIntentFormVisible
        }
        isWorkflowActive={isActive}
        key={intentId}
        onClose={onCloseIntentForm}
        onPostSave={(newWorkflowId, newIntentId) => {
          showAppropriateToast();
          const autoflowModeParam =
            isAutoflowsEnabled &&
            (currentView === 'widget' || currentView === 'api')
              ? '&mode=autoflow'
              : '';

          if (type === 'suggested') {
            const queryParams = `workflowId=${newWorkflowId}&view=widget&intentId=${
              intent?.intent_id ?? ''
            }`;

            navigate(
              `${Routes.WORKFLOW_BUILDER_EDIT}?${
                queryParams + autoflowModeParam
              }`,
            );

            return;
          }

          if (type === 'landing') {
            refetchIntents();
            if (!intent?.intent_id && newWorkflowId) {
              const baseParams = new URLSearchParams({
                intentId: newIntentId ?? '',
                workflowId: newWorkflowId,
              });

              const currentChannel = new URLSearchParams(search).get('channel');
              baseParams.append('view', currentChannel || 'widget');
              navigate(
                `${Routes.WORKFLOW_BUILDER_EDIT}?${
                  baseParams.toString() + autoflowModeParam
                }`,
              );
            }

            return;
          }

          const queryParams = isSolveEmailEnabled
            ? 'view=widget'
            : `workflowId=${workflowId}`;
          navigate(
            `${Routes.WORKFLOW_BUILDER_EDIT}?${
              queryParams + autoflowModeParam
            }`,
            {
              replace: true,
            },
          );
        }}
        onUpdateWithLessThanRequiredPhrases={() =>
          dispatch(setIsDisabledIntentEditionModalVisible(true))
        }
      />
      <DisabledIntentEditionModal
        isOpen={isDisabledIntentEditionModalVisible}
        onClick={onCloseDisabledIntentEditionModal}
      />
    </>
  );
};

export default Intents;
