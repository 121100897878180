import { useState } from 'react';
import { FieldArray, Form, Formik, useField } from 'formik';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import { IconEqual, IconPlus, IconX } from '@tabler/icons-react';

import {
  Alert,
  Button,
  IconButton,
  TextField,
  Typography,
} from '@forethought-technologies/forethought-elements';
import {
  authorizationConfigToKVPairs,
  KVPairsToAuthorizationConfig,
} from './helpers';
import BaseModal from 'src/components/base-modal';
import Spinner from 'src/components/spinner';
import {
  useGetActionUsagesQuery,
  useGetAuthConfigsQuery,
  useUpdateAuthConfigsMutation,
} from 'src/services/action-builder/actionBuilderApi';
import { useGetActionBuilderActionsQuery } from 'src/services/action-builder/actionBuilderApi';
import { Action, KVPairs } from 'src/types/actionBuilderApiTypes';
import { settingsSchema } from 'src/utils/actionBuilder/helpers';

interface ActionSettingsModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const ActionSettingsModal = ({ isOpen, onClose }: ActionSettingsModalProps) => {
  const [isErrorUpdating, setIsErrorUpdating] = useState<boolean>(false);

  const {
    data: authorizationConfigs = [],
    isFetching,
    isLoading,
  } = useGetAuthConfigsQuery();

  const [updateAuthConfigs, { isLoading: isLoadingSettingsMutation }] =
    useUpdateAuthConfigsMutation();

  const { data: actionsData } = useGetActionBuilderActionsQuery({});
  const { data } = useGetActionUsagesQuery();

  const isActionActive = (action: Action) => {
    if (action.action_id.endsWith('template')) {
      return true;
    }

    return (data?.action_id_to_intent_ids[action.action_id] ?? []).length > 0;
  };

  const handleSubmit = (values: { authorizationConfigs: KVPairs[] }) => {
    const actionAuthorizationIds = actionsData?.actions
      ?.filter(action => isActionActive(action))
      ?.map(action => {
        const fields = action.action_fields;
        return 'authorization_config_id' in fields
          ? fields.authorization_config_id
          : null;
      })
      ?.filter(id => id);

    const modifiedConfigs = values.authorizationConfigs.filter(
      (currentConfig, index) => {
        const previousConfig = authorizationConfigs[index];
        return (
          currentConfig.key !== previousConfig?.authorization_id ||
          currentConfig.value !==
            previousConfig?.authorization_fields?.api_token
        );
      },
    );

    const removedConfigs = authorizationConfigs.filter(
      oldConfig =>
        !values.authorizationConfigs.some(
          currentConfig => currentConfig.key === oldConfig.authorization_id,
        ),
    );

    const hasInvalidModificationOrRemoval =
      modifiedConfigs.some(config =>
        actionAuthorizationIds?.includes(config.key),
      ) ||
      removedConfigs.some(config =>
        actionAuthorizationIds?.includes(config.authorization_id),
      );

    if (hasInvalidModificationOrRemoval) {
      setIsErrorUpdating(true);
      return;
    }

    updateAuthConfigs(
      values.authorizationConfigs.map(KVPairsToAuthorizationConfig),
    );
  };

  return (
    <BaseModal
      headerTitle={<Typography variant='font16Bold'>API tokens</Typography>}
      isOpen={isOpen}
      maxWidth='sm'
      onClose={onClose}
    >
      {isLoading ? (
        <Spinner />
      ) : (
        <Formik
          initialValues={{
            authorizationConfigs: authorizationConfigs.map(
              authorizationConfigToKVPairs,
            ),
          }}
          onSubmit={handleSubmit}
          validationSchema={settingsSchema}
        >
          {({ isValid }) => {
            return (
              <Box
                component={Form}
                display='flex'
                flexDirection='column'
                justifyContent='space-between'
                minHeight='500px'
              >
                <SettingsForm />
                <Box margin={5}>
                  {isErrorUpdating && (
                    <Alert
                      onClose={() => {
                        setIsErrorUpdating(false);
                      }}
                      title='Cannot update or remove Auth that are currently in use in a workflow.'
                      variant='danger'
                    />
                  )}
                </Box>
                <Box display='flex' justifyContent='flex-end' p={3}>
                  <Button
                    disabled={
                      !isValid || isLoadingSettingsMutation || isFetching
                    }
                    isLoading={isLoadingSettingsMutation || isFetching}
                    type='submit'
                    variant='main'
                  >
                    Save
                  </Button>
                </Box>
              </Box>
            );
          }}
        </Formik>
      )}
    </BaseModal>
  );
};

const SettingsForm = () => {
  const [{ value }] = useField<KVPairs[]>('authorizationConfigs');
  const { palette } = useTheme();

  return (
    <Box mt={2} px={3}>
      <Box color={palette.colors.grey[700]} display='flex' gap={2} mb={1}>
        <Box flex={0.175}>
          <Typography variant='font14Bold'>Type</Typography>
        </Box>
        <Box flex={0.88}>
          <Typography variant='font14Bold'>Name</Typography>
        </Box>
        <Box flex={0.9}>
          <Typography variant='font14Bold'>Value</Typography>
        </Box>
      </Box>
      <FieldArray
        name='authorizationConfigs'
        render={arrayHelpers => (
          <>
            {value.map((_, index) => (
              <Row
                index={index}
                key={index}
                onRemove={() => {
                  arrayHelpers.remove(index);
                }}
              />
            ))}
            <Button
              onClick={() => arrayHelpers.push({ key: '', value: undefined })}
              startIcon={<IconPlus />}
              variant='ghost'
            >
              Add
            </Button>
          </>
        )}
      />
    </Box>
  );
};

interface RowProps {
  index: number;
  onRemove: () => void;
}

const Row = ({ index, onRemove }: RowProps) => {
  const [keyField, , keyCallbacks] = useField<string>(
    `authorizationConfigs.${index}.key`,
  );
  const [valueField, , valueCallbacks] = useField<string | undefined>(
    `authorizationConfigs.${index}.value`,
  );
  const { palette } = useTheme();

  return (
    <Box alignItems='center' display='flex' gap={2} mb={2}>
      <Typography variant='font14Bold'>Bearer</Typography>
      <Box flex={1}>
        <TextField
          aria-label={`Token name ${index}`}
          name={`authorizationConfigs.${index}.key`}
          onChange={e => keyCallbacks.setValue(e.target.value)}
          placeholder='Enter token name'
          value={keyField.value}
        />
      </Box>
      <Box alignItems='center' display='flex'>
        <IconEqual color={palette.colors.slate[600]} />
      </Box>
      <Box flex={1}>
        <TextField
          aria-label={`Token value ${index}`}
          name={`authorizationConfigs.${index}.value`}
          onChange={e => valueCallbacks.setValue(e.target.value)}
          placeholder='Enter value'
          value={valueField.value ?? ''}
        />
      </Box>
      <IconButton
        aria-label='Remove Authorization'
        onClick={onRemove}
        variant='ghost'
      >
        <IconX size={20} />
      </IconButton>
    </Box>
  );
};

export default ActionSettingsModal;
