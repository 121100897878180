import { Box, Palette } from '@mui/material';

import BarGraph from './BarGraph';
import { TEMP_BLUE } from 'src/constants/solve';
import {
  BarGraphResponse,
  CommonCsatResponse,
  QuickFeedbackResponse,
  TopicsEngagementResponse,
  TopicsRelevanceResponse,
} from 'src/services/insights/types';

export const BarGraphWrapper = ({
  data,
  isComparing = false,
  palette,
  showTooltip = false,
}: {
  data:
    | BarGraphResponse
    | CommonCsatResponse
    | TopicsRelevanceResponse
    | TopicsEngagementResponse
    | QuickFeedbackResponse;
  isComparing?: boolean;
  palette: Palette;
  showTooltip?: boolean;
}) => {
  const { bar, type } = data;
  let colors;
  switch (type) {
    case 'insights_csat_articles':
      colors = [
        palette.colors.purple[600],
        palette.colors.purple[500],
        palette.colors.purple[400],
        palette.colors.purple[300],
        palette.colors.purple[200],
      ];
      if (bar) {
        return (
          <Box
            sx={{
              '* > path': {
                clipPath: 'inset(0% 0% 1px 0% round 6px)',
              },
            }}
          >
            <BarGraph
              colors={colors}
              containerProps={{
                style: {
                  height: '160px',
                  width: '300px',
                },
              }}
              data={bar}
              disableTooltip={!showTooltip}
              disableYAxisLabel
              disableYGridLines
              horizontal
              showDataLabel
            />
          </Box>
        );
      }
    case 'insights_csat_common':
      colors = [
        palette.colors.purple[600],
        palette.colors.purple[500],
        palette.colors.purple[400],
        palette.colors.purple[300],
        palette.colors.purple[200],
      ];
      if (bar) {
        return (
          <Box
            sx={{
              '* > path': {
                clipPath: 'inset(0% 0% 1px 0% round 6px)',
              },
            }}
          >
            <BarGraph
              colors={colors}
              containerProps={{
                style: {
                  height: isComparing ? '220px' : '160px',
                  width: '280px',
                },
              }}
              data={bar}
              disableTooltip={!showTooltip}
              disableYAxisLabel
              disableYGridLines
              horizontal
              isComparing={isComparing}
              showDataLabel
            />
          </Box>
        );
      }
    case 'insights_workflow_csat':
      colors = [
        palette.colors.purple[600],
        palette.colors.purple[500],
        palette.colors.purple[400],
        palette.colors.purple[300],
        palette.colors.purple[200],
      ];
      if (bar) {
        return (
          <Box
            sx={{
              '* > path': {
                clipPath: 'inset(0% 0% 1px 0% round 6px)',
              },
            }}
          >
            <BarGraph
              colors={colors}
              containerProps={{
                style: {
                  height: isComparing ? '220px' : '160px',
                  width: '280px',
                },
              }}
              data={bar}
              disableTooltip={!showTooltip}
              disableYAxisLabel
              disableYGridLines
              horizontal
              isComparing={isComparing}
              showDataLabel
            />
          </Box>
        );
      }
    case 'insights_overall_relevance':
      colors = [TEMP_BLUE, palette.colors.blue[400], palette.colors.red[500]];
      if (bar) {
        return (
          <Box
            sx={{
              '* > path': {
                clipPath: 'inset(0% 0% 1px 0% round 6px)',
              },
            }}
          >
            <BarGraph
              colors={colors}
              containerProps={{
                style: {
                  height: isComparing ? '150px' : '160px',
                  width: isComparing ? '100%' : '280px',
                },
              }}
              data={bar}
              disableTooltip={!showTooltip}
              disableYAxisLabel
              disableYGridLines
              horizontal
              isComparing={isComparing}
              showDataLabel
            />
          </Box>
        );
      }
    case 'insights_overall_engagement':
      colors = [TEMP_BLUE, palette.colors.red[500]];
      if (bar) {
        return (
          <Box
            sx={{
              '* > path': {
                clipPath: 'inset(0% 0% 1px 0% round 6px)',
              },
            }}
          >
            <BarGraph
              colors={colors}
              containerProps={{
                style: {
                  height: isComparing ? '100px' : '160px',
                  width: isComparing ? '100%' : '280px',
                },
              }}
              data={bar}
              disableTooltip={!showTooltip}
              disableYAxisLabel
              disableYGridLines
              horizontal
              isComparing={isComparing}
              showDataLabel
            />
          </Box>
        );
      }
    // TODO - Remove insights_quick_feedback once cleaned up in BE
    case 'insights_quick_feedback':
    case 'insights_feedback':
      colors = [
        palette.colors.green[500],
        palette.colors.red[500],
        palette.colors.slate[100],
      ];

      if (bar) {
        return (
          <Box
            sx={{
              '* > path': {
                clipPath: 'inset(0% 0% 1px 0% round 6px)',
              },
            }}
          >
            <BarGraph
              colors={colors}
              containerProps={{
                style: {
                  height: isComparing ? '110px' : '160px',
                },
              }}
              data={bar}
              disableTooltip={!showTooltip}
              disableYAxisLabel
              disableYGridLines
              horizontal
              isComparing={isComparing}
              showDataLabel
            />
          </Box>
        );
      }

    case 'overview_ka_bar_chart_insight':
      colors = [palette.colors.purple[500]];
      if (bar) {
        return (
          <Box
            sx={{
              '* > path': {
                clipPath: 'inset(0% 0% 1px 0% round 6px)',
              },
            }}
          >
            <BarGraph
              colors={colors}
              containerProps={{
                style: {
                  height: '300px',
                  minWidth: '340px',
                  width: '100%',
                },
              }}
              data={bar}
            />
          </Box>
        );
      }
    case 'overview_csat_bar_chart_insight':
      colors = [palette.colors.purple[500]];
      if (bar) {
        return (
          <Box
            sx={{
              '* > path': {
                clipPath: 'inset(0% 0% 1px 0% round 6px)',
              },
            }}
          >
            <BarGraph
              colors={colors}
              containerProps={{
                style: {
                  height: '300px',
                  minWidth: '340px',
                  width: '100%',
                },
              }}
              data={bar}
            />
          </Box>
        );
      }
    case 'overview_quick_feedback_bar_chart_insight':
      colors = [
        palette.colors.green[500],
        palette.colors.red[500],
        palette.colors.slate[100],
      ];
      if (bar) {
        return (
          <Box
            sx={{
              '* > path': {
                clipPath: 'inset(0% 0% 1px 0% round 6px)',
              },
            }}
          >
            <BarGraph
              colors={colors}
              containerProps={{
                style: {
                  height: '300px',
                  minWidth: '340px',
                  width: '100%',
                },
              }}
              data={bar}
              isStacked
              showLegend
            />
          </Box>
        );
      }
    case 'overview_relevance_bar_chart_insight':
      colors = [TEMP_BLUE, palette.colors.blue[400], palette.colors.red[500]];
      if (bar) {
        return (
          <Box
            sx={{
              '* > path': {
                clipPath: 'inset(0% 0% 1px 0% round 6px)',
              },
            }}
          >
            <BarGraph
              colors={colors}
              containerProps={{
                style: {
                  height: '300px',
                  minWidth: '340px',
                  width: '100%',
                },
              }}
              data={bar}
              isStacked
              showLegend
            />
          </Box>
        );
      }
    case 'overview_engagement_bar_chart_insight':
      colors = [TEMP_BLUE, palette.colors.red[500]];
      if (bar) {
        return (
          <Box
            sx={{
              '* > path': {
                clipPath: 'inset(0% 0% 1px 0% round 6px)',
              },
            }}
          >
            <BarGraph
              colors={colors}
              containerProps={{
                style: {
                  height: '300px',
                  minWidth: '340px',
                  width: '100%',
                },
              }}
              data={bar}
              isStacked
              showLegend
            />
          </Box>
        );
      }
    case 'overview_deflected_rate_bar_chart_insight':
      colors = [palette.colors.purple[500]];
      if (bar) {
        return (
          <Box
            sx={{
              '* > path': {
                clipPath: 'inset(0% 0% 1px 0% round 6px)',
              },
            }}
          >
            <BarGraph
              colors={colors}
              containerProps={{
                style: {
                  height: '300px',
                  minWidth: '340px',
                  width: '100%',
                },
              }}
              data={bar}
            />
          </Box>
        );
      }
    case 'overview_deflected_count_bar_chart_insight':
      colors = [palette.colors.purple[500], palette.colors.red[500]];
      if (bar) {
        return (
          <Box
            sx={{
              '* > path': {
                clipPath: 'inset(0% 0% 1px 0% round 6px)',
              },
            }}
          >
            <BarGraph
              colors={colors}
              containerProps={{
                style: {
                  height: '300px',
                  minWidth: '340px',
                  width: '100%',
                },
              }}
              data={bar}
              isStacked
              showLegend
            />
          </Box>
        );
      }

    default:
      return <Box>Not Found</Box>;
  }
};
