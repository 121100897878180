import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Menu, MenuItem } from '@mui/material';
import { IconDotsVertical } from '@tabler/icons-react';

import {
  IconButton,
  Tooltip,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { CANT_PUBLISH_ERROR } from '../triage-model-detail-page/constants';
import DeleteVersionDialog from './DeleteVersionDialog';
import DuplicateVersionWrapper from './DuplicateVersionWrapper';
import PublishVersionDialog from './PublishVersionDialog';
import RenameVersionDialog from './RenameVersionDialog';
import { useModelValidityToast } from 'src/components/triage-label-mapping-drawer/hooks';
import useSelfServeEvents from 'src/hooks/triage/useSelfServeEvents';
import { VersionedTriageModel } from 'src/reducers/triageSettingsReducer/types';
import { useGetModelVersionsQuery } from 'src/services/triage/triageApi';
import { TRIAGE_LLM_TRACKING_EVENTS } from 'src/utils/constants';
import { Routes } from 'src/utils/enums';

interface VersionControlMenuProps {
  item: VersionedTriageModel;
  setLabelMappingIsOpen: (value: boolean) => void;
}

const VersionControlMenu = ({
  item,
  setLabelMappingIsOpen,
}: VersionControlMenuProps) => {
  const emitTrackingEventCallback = useSelfServeEvents({
    model: item,
  });
  const { refetch } = useGetModelVersionsQuery(item.model_id);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [deleteIsOpen, setDeleteIsOpen] = useState(false);
  const [renameIsOpen, setRenameIsOpen] = useState(false);
  const [publishIsOpen, setPublishIsOpen] = useState(false);
  const [duplicateIsOpen, setDuplicateIsOpen] = useState(false);

  const { checkValidity } = useModelValidityToast(item, isValid => {
    if (isValid) {
      setPublishIsOpen(true);
      emitTrackingEventCallback(
        TRIAGE_LLM_TRACKING_EVENTS.PUBLISH_VERSION_CLICKED,
      );
    } else {
      setLabelMappingIsOpen(true);
    }
  });

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const hasOutputField = item.model.outputs.length > 0;

  const publishDisabled = item.labels.length < 2 || !hasOutputField;

  const handleRenameSuccess = () => {
    setRenameIsOpen(false);
    refetch();
  };

  const navigate = useNavigate();
  const handleDeleteSuccess = (versionId: string) => {
    if (versionId !== item.version_id) {
      return;
    }
    refetch()
      .unwrap()
      .then(refetchData => {
        const data = refetchData.filter(item => item.version_id !== versionId);
        navigate(
          `${Routes.PREDICTIONS_SETTINGS}/version-control/${item.model_id}?version=${data[0].version_id}`,
        );
      });
  };

  return (
    <>
      <IconButton aria-label='More' onClick={handleClick} variant='ghost'>
        <IconDotsVertical />
      </IconButton>
      <Menu anchorEl={anchorEl} onClose={handleClose} open={open}>
        {!item.is_published && (
          <Tooltip tooltipContent={publishDisabled && CANT_PUBLISH_ERROR}>
            <MenuItem
              disabled={publishDisabled}
              onClick={() => {
                handleClose();
                checkValidity();
              }}
            >
              <Typography variant='font14'>Publish this version</Typography>
            </MenuItem>
          </Tooltip>
        )}
        <MenuItem
          onClick={() => {
            handleClose();
            setDuplicateIsOpen(true);
            emitTrackingEventCallback(
              TRIAGE_LLM_TRACKING_EVENTS.DUPLICATE_VERSION_CLICKED,
            );
          }}
        >
          <Typography variant='font14'>Duplicate to edit</Typography>
        </MenuItem>
        {!item.is_published && (
          <MenuItem
            onClick={() => {
              handleClose();
              emitTrackingEventCallback(
                TRIAGE_LLM_TRACKING_EVENTS.DELETE_VERSION_CLICKED,
              );
              setDeleteIsOpen(true);
            }}
          >
            <Typography variant='font14'>Delete</Typography>
          </MenuItem>
        )}
        <MenuItem
          onClick={() => {
            handleClose();
            setRenameIsOpen(true);
          }}
        >
          <Typography variant='font14'>Rename</Typography>
        </MenuItem>
      </Menu>
      <DeleteVersionDialog
        isOpen={deleteIsOpen}
        model={item}
        onClose={() => setDeleteIsOpen(false)}
        onSuccess={handleDeleteSuccess}
      />
      <PublishVersionDialog
        isOpen={publishIsOpen}
        model={item}
        onClose={() => setPublishIsOpen(false)}
      />
      <DuplicateVersionWrapper
        isOpen={duplicateIsOpen}
        model={item}
        onClose={() => setDuplicateIsOpen(false)}
      />
      <RenameVersionDialog
        isOpen={renameIsOpen}
        model={item}
        onClose={() => setRenameIsOpen(false)}
        onSuccess={handleRenameSuccess}
      />
    </>
  );
};

export default VersionControlMenu;
