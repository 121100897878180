import { useState } from 'react';
import Box from '@mui/material/Box';

import {
  INITIAL_SEARCH_TYPE,
  PARAM_SEARCH_NAME,
  PARAM_SEARCH_TYPE,
} from '../triage-model-detail-page/tickets-tab/constants';
import EmptyTicketsTable from '../triage-model-detail-page/tickets-tab/EmptyTicketsTable';
import { clientFilter } from '../triage-model-detail-page/tickets-tab/helpers';
import NotFound from '../triage-version-control-detail/NotFound';
import SimulationLabelsOverview from './SimulationLabelsOverview';
import SimulationTicketsTable from './SimulationTicketsTable';
import Spinner from 'src/components/spinner';
import { useStateParams } from 'src/hooks/hooks';
import { VersionedTriageModel } from 'src/reducers/triageSettingsReducer/types';
import { useGetPredictionTestResultsQuery } from 'src/services/triage/triageApi';

const SimulationTicketsTab = ({ model }: { model: VersionedTriageModel }) => {
  const predictionTestId = window.location.pathname.split('/')[2];

  const [filters, setFilters] = useState<string[]>([]);

  const [searchType, setSearchType] = useStateParams({
    deserialize: item => item,
    initialState: INITIAL_SEARCH_TYPE,
    paramsName: PARAM_SEARCH_TYPE,
    serialize: item => item,
  });
  const [searchText, setSearchText] = useStateParams({
    deserialize: item => item,
    initialState: '',
    paramsName: PARAM_SEARCH_NAME,
    serialize: item => item,
  });
  const { data, isLoading } = useGetPredictionTestResultsQuery(
    predictionTestId as string,
  );

  const handleSearch = async (text: string) => {
    setSearchText(text);
  };

  const handleSearchTypeChange = (value: string) => {
    setSearchType(value, () => setSearchText(''));
  };
  const handleFilterChange = async (values: string[]) => {
    setFilters(values);
  };

  const noTickets = !data?.tickets_metrics.length;
  const grid =
    noTickets && !isLoading ? 'auto 110px auto' : 'auto minmax(350px, auto)';
  const ticketsData = clientFilter(
    searchText,
    searchType,
    filters,
    data?.tickets_metrics ?? [],
  );

  if (isLoading) {
    return <Spinner />;
  }

  if (!data?.label_overall_metrics) {
    return <NotFound />;
  }

  return (
    <Box display='grid' gridTemplateRows={grid} rowGap={5}>
      {/* TODO - Replace this when we use a different overview for ticket section */}
      <SimulationLabelsOverview data={data.label_overall_metrics} />
      <SimulationTicketsTable
        data={ticketsData}
        filters={filters}
        isDataLoading={isLoading}
        model={model}
        onFilterChange={handleFilterChange}
        onSearch={handleSearch}
        onSearchTypeChange={handleSearchTypeChange}
        searchText={searchText}
        searchType={searchType}
      />
      {noTickets && !isLoading && <EmptyTicketsTable />}
    </Box>
  );
};

export default SimulationTicketsTab;
