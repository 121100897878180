import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';

import { Typography } from '@forethought-technologies/forethought-elements';

const ConfigureInstructions = ({
  openLabelMapping,
}: {
  openLabelMapping: () => void;
}) => {
  const { palette } = useTheme();
  const grey = palette.colors.grey[700];

  return (
    <Box display='flex' flexDirection='column' maxWidth='548px' rowGap={3}>
      <Box display='flex' flexDirection='column' rowGap={0.5}>
        <Typography variant='font20'>Triage configuration guide</Typography>
        <Typography color={grey} variant='font14'>
          After creating labels in your triage model, follow these steps to
          ensure a successful publication.
        </Typography>
      </Box>
      <Box display='flex' flexDirection='column' rowGap={0.5}>
        <Typography variant='font14Bold'>
          1. Select {'‘Output Field’'}
        </Typography>
        <Typography color={grey} variant='font14'>
          On this page, you will specify where to write the labels in your
          helpdesk. This is done by selecting options from the &quot;Output
          Field.&quot; Once you have identified the Output field, you will map
          the values of your tags from your helpdesk&apos;s output field.
        </Typography>
      </Box>

      <Box display='flex' flexDirection='column' rowGap={0.5}>
        <Typography variant='font14Bold'>
          2. Update {'‘Ground Truth Field’'} as needed
        </Typography>
        <Typography color={grey} variant='font14'>
          The Ground Truth Field represents the source of truth from your
          support ticket, used to measure accuracy. It is compared with the
          value in the {'"Output Field"'} to evaluate how well predictions align
          with the actual data.
        </Typography>
      </Box>

      <Box display='flex' flexDirection='column' rowGap={0.5}>
        <Typography variant='font14Bold'>3. Mapping values</Typography>
        <Typography color={grey} variant='font14'>
          Accurate mapping is essential for your triage model to function
          effectively. You can easily manage these mappings in the{' '}
          <Box
            component='span'
            onClick={openLabelMapping}
            sx={{ color: palette.colors.purple[500], cursor: 'pointer' }}
          >
            {'‘Mapping’ '}
          </Box>
          section.
        </Typography>
      </Box>

      <Box display='flex' flexDirection='column' rowGap={0.5}>
        <Typography variant='font14Bold'>4. Publish model</Typography>
        <Typography color={grey} variant='font14'>
          After completing all required mappings, you can publish your model.
        </Typography>
      </Box>
    </Box>
  );
};

export default ConfigureInstructions;
