import { labelInfoMap } from '../constants';
import { ModelLabelKey } from '../types';

export const LABELS_TABLE_COLUMNS: {
  key: ModelLabelKey;
  size?: number;
  title: string;
  tooltip: string | null;
}[] = [
  {
    key: 'name',
    size: 300,
    title: 'Labels',
    tooltip: null,
  },
  {
    key: 'description',
    size: 400,
    title: 'Description',
    tooltip: null,
  },
  {
    key: 'prediction_count',
    size: 240,
    title: labelInfoMap.prediction_count.key,
    tooltip: labelInfoMap.prediction_count.tooltip,
  },
  {
    key: 'pending_count',
    size: 240,
    title: labelInfoMap.pending_count.key,
    tooltip: labelInfoMap.pending_count.tooltip,
  },
  {
    key: 'finalized_count',
    size: 240,
    title: labelInfoMap.finalized_count.key,
    tooltip: labelInfoMap.finalized_count.tooltip,
  },
  {
    key: 'correct_count',
    size: 300,
    title: labelInfoMap.correct_count.key,
    tooltip: labelInfoMap.correct_count.tooltip,
  },
  {
    key: 'accuracy',
    size: 240,
    title: labelInfoMap.avg_accuracy.key,
    tooltip: labelInfoMap.avg_accuracy.tooltip,
  },
  {
    key: 'last_update_at',
    size: 200,
    title: 'Last update',
    tooltip: null,
  },
];

export const LABEL_FILTER_OPTIONS = [
  {
    label: 'All coverage',
    options: [
      {
        label: 'Above 80% coverage',
        value: 'above_80',
      },
      {
        label: 'Below 80% coverage',
        value: 'below_80',
      },
    ],
    value: 'all_coverage',
  },
  {
    label: 'All accuracy',
    options: [
      {
        label: 'Pending',
        value: 'pending',
      },
      {
        label: 'Above 80% accuracy',
        value: 'above_80',
      },
      {
        label: 'Below 80% accuracy',
        value: 'below_80',
      },
    ],
    value: 'all_accuracy',
  },
];

export const PERCENTAGE_COLUMNS: Array<Partial<ModelLabelKey>> = [
  'accuracy',
  'coverage',
];
