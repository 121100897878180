import React, { ChangeEvent, useMemo, useState } from 'react';
import { ReactSVG } from 'react-svg';
import { StyledComponent } from '@emotion/styled';
import { styled } from '@mui/material';
import { Box, SelectChangeEvent } from '@mui/material';
import { IconPlus } from '@tabler/icons-react';

import {
  Button,
  Checkbox,
  IconButton,
  MultiStringInput,
  SelectDropdown,
  TextField,
  theme,
  Tooltip,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { CreateTicketFieldMappingRequest } from '../types';
import {
  getContextVariableType,
  getDefaultOperatorByType,
  getDefaultVaulesByType,
  getTriageModelName,
  isValuelessOperator,
} from './conditionEditorHelpers';
import {
  checkboxTypeOperators,
  checkboxTypeValueOptions,
  FILLED_CONDITION_ERR_MSG,
  listTypeOperators,
  multiOptionsTypeOperators,
  multiSelectListTypeOperators,
  numberTypeOperators,
  operators,
} from './constants';
import pullAt from 'lodash/fp/pullAt';
import set from 'lodash/fp/set';
import deleteIcon from 'src/assets/images/delete-icon.svg';
import TriageSampleValuesDropdown from 'src/components/triage-sample-values-dropdown/TriageSampleValuesDropdown';
import {
  CompositeCondition,
  CompositeConditionsConfig,
  ConditionOperators,
  OtherwiseCondition,
} from 'src/pages/workflow-builder-edit/types/canvasComponentTypes';
import {
  emptyCondition,
  emptyConditionStepFields,
} from 'src/slices/canvas-workflow-builder/utils';
import { ContextVariable } from 'src/types/actionBuilderApiTypes';
import { ContextVariableTypeKeys } from 'src/utils/enums';

interface ConditionEditorProps {
  /** Allow the deletion of conditions even if they already have a step tied to them */
  allowConditionDeletion?: boolean;
  /** Conditions to display in the editor */
  compositeConditionsConfig?: CompositeConditionsConfig;
  /** Context variables to be displayed as options to create a condition */
  contextVariables: ContextVariable[];
  /** This value will indicate if an error outside the editor has happened, if true, will disable the save button */
  hasWorkflowConflict: boolean;
  /** Indicates if the otherwise checkbox should be hidden */
  hideOtherwise?: boolean;
  /** Minimun number of conditions supported */
  minConditionCount?: number;
  /** Callback function executed after the cancel button is clicked */
  onCancel: () => void;
  /** Callback function executed after the save button is clicked */
  onSave: (compositeConditionsConfig: CompositeConditionsConfig) => void;
  /** List of ticket field mappings */
  ticketFieldMappings?: Array<CreateTicketFieldMappingRequest>;
  /** List of context variables that have not been defined yet */
  undefinedCvs: string[];
}

export const ConditionEditor: React.FC<
  React.PropsWithChildren<ConditionEditorProps>
> = ({
  allowConditionDeletion = false,
  compositeConditionsConfig = emptyConditionStepFields,
  contextVariables,
  hasWorkflowConflict,
  hideOtherwise = false,
  minConditionCount = 2,
  onCancel,
  onSave,
  ticketFieldMappings = [],
  undefinedCvs,
}) => {
  // States
  const [newConditionName, setNewConditionName] = useState(
    compositeConditionsConfig.conditionName || '',
  );
  const [newCompositeConditions, setNewCompositeConditions] = useState<
    CompositeCondition[]
  >(compositeConditionsConfig.compositeConditions);
  const [newOtherwiseCondition, setNewOtherwiseCondition] =
    useState<OtherwiseCondition>(compositeConditionsConfig.otherwiseCondition);
  const [areErrorsVisible, setAreErrorsVisible] = useState(false);

  // consts
  const INSUFFICIENT_CONDITIONS_ERR_MSG = `Condition step requires at least ${minConditionCount} condition${
    minConditionCount > 1 ? 's' : ''
  }`;
  const otherwiseConditionLength = newOtherwiseCondition.isOtherwiseSelected
    ? 1
    : 0;
  // we only want to display the error msg in tooltip when the otherwise checkbox is checked
  // Then, first check if there's only <= minConditionCount branches, after that check whether the branch is already
  // filled with children step
  const otherwiseErrorMsg = newOtherwiseCondition.isOtherwiseSelected
    ? newCompositeConditions.length + otherwiseConditionLength <=
      minConditionCount
      ? INSUFFICIENT_CONDITIONS_ERR_MSG
      : newOtherwiseCondition.stepId
      ? FILLED_CONDITION_ERR_MSG
      : null
    : null;

  // components
  const otherwiseCheckbox = useMemo(() => {
    if (hideOtherwise) {
      return null;
    }

    const checkbox = (
      <Checkbox
        checked={newOtherwiseCondition.isOtherwiseSelected}
        disabled={Boolean(otherwiseErrorMsg)}
        label={
          <Typography variant='font14'>
            Add an <b>“Otherwise”</b> condition to capture all other cases.
          </Typography>
        }
        onChange={() => {
          setNewOtherwiseCondition(prevValue => ({
            ...prevValue,
            isOtherwiseSelected: !prevValue.isOtherwiseSelected,
          }));
        }}
      />
    );

    return otherwiseErrorMsg === null ? (
      checkbox
    ) : (
      <Tooltip tooltipContent={otherwiseErrorMsg}>{checkbox}</Tooltip>
    );
  }, [
    hideOtherwise,
    newOtherwiseCondition.isOtherwiseSelected,
    otherwiseErrorMsg,
  ]);

  // handlers
  function handleConditionNameChange(event: ChangeEvent<HTMLInputElement>) {
    setNewConditionName(event.target.value);
  }

  function handleAddConditionClick() {
    setNewCompositeConditions([
      ...newCompositeConditions,
      { conditions: [emptyCondition] },
    ]);
  }

  function handleAddCompositeConditionLogicClick(
    outerIndex: number,
    numOfConditions: number,
  ) {
    const compositeConditionToChange = newCompositeConditions[outerIndex];
    const addedEmptyCondition = set(
      [outerIndex, 'conditions'],
      [...compositeConditionToChange.conditions, emptyCondition],
      newCompositeConditions,
    );

    if (numOfConditions === 1) {
      const addedBooleanOperator = set(
        [outerIndex, 'booleanOperator'],
        'and',
        addedEmptyCondition,
      );

      setNewCompositeConditions(addedBooleanOperator);
    } else {
      setNewCompositeConditions(addedEmptyCondition);
    }
  }

  function handleDeleteConditionClick(
    outerIndex: number,
    index: number,
    isDeletingCompositeCondition: boolean,
  ) {
    if (isDeletingCompositeCondition) {
      setNewCompositeConditions(pullAt([outerIndex]));
    } else {
      const compositeConditionToChange = newCompositeConditions[outerIndex];
      const updatedCompositeConditions = set(
        [outerIndex, 'conditions'],
        pullAt([index], compositeConditionToChange.conditions),
        newCompositeConditions,
      );
      setNewCompositeConditions(updatedCompositeConditions);
    }
  }

  function handleContextVariableChange(
    outerIndex: number,
    index: number,
    event: SelectChangeEvent<string | string[]>,
  ) {
    const contextVariable = String(event?.target.value || '');

    const operator = getDefaultOperatorByType(
      contextVariable,
      contextVariables,
    );

    const defaultValue = getDefaultVaulesByType(
      contextVariable,
      contextVariables,
    );

    const compositeConditionToChange = newCompositeConditions[outerIndex];

    const updatedCondition = {
      ...compositeConditionToChange.conditions[index],
      contextVariable,
      operator,
      value: defaultValue,
    };
    const updatedCompositeConditions = set(
      [outerIndex, 'conditions', index],
      updatedCondition,
      newCompositeConditions,
    );

    setNewCompositeConditions(updatedCompositeConditions);
  }

  function handleBooleanOperatorChange(
    outerIndex: number,
    event: SelectChangeEvent<string | string[]>,
  ) {
    setNewCompositeConditions(
      set(
        [outerIndex, 'booleanOperator'],
        event?.target.value,
        newCompositeConditions,
      ),
    );
  }

  function handleOperatorChange(
    outerIndex: number,
    index: number,
    event: SelectChangeEvent<string | string[]>,
  ) {
    const contextVariable =
      newCompositeConditions[outerIndex].conditions[index].contextVariable;
    const defaultValue = getDefaultVaulesByType(
      contextVariable,
      contextVariables,
    );

    const updatedCondition = {
      ...newCompositeConditions[outerIndex].conditions[index],
      operator: event?.target.value,
      value: isValuelessOperator(event?.target.value as ConditionOperators)
        ? ''
        : defaultValue,
    };
    const updatedCompositeConditions = set(
      [outerIndex, 'conditions', index],
      updatedCondition,
      newCompositeConditions,
    );
    setNewCompositeConditions(updatedCompositeConditions);
  }

  function handleValueChange(
    outerIndex: number,
    index: number,
    value: string | boolean | number | string[],
  ) {
    setNewCompositeConditions(
      set(
        [outerIndex, 'conditions', index, 'value'],
        value,
        newCompositeConditions,
      ),
    );
  }

  function isContextVarCheckboxType(contextVariableId: string) {
    const cvType = getContextVariableType(contextVariableId, contextVariables);
    return cvType === 'CHECKBOX';
  }

  function getOperatorDropdownOptions(contextVarType: string) {
    const isCvTypeCheckbox = contextVarType === 'CHECKBOX';
    const isNumberType = contextVarType === 'NUMBER';
    const isListType = contextVarType === 'LIST';
    const isMultiOptionsType = contextVarType === 'MULTIOPTIONS';
    const isMultiSelectListType = contextVarType === 'MULTI_SELECT_LIST';

    if (isCvTypeCheckbox) {
      return checkboxTypeOperators;
    }
    if (isNumberType) {
      return numberTypeOperators;
    }
    if (isListType) {
      return listTypeOperators;
    }
    if (isMultiOptionsType) {
      return multiOptionsTypeOperators;
    }
    if (isMultiSelectListType) {
      return multiSelectListTypeOperators;
    }
    return operators;
  }

  function getDeleteButtonErrMessage(compositeCondition: CompositeCondition) {
    if (compositeCondition.conditions.length > 1) {
      return null; // Always allow delete button with > 1 nested conditions
    }

    // Prevent deletion of last step for cases when minConditionCount is 1 and otherwise is selected,
    // and ensure that minConditionCount is met with or without otherwise step
    if (
      newCompositeConditions.length === 1 ||
      newCompositeConditions.length + otherwiseConditionLength <=
        minConditionCount
    ) {
      return INSUFFICIENT_CONDITIONS_ERR_MSG;
    }

    // check whether the branch is already filled with children step
    if (!allowConditionDeletion && Boolean(compositeCondition.stepId)) {
      return FILLED_CONDITION_ERR_MSG;
    }

    return null;
  }

  const trimmedNewCompositeConditions: CompositeCondition[] =
    newCompositeConditions.map(newCompositeCondition => {
      return {
        ...newCompositeCondition,
        conditions: newCompositeCondition.conditions.map(newCondition => {
          const isCvTypeCheckbox = isContextVarCheckboxType(
            newCondition.contextVariable,
          );

          const cvType = getContextVariableType(
            newCondition.contextVariable,
            contextVariables,
          );

          const isCvTypeNumber = cvType === 'NUMBER';
          const isCvTypeMultiOptions = cvType === 'MULTIOPTIONS';
          const isCvTypeMultiSelectList = cvType === 'MULTI_SELECT_LIST';

          if (
            isCvTypeCheckbox ||
            isCvTypeNumber ||
            isCvTypeMultiOptions ||
            isCvTypeMultiSelectList
          ) {
            return newCondition;
          }

          return {
            ...newCondition,
            value: String(newCondition.value).trim(),
          };
        }),
      };
    });

  function areConditionsIncomplete(): boolean {
    return (
      newConditionName.trim() === '' ||
      trimmedNewCompositeConditions.some(compositeCondition =>
        compositeCondition.conditions.some(condition => {
          if (!condition.contextVariable) {
            return true;
          }

          if (isValuelessOperator(condition.operator)) {
            return false;
          }

          if (Array.isArray(condition.value)) {
            return condition.value.length === 0;
          }

          return condition.value === '';
        }),
      )
    );
  }

  const isSaveButtonDisabled =
    newConditionName.trim() === '' || hasWorkflowConflict;

  const onChangeValueHandler = (
    value: string | string[],
    cvType: string,
    outerIndex: number,
    index: number,
  ) => {
    let receivedValue: string | string[] | number | boolean = value;

    switch (cvType) {
      case ContextVariableTypeKeys.NUMBER: {
        receivedValue = Number(value);
        break;
      }
      case ContextVariableTypeKeys.CHECKBOX: {
        const isTrueSet = value === 'true';
        receivedValue = isTrueSet;
        break;
      }
    }

    handleValueChange(outerIndex, index, receivedValue);
  };

  return (
    <StyledForm aria-label='Condition editor'>
      <div>
        <Section>
          <Typography variant='font14'>Name</Typography>
          <TextField
            aria-label='condition name'
            error={
              areErrorsVisible &&
              newConditionName.trim() === '' &&
              'Condition name cannot be empty'
            }
            onChange={handleConditionNameChange}
            placeholder='Condition name'
            value={newConditionName}
          />
        </Section>
        {newCompositeConditions.map((compositeCondition, outerIndex) => {
          const { booleanOperator } = compositeCondition;
          return (
            <Section key={outerIndex}>
              {compositeCondition.conditions.map(
                ({ contextVariable, operator, value }, index) => {
                  const contextVariableObject = contextVariables.find(
                    ({ context_variable_id }) =>
                      context_variable_id === contextVariable,
                  ) || {
                    context_variable_id: '',
                    context_variable_name: '',
                  };

                  const cvType = getContextVariableType(
                    contextVariable,
                    contextVariables,
                  );

                  // determines what kind of value inputter should be displayed
                  // checkboxType: select dropdown with options true and false
                  // listType: select dropdown with options of the CV's list
                  // numberType: spin button that only allows number
                  // else: textbox
                  const isCheckboxType =
                    cvType === ContextVariableTypeKeys.CHECKBOX;
                  const isListType = cvType === ContextVariableTypeKeys.LIST;
                  const isNumberType =
                    cvType === ContextVariableTypeKeys.NUMBER;
                  const isMultiOptionsType =
                    cvType === ContextVariableTypeKeys.MULTIOPTIONS;
                  const isMultiSelectListType =
                    cvType === ContextVariableTypeKeys.MULTI_SELECT_LIST;

                  const dropdownOperatorOptions = getOperatorDropdownOptions(
                    cvType || '',
                  );

                  const deleteButtonErrMsg =
                    getDeleteButtonErrMessage(compositeCondition);
                  const isDeleteButtonDisabled = Boolean(deleteButtonErrMsg);

                  const cvDropdownError =
                    areErrorsVisible &&
                    !contextVariable &&
                    !isValuelessOperator(operator) &&
                    'Choose a context variable';

                  const valueTextInputError =
                    areErrorsVisible &&
                    value.toString().trim() === '' &&
                    !isValuelessOperator(operator) &&
                    'Enter a value';

                  const isMultiOptionsError =
                    areErrorsVisible &&
                    Array.isArray(value) &&
                    value.length === 0;

                  const triageModelName = getTriageModelName(
                    contextVariable,
                    ticketFieldMappings,
                  );

                  const shouldDisplayListTypeValueDropdown =
                    isListType &&
                    !!(contextVariableObject as ContextVariable)
                      .list_type_options;

                  const DeleteConditionButton = (
                    <Box>
                      <IconButton
                        aria-label={`delete condition ${
                          outerIndex + 1
                        }, composite condition ${index + 1}`}
                        disabled={isDeleteButtonDisabled}
                        onClick={() =>
                          handleDeleteConditionClick(
                            outerIndex,
                            index,
                            compositeCondition.conditions.length === 1,
                          )
                        }
                        variant='ghost'
                      >
                        <DeleteIcon
                          disabled={isDeleteButtonDisabled}
                          src={deleteIcon}
                        />
                      </IconButton>
                    </Box>
                  );

                  return (
                    <Row key={`${outerIndex}-${index}`}>
                      <Col>
                        {index === 0 ? (
                          <Box pt='8px'>
                            <Typography variant='font16Bold'>
                              {outerIndex + 1}
                            </Typography>
                          </Box>
                        ) : (
                          <StyledSelectDropdown
                            id={`boolean-operator-dropdown-${outerIndex}-${index}`}
                            inputProps={{
                              'aria-label': `boolean dropdown condition ${
                                outerIndex + 1
                              }, composite condition ${index + 1}`,
                            }}
                            onChange={event =>
                              handleBooleanOperatorChange(outerIndex, event)
                            }
                            options={[
                              { label: 'and', value: 'and' },
                              { label: 'or', value: 'or' },
                            ]}
                            value={booleanOperator || 'and'}
                          />
                        )}
                      </Col>

                      <Box flex={1}>
                        <StyledSelectDropdown
                          error={cvDropdownError}
                          id={`context-variable-dropdown-${outerIndex}-${index}`}
                          inputProps={{
                            'aria-label': `context variable dropdown condition ${
                              outerIndex + 1
                            }, composite condition ${index + 1}`,
                          }}
                          isMenuSearchable={true}
                          onChange={event =>
                            handleContextVariableChange(
                              outerIndex,
                              index,
                              event,
                            )
                          }
                          options={contextVariables.map(
                            ({
                              context_variable_id: id,
                              context_variable_name: displayName,
                            }) => ({
                              color: undefinedCvs.includes(id)
                                ? theme.palette.colors.red[500]
                                : theme.palette.text.primary,
                              label: '$' + displayName,
                              value: id,
                            }),
                          )}
                          placeholder='Context Variable'
                          value={
                            contextVariable
                              ? contextVariableObject.context_variable_id
                              : ''
                          }
                        />
                      </Box>

                      <Box flex={1}>
                        <StyledSelectDropdown
                          id={`operator-dropdown-${outerIndex}-${index}`}
                          inputProps={{
                            'aria-label': `operator dropdown condition ${
                              outerIndex + 1
                            }, composite condition ${index + 1}`,
                          }}
                          onChange={event =>
                            handleOperatorChange(outerIndex, index, event)
                          }
                          options={dropdownOperatorOptions.map(operator => ({
                            label: operator,
                            value: operator,
                          }))}
                          value={operator}
                        />
                      </Box>

                      <Box flex={1}>
                        {triageModelName ? (
                          <TriageSampleValuesDropdown
                            componentId={`list-value-dropdown-${outerIndex}-${index}`}
                            disabled={isValuelessOperator(operator)}
                            errorMessage={valueTextInputError || ''}
                            model={triageModelName}
                            onChange={value => {
                              {
                                if (cvType) {
                                  onChangeValueHandler(
                                    value,
                                    cvType,
                                    outerIndex,
                                    index,
                                  );
                                }
                              }
                            }}
                            value={value.toString()}
                          />
                        ) : (
                          <>
                            {isCheckboxType ? (
                              <StyledSelectDropdown
                                disabled={isValuelessOperator(operator)}
                                id={`checkbox-value-dropdown-${outerIndex}-${index}`}
                                inputProps={{
                                  'aria-label': `checkbox value dropdown condition ${
                                    outerIndex + 1
                                  }, composite condition ${index + 1}`,
                                }}
                                onChange={e => {
                                  const value = e?.target.value;
                                  if (cvType) {
                                    onChangeValueHandler(
                                      value,
                                      cvType,
                                      outerIndex,
                                      index,
                                    );
                                  }
                                }}
                                options={checkboxTypeValueOptions}
                                placeholder={
                                  isValuelessOperator(operator)
                                    ? ''
                                    : 'Select a value'
                                }
                                value={value.toString()}
                              />
                            ) : shouldDisplayListTypeValueDropdown ? (
                              <StyledSelectDropdown
                                disabled={isValuelessOperator(operator)}
                                error={valueTextInputError}
                                id={`list-value-dropdown-${outerIndex}-${index}`}
                                inputProps={{
                                  'aria-label': `list value dropdown condition ${
                                    outerIndex + 1
                                  }, composite condition ${index + 1}`,
                                }}
                                onChange={e => {
                                  const val = e?.target.value;
                                  if (cvType && val) {
                                    onChangeValueHandler(
                                      val,
                                      cvType,
                                      outerIndex,
                                      index,
                                    );
                                  }
                                }}
                                options={
                                  (contextVariableObject as ContextVariable)
                                    .list_type_options || []
                                }
                                placeholder={
                                  isValuelessOperator(operator)
                                    ? ''
                                    : 'Select a value'
                                }
                                value={value.toString()}
                              />
                            ) : isMultiOptionsType ? (
                              <Box width='100%'>
                                <MultiStringInput
                                  onChange={(_, value) => {
                                    if (cvType) {
                                      onChangeValueHandler(
                                        value,
                                        cvType,
                                        outerIndex,
                                        index,
                                      );
                                    }
                                  }}
                                  options={[]}
                                  placeholder='Add values on enter'
                                  textFieldProps={{
                                    error: isMultiOptionsError,
                                    helperText: isMultiOptionsError
                                      ? 'Enter a value'
                                      : undefined,
                                  }}
                                  value={Array.isArray(value) ? value : []}
                                />
                              </Box>
                            ) : isMultiSelectListType ? (
                              <Box
                                sx={{ '.MuiBox-root': { flexWrap: 'wrap' } }}
                              >
                                <StyledSelectDropdown
                                  disabled={isValuelessOperator(operator)}
                                  error={valueTextInputError}
                                  id={`list-value-multi-select-dropdown-${outerIndex}-${index}`}
                                  inputProps={{
                                    'aria-label': `list value multi-select dropdown condition ${
                                      outerIndex + 1
                                    }, composite condition ${index + 1}`,
                                  }}
                                  multiple
                                  onChange={e => {
                                    const value = e?.target.value;
                                    if (cvType && value) {
                                      onChangeValueHandler(
                                        value,
                                        cvType,
                                        outerIndex,
                                        index,
                                      );
                                    }
                                  }}
                                  options={
                                    (contextVariableObject as ContextVariable)
                                      .list_type_options || []
                                  }
                                  placeholder={
                                    isValuelessOperator(operator)
                                      ? ''
                                      : 'Select a value'
                                  }
                                  value={value ? (value as string[]) : []}
                                />
                              </Box>
                            ) : (
                              <Box width='100%'>
                                <TextField
                                  aria-label={`text value condition ${
                                    outerIndex + 1
                                  }, composite condition ${index + 1}`}
                                  disabled={isValuelessOperator(operator)}
                                  error={valueTextInputError}
                                  onChange={event => {
                                    const value = event.target.value;
                                    if (cvType) {
                                      onChangeValueHandler(
                                        value,
                                        cvType,
                                        outerIndex,
                                        index,
                                      );
                                    }
                                  }}
                                  placeholder='Value'
                                  type={isNumberType ? 'number' : 'text'}
                                  value={value as string}
                                />
                              </Box>
                            )}
                          </>
                        )}
                      </Box>
                      <Box pt='4px'>
                        {deleteButtonErrMsg !== null ? (
                          <Tooltip tooltipContent={deleteButtonErrMsg}>
                            {DeleteConditionButton}
                          </Tooltip>
                        ) : (
                          DeleteConditionButton
                        )}
                      </Box>
                    </Row>
                  );
                },
              )}
              <Row>
                <Button
                  aria-label={`add composite logic for condition ${
                    outerIndex + 1
                  }`}
                  onClick={() => {
                    handleAddCompositeConditionLogicClick(
                      outerIndex,
                      compositeCondition.conditions.length,
                    );
                  }}
                  startIcon={<IconPlus size={16} />}
                  variant='ghost'
                >
                  Add composite logic
                </Button>
              </Row>
            </Section>
          );
        })}
        <Section>
          <Button
            onClick={handleAddConditionClick}
            startIcon={<IconPlus size={16} />}
            variant='secondary'
          >
            New Condition
          </Button>
        </Section>
        {otherwiseCheckbox}
      </div>
      <Footer>
        <Button onClick={onCancel} variant='secondary'>
          Cancel
        </Button>
        <Button
          aria-label='save conditions'
          disabled={isSaveButtonDisabled}
          onClick={() => {
            if (areConditionsIncomplete()) {
              setAreErrorsVisible(true);
            } else {
              setAreErrorsVisible(false);
              onSave({
                compositeConditions: trimmedNewCompositeConditions,
                conditionName: newConditionName.trim(),
                otherwiseCondition: newOtherwiseCondition,
              });
            }
          }}
          variant='main'
        >
          Save
        </Button>
      </Footer>
    </StyledForm>
  );
};

const Footer = styled('div')`
  display: flex;
  justify-content: flex-end;
  padding: 24px 0 24px 0;
  gap: 16px;
`;

const Section = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 24px 0 24px 0;
  border-bottom: 1px solid ${theme.palette.colors.slate[200]};
`;

const Row = styled('div')`
  display: flex;
  gap: 8px;
  align-items: flex-start;
`;

const Col = styled('div')`
  flex-basis: 80px;
  justify-content: center;
  text-align: center;
`;

const StyledSelectDropdown = styled(SelectDropdown)`
  background-color: white;
`;

export const DeleteIcon: StyledComponent<
  { disabled: boolean },
  // eslint-disable-next-line @typescript-eslint/ban-types -- {} is used by default
  {},
  { src: string }
> = styled(ReactSVG)<{
  disabled: boolean;
}>`
  height: 20px;
  width: 20px;
  [fill] {
    fill: ${props =>
      props.disabled
        ? theme.palette.colors.grey[400]
        : theme.palette.colors.purple[500]};
  }
  [stroke] {
    stroke: ${props =>
      props.disabled
        ? theme.palette.colors.grey[400]
        : theme.palette.colors.purple[500]};
  }
`;

const StyledForm = styled('form')`
  background-color: white;
  padding: 0 24px 0 24px;
  height: 100%;
  border-radius: 8px;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
