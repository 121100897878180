import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';

import {
  Button,
  Typography,
} from '@forethought-technologies/forethought-elements';

interface CtaCellProps {
  isActive: boolean;
  url: string;
}

const CtaCell = ({ isActive, url }: CtaCellProps) => {
  const text = isActive ? 'View workflow' : 'Activate workflow';
  return (
    <StyledLink to={url}>
      <Button variant='ghost'>
        <Typography noWrap variant='font14Bold'>
          {text}
        </Typography>
      </Button>
    </StyledLink>
  );
};

const StyledLink = styled(Link)`
  text-decoration: none;
`;

export default CtaCell;
