import { useMemo } from 'react';

import { useGetIntentsQueryWithProduct } from 'src/hooks/hooks';
import { ConversationChannel } from 'src/types/workflowBuilderAPITypes';

export function useFallbackIntentForChannel(channel?: ConversationChannel) {
  const { data: intentsResponse } = useGetIntentsQueryWithProduct();
  const fallbackIntent = useMemo(() => {
    if (!intentsResponse || !channel) {
      return;
    }
    const wfTypeForChannel =
      channel === 'email' ? 'email' : 'flamethrower-live';
    const kaIntent = intentsResponse.intents.find(
      intent => intent.intent_definition_id === 'Knowledge_Article',
    );
    if (kaIntent?.active_workflow_types.includes(wfTypeForChannel)) {
      return kaIntent;
    }
    return intentsResponse.intents.find(
      intent => intent.intent_definition_id === 'general-handoff',
    );
  }, [channel, intentsResponse]);

  return fallbackIntent;
}
