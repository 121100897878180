import OverviewHeader from '../common/OverviewHeader';
import { DataItem } from '../common/OverviewItem';
import { labelInfoMap, overviewPlaceholder } from '../constants';
import {
  TriageModelLabelOverview,
  TriageModelLabelOverviewKeys,
} from '../types';
import { NA } from 'src/constants/solve';
import { DateRange } from 'src/types/types';

const rowOrder: Array<
  Exclude<
    TriageModelLabelOverviewKeys,
    'predicted_count' | 'avg_coverage' | 'recall'
  >
> = [
  'total_count',
  'eligible_count',
  'prediction_count',
  'pending_count',
  'finalized_count',
  'correct_count',
  'avg_accuracy',
];

const LabelsOverview = ({
  dateRange,
  isLoading,
  overview,
}: {
  dateRange: DateRange;
  isLoading?: boolean;
  overview?: Exclude<TriageModelLabelOverview, 'avg_coverage'>;
}) => {
  const overviewData = rowOrder
    .map(key => {
      const value = overview?.[key] || overviewPlaceholder[key];
      const target = labelInfoMap[key];

      if (!key || !target) {
        return null;
      }

      return {
        key: target.key,
        tooltip: target.tooltip,
        value: value ? target.formatValue(value, overview) : NA,
      };
    })
    .filter((item): item is DataItem => item !== null);

  return (
    <OverviewHeader
      data={overviewData}
      dateRange={dateRange}
      gridTemplateColumns='1fr 1fr 1.5fr 1fr 1fr 1fr 1fr'
      isLoading={isLoading}
      title='All labels overview'
    />
  );
};

export default LabelsOverview;
