import { useMemo } from 'react';
import { createMRTColumnHelper, MRT_Row } from 'material-react-table';

import {
  InfiniteTable,
  Tooltip,
  Typography,
} from '@forethought-technologies/forethought-elements';
import EmptyTabState from '../components/EmptyTabState';
import NumberValueCell from '../components/NumberValueCell';
import { TABLE_COLUMNS } from './constants';
import CtaCell from './CtaCell';
import DiscoverRealizedImpactTopicRow from './DiscoverRealizedImpactTopicRow';
import StatusValueCell from './StatusValueCell';
import get from 'lodash/get';
import TableOverflowCell from 'src/components/table-overflow-cell';
import { DiscoverAutomationResponse } from 'src/reducers/discoverReducer/types';

interface DiscoverRealizedImpactProps {
  data?: DiscoverAutomationResponse[];
  isError: boolean;
  loading: boolean;
  onMouseEnter: (automationName: string) => void;
  onScroll: (scroll: number) => void;
}

const DiscoverRealizedImpactTable = ({
  data,
  isError,
  loading,
  onMouseEnter,
  onScroll,
}: DiscoverRealizedImpactProps) => {
  const columnHelper = createMRTColumnHelper<DiscoverAutomationResponse>();

  const columns = useMemo(
    () =>
      TABLE_COLUMNS.map(colItem =>
        columnHelper.accessor(colItem.key, {
          Cell: ({ row }: { row: MRT_Row<DiscoverAutomationResponse> }) => {
            const { automated_object, topics, value } = row.original;

            const columnToComponentMap = {
              cost: <NumberValueCell prefix='$' value={value?.cost} />,
              name: (
                <Tooltip tooltipContent={automated_object.name}>
                  <TableOverflowCell>
                    <Typography variant='font14'>
                      {automated_object.name}
                    </Typography>
                  </TableOverflowCell>
                </Tooltip>
              ),
              status: (
                <StatusValueCell
                  isActive={automated_object.status === 'active'}
                />
              ),
              topics: <DiscoverRealizedImpactTopicRow topics={topics} />,
              volume: <NumberValueCell value={value?.volume} />,
            };

            const key = (colItem.target || colItem.key) as
              | 'name'
              | 'topics'
              | 'volume'
              | 'cost'
              | 'status';

            return get(columnToComponentMap, key);
          },
          header: colItem.title,
          size: colItem.size,
        }),
      ),
    [columnHelper],
  );
  return (
    <InfiniteTable
      hasNextPage={false}
      initialSorting={[{ desc: true, id: 'name' }]}
      isError={isError}
      isLoadingFirstPage={loading}
      isLoadingNextPage={false}
      stickyHeaderHeight={0}
      tableOptions={{
        columns,
        data: data || [],
        displayColumnDefOptions: {
          'mrt-row-actions': {
            header: '',
            muiTableBodyCellProps: {
              align: 'center',
            },
            muiTableHeadCellProps: {
              align: 'center',
            },
            size: 180,
          },
        },
        enableBottomToolbar: false,
        enableColumnActions: false,
        enableColumnFilters: false,
        enableExpanding: false,
        enableFilters: false,
        enableFullScreenToggle: false,
        enableGlobalFilter: false,
        enableRowActions: true,
        initialState: {
          columnPinning: {
            right: ['mrt-row-actions'],
          },
        },
        manualSorting: false,
        muiTableBodyRowProps: ({ row }) => ({
          onMouseEnter: () => onMouseEnter(row.original.automated_object.name),
        }),
        muiTableContainerProps: () => ({
          onScroll: event => onScroll(event.currentTarget.scrollTop),
          sx: {
            height: 'calc(100vh - 300px)',
          },
        }),
        renderEmptyRowsFallback: () => <EmptyTabState type='automation' />,
        renderRowActions: ({ row }) => (
          <CtaCell
            isActive={row.original.automated_object.status === 'active'}
            url={row.original.automated_object.redirect_url}
          />
        ),
      }}
    />
  );
};

export default DiscoverRealizedImpactTable;
