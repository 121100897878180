import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { getEmailModeParam } from './helpers';
import { useGetIntentsQueryWithProduct } from 'src/hooks/hooks';
import { CustomHandoffForm } from 'src/pages/workflow-builder/intent-form/CustomHandoffForm';
import { useEmailMode } from 'src/pages/workflow-builder-edit/hooks';
import {
  selectIsSolveLiteEnabled,
  selectUserRole,
} from 'src/reducers/userReducer/userReducer';
import { selectCanvasWorkflowBuilder } from 'src/reducers/workflowBuilderReducer/workflowBuilderReducer';
import {
  dashboardApi,
  useGetFeatureFlagsQuery,
} from 'src/services/dashboard-api';
import { useGetHandoffConfigurationsQuery } from 'src/services/workflow-builder-metrics/workflowBuilderMetricsApi';
import { CanvasWorkflowBuilderState } from 'src/slices/canvas-workflow-builder/workflowBuilderSlice';
import {
  selectGlobalHandoffDrawerOptions,
  setGlobalHandoffDrawerOptions,
  setGlobalToastOptions,
} from 'src/slices/ui/uiSlice';
import { useAppDispatch } from 'src/store/hooks';
import { ConversationChannel } from 'src/types/workflowBuilderAPITypes';
import { Routes, UserRoles } from 'src/utils/enums';

export const CustomHandoffDrawer = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const canvasData: CanvasWorkflowBuilderState = useSelector(
    selectCanvasWorkflowBuilder,
  );
  const isSolveLiteEnabled = useSelector(selectIsSolveLiteEnabled);

  const params = new URLSearchParams(location.search);
  const channel = params.get('channel') as ConversationChannel;
  const { emailMode } = useEmailMode();
  const { data: featureFlagsData } = useGetFeatureFlagsQuery();
  const { feature_flags: featureFlags = [] } = featureFlagsData ?? {};
  const userRole = useSelector(selectUserRole);
  const { isCreateHandoffDrawerOpen, isEditing } = useSelector(
    selectGlobalHandoffDrawerOptions,
  );
  const { refetch: refetchIntents } = useGetIntentsQueryWithProduct(undefined, {
    skip: ![UserRoles.ROLE_SUPER_ADMIN, UserRoles.ROLE_ADMIN].includes(
      userRole,
    ),
  });
  const { refetch: refetchHandoffConfigurations } =
    useGetHandoffConfigurationsQuery(undefined, {
      skip: ![UserRoles.ROLE_SUPER_ADMIN, UserRoles.ROLE_ADMIN].includes(
        userRole,
      ),
    });
  const isSolveEmailEnabled = featureFlags.includes('solve_email_enabled');

  const showAppropriateToast = () => {
    dispatch(
      setGlobalToastOptions({
        autoHideDuration: 2000,
        title: 'Handoff has been created',
        variant: 'main',
      }),
    );
    return;
  };

  return (
    <CustomHandoffForm
      isEditing={isEditing}
      isOpen={isCreateHandoffDrawerOpen}
      onClose={() => {
        dispatch(
          setGlobalHandoffDrawerOptions({
            isCreateHandoffDrawerOpen: false,
            isEditing: false,
          }),
        );
      }}
      onPostSave={(newWorkflowId, intentId) => {
        refetchHandoffConfigurations();
        dispatch(
          dashboardApi.util.invalidateTags(['Tools', 'AutoflowWorkflow']),
        );
        if (isEditing) {
          refetchIntents();
        } else {
          // navigate to the newly created handoff workflow
          showAppropriateToast();
          if (!isSolveLiteEnabled) {
            navigate(
              `${
                Routes.WORKFLOW_BUILDER_EDIT
              }?view=${channel}&workflowId=${newWorkflowId}&mode=classic&intentId=${intentId}${getEmailModeParam(
                emailMode,
                channel,
              )}`,
              { replace: true },
            );

            return;
          }
          if (canvasData.intent) {
            const queryParams = isSolveEmailEnabled
              ? `view=${channel}`
              : `workflowId=${newWorkflowId}`;
            navigate(`${Routes.WORKFLOW_BUILDER_EDIT}?${queryParams}`, {
              replace: true,
            });
          }
        }
      }}
    />
  );
};
