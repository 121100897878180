import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@mui/material';
import { IconDownload } from '@tabler/icons-react';

import {
  IconButton,
  Tooltip,
} from '@forethought-technologies/forethought-elements';
import { useEmitTrackingEventCallback } from 'src/hooks/hooks';
import { selectCurrentUser } from 'src/reducers/userReducer/userReducer';
import { sendAuthRequest } from 'src/services/api';
import { setGlobalToastOptions } from 'src/slices/ui/uiSlice';

interface InsightDownloadCsvProps {
  filename: string;
  requestData?: Record<string, unknown>;
  url: string;
}

const InsightDownloadCsv: React.FC<
  React.PropsWithChildren<InsightDownloadCsvProps>
> = ({ filename, requestData, url }) => {
  const user = useSelector(selectCurrentUser) || {};
  const dispatch = useDispatch();
  const theme = useTheme();
  const [isDownloading, setIsDownloading] = React.useState(false);

  const emitTrackingEventCallback = useEmitTrackingEventCallback();

  const downloadFile = ({
    filename,
    onError,
    url,
  }: {
    filename: string;
    onError?: () => void;
    url: string;
  }) => {
    setIsDownloading(true);
    emitTrackingEventCallback('insight-download-csv', {
      filename,
      url,
    });
    sendAuthRequest(url, requestData, 'POST')
      .then(() => {
        dispatch(
          setGlobalToastOptions({
            title: `Please allow up to 10 minutes for the report to arrive. We will notify you by email (${user?.user?.user?.email}).`,
            variant: 'main',
          }),
        );
      })
      .catch(err => {
        console.error(err);
        onError?.();
      })
      .finally(() => {
        setIsDownloading(false);
      });
  };

  return (
    <Tooltip tooltipContent='Export CSV'>
      <IconButton
        aria-label='Export CSV'
        disabled={isDownloading}
        isLoading={isDownloading}
        onClick={() => downloadFile({ filename, url })}
        variant='ghost'
      >
        <IconDownload color={theme.palette.primary.main} />
      </IconButton>
    </Tooltip>
  );
};

export default InsightDownloadCsv;
