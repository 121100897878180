import { useMemo } from 'react';
import { createMRTColumnHelper, MRT_Row } from 'material-react-table';
import Box from '@mui/material/Box';

import { Tooltip } from '@forethought-technologies/forethought-elements';
import TableDownloadButton from '../common/table-download-button';
import FilterSelect from '../triage-model-detail-page/common/FilterSelect';
import TextWithInfo from '../triage-model-detail-page/common/TextWithInfo';
import TriageModelTable from '../triage-model-detail-page/common/TriageModelTable';
import ValueCell from '../triage-model-detail-page/common/ValueCell';
import {
  FILTER_KEY_FINALIZED_LABEL,
  FILTER_KEY_PREDICTED_LABEL,
  FILTER_OPTIONS,
  FILTER_OPTIONS_STATUS_ITEM,
} from '../triage-model-detail-page/tickets-tab/constants';
import {
  createLabelFiltersOptions,
  formatRowValue,
} from '../triage-model-detail-page/tickets-tab/helpers';
import TicketStatusTableValue from '../triage-model-detail-page/tickets-tab/TicketStatusTableValue';
import {
  FILTER_KEY_ACTUAL_PREDICTION_LABEL,
  TICKET_SEARCH_TYPE_OPTIONS,
} from './constants';
import { NA } from 'src/constants/solve';
import { VersionedTriageModel } from 'src/reducers/triageSettingsReducer/types';
import { TicketMetrics } from 'src/services/triage/types';

const SIMULATION_TICKET_COLUMNS: {
  key: keyof TicketMetrics;
  size?: number;
  title: string;
  tooltip: string;
}[] = [
  {
    key: 'ticket_id',
    size: 150,
    title: 'Ticket ID',
    tooltip: '',
  },
  {
    key: 'title',
    size: 300,
    title: 'Ticket title',
    tooltip: '',
  },
  {
    key: 'body',
    size: 400,
    title: 'Ticket body',
    tooltip: '',
  },
  {
    key: 'status',
    size: 240,
    title: 'Ticket status',
    tooltip: '',
  },
  {
    key: 'predicted_label',
    size: 250,
    title: 'Predicted label',
    tooltip: '',
  },
  {
    key: 'prediction_value',
    size: 250,
    title: 'Actual prediction',
    tooltip:
      'The predicted output value written to the helpdesk by Forethought. If a value mapping exists, the mapped value will be written instead.',
  },
  {
    key: 'final_label',
    size: 250,
    title: 'Ground truth label',
    tooltip: '',
  },
  {
    key: 'is_correct',
    size: 200,
    title: 'Correct prediction',
    tooltip: '',
  },
  {
    key: 'reason_no_prediction',
    size: 300,
    title: 'Reason for no prediction',
    tooltip: '',
  },
];

interface SimulationTicketsTableProps {
  data?: TicketMetrics[];
  filters: string[];
  isDataLoading: boolean;
  model: VersionedTriageModel;
  onFilterChange: (filters: string[]) => void;
  onSearch: (text: string) => void;
  onSearchTypeChange?: (value: string) => void;
  searchText: string;
  searchType?: string;
}

const SimulationTicketsTable = ({
  data,
  filters,
  isDataLoading,
  model,
  onFilterChange,
  onSearch,
  onSearchTypeChange,
  searchText,
  searchType,
}: SimulationTicketsTableProps) => {
  const predictionTestId = window.location.pathname.split('/')[2];
  const columnHelper = createMRTColumnHelper<TicketMetrics>();
  const columns = useMemo(() => {
    return SIMULATION_TICKET_COLUMNS.map(item => {
      return columnHelper.accessor(item.key, {
        Cell: ({ row }: { row: MRT_Row<TicketMetrics> }) => {
          const rowValue = formatRowValue(item.key, row.original[item.key]);
          if (item.key === 'status') {
            return (
              <TicketStatusTableValue
                status={rowValue as TicketMetrics['status']}
              />
            );
          }
          if (item.key === 'title' || item.key === 'body') {
            return (
              <Tooltip tooltipContent={String(rowValue)}>
                <ValueCell
                  isLoading={isDataLoading}
                  itemKey={item.key}
                  value={rowValue}
                />
              </Tooltip>
            );
          }
          if (
            ['predicted_label', 'prediction_value', 'final_label'].includes(
              item.key,
            )
          ) {
            return (
              <Tooltip tooltipContent={String(rowValue)}>
                <ValueCell
                  isLoading={isDataLoading}
                  itemKey={item.key}
                  value={rowValue || NA}
                  wrap
                />
              </Tooltip>
            );
          }
          if (item.key === 'reason_no_prediction') {
            return (
              <Tooltip tooltipContent={String(rowValue)}>
                <ValueCell
                  isLoading={isDataLoading}
                  itemKey={item.key}
                  value={rowValue}
                  wrap
                />
              </Tooltip>
            );
          }
          const isEmpty =
            rowValue === undefined || rowValue === null || rowValue === '';
          return (
            <ValueCell
              isLoading={isDataLoading}
              itemKey={item.key}
              value={isEmpty ? NA : rowValue}
            />
          );
        },
        header: item.title,
        Header: (
          <TextWithInfo
            textColor={['grey', 800]}
            title={item.title}
            tooltip={item.tooltip}
            variant='font14Bold'
          />
        ),
        size: item.size,
      });
    });
  }, [columnHelper, isDataLoading]);

  const filterOptions = useMemo(() => {
    const finalizedLabel = {
      label: 'Ground truth label',
      options: createLabelFiltersOptions(model, FILTER_KEY_FINALIZED_LABEL),
      value: 'finalized_label_option',
    };

    const actualPredictedLabel = {
      label: 'Actual prediction label',
      options: createLabelFiltersOptions(
        model,
        FILTER_KEY_ACTUAL_PREDICTION_LABEL,
        true,
      ),
      value: 'actual_predicted_label_option',
    };

    const predictedLabel = {
      label: 'Predicted label',
      options: createLabelFiltersOptions(
        model,
        FILTER_KEY_PREDICTED_LABEL,
        false,
        true,
      ),
      value: 'predicted_label_option',
    };

    return [
      FILTER_OPTIONS_STATUS_ITEM,
      finalizedLabel,
      actualPredictedLabel,
      predictedLabel,
    ].concat(
      FILTER_OPTIONS.filter(item => {
        return item.label === 'Written to Helpdesk' ? false : true;
      }),
    );
  }, [model]);

  return (
    <TriageModelTable<TicketMetrics, string | boolean>
      columns={columns}
      Control={
        <TableDownloadButton
          data_export_type='triage_llm_simulation_tickets_table'
          requestData={{
            prediction_test_id: predictionTestId,
          }}
        />
      }
      data={data || []}
      FilterComponent={
        <FilterSelect
          maxHeight={400}
          menuTitle='Filter by'
          onChange={onFilterChange}
          options={filterOptions}
          value={filters}
        />
      }
      initialSorting={[{ desc: false, id: 'ticket_id' }]}
      initialState={{
        columnPinning: {
          left: ['ticket_id'],
        },
      }}
      isLoading={isDataLoading}
      onSearch={onSearch}
      onSearchTypeChange={onSearchTypeChange}
      renderEmptyRowsFallback={() => {
        if (!data?.length && !isDataLoading) {
          return (
            <Box alignItems='center' display='flex'>
              No search results found.
            </Box>
          );
        }
        return <div></div>;
      }}
      searchOptions={TICKET_SEARCH_TYPE_OPTIONS}
      searchPlaceholderText='Search ticket'
      searchText={searchText}
      searchType={searchType}
      stickyHeaderHeight={160}
    />
  );
};

export default SimulationTicketsTable;
