import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { styled } from '@mui/material';

import { Typography } from '@forethought-technologies/forethought-elements';
import { ForethoughtEditStepMenu } from './edit-menu/ForethoughtEditStepMenu';
import { ChatMessage } from './forethought-canvas/ForethoughtCanvas';
import TextStepEditor from './Input/TextStepEditor';
import { useGetBuilderQueryParams } from './hooks';
import UndefinedContextVariablesTooltip from './UndefinedContextVariablesTooltip';
import HtmlComponent from 'src/components/html-component/HtmlComponent';
import { solveLinkifyHtmlOptions } from 'src/components/html-component/solveLinkifyHtmlOptions';
import { TextStepProps } from 'src/pages/workflow-builder-edit/types/canvasComponentTypes';
import {
  selectIsBeingEdited,
  selectUndefinedContextVariablesInStep,
} from 'src/reducers/workflowBuilderReducer/workflowBuilderReducer';
import { useGetProductMentionFieldsQuery } from 'src/services/dashboard-api';
import {
  setEditingStepId,
  setEditingStepType,
} from 'src/slices/canvas-workflow-builder/workflowBuilderSlice';
import { useAppDispatch } from 'src/store/hooks';
import { TextMessageFields } from 'src/types/workflowBuilderAPITypes';
import { contextVariableAliasHtml } from 'src/utils/cleanStr';
import { CHANNEL_TO_PRODUCT_MAP } from 'src/utils/constants';
import { flattenDynamicContextVariables } from 'src/utils/solve/dynamicContextVariableUtils';

const TextStep: React.FC<React.PropsWithChildren<TextStepProps>> = ({
  contextVariables,
  isCanvasDisabled,
  isInAction,
  setShouldShowEditMenu,
  shouldShowEditMenu,
  step,
  stepId,
}) => {
  const dispatch = useAppDispatch();

  const isBeingEdited = useSelector(selectIsBeingEdited(stepId));
  const undefinedContextVariablesInStep = useSelector(
    selectUndefinedContextVariablesInStep,
  );
  const { view } = useGetBuilderQueryParams();
  const { data = [] } = useGetProductMentionFieldsQuery({
    product: CHANNEL_TO_PRODUCT_MAP[view],
  });

  const fields = step.step_fields as TextMessageFields;

  const message = useMemo(() => {
    const undefinedContextVariableIds =
      undefinedContextVariablesInStep[stepId] || [];

    // flatten dynamic cvs to include nested values
    const flattenedContextVariableOptions =
      flattenDynamicContextVariables(contextVariables);
    // format nested dynamic list cv options so they can be used in contextVariableAliasHtml
    const formattedContextVariables = flattenedContextVariableOptions.map(
      option => {
        return {
          context_variable_id: option.id,
          context_variable_name: option.fullLabel || option.label,
        };
      },
    );

    return contextVariableAliasHtml({
      additionalMentions: data,
      contextVariables: formattedContextVariables,
      html: fields.message,
      undefinedContextVariableIds,
    });
  }, [
    undefinedContextVariablesInStep,
    stepId,
    contextVariables,
    data,
    fields.message,
  ]);

  const hasCondition = !!step.condition_name;

  return (
    <TextStepWrapper>
      <div
        onClick={() => {
          if (isInAction) {
            return;
          }
          dispatch(setEditingStepId(stepId));
          dispatch(setEditingStepType(step.step_type));
        }}
      >
        {isBeingEdited && (
          <TextStepEditor
            autoFocus
            isSqueezingStep={false}
            shouldSqueezeIntoEntry={false}
            squeezeStepParentId={''}
          />
        )}
        {!isBeingEdited && (
          <ChatMessage isBeingEdited={isBeingEdited}>
            <Typography component='div' variant='font14'>
              <HtmlComponent
                content={message}
                linkifyHtmlOptions={solveLinkifyHtmlOptions}
              />
            </Typography>
            <UndefinedContextVariablesTooltip
              contextVariables={contextVariables}
              stepId={stepId}
            />
          </ChatMessage>
        )}
      </div>
      {!isCanvasDisabled && !isInAction && setShouldShowEditMenu && (
        <ForethoughtEditStepMenu
          hasCondition={hasCondition}
          isDeletionDisabled={hasCondition}
          isVisible={shouldShowEditMenu}
          setIsVisible={setShouldShowEditMenu}
          stepId={stepId}
          stepType={step.step_type}
        />
      )}
    </TextStepWrapper>
  );
};

const TextStepWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export default TextStep;
