import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';

import { Chip } from '@forethought-technologies/forethought-elements';

const StatusValueCell = ({ isActive }: { isActive: boolean }) => {
  const { palette } = useTheme();
  return (
    <Chip
      label={isActive ? 'Active' : 'Inactive'}
      startAdornment={
        <Box
          bgcolor={
            isActive ? palette.colors.green[500] : palette.colors.grey[400]
          }
          borderRadius={100}
          height='12px'
          width='12px'
        />
      }
      sx={{
        borderColor: palette.colors.slate[200],
        color: palette.colors.grey[700],
        fontSize: 12,
        fontWeight: 500,
        height: '26px',
      }}
      variant='outlined'
    />
  );
};

export default StatusValueCell;
