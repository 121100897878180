import React from 'react';
import { MRT_Cell, MRT_RowData } from 'material-react-table';
import { Box, Palette } from '@mui/material';
import {
  IconChevronDown,
  IconChevronRight,
  IconInfoCircle,
} from '@tabler/icons-react';
import { createColumnHelper } from '@tanstack/react-table';

import {
  IconButton,
  Tooltip,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { isOther } from '../../common/tableHelpers';
import {
  BUTTON_COLUMN_WIDTH,
  EXPANDER_COLUMN_ID,
  NAME_COLUMN_ID,
  NAME_COLUMN_WIDTH,
} from '../../discover-all-topics-page/AllTopicsTableV2/constants';
import { tableLoadingSkeleton } from '../../discover-all-topics-page/AllTopicsTableV2/helpers';
import InsightTooltip from '../common/InsightTooltip';
import OverflowDropdown from '../common/overflow-dropdown/OverflowDropdown';
import { INSIGHT_TOOLTIP_COPY } from '../constants';
import { numbersToStringWithCommas, numberToCurrency } from '../helpers';
import ColumnHeaderItem, { ColumnHeaderItemProps } from './ColumnHeaderItem';
import {
  INFINITE_TABLE_COLUMNS,
  TABLE_COLUMN_TO_LABEL_MAP,
  TABLE_COLUMN_TO_TOOLTIP_MAP,
} from './constants';
import CsatValue from './CsatValue';
import DropdownValue from './DropdownValue';
import { isRowChild } from './isRowChild';
import NameValue from './NameValue';
import NumberValue from './NumberValue';
import TopicNameCell from './TopicNameCell';
import TopicNameHeader from './TopicNameHeader';
import { InsightTopicData, InsightTopicDataColumnKeys } from './types';
import collapseIcon from 'src/assets/images/collapse-all-icon.svg';
import { TrackingEventType } from 'src/components/app/types';
import { TEMP_BLUE } from 'src/constants/solve';
import { TrackingEventAdditionalParams } from 'src/hooks/hooks';
import { NA } from 'src/pages/intent-conversation-analytics/constants';
import { formatCSAT } from 'src/pages/intent-conversation-analytics/helpers';
import { BreakdownPaletteTooltip } from 'src/pages/workflow-builder/BreakdownPaletteTooltip';
import { RelevanceTooltip } from 'src/pages/workflow-builder/intent-workflows-table/containers/WidgetIntentWorkflowsTableContainer';
import { HorizontalStackedBar } from 'src/pages/workflow-builder/intent-workflows-table/HorizontalStackedBar';
import { PaletteTooltip } from 'src/pages/workflow-builder/intent-workflows-table/PaletteTooltip';
import { formatNumberWithCommas } from 'src/pages/workflow-builder/intent-workflows-table/utils';
import { deriveTopicNameFromTopic } from 'src/utils/discover/helpers';
import { getEngagementRate } from 'src/utils/solve/deflectionInsightsUtils';

export const generateTableColumns = ({
  costPerTicket,
  emitTrackingEventCallback,
  getCSATColor,
  isLoading,
  palette,
  visibleColumns,
}: {
  costPerTicket: string;
  emitTrackingEventCallback: (
    eventType: TrackingEventType,
    additionalParams?: TrackingEventAdditionalParams,
  ) => void;
  getCSATColor: (score?: number | null) => string;
  handleAutomationAction: ({ topicId }: { topicId: string }) => void;
  handleWorkflowAction: ({ workflowId }: { workflowId: string }) => void;
  isLoading: boolean;
  palette: Palette;
  visibleColumns: InsightTopicDataColumnKeys[];
}) => {
  const buttonColumns = generateButtonColumns({ emitTrackingEventCallback });
  const topicNameColumn = generateTopicNameColumn({ isLoading, palette });
  const genericColumns = visibleColumns.map(columnKey =>
    generateColumns({
      columnKey: columnKey,
      costPerTicket,
      emitTrackingEventCallback,
      getCSATColor,
      palette,
    }),
  );
  // const actionColumn = generateActionColumn({
  //   emitTrackingEventCallback,
  //   handleAutomationAction,
  //   handleWorkflowAction,
  //   palette,
  // });

  const columns = [
    ...buttonColumns,
    topicNameColumn,
    ...genericColumns,
    // ...actionColumn,
  ];
  return columns;
};

export const generateColumns = ({
  columnKey,
  costPerTicket,
  emitTrackingEventCallback,
  getCSATColor,
  palette,
}: {
  columnKey: InsightTopicDataColumnKeys;
  costPerTicket: string;
  emitTrackingEventCallback: (
    eventType: TrackingEventType,
    additionalParams?: TrackingEventAdditionalParams,
  ) => void;
  getCSATColor: (score?: number | null) => string;
  palette: Palette;
}) => {
  const columnHelper = createColumnHelper<InsightTopicData>();

  return columnHelper.display({
    cell: cellProps => {
      const { row } = cellProps;
      const isChild = isRowChild(row);
      // UI column logic
      if (columnKey === 'engagement_rate') {
        const dropoffBreakdown = row.original?.dropoff_breakdown;
        if (dropoffBreakdown) {
          return (
            <Typography
              color={
                dropoffBreakdown
                  ? palette.colors.black
                  : palette.colors.grey[600]
              }
              variant={isChild ? 'font14' : 'font16Bold'}
            >
              {`${getEngagementRate(dropoffBreakdown).toFixed(1)}%`}
            </Typography>
          );
        }
        return (
          <Typography
            color={palette.colors.grey[600]}
            variant={isChild ? 'font14' : 'font16Bold'}
          >
            {NA}
          </Typography>
        );
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const commonValue: any = row.original?.[columnKey] ?? {};
      if (
        [
          'chats_count',
          'deflected_chats_count',
          'csat',
          'non_deflected_chats_count',
          'realized_saving',
          'potential_saving',
        ].includes(columnKey)
      ) {
        let value = commonValue ?? NA;
        if (typeof value === 'object') {
          value = NA;
        }
        const isFloat = typeof value === 'number' && !Number.isInteger(value);
        const isSaving = ['realized_saving', 'potential_saving'].includes(
          columnKey,
        );
        const isCsat = ['csat'].includes(columnKey);

        const handleValue = () => {
          if (isSaving) {
            return numberToCurrency({
              number: value,
              shouldRound: true,
            });
          }
          if (isCsat) {
            if (!value) {
              return (
                <Typography
                  color={palette.colors.grey[600]}
                  variant={isChild ? 'font14' : 'font16Bold'}
                >
                  {NA}
                </Typography>
              );
            }
            return (
              <Typography
                color={getCSATColor(value)}
                variant={isChild ? 'font14' : 'font16Bold'}
              >
                {formatCSAT(value)}
              </Typography>
            );
          }
          if (isFloat) {
            return numbersToStringWithCommas({
              canBeFloat: true,
              number: value,
              style: 'decimal',
            });
          }
          return value;
        };
        return (
          <Box display='flex' gap='4px'>
            <Typography
              color={
                value === NA ? palette.colors.grey[600] : palette.colors.black
              }
              variant={isChild ? 'font14' : 'font16Bold'}
            >
              {handleValue()}
            </Typography>
          </Box>
        );
      }

      if (['workflows', 'related_articles'].includes(columnKey)) {
        const value = commonValue ?? [];
        const isWorkflow = columnKey.includes('workflows');
        if (value.length > 0) {
          return (
            <Box>
              <OverflowDropdown
                data={value}
                menuTitle={`${
                  isWorkflow ? 'Workflows' : 'Articles'
                } surfaced in this chat`}
                scope='main'
                tab='topic'
                variant={isWorkflow ? 'workflow' : 'article'}
                width='220px'
              />
            </Box>
          );
        }
      }

      if (['dropoff_breakdown'].includes(columnKey)) {
        if (
          commonValue &&
          commonValue?.dropoff_count === 0 &&
          commonValue?.no_dropoff_count === 0
        ) {
          return (
            <Typography color={palette.colors.grey[600]} variant='font14'>
              {NA}
            </Typography>
          );
        }
        return (
          <Box flex={1} maxWidth={140} padding='16px 16px 0 0'>
            <Tooltip
              fullWidth
              maxWidth={320}
              tooltipContent={
                <BreakdownPaletteTooltip
                  values={[
                    {
                      color: TEMP_BLUE,
                      label: 'Yes',
                      value: commonValue.no_dropoff_count,
                    },
                    {
                      color: palette.colors.red[500],
                      label: 'No',
                      value: commonValue.dropoff_count,
                    },
                  ]}
                />
              }
            >
              <HorizontalStackedBar
                data={[
                  {
                    color: TEMP_BLUE,
                    value: commonValue.no_dropoff_count,
                  },
                  {
                    color: palette.colors.red[500],
                    value: commonValue?.dropoff_count,
                  },
                ]}
              />
            </Tooltip>
          </Box>
        );
      }

      if (['relevance_breakdown'].includes(columnKey)) {
        if (
          commonValue &&
          commonValue?.relevant_count === 0 &&
          commonValue?.somewhat_relevant_count === 0 &&
          commonValue?.irrelevant_count === 0
        ) {
          return (
            <Typography color={palette.colors.grey[600]} variant='font14'>
              {NA}
            </Typography>
          );
        }
        return (
          <Box flex={1} maxWidth={140} padding='16px 16px 0 0'>
            <Tooltip
              fullWidth
              maxWidth={320}
              tooltipContent={
                <BreakdownPaletteTooltip
                  values={[
                    {
                      color: TEMP_BLUE,
                      label: 'Relevant',

                      value: commonValue.relevant_count,
                    },
                    {
                      color: palette.colors.blue[400],
                      label: 'Somewhat relevant',
                      value: commonValue.somewhat_relevant_count,
                    },
                    {
                      color: palette.colors.red[500],
                      label: 'Irrelevant',
                      value: commonValue.irrelevant_count,
                    },
                  ]}
                />
              }
            >
              <HorizontalStackedBar
                data={[
                  {
                    color: TEMP_BLUE,
                    value: commonValue?.relevant_count,
                  },
                  {
                    color: palette.colors.blue[400],
                    value: commonValue?.somewhat_relevant_count,
                  },
                  {
                    color: palette.colors.red[500],
                    value: commonValue?.irrelevant_count,
                  },
                ]}
              />
            </Tooltip>
          </Box>
        );
      }

      return (
        <Typography color={palette.colors.grey[600]} variant='font14'>
          {NA}
        </Typography>
      );
    },
    header: () => {
      let tooltipForSavings = '';
      if (columnKey === 'realized_saving') {
        tooltipForSavings = `Dollarized savings assuming a $${costPerTicket} cost per deflection.`;
      }
      const handleTooltip = () => {
        if (['dropoff_breakdown', 'relevance_breakdown'].includes(columnKey)) {
          return (
            <Tooltip
              maxWidth={320}
              tooltipContent={
                columnKey === 'dropoff_breakdown' ? (
                  <PaletteTooltip
                    text={TABLE_COLUMN_TO_TOOLTIP_MAP.topic[columnKey]}
                    values={[
                      { color: TEMP_BLUE, label: 'Yes' },
                      { color: palette.colors.red[500], label: 'No' },
                    ]}
                  />
                ) : (
                  <RelevanceTooltip text={INSIGHT_TOOLTIP_COPY.relevance} />
                )
              }
            >
              <IconInfoCircle color='currentColor' height={16} width={16} />
            </Tooltip>
          );
        }
        if (TABLE_COLUMN_TO_TOOLTIP_MAP.topic[columnKey]) {
          return (
            <Box
              display='flex'
              onMouseEnter={() => {
                emitTrackingEventCallback('insight-tooltip-hover', {
                  scope: 'main',
                  tab: 'topic',
                  table: 'topic',
                  value: columnKey,
                });
              }}
            >
              <InsightTooltip
                tooltip={
                  tooltipForSavings
                    ? tooltipForSavings
                    : TABLE_COLUMN_TO_TOOLTIP_MAP.topic[columnKey]
                }
              />
            </Box>
          );
        }
      };
      return (
        <Box alignItems='center' display='flex' gap='4px'>
          <Typography color={palette.colors.grey[800]} variant='font14Bold'>
            {TABLE_COLUMN_TO_LABEL_MAP.topic[columnKey]}
          </Typography>
          {handleTooltip()}
        </Box>
      );
    },

    id: columnKey,
    size: NAME_COLUMN_WIDTH / 2,
  });
};

export const generateTopicNameColumn = ({
  isLoading,
  palette,
  searchText,
}: {
  isLoading: boolean;
  palette: Palette;
  searchText?: string;
}) => {
  const columnHelper = createColumnHelper<InsightTopicData>();

  return columnHelper.accessor(
    row =>
      deriveTopicNameFromTopic({
        topic_display_name: row.display_name,
        topic_name: row.cluster_name,
      }),
    {
      cell: cellProps =>
        isLoading ? (
          tableLoadingSkeleton
        ) : (
          <TopicNameCell cellProps={cellProps} searchText={searchText} />
        ),
      header: ({ table }) => {
        if (isLoading) {
          return (
            <Typography color={palette.colors.grey[800]} variant='font14Bold'>
              Topics
            </Typography>
          );
        }

        const rows = table.getCenterRows();
        const totalNumRows = rows.reduce((prev, curr) => {
          const lengthOfSubRows = curr.subRows.filter(
            subRow => subRow.depth === 1,
          ).length;

          return prev + lengthOfSubRows;
        }, 0);

        return (
          <Typography color={palette.colors.grey[800]} variant='font14Bold'>
            {`Topics (${totalNumRows})`}
          </Typography>
        );
      },

      id: NAME_COLUMN_ID,
      size: NAME_COLUMN_WIDTH,
    },
  );
};

export const generateButtonColumns = ({
  emitTrackingEventCallback,
}: {
  emitTrackingEventCallback: (
    eventType: TrackingEventType,
    additionalParams?: TrackingEventAdditionalParams,
  ) => void;
}) => {
  const columnHelper = createColumnHelper<InsightTopicData>();
  return [
    columnHelper.display({
      cell: ({ row }) => {
        return (
          <Box pl={1}>
            {row.getCanExpand() ? (
              <IconButton
                aria-label='expand or collapse row'
                onClick={event => {
                  row.getToggleExpandedHandler()();
                  emitTrackingEventCallback('insight-table-collapse-clicked', {
                    id: row.original.cluster_id,
                    scope: 'main',
                    tab: 'topic',
                    table: 'topic',
                    value: row.getIsExpanded() ? 'close' : 'open',
                  });
                  event.stopPropagation();
                }}
                variant='ghost'
              >
                {row.getIsExpanded() ? (
                  <IconChevronDown color='black' />
                ) : (
                  <IconChevronRight color='black' />
                )}
              </IconButton>
            ) : (
              ''
            )}
          </Box>
        );
      },
      header: ({ table }) => {
        return (
          <Box
            display='flex'
            marginLeft='6px'
            onClick={event => {
              table.getToggleAllRowsExpandedHandler()(event);
              emitTrackingEventCallback('insight-table-collapse-clicked', {
                id: 'all',
                scope: 'main',
                tab: 'topic',
                table: 'topic',
                value: table.getIsAllRowsExpanded() ? 'close' : 'open',
              });
              event.stopPropagation();
            }}
            padding='6px'
          >
            <img src={collapseIcon} />
          </Box>
        );
      },
      id: EXPANDER_COLUMN_ID,
      maxSize: BUTTON_COLUMN_WIDTH,
      size: BUTTON_COLUMN_WIDTH,
    }),
  ];
};

export const createRows = (data: InsightTopicData[]): MRT_RowData[] => {
  if (!data) {
    return [];
  }

  return data.map(item => {
    return {
      ...item,
      engagement_rate_percentage: item.dropoff_breakdown
        ? getEngagementRate(item.dropoff_breakdown)
        : null,
      subRows: createRows(item.insight_subtopics),
    };
  });
};

const createColSize = (data?: InsightTopicData[]) => {
  let len = 0;
  data?.forEach(item => {
    const nameLength = ((item.cluster_name.length || 0) + 3) * 1.2;
    if (nameLength > len) {
      len = nameLength;
    }
    item.insight_subtopics?.forEach(subItem => {
      const subNameLength = subItem.cluster_name.length || 0;
      if (subNameLength > len) {
        len = subNameLength;
      }
    });
  });
  return len * 7 + 100;
};

export type CreateColumnHeaderProps = {
  costPerTicket: string;
  data?: InsightTopicData[];
  emitTrackingEventCallback: ColumnHeaderItemProps['emitTrackingEventCallback'];
  isGrouped: boolean;
  onClick: (params: { clusterId: string; isChild: boolean }) => void;
  palette: Palette;
  sortDirection: 'desc' | 'asc';
  totalTopicsCount: number;
};

type ColItem = {
  accessorKey: InsightTopicDataColumnKeys | 'cluster_name';
  Cell: () => JSX.Element;
  header: string;
  Header: React.JSX.Element;
  minSize?: number;
  size?: number;
  sortingFn: (rowA: MRT_RowData, rowB: MRT_RowData) => 1 | -1 | 0;
};
type HeaderCellProps = { cell: MRT_Cell<InsightTopicData, never> };

const getSortColumnValue = (
  key: InsightTopicDataColumnKeys,
  rowData: InsightTopicData & { engagement_rate_percentage: number | null },
) => {
  const value = rowData[key];
  if (key === 'relevance_breakdown') {
    const { relevance_breakdown } = rowData;
    const relevanceValue =
      relevance_breakdown?.relevant_count ||
      relevance_breakdown?.somewhat_relevant_count ||
      relevance_breakdown?.irrelevant_count;
    return !relevanceValue ? null : relevanceValue;
  }
  if (key === 'dropoff_breakdown') {
    const dropOffValue =
      rowData.dropoff_breakdown?.no_dropoff_count ||
      rowData.dropoff_breakdown?.dropoff_count;
    return !dropOffValue ? null : dropOffValue;
  }
  if (key === 'engagement_rate') {
    const { engagement_rate_percentage } = rowData;
    return engagement_rate_percentage === null
      ? null
      : engagement_rate_percentage;
  }
  return value || 0;
};

const isChildElement = (
  cell: MRT_Cell<InsightTopicData, never>,
  isGrouped: boolean,
) => {
  const item = cell.row.original;
  return cell.row.depth > 0 || (!item.insight_subtopics.length && !isGrouped);
};

export const createColumnHeader = ({
  costPerTicket,
  data,
  emitTrackingEventCallback,
  isGrouped,
  onClick,
  palette,
  sortDirection,
  totalTopicsCount,
}: CreateColumnHeaderProps) => {
  const size = createColSize(data);

  return INFINITE_TABLE_COLUMNS.map(item => {
    const sortingFn = (rowA: MRT_RowData, rowB: MRT_RowData) => {
      const isDescending = sortDirection === 'desc';
      const a = getSortColumnValue(
        item.accessorKey as InsightTopicDataColumnKeys,
        rowA.original,
      );
      const b = getSortColumnValue(
        item.accessorKey as InsightTopicDataColumnKeys,
        rowB.original,
      );
      const aIsEmpty = Array.isArray(a) && a.length === 0;
      const bIsEmpty = Array.isArray(b) && b.length === 0;
      const aZeroCsat = item.accessorKey === 'csat' && a === 0;
      const bZeroCsat = item.accessorKey === 'csat' && b === 0;

      // null or undefined values will always get
      // sorted after or below the "- Others"
      if (b === null || b === undefined || bIsEmpty || bZeroCsat) {
        return isDescending ? 1 : -1;
      }

      if (a === null || a === undefined || aIsEmpty || aZeroCsat) {
        return isDescending ? -1 : 1;
      }

      // Sorting the topics with `- Others` to be always
      // at the bottom is a "product decision"
      if (isOther(rowA.original.cluster_name)) {
        return isDescending ? -1 : 1;
      }

      if (isOther(rowB.original.cluster_name)) {
        return isDescending ? 1 : -1;
      }
      if (a < b) {
        return -1;
      }

      if (a > b) {
        return 1;
      }

      return 0;
    };

    const { accessorKey } = item;
    if (accessorKey === 'cluster_name') {
      return {
        ...item,
        Cell: ({ cell }: HeaderCellProps) => {
          const item = cell.row.original;
          const isChild = isChildElement(cell, isGrouped);
          const parentTopicName = item.parentTopicName || '';

          return (
            <NameValue
              clusterId={item.cluster_id}
              clusterName={item.cluster_name}
              isChild={isChild}
              onClick={onClick}
              parentTopicName={parentTopicName}
              subtopics={item.insight_subtopics}
            />
          );
        },
        Header: <TopicNameHeader totalTopicsCount={totalTopicsCount} />,
        size,
        sortingFn,
      };
    }

    /**
    if (!visibleColumns.includes(accessorKey)) {
      return null;
    }
     */

    const defaultHeaderProps = {
      ...item,
      Header: (
        <ColumnHeaderItem
          accessorKey={accessorKey}
          costPerTicket={costPerTicket}
          emitTrackingEventCallback={emitTrackingEventCallback}
          header={item.header}
        />
      ),
      sortingFn,
    };

    if (accessorKey === 'engagement_rate') {
      return {
        ...defaultHeaderProps,
        Cell: ({ cell }: HeaderCellProps) => {
          const isChild = isChildElement(cell, isGrouped);
          const dropoffBreakdown = cell.row.original?.dropoff_breakdown;
          if (dropoffBreakdown) {
            return (
              <Typography
                color={
                  dropoffBreakdown
                    ? palette.colors.black
                    : palette.colors.grey[600]
                }
                variant={isChild ? 'font14' : 'font14Bold'}
              >
                {`${getEngagementRate(dropoffBreakdown).toFixed(1)}%`}
              </Typography>
            );
          }
          return (
            <Typography
              color={palette.colors.grey[600]}
              variant={isChild ? 'font14' : 'font14Bold'}
            >
              {NA}
            </Typography>
          );
        },
      };
    }
    if (accessorKey === 'dropoff_breakdown') {
      return {
        ...defaultHeaderProps,
        Cell: ({ cell }: HeaderCellProps) => {
          const commonValue = cell.row.original.dropoff_breakdown;

          if (
            !commonValue ||
            (commonValue &&
              commonValue.dropoff_count === 0 &&
              commonValue.no_dropoff_count === 0)
          ) {
            return (
              <Typography color={palette.colors.grey[600]} variant='font14'>
                {NA}
              </Typography>
            );
          }
          return (
            <Box flex={1} maxWidth={140} padding='16px 16px 0 0'>
              <Tooltip
                fullWidth
                maxWidth={320}
                tooltipContent={
                  <BreakdownPaletteTooltip
                    values={[
                      {
                        color: TEMP_BLUE,
                        label: 'Yes',
                        value: commonValue.no_dropoff_count,
                      },
                      {
                        color: palette.colors.red[500],
                        label: 'No',
                        value: commonValue.dropoff_count,
                      },
                    ]}
                  />
                }
              >
                <HorizontalStackedBar
                  data={[
                    {
                      color: TEMP_BLUE,
                      value: commonValue.no_dropoff_count,
                    },
                    {
                      color: palette.colors.red[500],
                      value: commonValue?.dropoff_count,
                    },
                  ]}
                />
              </Tooltip>
            </Box>
          );
        },
      };
    }
    if (accessorKey === 'relevance_breakdown') {
      return {
        ...defaultHeaderProps,
        Cell: ({ cell }: HeaderCellProps) => {
          const commonValue = cell.row.original.relevance_breakdown;

          if (
            !commonValue ||
            (commonValue &&
              commonValue.relevant_count === 0 &&
              commonValue.somewhat_relevant_count === 0 &&
              commonValue.irrelevant_count === 0)
          ) {
            return (
              <Typography color={palette.colors.grey[600]} variant='font14'>
                {NA}
              </Typography>
            );
          }
          return (
            <Box flex={1} maxWidth={140} padding='16px 16px 0 0'>
              <Tooltip
                fullWidth
                maxWidth={320}
                tooltipContent={
                  <BreakdownPaletteTooltip
                    values={[
                      {
                        color: TEMP_BLUE,
                        label: 'Relevant',

                        value: commonValue.relevant_count,
                      },
                      {
                        color: palette.colors.blue[400],
                        label: 'Somewhat relevant',
                        value: commonValue.somewhat_relevant_count,
                      },
                      {
                        color: palette.colors.red[500],
                        label: 'Irrelevant',
                        value: commonValue.irrelevant_count,
                      },
                    ]}
                  />
                }
              >
                <HorizontalStackedBar
                  data={[
                    {
                      color: TEMP_BLUE,
                      value: commonValue?.relevant_count,
                    },
                    {
                      color: palette.colors.blue[400],
                      value: commonValue?.somewhat_relevant_count,
                    },
                    {
                      color: palette.colors.red[500],
                      value: commonValue?.irrelevant_count,
                    },
                  ]}
                />
              </Tooltip>
            </Box>
          );
        },
      };
    }
    if (accessorKey === 'csat') {
      return {
        ...defaultHeaderProps,
        Cell: ({ cell }: HeaderCellProps) => (
          <CsatValue
            isChild={isChildElement(cell, isGrouped)}
            value={cell.getValue()}
          />
        ),
      };
    }

    if (
      accessorKey === 'potential_saving' ||
      accessorKey === 'realized_saving'
    ) {
      return {
        ...defaultHeaderProps,
        Cell: ({ cell }: HeaderCellProps) => {
          const isChild = isChildElement(cell, isGrouped);
          const val = numberToCurrency({
            number: cell.getValue(),
            shouldRound: true,
          });
          return <NumberValue isChild={isChild} value={val} />;
        },
      };
    }

    if (accessorKey === 'related_articles' || accessorKey === 'workflows') {
      return {
        ...defaultHeaderProps,
        Cell: ({ cell }: HeaderCellProps) => {
          return (
            <DropdownValue dataType={accessorKey} value={cell.getValue()} />
          );
        },
      };
    }

    if (
      accessorKey === 'chats_count' ||
      accessorKey === 'deflected_chats_count' ||
      accessorKey === 'non_deflected_chats_count'
    ) {
      return {
        ...defaultHeaderProps,
        Cell: ({ cell }: HeaderCellProps) => {
          const isChild = isChildElement(cell, isGrouped);
          const value = formatNumberWithCommas(cell.getValue());
          return <NumberValue isChild={isChild} value={value} />;
        },
      };
    }

    return defaultHeaderProps;
  }).filter((item): item is ColItem => item !== null);
};

export const applyShowOnlyFilter = (
  showOnlyFilter: string[],
  data?: InsightTopicData[],
) => {
  if (!showOnlyFilter.length || !data) {
    return data || [];
  }

  const hasWorkflow = showOnlyFilter.find(item => item === 'has-workflow');
  const hasKnowledgeRetrieval = showOnlyFilter.find(
    item => item === 'has-knowledge-retrieval',
  );
  const hasNewTopics = showOnlyFilter.find(item => item === 'new-topics');

  return data
    .map(item => {
      const filtered = item.insight_subtopics.filter(subtopic => {
        if (hasWorkflow && subtopic.workflows.length) {
          return true;
        }
        if (
          hasKnowledgeRetrieval &&
          subtopic.workflows.find(
            target => target.intent_id === 'Knowledge_Article',
          )
        ) {
          return true;
        }
        if (hasNewTopics && item.type === 'new') {
          return true;
        }
      });
      if (!filtered.length) {
        return null;
      }
      return {
        ...item,
        insight_subtopics: filtered,
      };
    })
    .filter((item): item is InsightTopicData => item !== null);
};

export const extractChildItems = (data: Array<InsightTopicData> | undefined) =>
  data?.reduce(
    (prevValue, currentValue) =>
      (prevValue || []).concat(
        currentValue.insight_subtopics?.map(subTopic => {
          return {
            ...subTopic,
            parentTopicName: currentValue?.cluster_name,
          };
        }) || [],
      ),
    [] as Array<InsightTopicData>,
  ) || [];
