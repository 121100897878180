import { useCallback, useMemo, useState } from 'react';
import { useField, useFormikContext } from 'formik';
import { SelectChangeEvent } from '@mui/material';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { IconSettingsFilled, IconX } from '@tabler/icons-react';

import {
  Button,
  IconButton,
  SelectDropdown,
  Tabs,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { a11yProps, methodOptions } from '../../constants';
import KeyValuePairs from '../KeyValuePairs';
import unset from 'lodash/fp/unset';
import ContextVariableAutocomplete from 'src/components/context-variable-autocomplete';
import ActionSettingsModal from 'src/pages/action-builder/action-builder-v2/action-settings-modal';
import { NewBearerTokenModal } from 'src/pages/action-builder/endpoint-authorization/NewBearerTokenModal';
import { useGetActionBuilderDetailFormIsActive } from 'src/pages/action-builder/hooks';
import {
  useGetActionBuilderIntegrationsQuery,
  useGetAuthConfigsQuery,
  useUpdateAuthConfigsMutation,
} from 'src/services/action-builder/actionBuilderApi';
import { setGlobalToastOptions } from 'src/slices/ui/uiSlice';
import { useAppDispatch } from 'src/store/hooks';
import {
  AuthorizationType,
  ContentType,
  KVPairs,
  SelectedActionDetail,
} from 'src/types/actionBuilderApiTypes';
import {
  EMPTY_KEY_VALUE,
  getContentTypePlaceholder,
  urlToParamsKVPairs,
} from 'src/utils/actionBuilder/helpers';
import { ActionBuilderSettingsTokensDict } from 'src/utils/actionBuilder/types';
import { capitalizeFirstLetter } from 'src/utils/capitalizeFirstLetter';

const AuthorizationTab = () => {
  const dispatch = useAppDispatch();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [authorizationTypeField, , authorizationTypeCallbacks] = useField<
    AuthorizationType | undefined
  >({
    name: 'fields.authorization.type',
  });
  const [authorizationIdField, , authorizationIdCallbacks] = useField<
    string | undefined
  >({
    name: 'fields.authorization.id',
  });
  const [, , authorizationCombinedFieldCallbacks] = useField({
    name: 'fields.authorization',
  });

  const isActive = useGetActionBuilderDetailFormIsActive();

  const { data: integrationsData } = useGetActionBuilderIntegrationsQuery();
  const { data: authorizationConfigs = [], isLoading } =
    useGetAuthConfigsQuery();
  const [updateAuthConfigs] = useUpdateAuthConfigsMutation();

  const isBearerTokenAuthType = authorizationTypeField.value === 'bearer';

  const handleOnCloseModal = () => setIsModalOpen(false);
  const handleNewBearerTokenOnSave = (
    token: ActionBuilderSettingsTokensDict,
  ) => {
    const authorizationConfig = {
      authorization_fields: {
        api_token: token.value,
      },
      authorization_id: token.name,
      authorization_type: 'bearer',
    };

    updateAuthConfigs([...authorizationConfigs, authorizationConfig]);
    handleOnCloseModal();
    dispatch(
      setGlobalToastOptions({
        title: 'New bearer token has been created.',
        variant: 'main',
      }),
    );
    authorizationIdCallbacks.setValue(token.name);
  };

  return (
    <Box>
      <SelectDropdown
        disabled={isActive}
        id='authentication-type-dropdown'
        label='Authentication type'
        onChange={({ target }) => {
          authorizationTypeCallbacks.setValue(
            target.value as AuthorizationType,
          );
          authorizationIdCallbacks.setValue(undefined);
        }}
        options={[
          {
            label: 'Bearer Token',
            value: 'bearer',
          },
          {
            label: 'Integration',
            value: 'integration',
          },
        ]}
        placeholder='Select an authentication type'
        value={authorizationTypeField.value ?? ''}
      />
      <Box mb={1} />
      <SelectDropdown
        addNewButtonText={
          isBearerTokenAuthType ? 'New bearer token' : undefined
        }
        disabled={!authorizationTypeField.value || isActive}
        id='authentication-value-dropdown'
        isMenuSearchable={isBearerTokenAuthType}
        label='Value'
        onAddNewClick={
          isBearerTokenAuthType
            ? () => {
                setIsModalOpen(true);
              }
            : undefined
        }
        onChange={({ target }) => {
          authorizationIdCallbacks.setValue(target.value);
        }}
        options={
          (isBearerTokenAuthType
            ? authorizationConfigs.map(item => ({
                label: item.authorization_id,
                value: item.authorization_id,
              }))
            : integrationsData?.integrations.map(item => ({
                label: capitalizeFirstLetter(item.integration),
                optionStartAdornment: (
                  <Box
                    component='img'
                    height='20px'
                    src={item.emblem_url}
                    width='20px'
                  />
                ),
                value: item.integration,
              }))) ?? []
        }
        placeholder='Select a value'
        value={authorizationIdField.value ?? ''}
      />
      <NewBearerTokenModal
        authorizationConfigs={authorizationConfigs}
        authType={authorizationTypeField.value ?? ''}
        isDisabled={isLoading}
        isOpen={isModalOpen}
        onClose={handleOnCloseModal}
        onSave={handleNewBearerTokenOnSave}
      />
      {authorizationTypeField.value && (
        <>
          <Box mb={1} />
          <Button
            disabled={isActive}
            onClick={() => {
              authorizationCombinedFieldCallbacks.setValue(null);
            }}
            startIcon={<IconX />}
            variant='ghost'
          >
            Clear field
          </Button>
        </>
      )}
    </Box>
  );
};

const BodyTab = () => {
  const [{ value }, { error }, { setValue }] = useField({
    name: 'fields.body',
  });
  const [{ value: contentType }, , { setValue: setContentType }] =
    useField<ContentType>({
      name: 'fields.contentType',
    });
  const isActive = useGetActionBuilderDetailFormIsActive();

  return (
    <Box mt={1}>
      <FormControl>
        <RadioGroup
          aria-label='Content type'
          name='content-type-radio-buttons-group'
          onChange={e => setContentType(e.target.value as ContentType)}
          sx={{ flexDirection: 'row' }}
          value={contentType}
        >
          <FormControlLabel
            control={<Radio />}
            disabled={isActive}
            label={
              <Typography variant='font14'>x-www-form-urlencoded</Typography>
            }
            value='form-url'
          />
          <FormControlLabel
            control={<Radio />}
            disabled={isActive}
            label={<Typography variant='font14'>JSON</Typography>}
            value='json'
          />
          <FormControlLabel
            control={<Radio />}
            disabled={isActive}
            label={<Typography variant='font14'>None</Typography>}
            value='none'
          />
        </RadioGroup>
      </FormControl>
      <ContextVariableAutocomplete
        disabled={isActive}
        error={error}
        multiline
        onChange={setValue}
        placeholder={getContentTypePlaceholder(contentType)}
        rows={4}
        shouldIncludeTemplateContextVariables={isActive}
        value={value ?? ''}
      />
    </Box>
  );
};

const tabs = [
  {
    Component: KeyValuePairs,
    key: 'fields.params',
    name: 'Params',
  },
  { Component: KeyValuePairs, key: 'fields.headers', name: 'Headers' },
  { Component: BodyTab, key: 'fields.body', name: 'Body' },
  { Component: AuthorizationTab, key: 'fields.params', name: 'Authorization' },
] as const;

interface EndpointFormProps {
  onClickSend: () => void;
}

const EndpointForm = ({ onClickSend }: EndpointFormProps) => {
  const [shouldShowSettingsModal, setShouldShowSettingsModal] = useState(false);

  const { errors } = useFormikContext<SelectedActionDetail>();
  const [tabIndex, setTabIndex] = useState(0);
  const [outputParams, , { setValue: setOutputParamsValue }] = useField<
    KVPairs[]
  >({
    name: 'outputValues',
  });
  const [methodField, , { setValue: setMethodValue }] = useField({
    name: 'fields.method',
  });
  const [urlField, meta, { setValue: setUrlValue }] = useField({
    name: 'fields.url',
  });
  const [paramsField, , { setValue: setParamsValue }] = useField<KVPairs[]>({
    name: 'fields.params',
  });
  const isActive = useGetActionBuilderDetailFormIsActive();

  const availableTabs = useMemo(() => {
    return tabs.filter(
      tab => tab.name !== 'Body' || methodField.value !== 'GET',
    );
  }, [methodField.value]);

  const availableParams = useMemo(() => {
    return paramsField?.value
      .map(({ key, value }) => (value === undefined ? key : `${key}=${value}`))
      .join('&');
  }, [paramsField?.value]);

  const onChangeMethod = useCallback(
    (e: SelectChangeEvent) => {
      setMethodValue(e.target.value);
    },
    [setMethodValue],
  );

  const onChangeUrl = useCallback(
    (value: string) => {
      if (value.trim() && outputParams.value.length) {
        setOutputParamsValue([]);
      }

      const [first, ...rest] = value.split('?');
      const second = rest.join('?');
      if (first.trim() && second.trim()) {
        setParamsValue(urlToParamsKVPairs(second));
        setUrlValue(first);
        return;
      }

      setParamsValue([...EMPTY_KEY_VALUE]);
      setUrlValue(value);
    },
    [
      outputParams.value.length,
      setOutputParamsValue,
      setParamsValue,
      setUrlValue,
    ],
  );

  const onChangeTab = useCallback((_: unknown, value: number) => {
    setTabIndex(value);
  }, []);

  // Ignore JMESPath errors:
  const isSendButtonDisabled =
    errors.fields &&
    Object.keys(unset('jmespathQuery', errors.fields)).length > 0;

  return (
    <>
      <Box display='flex' gap={1}>
        <Box width='100px'>
          <SelectDropdown
            disabled={isActive}
            id='method-select'
            onChange={onChangeMethod}
            options={methodOptions}
            value={methodField.value}
          />
        </Box>
        <Box flex={1}>
          <ContextVariableAutocomplete
            disabled={isActive}
            error={meta.error}
            onChange={onChangeUrl}
            placeholder='Select method, enter endpoint then send'
            shouldIncludeTemplateContextVariables={isActive}
            value={
              urlField.value + (availableParams ? '?' : '') + availableParams
            }
          />
        </Box>
        <Button
          disabled={isSendButtonDisabled}
          onClick={onClickSend}
          size='large'
          variant='main'
        >
          Send
        </Button>
      </Box>
      <Box alignItems='center' display='flex' justifyContent='space-between'>
        <Tabs
          a11yProps={a11yProps}
          aria-label='Endpoint form'
          onChange={onChangeTab}
          tabs={availableTabs.map(({ name }) => name)}
          typographyVariant={'button'}
          value={tabIndex}
        />
        {availableTabs.length - 1 === tabIndex && (
          <IconButton
            aria-label='Settings'
            onClick={() => setShouldShowSettingsModal(true)}
            variant='ghost'
          >
            <IconSettingsFilled />
          </IconButton>
        )}
      </Box>

      {availableTabs.map(({ Component, key, name }, index) => (
        <div
          hidden={tabIndex !== index}
          key={name}
          role='tabpanel'
          {...a11yProps(index)}
        >
          {tabIndex === index && <Component name={key} />}
        </div>
      ))}
      <ActionSettingsModal
        isOpen={shouldShowSettingsModal}
        onClose={() => setShouldShowSettingsModal(false)}
      />
    </>
  );
};

export default EndpointForm;
