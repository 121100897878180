import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';

import { Typography } from '@forethought-technologies/forethought-elements';
import { lightPurpleGradient } from 'src/utils/constants';

interface TicketSummaryProps {
  summary: string | null;
}

const TicketSummary = ({ summary }: TicketSummaryProps) => {
  const { palette } = useTheme();

  if (!summary) {
    return null;
  }

  return (
    <Box display='flex' flexDirection='column' rowGap={1}>
      <Typography variant='font11'>Summary</Typography>
      <Box
        borderRadius={1}
        display='flex'
        p={2}
        sx={{
          background: lightPurpleGradient,
        }}
      >
        <Typography color={palette.colors.slate[700]} variant='font12Medium'>
          {summary}
        </Typography>
      </Box>
    </Box>
  );
};

export default TicketSummary;
