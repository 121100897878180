import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';

import { Typography } from '@forethought-technologies/forethought-elements';
import { normalizeTableFloat } from '../normalizeTableFloat';
import DiscoverSentimentEmoji from 'src/components/discover-sentiment-emoji';
import { DiscoverTopicAggregatedMetricType } from 'src/reducers/discoverReducer/types';
import {
  getHoursFromSeconds,
  getSentimentEmojiVariantFromValue,
} from 'src/utils/discover/helpers';

export const AverageRendererComponent = ({
  isHidden,
  metricFilter,
  percentOfTotal,
  value,
}: {
  isHidden: boolean;
  isSummaryRow?: boolean;
  metricFilter: DiscoverTopicAggregatedMetricType | '';
  percentOfTotal: number | null;
  value: number | null;
}) => {
  const { palette } = useTheme();

  if (value === null) {
    return (
      <Typography
        color={isHidden ? palette.colors.grey[600] : palette.colors.grey[800]}
        variant='font14'
      >
        <i>N/A</i>
      </Typography>
    );
  }

  const renderValue = () => {
    if (
      metricFilter === 'number_of_agent_replies' ||
      metricFilter === 'sentiment' ||
      metricFilter === 'volume'
    ) {
      return value;
    }

    if (metricFilter === 'first_contact_resolution') {
      return `${normalizeTableFloat(value)}%`;
    }

    return getHoursFromSeconds(value);
  };

  return (
    <Box alignItems='center' display='flex' sx={{ whiteSpace: 'normal' }}>
      <Typography
        color={isHidden ? palette.colors.grey[600] : palette.colors.black}
        variant='font14'
      >
        {renderValue()}
        {metricFilter === 'volume' && percentOfTotal !== null && (
          <Typography color={palette.colors.grey[600]} variant='font14'>
            {` (${percentOfTotal}%)`}
          </Typography>
        )}
      </Typography>
      {metricFilter === 'sentiment' && (
        <DiscoverSentimentEmoji
          ml={1}
          size='medium'
          variant={getSentimentEmojiVariantFromValue(value)}
        />
      )}
    </Box>
  );
};
