// Tracks which fields have errors or have been touched
export enum FieldNameToIdx {
  HANDOFF_FORM_QUESTION_MESSAGE = 0,
  CONFIRMATION_MESSAGE = 1,
  CUSTOM_BRAND_VALUE = 2,
  TICKET_TAGS = 3,
  CUSTOM_MESSAGE = 4,
  CHAT_TAGS = 5,
  NAME = 6,
  EMAIL = 7,
  QUESTION = 8,
}
