import { useTheme } from '@mui/material';

import { Typography } from '@forethought-technologies/forethought-elements';
import { NA } from 'src/constants/solve';

const NumberValueCell = ({
  prefix,
  value,
}: {
  prefix?: string;
  value?: number | null;
}) => {
  const { palette } = useTheme();

  if (value === null || value === undefined) {
    return (
      <Typography color={palette.colors.grey[600]} variant='font14'>
        {NA}
      </Typography>
    );
  }

  const valueStr = value.toLocaleString();
  const text = prefix ? `${prefix}${valueStr}` : valueStr;

  return <Typography variant='font14'>{text}</Typography>;
};

export default NumberValueCell;
