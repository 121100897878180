import React, { FC } from 'react';
import { styled } from '@mui/material';
import { Box } from '@mui/material';

import {
  SelectDropdown,
  theme,
} from '@forethought-technologies/forethought-elements';
import get from 'lodash/fp/get';
import keyBy from 'lodash/fp/keyBy';
import xIcon from 'src/assets/images/icon-close-bttn.svg';
import ContextVariableAutocomplete from 'src/components/context-variable-autocomplete';
import ContextVariableSelectDropdown from 'src/components/context-variable-select-dropdown';
import {
  Label,
  Row,
  Spacer,
} from 'src/pages/workflow-builder-edit/handoff-configuration/styles';
import { useGetUndefinedContextVariableIdsOnCurrentAction } from 'src/pages/workflow-builder-edit/hooks/useGetUndefinedContextVariableIdsOnCurrentAction';
import {
  TicketStaticFieldAndValue,
  ZendeskTicketField,
} from 'src/types/workflowBuilderAPITypes';

interface TicketFieldsProps {
  setStaticFieldsAndValues: (newVal: TicketStaticFieldAndValue[]) => void;
  staticFieldsAndValues?: TicketStaticFieldAndValue[];
  ticketFields?: ZendeskTicketField[];
}

const TicketFields: FC<React.PropsWithChildren<TicketFieldsProps>> = ({
  setStaticFieldsAndValues,
  staticFieldsAndValues,
  ticketFields,
}) => {
  const undefinedContextVariableIds =
    useGetUndefinedContextVariableIdsOnCurrentAction();

  const customFieldArr = ticketFields || [];

  const idCustomFieldMap = keyBy('id', customFieldArr);
  const idValueArr = staticFieldsAndValues || [{ id: '-1', value: '' }];

  return (
    <>
      <Label>Update Ticket Fields</Label>
      {idValueArr.map(({ id, value }, idx: number) => {
        return (
          <div key={`custom-field-row-${idx}`}>
            <Row>
              <SelectWrapper>
                <SelectDropdown
                  aria-label='Select a Field...'
                  id={`custom-field-row-${idx}`}
                  isMenuSearchable
                  menuMaxHeight={180}
                  onChange={e => {
                    if (e.target.value) {
                      const updatedFieldValues = idValueArr
                        .slice(0, idx)
                        .concat({ id: e.target.value, value: '' })
                        .concat(idValueArr.slice(idx + 1));

                      setStaticFieldsAndValues(updatedFieldValues);
                    }
                  }}
                  options={customFieldArr.map(customField => ({
                    label: customField.title,
                    value: customField.id.toString(),
                  }))}
                  value={String(get([id, 'id'], idCustomFieldMap))}
                />
              </SelectWrapper>
              <Spacer width='8px' />
              {/* Initially, if user hasn't selected a field type, we wouldn't know what field value
                  input type to render. So we default it to text field. */}
              {get([id, 'type'], idCustomFieldMap) !== 'tagger' && (
                <InputWrapper>
                  <ContextVariableAutocomplete
                    aria-label='Field Value'
                    onChange={fieldValue => {
                      const updatedFieldValues = idValueArr
                        .slice(0, idx)
                        .concat({
                          id: idValueArr[idx].id,
                          value: fieldValue,
                        })
                        .concat(idValueArr.slice(idx + 1));
                      setStaticFieldsAndValues(updatedFieldValues);
                    }}
                    placeholder='Field Value'
                    shouldIncludeSystemContextVariables
                    undefinedContextVariables={undefinedContextVariableIds}
                    value={value.toString()}
                  />
                </InputWrapper>
              )}
              {get([id, 'type'], idCustomFieldMap) === 'tagger' && (
                <Box
                  sx={{
                    flex: '1 1 50%',
                  }}
                >
                  <ContextVariableSelectDropdown
                    additionalOptions={get(
                      [id, 'field_options'],
                      idCustomFieldMap,
                    ).map(
                      ({ name, value }: { name: string; value: string }) => ({
                        category: 'Field Options',
                        label: name,
                        value,
                      }),
                    )}
                    aria-label='Field Value'
                    id={idValueArr[idx].id.toString()}
                    onChange={value => {
                      const updatedFieldValues = idValueArr
                        .slice(0, idx)
                        .concat({
                          id: idValueArr[idx].id,
                          value,
                        })
                        .concat(idValueArr.slice(idx + 1));

                      setStaticFieldsAndValues(updatedFieldValues);
                    }}
                    shouldIncludeSystemContextVariables
                    shouldProvideCVIdFormatting
                    value={value.toString()}
                  />
                </Box>
              )}
              <ButtonWrapper>
                <CloseButton
                  onClick={() => {
                    setStaticFieldsAndValues(
                      idValueArr
                        .slice(0, idx)
                        .concat(idValueArr.slice(idx + 1, idValueArr.length)),
                    );
                  }}
                  role='button'
                  src={xIcon}
                />
              </ButtonWrapper>
            </Row>
            <Spacer height='8px' />
          </div>
        );
      })}
      <LinkOut
        isDisabled={idValueArr.length === customFieldArr.length}
        onClick={() => {
          if (idValueArr.length !== customFieldArr.length) {
            setStaticFieldsAndValues(
              idValueArr.concat({
                id: '-1',
                value: '',
              }),
            );
          }
        }}
      >
        + Add field
      </LinkOut>
    </>
  );
};

export default TicketFields;

const SelectWrapper = styled('div')`
  flex: 1 1 50%;
`;

const InputWrapper = styled('div')`
  flex: 1 1 50%;
`;

const CloseButton = styled('img')`
  cursor: pointer;
  height: 20px;
`;

const ButtonWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  height: 42px;
  width: 30px;
`;

const LinkOut = styled('a')<{ isDisabled: boolean }>`
  font-size: 16px;
  line-height: 16px;
  text-align: left;
  color: ${props =>
    props.isDisabled
      ? theme.palette.colors.grey[300]
      : theme.palette.colors.purple[500]};
  font-style: ${props => props.isDisabled && 'italic'};
  margin-top: 4px;
  width: 100%;
  cursor: pointer;
  max-width: 100%;
  pointer-events: ${props => (props.isDisabled ? 'none' : 'cursor')};
`;
