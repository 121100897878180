import { useEffect } from 'react';

import { useGetGeneratedArticlesMutation } from 'src/services/insights/insightsApi';

export const useGetGeneratedArticles = ({
  isParentTopic,
  shouldIncludeGeneratedDiscoverArticles,
  topicId,
}: {
  isParentTopic?: boolean;
  shouldIncludeGeneratedDiscoverArticles: boolean;
  topicId?: string;
}) => {
  const [
    getGeneratedArticles,
    {
      isError: isErrorGeneratedArticles,
      isLoading: isLoadingGeneratedArticles,
    },
  ] = useGetGeneratedArticlesMutation();

  useEffect(() => {
    if (shouldIncludeGeneratedDiscoverArticles) {
      getGeneratedArticles({
        is_parent_topic: isParentTopic,
        topic_id: topicId,
      });
    }
  }, [
    getGeneratedArticles,
    shouldIncludeGeneratedDiscoverArticles,
    isParentTopic,
    topicId,
  ]);

  return { isErrorGeneratedArticles, isLoadingGeneratedArticles };
};
