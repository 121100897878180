import { useCallback, useMemo, useState } from 'react';
import { createMRTColumnHelper, MRT_Row } from 'material-react-table';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';

import {
  InfiniteTable,
  SearchBar,
  Tooltip,
  Typography,
} from '@forethought-technologies/forethought-elements';
import EmptyTabState from '../components/EmptyTabState';
import NumberValueCell from '../components/NumberValueCell';
import ArticleList from './ArticleList';
import { TABLE_COLUMNS } from './constants';
import RelatedTopics from './RelatedTopics';
import get from 'lodash/get';
import TableOverflowCell from 'src/components/table-overflow-cell';
import { useEmitTrackingEventCallback } from 'src/hooks/hooks';
import { useGenerateLinkWithSearch } from 'src/hooks/useGenerateLinkWithSearch';
import {
  KnowledgeGapArticleRecommendation,
  KnowledgeGapArticleRecommendations,
} from 'src/reducers/discoverReducer/types';
import {
  overrideDiscoverSearchParams,
  replaceIdInRoute,
} from 'src/utils/discover/helpers';
import { Routes } from 'src/utils/enums';

interface KnowledgeGapTableProps {
  data?: KnowledgeGapArticleRecommendations['articles'];
  isError: boolean;
  loading: boolean;
  onMouseEnter?: (topicName: string) => void;
  onScroll?: (scroll: number) => void;
  onSelect: (row: { original: { article_id: string } }) => void;
}

const KnowledgeGapTable = ({
  data,
  isError,
  loading,
  onScroll,
  onSelect,
}: KnowledgeGapTableProps) => {
  const [searchText, setSearchText] = useState('');
  const columnHelper =
    createMRTColumnHelper<KnowledgeGapArticleRecommendation>();

  const navigate = useNavigate();
  const linkWithSearch = useGenerateLinkWithSearch(
    Routes.DISCOVER_TOPIC_DETAIL,
  );
  const emitTrackingEventCallback = useEmitTrackingEventCallback();
  const handleTopicClick = useCallback(
    (event: React.MouseEvent, topic_name: string, topic_id: string) => {
      event.stopPropagation();

      emitTrackingEventCallback('discover-clicked-on-topic', {
        page: 'Knowledge Gap',
        topic: topic_name,
      });

      navigate(
        overrideDiscoverSearchParams(
          replaceIdInRoute(linkWithSearch, topic_id),
          {
            name: topic_name,
            source: 'knowledge gap',
          },
        ),
      );
    },
    [navigate, emitTrackingEventCallback, linkWithSearch],
  );

  const columns = useMemo(
    () =>
      TABLE_COLUMNS.map(colItem =>
        columnHelper.accessor(colItem.key, {
          Cell: ({
            row,
          }: {
            row: MRT_Row<KnowledgeGapArticleRecommendation>;
          }) => {
            const {
              generated_body,
              generated_title,
              num_of_tickets_in_article,
              relevant_articles,
              topic_id,
              topic_name,
            } = row.original;

            const columnToComponentMap = {
              generated_body: (
                <TableOverflowCell>
                  <Typography variant='font14'>{generated_body}</Typography>
                </TableOverflowCell>
              ),
              generated_title: (
                <Tooltip tooltipContent={generated_title}>
                  <TableOverflowCell>
                    <Typography variant='font14'>{generated_title}</Typography>
                  </TableOverflowCell>
                </Tooltip>
              ),
              num_of_tickets_in_article: (
                <NumberValueCell value={num_of_tickets_in_article} />
              ),
              relevant_articles: <ArticleList articles={relevant_articles} />,
              topic_name: (
                <RelatedTopics
                  onTopicClick={event =>
                    handleTopicClick(event, topic_name, topic_id)
                  }
                  topicNames={topic_name}
                />
              ),
            };
            return get(columnToComponentMap, colItem.key);
          },
          header: colItem.title,
          size: colItem.size,
        }),
      ),
    [handleTopicClick, columnHelper],
  );

  const rows = useMemo(() => {
    if (!searchText) {
      return data;
    }
    return data?.filter(item =>
      item.generated_title.toLowerCase().includes(searchText.toLowerCase()),
    );
  }, [searchText, data]);

  const handleSearch = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    setSearchText(event.target.value);
  };

  return (
    <InfiniteTable
      hasNextPage={false}
      initialSorting={[{ desc: true, id: 'num_of_tickets_in_article' }]}
      isError={isError}
      isLoadingFirstPage={loading}
      isLoadingNextPage={false}
      stickyHeaderHeight={0}
      tableOptions={{
        columns,
        data: rows || [],
        enableBottomToolbar: false,
        enableColumnActions: false,
        enableColumnFilters: false,
        enableExpanding: false,
        enableFilters: false,
        enableFullScreenToggle: false,
        enableGlobalFilter: false,
        enableRowActions: false,
        manualSorting: false,
        muiTableBodyRowProps: ({ row }) => ({
          onClick: () => onSelect(row),
          sx: {
            cursor: 'pointer',
          },
        }),
        muiTableContainerProps: () => ({
          onScroll: event => onScroll?.(event.currentTarget.scrollTop),
          sx: {
            height: 'calc(100vh - 300px)',
          },
        }),
        renderEmptyRowsFallback: () => <EmptyTabState type='knowledge' />,
        renderTopToolbarCustomActions: () => (
          <Box key={1} paddingTop='4px' width='200px'>
            <SearchBar
              onChange={handleSearch}
              onClear={() => setSearchText('')}
              placeholder='Search article title'
              size='small'
            />
          </Box>
        ),
      }}
    />
  );
};

export default KnowledgeGapTable;
