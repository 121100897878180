import { useMemo, useState } from 'react';
import { useFormikContext } from 'formik';
import { styled } from '@mui/material';
import Box from '@mui/material/Box';

import {
  theme,
  Typography,
} from '@forethought-technologies/forethought-elements';
import ActionBuilderFormTextField from '../ActionBuilderFormTextField';
import { useGetContextVariables } from 'src/hooks/useGetContextVariables';
import { useGetIntentIdUsages } from 'src/pages/action-builder/hooks';
import IntentBadge from 'src/pages/intent-conversation-analytics/IntentBadge';
import {
  ContextVariable,
  DynamicListKVPairs,
  SelectedActionDetail,
} from 'src/types/actionBuilderApiTypes';
import { isDynamicListKvPairs } from 'src/utils/actionBuilder/helpers';

interface DetailsFormProps {
  inputParams: ContextVariable[];
}

const DEFAULT_OUTPUT_PARAMS_CHARACTERS_TO_SHOW = 190;

const DetailsForm = ({ inputParams }: DetailsFormProps) => {
  const { values } = useFormikContext<SelectedActionDetail>();
  const { outputValues = [] } = values ?? {};
  const { contextVariables } = useGetContextVariables({
    shouldIncludeTemplateContextVariables: true,
  });

  const [isOutputParamsExpanded, setIsOutputParamsExpanded] = useState(false);

  const { usages } = useGetIntentIdUsages(values.actionId);
  const { dynamicListOutputParams, outputParams } = useMemo(() => {
    const newDynamicCVs = outputValues
      .filter((outputValue): outputValue is DynamicListKVPairs => {
        if (!isDynamicListKvPairs(outputValue)) {
          return false;
        }

        return !outputValue.value;
      })
      .map(outputValue => `$${outputValue.newContextVariableName}`);

    const cvOutput = outputValues
      .map(outputValue =>
        contextVariables.find(
          ({ context_variable_id: id }) => id === outputValue.value,
        ),
      )
      .filter((param): param is ContextVariable => param !== undefined);

    return {
      dynamicListOutputParams: [
        ...newDynamicCVs,
        ...cvOutput
          .filter(cv => cv.context_variable_type === 'DYNAMIC_LIST')
          .map(cv => `$${cv.context_variable_name}`),
      ],
      outputParams: cvOutput
        .filter(cv => cv.context_variable_type !== 'DYNAMIC_LIST')
        .map(cv => `$${cv.context_variable_name}`),
    };
  }, [outputValues, contextVariables]);

  const outputParamsString = outputParams.join(', ');
  const outputParamsToRender = isOutputParamsExpanded
    ? outputParamsString
    : outputParamsString.slice(0, DEFAULT_OUTPUT_PARAMS_CHARACTERS_TO_SHOW);
  const shouldOutputParamsTruncate =
    outputParamsString.length > DEFAULT_OUTPUT_PARAMS_CHARACTERS_TO_SHOW;

  return (
    <>
      <ActionBuilderFormTextField
        label='Action name *'
        name='name'
        placeholder='Provide a name for the action'
        required
      />
      <Box height='20px' />
      <ActionBuilderFormTextField
        label='Description *'
        multiline
        name='description'
        placeholder='Provide information about the purpose and function of the API endpoint you are utilizing'
        required
      />
      <Box height='20px' />
      <Box>
        <Typography color='var(--color-grey-500)' variant='font12'>
          Input Parameters:
        </Typography>
      </Box>
      <Box
        fontFamily='Fira Code'
        fontSize='12px'
        fontWeight='400'
        lineHeight='18px'
      >
        <Typography variant='font12'>
          {inputParams.map(cv => `$${cv.context_variable_name}`).join(', ')}
        </Typography>
      </Box>
      <Box>
        <Typography color='var(--color-grey-500)' variant='font12'>
          Output Parameters:
        </Typography>
      </Box>
      <Box
        fontFamily='Fira Code'
        fontSize='12px'
        fontWeight='400'
        lineHeight='18px'
      >
        <Typography variant='font12'>
          {outputParamsToRender}
          {shouldOutputParamsTruncate && !isOutputParamsExpanded && '...'}
        </Typography>
      </Box>
      <Box>
        {shouldOutputParamsTruncate && (
          <Box pt={1}>
            <Typography
              color={theme.palette.colors.purple[500]}
              variant='font12'
            >
              <ClickableLink
                onClick={() => setIsOutputParamsExpanded(prev => !prev)}
              >
                See {isOutputParamsExpanded ? 'less' : 'more'}
              </ClickableLink>
            </Typography>
          </Box>
        )}
      </Box>
      {dynamicListOutputParams.length > 0 && (
        <>
          <Box>
            <Typography color='var(--color-grey-500)' variant='font12'>
              Dynamic list Output Parameters:
            </Typography>
          </Box>
          <Box
            fontFamily='Fira Code'
            fontSize='12px'
            fontWeight='400'
            lineHeight='18px'
          >
            <Typography variant='font12'>
              {dynamicListOutputParams.join(', ')}
            </Typography>
          </Box>
        </>
      )}
      {usages.length ? (
        <Box mt={1}>
          <Typography color='var(--color-grey-500)' variant='font12'>
            In use:
          </Typography>
          <Box display='flex' flexWrap='wrap' gap={1} mt={0.5}>
            {usages.map(usage => (
              <IntentBadge intentDefinitionId={usage} key={usage} />
            ))}
          </Box>
        </Box>
      ) : null}
    </>
  );
};

const ClickableLink = styled('u')`
  cursor: pointer;
`;

export default DetailsForm;
