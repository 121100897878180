import { useMemo, useState } from 'react';
import { createMRTColumnHelper, MRT_Row } from 'material-react-table';
import { useLocation } from 'react-router';

import TextWithInfo from '../common//TextWithInfo';
import TriageModelTable from '../common/TriageModelTable';
import ValueCell from '../common/ValueCell';
import { labelInfoMap } from '../constants';
import { TriageModelLabel } from '../types';
import { LABELS_TABLE_COLUMNS, PERCENTAGE_COLUMNS } from './constants';
import { createLabelsFirstLoadingData, filterSearchLabels } from './helpers';
import LabelsTableControl, { LabelControlOptions } from './LabelsTableControl';
import { VersionedTriageModel } from 'src/reducers/triageSettingsReducer/types';
import { DateRange } from 'src/types/types';

interface LabelsTableProps {
  areRowsFetching: boolean;
  dateRange: DateRange;
  isTableDataLoading: boolean;
  labelControlOptions: LabelControlOptions;
  labels: TriageModelLabel[];
  model: VersionedTriageModel;
  onDateRangeChange: (value: DateRange) => void;
}

const LabelsTable = ({
  areRowsFetching,
  dateRange,
  isTableDataLoading,
  labelControlOptions,
  labels,
  model,
  onDateRangeChange,
}: LabelsTableProps) => {
  const location = useLocation();
  const isSimulation = location.pathname.includes('simulations');
  const [searchText, setSearchText] = useState('');
  const updatedTableColumns = useMemo(() => {
    let columns = [...LABELS_TABLE_COLUMNS];
    if (isSimulation) {
      columns = columns.filter(
        item =>
          item.key !== 'last_update_at' &&
          item.key !== 'pending_count' &&
          item.key !== 'correct_count' &&
          item.key !== 'accuracy',
      );
      columns.push({
        key: 'recall',
        size: 200,
        title: 'Recall',
        tooltip: null,
      });
      columns.push({
        key: 'accuracy',
        size: 240,
        title: labelInfoMap.avg_accuracy.key,
        tooltip: labelInfoMap.avg_accuracy.tooltip,
      });
    }
    return columns;
  }, [isSimulation]);

  const columnHelper = createMRTColumnHelper<TriageModelLabel>();
  const dateRangeFilter = {
    onChange: onDateRangeChange,
    value: dateRange,
  };
  const columns = useMemo(() => {
    return updatedTableColumns.map(item => {
      return columnHelper.accessor(item.key, {
        Cell: ({ row }: { row: MRT_Row<TriageModelLabel> }) => {
          if (item.key === 'last_update_at') {
            const dateValue = new Date(
              row.original.last_update_at,
            ).toLocaleString();
            return (
              <ValueCell
                isLoading={isTableDataLoading || areRowsFetching}
                itemKey={item.key}
                value={dateValue}
              />
            );
          }
          return (
            <ValueCell
              isLoading={isTableDataLoading || areRowsFetching}
              isPercentage={PERCENTAGE_COLUMNS.includes(item.key)}
              itemKey={item.key}
              value={row.original[item.key]}
            />
          );
        },
        header: item.title,
        Header: (
          <TextWithInfo
            textColor={['grey', 800]}
            title={item.title}
            tooltip={item.tooltip || ''}
            variant='font14Bold'
          />
        ),
        size: item.size,
      });
    });
  }, [columnHelper, isTableDataLoading, areRowsFetching, updatedTableColumns]);

  const rows = useMemo(() => {
    if (isTableDataLoading && model) {
      return createLabelsFirstLoadingData(model, searchText);
    }

    if (!searchText) {
      return labels;
    }

    return filterSearchLabels(labels, searchText);
  }, [searchText, labels, model, isTableDataLoading]);

  const handleSearch = (value: string) => {
    setSearchText(value);
  };

  return (
    <TriageModelTable<TriageModelLabel, number | string | null>
      columns={columns}
      Control={
        <LabelsTableControl
          dateRange={dateRange}
          labelControlOptions={labelControlOptions}
          model={model}
        />
      }
      data={rows || []}
      dateRangeFilter={isSimulation ? undefined : dateRangeFilter}
      initialSorting={[{ desc: true, id: 'name' }]}
      initialState={{
        columnPinning: {
          left: ['name'],
        },
      }}
      isFetching={areRowsFetching}
      isLoading={!model}
      onSearch={handleSearch}
      options={{
        muiTableBodyRowProps: ({
          row,
        }: {
          row: { original: { tag_id: string } };
        }) => ({
          onClick: () => {
            // eslint-disable-next-line react/prop-types
            const tagId: string = row.original.tag_id;
            labelControlOptions.selectTagId(tagId);
          },
          sx: {
            cursor: 'pointer',
          },
        }),
      }}
      renderEmptyRowsFallback={() => <div></div>}
    />
  );
};

export default LabelsTable;
