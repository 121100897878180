import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';

import {
  formatUnixTimestampToDateAndTime,
  Typography,
} from '@forethought-technologies/forethought-elements';
import {
  createVersionName,
  getStatusIndicator,
  getTargetDate,
} from './helpers';
import { getVersionModifiedType } from './helpers';
import { createVersionBy } from './helpers';
import VersionControlMenu from './VersionControlMenu';
import StatusIndicator from 'src/components/status-indicator';
import useSelfServeEvents from 'src/hooks/triage/useSelfServeEvents';
import { VersionedTriageModel } from 'src/reducers/triageSettingsReducer/types';
import { TRIAGE_LLM_TRACKING_EVENTS } from 'src/utils/constants';

interface VersionControlItemProps {
  isCurrent?: boolean;
  item?: VersionedTriageModel;
  onSelect: (id: string) => void;
  selected: boolean;
  setLabelMappingIsOpen: (value: boolean) => void;
}

const VersionControlItem = ({
  isCurrent,
  item,
  onSelect,
  selected,
  setLabelMappingIsOpen,
}: VersionControlItemProps) => {
  const emitTrackingEventCallback = useSelfServeEvents({
    model: item,
  });
  const { palette } = useTheme();
  const grey = palette.colors.grey[600];

  if (!item) {
    return null;
  }

  const versionModifiedType = getVersionModifiedType(item);
  const targetDate = getTargetDate(versionModifiedType, item);
  const lastUpdateAtTimeStamp = Number(new Date(targetDate as string)) / 1000;

  const handleSelect = () => {
    emitTrackingEventCallback(
      TRIAGE_LLM_TRACKING_EVENTS.VIEW_OTHER_VERSION_CLICKED,
    );
    onSelect(item.version_id);
  };

  return (
    <Box
      alignItems='center'
      columnGap={1}
      display='grid'
      gridTemplateColumns='auto 32px'
      px={3}
      py={1.5}
      sx={{
        '&:hover': {
          backgroundColor: '#F8FAFE',
        },
        backgroundColor: selected ? palette.colors.slate[100] : undefined,
        cursor: 'pointer',
      }}
    >
      <Box
        display='flex'
        flexDirection='column'
        onClick={handleSelect}
        overflow='hidden'
        rowGap={0.5}
      >
        <Box alignItems='center' columnGap={1} display='flex'>
          {isCurrent && (
            <StatusIndicator status={getStatusIndicator(item.is_published)} />
          )}
          <Typography variant='font14Medium'>
            {createVersionName(item, isCurrent)}
          </Typography>
        </Box>
        <Box sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
          <Typography color={grey} variant='font12'>
            {createVersionBy(versionModifiedType, item)}
          </Typography>
        </Box>
        <Typography color={grey} variant='font12'>
          {formatUnixTimestampToDateAndTime(lastUpdateAtTimeStamp)}
        </Typography>
      </Box>
      <VersionControlMenu
        item={item}
        setLabelMappingIsOpen={setLabelMappingIsOpen}
      />
    </Box>
  );
};

export default VersionControlItem;
