import { useMemo } from 'react';
import { FieldArray, Form, Formik, FormikHelpers } from 'formik';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box } from '@mui/material';
import { IconTrash } from '@tabler/icons-react';

import {
  Button,
  IconButton,
  SelectDropdown,
  TextField,
  Tooltip,
  Typography,
} from '@forethought-technologies/forethought-elements';
import Spinner from 'src/components/spinner/Spinner';
import { useGetHelpdeskQuery } from 'src/services/dashboard-api';
import {
  useGetEmailSettingsQuery,
  useUpdateEmailSettingsMutation,
} from 'src/services/email-settings/emailSettingsApi';
import { EmailSettings } from 'src/services/email-settings/types';
import { setGlobalToastOptions } from 'src/slices/ui/uiSlice';
import { useAppDispatch } from 'src/store/hooks';

const defaultEmailSettings: EmailSettings = {
  email_response_width: 'fixed',
  from_email_mapping: null,
  modified_date: null,
  org_wide_email_name: null,
};

export function EmailSettingsPage() {
  const { data: helpdeskResponse } = useGetHelpdeskQuery();
  const { data: emailSettings, isLoading } = useGetEmailSettingsQuery();
  const [updateEmailSettings] = useUpdateEmailSettingsMutation();
  const dispatch = useAppDispatch();

  const initialValues = useMemo(() => {
    const settings = emailSettings ?? defaultEmailSettings;
    return {
      ...settings,
      from_email_mapping: Object.entries(settings.from_email_mapping ?? {}).map(
        ([key, value]) => ({
          key,
          value,
        }),
      ),
    };
  }, [emailSettings]);

  if (isLoading || !helpdeskResponse) {
    return <Spinner />;
  }

  const handleSubmit = async (
    values: typeof initialValues,
    formikHelpers: FormikHelpers<typeof initialValues>,
  ) => {
    const fromEmailMapping = values.from_email_mapping.reduce(
      (acc, { key, value }) => {
        acc[key] = value;
        return acc;
      },
      {} as Record<string, string>,
    );
    try {
      await updateEmailSettings({
        email_response_width: values.email_response_width,
        from_email_mapping: fromEmailMapping,
        last_modified_date: emailSettings?.modified_date ?? null,
        org_wide_email_name: values.org_wide_email_name,
      }).unwrap();

      // reset 'dirty' state of form
      formikHelpers.resetForm({ values });

      dispatch(
        setGlobalToastOptions({
          autoHideDuration: 3000,
          title: 'Email settings updated',
          variant: 'main',
        }),
      );
    } catch {
      dispatch(
        setGlobalToastOptions({
          subtitle:
            'This can happen if the email settings were updated by another user. Please refresh the page and try again.',
          title: 'Something went wrong updating email settings',
          variant: 'danger',
        }),
      );
    }
  };

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      {({ dirty, isSubmitting, setFieldValue, values }) => (
        <Form>
          <Box
            display='flex'
            flexDirection='column'
            gap={3}
            maxWidth='800px'
            p={4}
          >
            <SelectDropdown
              id='email_response_width'
              label='Email Response Width'
              onChange={e =>
                setFieldValue('email_response_width', e.target.value)
              }
              options={[
                {
                  description: 'Fix the width of email responses to 540px',
                  label: 'Fixed',
                  value: 'fixed',
                },
                {
                  description: 'Set no width constraint on email responses',
                  label: 'Full',
                  value: 'full',
                },
              ]}
              value={values.email_response_width}
            />
            {helpdeskResponse.helpdesk == 'salesforce' && (
              <>
                <TextField
                  label='Org-Wide Email Name'
                  onChange={e =>
                    setFieldValue('org_wide_email_name', e.target.value)
                  }
                  required={false}
                  tooltipContent='Name of the Salesforce org wide email used as the from address in the email thread.'
                  value={values.org_wide_email_name ?? ''}
                />

                <Box>
                  <Box
                    component='label'
                    htmlFor='from_email_mapping'
                    sx={{
                      alignItems: 'center',
                      display: 'flex',
                      gap: '8px',
                      marginBottom: '8px',
                    }}
                  >
                    <Typography variant='font14Medium'>
                      From Email Mapping
                    </Typography>

                    <Tooltip tooltipContent='Mapping of Case.RecordTypeId to OrgWideEmailAddress.DisplayName to use as from address. Values here will take precendence over the Org-Wide Email Address above.'>
                      <InfoOutlinedIcon fontSize='inherit' />
                    </Tooltip>
                  </Box>
                  <FieldArray name='from_email_mapping'>
                    {({ push, remove }) => (
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: 2,
                        }}
                      >
                        {values.from_email_mapping.map((mapping, index) => (
                          <Box key={index} sx={{ display: 'flex', gap: 1 }}>
                            <TextField
                              aria-label='Org-Wide Email Name'
                              onChange={e =>
                                setFieldValue(
                                  `from_email_mapping.${index}.key`,
                                  e.target.value,
                                )
                              }
                              placeholder='Case RecordTypeId'
                              value={mapping.key}
                            />
                            <TextField
                              aria-label='Org-Wide Email Name'
                              onChange={e =>
                                setFieldValue(
                                  `from_email_mapping.${index}.value`,
                                  e.target.value,
                                )
                              }
                              placeholder='Org-Wide Email Name'
                              value={mapping.value}
                            />
                            <Box>
                              <IconButton
                                aria-label='delete'
                                onClick={() => remove(index)}
                                size='large'
                                variant='ghost'
                              >
                                <IconTrash size={20} />
                              </IconButton>
                            </Box>
                          </Box>
                        ))}
                        <Button
                          onClick={() => push({ key: '', value: '' })}
                          type='button'
                          variant='secondary'
                        >
                          Add New From Email Mapping
                        </Button>
                      </Box>
                    )}
                  </FieldArray>
                </Box>
              </>
            )}
            <Button
              disabled={!dirty || isSubmitting}
              isLoading={isSubmitting}
              size='large'
              type='submit'
              variant='main'
            >
              Submit
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  );
}
