import { memo } from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';

import { EMAIL, WIDGET } from '../constants';
import { ProductMode } from '../types';
import { LabelBadge } from 'src/components/label-badge';
import ToggleButtonGroup from 'src/components/toggle-button-group';
import { selectIsUserSuperAdmin } from 'src/reducers/userReducer/userReducer';
import { capitalizeFirstLetter } from 'src/utils/capitalizeFirstLetter';

interface ProductToggleBarProps {
  productMode: ProductMode;
  setProductMode: (
    product: ProductMode,
    batchUpdater?: () => URLSearchParams,
  ) => void;
}

const ProductToggleBar = ({
  productMode,
  setProductMode,
}: ProductToggleBarProps) => {
  const isSuperAdmin = useSelector(selectIsUserSuperAdmin);

  if (!isSuperAdmin) {
    return null;
  }

  return (
    <Box
      borderBottom={theme => '1px solid ' + theme.palette.colors.slate[100]}
      p={3}
    >
      <ToggleButtonGroup
        alignment={productMode}
        items={[
          {
            label: capitalizeFirstLetter(WIDGET),
            value: WIDGET,
          },
          {
            icon: <LabelBadge badgeLabel='Internal' />,
            label: capitalizeFirstLetter(EMAIL),
            value: EMAIL,
          },
        ]}
        onChange={newAlignment => {
          if (!newAlignment) {
            return;
          }
          const isWidget = newAlignment === 'widget';
          setProductMode(
            newAlignment,
            // When navigating back to widget productMode, update article tab to default (widget does not have knowledge gap)
            isWidget
              ? () => new URLSearchParams({ articlesTab: '0' })
              : undefined,
          );
        }}
        toggleWidth='100%'
        width='404px'
      />
    </Box>
  );
};

const MemoizedProductToggleBar = memo(ProductToggleBar);

export default MemoizedProductToggleBar;
