import React from 'react';
import Box from '@mui/material/Box';

import {
  Button,
  Dialog,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { useGetIntentsQueryWithProduct } from 'src/hooks/hooks';
import {
  API_WORKFLOW_NOT_FOUND,
  EMAIL_WORKFLOW_NOT_CREATED,
  EMPTY_WORKFLOW,
  NO_LIVE_VERSION_IS_PUBLISHED,
} from 'src/pages/workflow-builder-edit/constants';
import { WorkflowTypes } from 'src/pages/workflow-builder-edit/types';
import {
  useActivateIntentWorkflowMutation,
  useDeactivateIntentWorkflowMutation,
} from 'src/services/workflow-builder-metrics';
import { setGlobalToastOptions } from 'src/slices/ui/uiSlice';
import { useAppDispatch } from 'src/store/hooks';
import { isApiError } from 'src/types/types';

interface ActivateWorkflowModalProps {
  intentWorkflowId: string;
  isActive: boolean;
  isDialogVisible: boolean;
  message: string;
  onClose: () => void;
  onSuccess?: () => void;
  workflowTypes: WorkflowTypes[];
}

const apiErrors = [
  EMPTY_WORKFLOW,
  NO_LIVE_VERSION_IS_PUBLISHED,
  EMAIL_WORKFLOW_NOT_CREATED,
  API_WORKFLOW_NOT_FOUND,
];

const ActivateWorkflowModal: React.FC<
  React.PropsWithChildren<ActivateWorkflowModalProps>
> = ({
  intentWorkflowId,
  isActive,
  isDialogVisible,
  message,
  onClose,
  onSuccess,
  workflowTypes,
}) => {
  const dispatch = useAppDispatch();
  const [activateIntentWorkflow, { isLoading: isLoadingActivate }] =
    useActivateIntentWorkflowMutation();
  const [deactivateIntentWorkflow, { isLoading: isLoadingDeactivate }] =
    useDeactivateIntentWorkflowMutation({
      fixedCacheKey: 'deactivateIntentWorkflow',
    });
  const { isFetching } = useGetIntentsQueryWithProduct();
  const isLoading = isLoadingActivate || isLoadingDeactivate || isFetching;

  const activateWorkflow = async () => {
    try {
      await activateIntentWorkflow({
        intentWorkflowId,
        workflowTypes,
      }).unwrap();
      onSuccess?.();
    } catch (error) {
      if (isApiError(error) && apiErrors.includes(error.data?.error_type)) {
        dispatch(
          setGlobalToastOptions({
            autoHideDuration: 10000,
            title: error.data.error_message,
            variant: 'warning',
          }),
        );
      }
    }
  };

  const deactivateWorkflow = async () => {
    try {
      await deactivateIntentWorkflow({
        intentWorkflowId,
        workflowTypes,
      }).unwrap();
      onSuccess?.();
    } catch {
      dispatch(
        setGlobalToastOptions({
          autoHideDuration: 4000,
          title: 'Something went wrong deactivating workflow.',
          variant: 'danger',
        }),
      );
    }
  };

  const onPositiveOptionSelected = async () => {
    if (isActive) {
      await deactivateWorkflow();
    } else {
      await activateWorkflow();
    }

    onClose();
  };

  return (
    <Dialog
      footer={
        <>
          <Button disabled={isLoading} onClick={onClose} variant='ghost'>
            No
          </Button>
          <Button
            disabled={isLoading}
            isLoading={isLoading}
            onClick={onPositiveOptionSelected}
            variant='secondary'
          >
            Yes
          </Button>
        </>
      }
      hideCloseButton
      onClose={onClose}
      open={isDialogVisible}
      title={isActive ? 'Deactivate?' : 'Activate?'}
    >
      <Box maxWidth='400px'>
        <Typography variant='font14'>{message}</Typography>
      </Box>
    </Dialog>
  );
};

export default ActivateWorkflowModal;
