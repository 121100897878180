import { useMemo, useState } from 'react';
import Box from '@mui/material/Box';

import NotFound from '../../triage-version-control-detail/NotFound';
import { TriageModelLabel } from '../types';
import EmptyLabelsTable from './EmptyLabelsTable';
import LabelsOverview from './LabelsOverview';
import LabelsTable from './LabelsTable';
import Spinner from 'src/components/spinner';
import {
  DISCOVER_SHARED_PARAM_NAMES,
  timeFilterOptions,
} from 'src/constants/discover';
import { useStateParams } from 'src/hooks/hooks';
import {
  useGetModelByIdQuery,
  useGetModelLabelsQuery,
} from 'src/services/triage/triageApi';
import {
  dateRangeDeserialize,
  dateRangeSerialize,
  timeFilterParameterValidator,
} from 'src/utils/discover/helpers';
import { last14DaysTimeRange } from 'src/utils/timeRangeHelpers';

const LabelsTab = ({ modelId }: { modelId: string }) => {
  const {
    data: currentVersionedModel,
    isError,
    isLoading: isModelLoading,
  } = useGetModelByIdQuery({ modelId: modelId || '' });
  const [dateRange, setDateRange] = useStateParams({
    deserialize: dateRangeDeserialize,
    initialState: last14DaysTimeRange,
    paramsName: DISCOVER_SHARED_PARAM_NAMES.TIME_FILTER,
    serialize: dateRangeSerialize,
    validator: timeFilterParameterValidator(timeFilterOptions),
  });
  const [selectedTagId, selectTagId] = useState<string | null>(null);
  const {
    data: tableData,
    isFetching: isTableFetching,
    isLoading: isTableLoading,
  } = useGetModelLabelsQuery({
    endDate: dateRange.to?.valueOf() || 0,
    modelId,
    startDate: dateRange.from?.valueOf() || 0,
  });
  const { labels } = tableData || {};
  const labelSet = useMemo<TriageModelLabel[]>(() => {
    if (currentVersionedModel && !labels) {
      return currentVersionedModel.labels.map(label => ({
        ...label,
        accuracy: 0,
        actual_count: 0,
        correct_count: 0,
        coverage: 0,
        finalized_count: 0,
        last_update_at: '',
        last_updated_by: '',
        name: label.title,
        pending_count: 0,
        predicted_count: 0,
        prediction_count: 0,
        qualified_count: 0,
        recall: null,
      }));
    }
    if (!currentVersionedModel || !labels) {
      return [];
    }
    return labels;
  }, [currentVersionedModel, labels]);

  const isLoading = isModelLoading;
  if (isLoading) {
    return <Spinner />;
  }

  if (!currentVersionedModel || isError) {
    return <NotFound />;
  }

  const isEmpty = !labelSet.length;
  const grid = isEmpty ? 'auto 110px auto' : 'auto minmax(350px, auto)';

  return (
    <Box display='grid' gridTemplateRows={grid} rowGap={5}>
      <LabelsOverview
        dateRange={dateRange}
        isLoading={isTableLoading || isTableFetching}
        overview={tableData?.overview}
      />
      <LabelsTable
        areRowsFetching={isTableFetching || isTableLoading}
        dateRange={dateRange}
        isTableDataLoading={isTableLoading}
        labelControlOptions={{
          selectTagId,
          tagId: selectedTagId,
        }}
        labels={labelSet}
        model={currentVersionedModel}
        onDateRangeChange={setDateRange}
      />
      {isEmpty && <EmptyLabelsTable />}
    </Box>
  );
};

export default LabelsTab;
