import { useEffect, useMemo } from 'react';
import { MRT_Row } from 'material-react-table';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';

import { generateDiscoverTopicDetailUrl } from '../helpers';
// import { MAX_RECOMMENDATIONS } from '../constants';
import { AutomationTabComponentProps } from '../types';
import DiscoverRecommendationTable from './DiscoverRecommendationTable';
import debounce from 'lodash/fp/debounce';
import { DEBOUNCED_AUTOMATION_TRACKING_EVENT_TIMEOUT } from 'src/constants/discover';
import { useEmitTrackingEventCallback } from 'src/hooks/hooks';
import { selectRecommendations } from 'src/reducers/discoverReducer/discoverReducer';
import { DiscoverTopic } from 'src/reducers/discoverReducer/types';
import { setGlobalDiscoverOptions } from 'src/slices/ui/uiSlice';
import { useAppDispatch } from 'src/store/hooks';

const DiscoverRecommendationTab = ({
  // hasScrolled,
  setHasScrolled,
}: AutomationTabComponentProps) => {
  const { data, error, loading } = useSelector(selectRecommendations);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const emitTrackingEventCallback = useEmitTrackingEventCallback();

  const handleMouseEnter = useMemo(() => {
    return debounce(
      DEBOUNCED_AUTOMATION_TRACKING_EVENT_TIMEOUT,
      (row: MRT_Row<DiscoverTopic>) =>
        emitTrackingEventCallback('discover-recommendation-hovered', {
          page: 'Automation',
          'page-area': 'Recommendation',
          'topic-name': row.original.topic_name,
        }),
    );
  }, [emitTrackingEventCallback]);

  useEffect(() => {
    setHasScrolled(false);
  }, [setHasScrolled]);

  const handleSelect = (row: MRT_Row<DiscoverTopic>) => {
    const topic = row.original;
    const link = generateDiscoverTopicDetailUrl(topic);
    navigate(link);
  };

  const handleScroll = (scroll: number) => {
    setHasScrolled(Boolean(scroll));
  };

  const handleAutomationClick = (row: MRT_Row<DiscoverTopic>) => {
    emitTrackingEventCallback('discover-automate-button-clicked', {
      page: 'Automation',
      'page-area': 'Recommendation',
      'topic-name': row.original.topic_name,
    });

    dispatch(
      setGlobalDiscoverOptions({
        topicToAutomate: row.original,
      }),
    );
  };

  return (
    <Box height='71vh' pt={2}>
      <DiscoverRecommendationTable
        data={data?.recommendations}
        isError={Boolean(error)}
        loading={loading}
        onAutomationClick={handleAutomationClick}
        onMouseEnter={handleMouseEnter}
        onScroll={handleScroll}
        onSelect={handleSelect}
      />
    </Box>
  );
};

export default DiscoverRecommendationTab;
