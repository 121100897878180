import React from 'react';
import { Box, useTheme } from '@mui/material';

import DataOverviewCard from 'src/components/reusable-components/data-overview-card';
import DataOverviewCardSkeleton from 'src/components/reusable-components/data-overview-card/DataOverviewCardSkeleton';

const oneRowCardLimit = 6;

export interface AggregateMetric {
  /** color to render metric */
  color?: string;
  /** Difference Value */
  difference?: number;
  isLoading?: boolean;
  /** Label for the component */
  label: string;
  /** Optional tooltip content */
  tooltip?: string;
  /** value to be displayed */
  value?: string | AggregateMetric[];
}

interface AggregateMetricsProps {
  isDeflectionInsightsEnabled?: boolean;
  metrics: AggregateMetric[] | null;
  numLoadingSkeletons?: number;
}

export default function AggregateMetrics({
  isDeflectionInsightsEnabled,
  metrics,
  numLoadingSkeletons,
}: AggregateMetricsProps) {
  const { breakpoints } = useTheme();

  const containsSubMetric = Boolean(
    metrics?.find(metric => Array.isArray(metric.value)),
  );

  const metricsLength = metrics?.length || 0;

  const defaultRepeatCount =
    metricsLength <= oneRowCardLimit ? metricsLength : oneRowCardLimit;

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: `repeat(${
          defaultRepeatCount || numLoadingSkeletons
        }, 1fr)`,
        marginBottom: '24px',
        width: '100%',
        [breakpoints.down('xl')]: {
          gridTemplateColumns: 'repeat(4, 1fr)',
        },
        [breakpoints.down('md')]: {
          gridTemplateColumns: 'repeat(2, 1fr)',
        },
      }}
    >
      {!numLoadingSkeletons &&
        metrics?.map(
          ({ color, difference, isLoading, label, tooltip, value }, index) => {
            return (
              <CardContainer
                cardIndex={index}
                containsSubMetric={containsSubMetric}
                data-testid={`aggregate-card-${label}`}
                isDeflectionInsightsEnabled={isDeflectionInsightsEnabled}
                key={index}
                totalCards={metricsLength}
              >
                {isLoading ? (
                  <DataOverviewCardSkeleton />
                ) : (
                  <DataOverviewCard
                    color={color}
                    difference={difference}
                    label={label}
                    tooltip={tooltip}
                    value={value}
                  />
                )}
              </CardContainer>
            );
          },
        )}
      {!!numLoadingSkeletons &&
        Array.from({ length: numLoadingSkeletons }).map((_, index) => (
          <CardContainer
            cardIndex={index}
            key={index}
            totalCards={numLoadingSkeletons}
          >
            <DataOverviewCardSkeleton />
          </CardContainer>
        ))}
    </Box>
  );
}

function CardContainer({
  cardIndex,
  children,
  containsSubMetric,
  isDeflectionInsightsEnabled,
  totalCards,
  ...rest
}: {
  cardIndex: number;
  children: React.ReactNode;
  containsSubMetric?: boolean;
  isDeflectionInsightsEnabled?: boolean;
  totalCards: number;
}) {
  if (containsSubMetric && !isDeflectionInsightsEnabled) {
    return <Box flex={1}>{children}</Box>;
  }

  const isExceedingRowCardLimit = totalCards > oneRowCardLimit;

  // If there are more than oneRowCardLimit cards, add bottom margin to first oneRowCardLimit cards.
  const fullWidthMarginBottom =
    isExceedingRowCardLimit && cardIndex <= oneRowCardLimit - 1 ? '24px' : 0;

  return (
    <Box
      sx={{
        mb: fullWidthMarginBottom,
        paddingRight: 2,
      }}
      {...rest}
    >
      {children}
    </Box>
  );
}
