import { useState } from 'react';

import {
  Button,
  Dialog,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { useGetDeleteWorkflowMessage } from '../workflow-builder-edit/hooks';
import { useDeleteIntentMutation } from 'src/services/workflow-builder-canvas/workflowBuilderCanvasApi';
import { setGlobalToastOptions } from 'src/slices/ui/uiSlice';
import { useAppDispatch } from 'src/store/hooks';

interface DeleteWorkflowDialogProps {
  intentIdToDelete: string;
  isOpen: boolean;
  onClose: () => void;
  onSuccess?: () => void;
}

export const DeleteWorkflowDialog = ({
  intentIdToDelete,
  isOpen,
  onClose,
  onSuccess,
}: DeleteWorkflowDialogProps) => {
  const dispatch = useAppDispatch();
  const deleteMessage = useGetDeleteWorkflowMessage();
  const [isDeleting, setIsDeleting] = useState(false);
  const [mutate] = useDeleteIntentMutation({
    fixedCacheKey: 'deleteIntent',
  });

  return (
    <Dialog
      footer={
        <>
          <Button onClick={onClose} variant='ghost'>
            Cancel
          </Button>
          <Button
            aria-label='Delete confirm'
            disabled={isDeleting}
            isLoading={isDeleting}
            onClick={async () => {
              setIsDeleting(true);
              try {
                const res = await mutate({
                  intentDefinitionId: intentIdToDelete,
                });

                if ('data' in res && res.data.success) {
                  onSuccess?.();
                  dispatch(
                    setGlobalToastOptions({
                      autoHideDuration: 3000,
                      title: 'Intent deleted successfully',
                      variant: 'main',
                    }),
                  );
                }
              } catch (error) {
                dispatch(
                  setGlobalToastOptions({
                    autoHideDuration: 3000,
                    subtitle: 'Please try again later',
                    title: 'Intent could not be deleted',
                    variant: 'danger',
                  }),
                );
              }

              setIsDeleting(false);
              onClose();
            }}
            variant='secondary'
          >
            Delete
          </Button>
        </>
      }
      hideCloseButton
      onClose={onClose}
      open={isOpen}
      title='Delete?'
    >
      <Typography variant='font14'>
        Are you sure you want to delete this item? <br />
        {deleteMessage}
      </Typography>
    </Dialog>
  );
};
