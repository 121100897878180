import { ReactSVG } from 'react-svg';
import { StyledComponent } from '@emotion/styled';
import { styled } from '@mui/material';

import { Colors, theme } from '@forethought-technologies/forethought-elements';

export const Outline = styled('div')<{
  isCustomHandoffIntent?: boolean;
  isSqueezingConditionMode?: boolean;
}>`
  border: 1px solid
    ${props =>
      props.isCustomHandoffIntent
        ? theme.palette.colors.slate[800]
        : theme.palette.colors.green[500]};
  border-radius: 20px;
  min-height: 40px;
  min-width: 472px;
  margin: 10px 5px 10px 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
`;

export const Icon: StyledComponent<
  // eslint-disable-next-line @typescript-eslint/ban-types -- {} is used by default
  {},
  // eslint-disable-next-line @typescript-eslint/ban-types -- {} is used by default
  {},
  { src: string }
> = styled(ReactSVG)`
  position: relative;
  top: 2px;
  margin-right: 7px;
`;

export const Container = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const Row = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const DescriptionAndIcon = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: ${theme.typography.font11.fontSize};
  font-weight: var(--font-weight-bold);
  line-height: ${theme.typography.font11.lineHeight};
  color: ${Colors.ui.text.tertiary};
`;
