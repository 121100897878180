import { useMemo, useState } from 'react';
import { createMRTColumnHelper, MRT_Row } from 'material-react-table';
import Box from '@mui/material/Box';

import {
  Button,
  InfiniteTable,
  SearchBar,
  Tooltip,
  Typography,
} from '@forethought-technologies/forethought-elements';
import EmptyTabState from '../components/EmptyTabState';
import NumberValueCell from '../components/NumberValueCell';
import { TABLE_COLUMNS } from './constants';
import get from 'lodash/get';
import { DISCOVER_CARD_TEXT } from 'src/components/global-discover/constants';
import TableOverflowCell from 'src/components/table-overflow-cell';
import { DiscoverTopic } from 'src/reducers/discoverReducer/types';

interface DiscoverRecommendationTableProps {
  data?: DiscoverTopic[];
  isError: boolean;
  loading: boolean;
  onAutomationClick: (row: MRT_Row<DiscoverTopic>) => void;
  onMouseEnter: (row: MRT_Row<DiscoverTopic>) => void;
  onScroll: (scroll: number) => void;
  onSelect: (row: MRT_Row<DiscoverTopic>) => void;
}

const DiscoverRecommendationTable = ({
  data,
  isError,
  loading,
  onAutomationClick,
  onMouseEnter,
  onScroll,
  onSelect,
}: DiscoverRecommendationTableProps) => {
  const [searchText, setSearchText] = useState('');
  const columnHelper = createMRTColumnHelper<DiscoverTopic>();

  const rows = useMemo(() => {
    if (!searchText) {
      return data;
    }
    return data?.filter(row =>
      row.topic_name.toLowerCase().includes(searchText.toLowerCase()),
    );
  }, [searchText, data]);

  const columns = useMemo(
    () =>
      TABLE_COLUMNS.map(colItem =>
        columnHelper.accessor(colItem.key, {
          Cell: ({ row }: { row: MRT_Row<DiscoverTopic> }) => {
            const { actionable_value, topic_name } = row.original;

            if (!actionable_value) {
              return (
                <Typography variant='font14'>
                  {row.original[colItem.key as keyof DiscoverTopic] as string}
                </Typography>
              );
            }

            const columnToComponentMap = {
              cost: (
                <NumberValueCell
                  prefix='$'
                  value={actionable_value?.solve_workflow?.cost}
                />
              ),
              topic_name: (
                <Tooltip tooltipContent={topic_name}>
                  <TableOverflowCell>
                    <Typography variant='font14'>{topic_name}</Typography>
                  </TableOverflowCell>
                </Tooltip>
              ),
              volume: (
                <NumberValueCell
                  value={actionable_value?.solve_workflow?.volume}
                />
              ),
            };

            const key =
              colItem.target ||
              (colItem.key as 'cost' | 'volume' | 'topic_name');

            return get(columnToComponentMap, key);
          },
          header: colItem.title,
          size: colItem.size,
        }),
      ),
    [columnHelper],
  );

  const handleSearch = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    setSearchText(event.target.value);
  };

  return (
    <InfiniteTable
      hasNextPage={false}
      initialSorting={[{ desc: true, id: 'volume' }]}
      isError={isError}
      isLoadingFirstPage={loading}
      isLoadingNextPage={false}
      stickyHeaderHeight={0}
      tableOptions={{
        columns,
        data: rows || [],
        displayColumnDefOptions: {
          'mrt-row-actions': {
            header: '',
            muiTableBodyCellProps: {
              align: 'center',
            },
            muiTableHeadCellProps: {
              align: 'center',
            },
            size: 130,
          },
        },
        enableBottomToolbar: false,
        enableColumnActions: false,
        enableColumnFilters: false,
        enableExpanding: false,
        enableFilters: false,
        enableFullScreenToggle: false,
        enableGlobalFilter: false,
        enableRowActions: true,
        initialState: {
          columnPinning: {
            right: ['mrt-row-actions'],
          },
        },
        manualSorting: false,
        muiTableBodyRowProps: ({ row }) => ({
          onClick: () => onSelect(row),
          onMouseEnter: () => onMouseEnter(row),
          sx: {
            cursor: 'pointer',
          },
        }),
        muiTableContainerProps: () => ({
          onScroll: event => onScroll(event.currentTarget.scrollTop),
          sx: {
            height: 'calc(100vh - 300px)',
          },
        }),
        renderEmptyRowsFallback: () => <EmptyTabState type='recommendation' />,
        renderRowActions: ({ row }) => (
          <Tooltip tooltipContent={DISCOVER_CARD_TEXT.automateButtonTooltip}>
            <Button
              onClick={event => {
                event.stopPropagation();
                onAutomationClick(row);
              }}
              variant='ghost'
            >
              <Typography variant='font14Bold'>Automate</Typography>
            </Button>
          </Tooltip>
        ),
        renderTopToolbarCustomActions: () => (
          <Box>
            <SearchBar
              onChange={handleSearch}
              onClear={() => setSearchText('')}
              placeholder='Search topic'
              size='small'
            />
          </Box>
        ),
      }}
    />
  );
};

export default DiscoverRecommendationTable;
