import { useCallback, useMemo, useState } from 'react';
import { MRT_TableOptions } from 'material-react-table';
import { useParams } from 'react-router-dom';
import { ValidationError } from 'yup';
import { Box, useTheme } from '@mui/material';

import {
  Button,
  Checkbox,
  DateRangeFilterButton,
  InfiniteTable,
  MultiSelectFilter,
  Order,
  SearchWithDropdown,
  Tooltip,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { DateRange } from '../../discover-dashboard-page/types';
import InsightDownloadCsv from '../common/InsightDownloadCsv';
import { initialInsightParams, INTENT_FILTER_PARAM_NAME } from '../constants';
import {
  dateRangeToTimestamp,
  sortDirectionToInsightSortDirection,
} from '../helpers';
import { useGetSearchQuery } from '../hooks/useGetSearchQuery';
import { InsightPeriod, InsightSortDirection, Scope } from '../types';
import ChatsTableLayout from './ChatsTableLayout';
import { buildConversationColumns, getTableHeight, View } from './helpers';
import isEqual from 'lodash/fp/isEqual';
import _orderBy from 'lodash/fp/orderBy';
import filterIcon from 'src/assets/images/filter-analytic-icon.svg';
import WithHelperText from 'src/components/with-helper-text/WithHelperText';
import {
  useEmitTrackingEventCallback,
  useGetIntentsQueryWithProduct,
  useStateParams,
} from 'src/hooks/hooks';
import {
  ALLTIME_START,
  DEFLECTION_INSIGHT_FILTER_OPTIONS,
  getChatInsightsFilterOptions,
} from 'src/pages/intent-conversation-analytics/constants';
import ConversationDrawer from 'src/pages/intent-conversation-analytics/ConversationDrawer';
import { buildQueryFromMultiSelected } from 'src/pages/intent-conversation-analytics/hooks/useConversations';
import useGetCSATColor from 'src/pages/intent-conversation-analytics/hooks/useGetCSATColor';
import useIsDeflectionInsightsEnabled from 'src/pages/intent-conversation-analytics/hooks/useIsDeflectionInsightsEnabled';
import {
  filterCommonIntentWorkflowsToTop,
  IntentTableDataFilter,
} from 'src/pages/workflow-builder/intent-workflows-table/helper';
import { useGetFeatureFlagsQuery } from 'src/services/dashboard-api';
import {
  useGetConversationQuery,
  useGetConversationTableQuery,
} from 'src/services/insights';
import {
  InsightConversation,
  InsightConversationSortColumns,
} from 'src/services/insights/types';
import { ConversationChannel } from 'src/types/workflowBuilderAPITypes';
import {
  dateRangeToTimeFilter,
  genericSerializeAndDeserialize,
  listDeserialize,
  listSerialize,
} from 'src/utils/discover/helpers';
import { CommonIntentWorkflowType, ExportableTableType } from 'src/utils/enums';
import { datePickerRangeOptionsRevamp } from 'src/utils/timeRangeHelpers';
import { uuidOrEmptySchema } from 'src/utils/validators';

interface ChatsTableProps {
  dateRange: DateRange;
  handleFilterReset?: () => void;
  isDetail?: boolean;
  period?: InsightPeriod;
  scope: Scope;
  scrollTop: number;
  scrollToTop: (yOffset: number, smooth?: boolean) => void;
  setDateRange: (
    dateRange: DateRange,
    batchUpdater?: () => URLSearchParams,
  ) => URLSearchParams;
  setPeriodicalFilter?: (period: InsightPeriod) => void;
  view: View;
}

const ChatsTable = ({
  dateRange,
  handleFilterReset,
  isDetail,
  period,
  scope,
  scrollToTop,
  setDateRange,
  setPeriodicalFilter,
  view,
}: ChatsTableProps) => {
  const { articleId = undefined } = useParams<'articleId'>();
  const { topicId = undefined } = useParams<'topicId'>();
  const { tableTab = undefined } = useParams<'tableTab'>();
  const { workflowId = undefined } = useParams<'workflowId'>();
  const isParentTopic = location.pathname.includes('parent');
  const [page, setPage] = useState(1);
  const getCSATColor = useGetCSATColor();
  const { palette } = useTheme();
  const [multiSelected, setMultiSelected] = useStateParams<string[]>({
    deserialize: listDeserialize,
    initialState: [],
    paramsName: INTENT_FILTER_PARAM_NAME,
    serialize: listSerialize,
  });

  const deserializeSortDirection = useCallback(
    (param: string) => (param === 'ASC' ? 'ASC' : 'DESC'),
    [],
  );

  const deserializeSortColumn = useCallback(
    (param: string) => param as InsightConversationSortColumns,
    [],
  );

  const { data: featureFlagsData } = useGetFeatureFlagsQuery();
  const { feature_flags: featureFlags = [] } = featureFlagsData ?? {};
  const isQuickFeedbackEnabled = featureFlags.includes('quick_feedback');
  const isQuickFeedbackAnalyticsEnabled = featureFlags.includes(
    'show_quick_feedback_analytics',
  );
  const shouldShowQuickFeedbackAnalytics =
    isQuickFeedbackEnabled && isQuickFeedbackAnalyticsEnabled;
  // state params
  const [sortDirection, setSortDirection] =
    useStateParams<InsightSortDirection>({
      deserialize: deserializeSortDirection,
      initialState: 'DESC',
      paramsName: 'chats_sort_direction',
      serialize: String,
    });
  const [searchQuery, setSearchQuery] = useStateParams<string>({
    deserialize: (str: string) => (str === 'null' ? '' : str),
    initialState: '',
    paramsName: 'chats_search',
    serialize: genericSerializeAndDeserialize,
  });
  type SearchQueryMode = 'conversation_id' | 'user_query' | 'chat';
  const [searchQueryMode, setSearchQueryMode] = useStateParams<SearchQueryMode>(
    {
      deserialize: genericSerializeAndDeserialize as (
        str: string,
      ) => SearchQueryMode,
      initialState: 'conversation_id',
      paramsName: 'chats_search_mode',
      serialize: genericSerializeAndDeserialize,
    },
  );
  const [sortColumn, setSortColumn] =
    useStateParams<InsightConversationSortColumns>({
      deserialize: deserializeSortColumn,
      initialState: initialInsightParams.chatColumnSort,
      paramsName: 'chats_sort_column',
      serialize: String,
    });
  // end state params

  // Hooks
  const emitTrackingEventCallback = useEmitTrackingEventCallback();

  const getCurrentTab = () => {
    if (topicId || isParentTopic) {
      return 'topic';
    }
    if (workflowId) {
      return 'workflow';
    }
    if (articleId || tableTab === '1') {
      return 'article';
    }
    return 'chat';
  };

  const tab = getCurrentTab();

  const { setUiQuery, uiQuery } = useGetSearchQuery({
    scope,
    searchQuery,
    setSearchQuery,
    tab,
    type: 'chat',
  });

  const uiQueryError = (() => {
    if (searchQueryMode !== 'conversation_id') {
      return '';
    }

    try {
      uuidOrEmptySchema.validateSync(uiQuery);

      return '';
    } catch (err) {
      return err instanceof ValidationError
        ? err.message
        : 'An unexpected error occurred';
    }
  })();

  const isDeflectionInsightsEnabled = useIsDeflectionInsightsEnabled();

  // get conversation details once clicked
  const [selectedConversationId, setSelectedConversationId] = useState<
    string | null
  >(null);
  const {
    data: conversationData,
    isFetching: isFetchingConversation,
    isLoading: isLoadingConversation,
  } = useGetConversationQuery(
    { conversation_id: selectedConversationId || '' },
    {
      skip: !selectedConversationId,
    },
  );
  // end get conversation details once clicked

  const columns = useMemo(
    () =>
      buildConversationColumns(
        palette,
        getCSATColor,
        emitTrackingEventCallback,
        scope,
        tab,
        isDetail,
        isDeflectionInsightsEnabled,
        shouldShowQuickFeedbackAnalytics,
      ),
    [
      palette,
      getCSATColor,
      isDetail,
      emitTrackingEventCallback,
      scope,
      tab,
      isDeflectionInsightsEnabled,
      shouldShowQuickFeedbackAnalytics,
    ],
  );
  const backendReadyTimestamps = dateRangeToTimestamp(dateRange);

  const isSearchingByChatId =
    searchQueryMode === 'conversation_id' && uiQuery && !uiQueryError;

  const {
    data: conversationTableData,
    isError: isChatsTableError,
    isFetching: isConversationTableFetching,
    isLoading: isConversationTableLoading,
  } = useGetConversationTableQuery({
    article_id: articleId,
    chat: searchQueryMode === 'chat' ? searchQuery : '',
    conversation_id:
      searchQueryMode === 'conversation_id' && !uiQueryError ? searchQuery : '',
    data_export_type: ExportableTableType.INSIGHT_CHATS_TABLE,
    is_parent_topic: isParentTopic,
    sort_column: sortColumn,
    sort_direction: sortDirection,
    topic_id: topicId,
    user_query: searchQueryMode === 'user_query' ? searchQuery : '',
    workflow_id: workflowId,

    ...(isSearchingByChatId
      ? // Use maximum date range:
        {
          end: Math.floor(Date.now() / 1000),
          page: 1,
          start: Number(ALLTIME_START),
        }
      : {
          end: backendReadyTimestamps.end_timestamp,
          page,
          start: backendReadyTimestamps.start_timestamp,
          ...buildQueryFromMultiSelected(multiSelected),
        }),
  });

  const rows = useMemo(() => {
    if (!conversationTableData?.data) {
      return [];
    }
    return conversationTableData.data;
  }, [conversationTableData]);

  const showLoadingSkeleton =
    isConversationTableLoading || (page === 1 && isConversationTableFetching);

  const timeFilter = dateRangeToTimeFilter(dateRange);
  const initialTimeFilter = dateRangeToTimeFilter(initialInsightParams.date);

  const selectedConversation = useMemo(() => {
    if (!selectedConversationId || !conversationData) {
      return;
    }
    return conversationData;
  }, [conversationData, selectedConversationId]);

  const areFiltersUsed = Boolean(
    uiQuery.length ||
      timeFilter.key !== initialTimeFilter.key ||
      multiSelected.length ||
      (period && period !== initialInsightParams.period),
  );

  const getChatsTableDownloadUrl = useCallback(() => {
    const url = new URL(`${API_URL}data-export`);
    return url.href;
  }, []);

  const handleSortCallback = useCallback(
    (property: keyof InsightConversation, order: Order) => {
      setPage(1);
      setSortDirection(sortDirectionToInsightSortDirection(order));
      setSortColumn(
        property === 'useful_count'
          ? 'quick_feedback'
          : (property as InsightConversationSortColumns),
      );
      scrollToTop(72);
      emitTrackingEventCallback('insight-table-sort-change', {
        column: property,
        direction: order,
        scope,
        tab,
        table: 'chat',
      });
    },
    [
      tab,
      scrollToTop,
      emitTrackingEventCallback,
      scope,
      setSortColumn,
      setSortDirection,
    ],
  );

  const initialSorting = useMemo(
    () => [
      {
        desc: sortDirection === 'DESC',
        id: sortColumn,
      },
    ],
    [sortColumn, sortDirection],
  );

  const handleLoadMore = useCallback(() => {
    const nextPage = page + 1;
    setPage(nextPage);
    emitTrackingEventCallback('insight-table-load-change', {
      currentPage: nextPage,
      scope,
      tab,
      table: 'chat',
    });
  }, [tab, emitTrackingEventCallback, page, scope]);

  const { data: intentsData } = useGetIntentsQueryWithProduct();

  // TODO: get this from query param once channels are implemented:
  const channel: ConversationChannel = 'widget';

  const intentsForFilter = useMemo(() => {
    if (!intentsData?.intents) {
      return [];
    }

    const filteredByChannel = intentsData.intents.filter(intent =>
      intent.channels.includes(channel),
    );
    const filter = new IntentTableDataFilter(filteredByChannel);
    const filteredTable = filter
      .setIsOtherQuestionsActive(channel)
      .setDefaultHandoffWorkflowActive();

    const withDefaultSorting = _orderBy(
      [
        item => item.active_workflow_types.length > 0,
        item => item.inquiry_count_per_channel[channel],
      ],
      ['desc', 'desc'],
      filteredTable.getTableData(),
    );

    // always show knowledge retrieval and handoff workflows on top
    const filtered = filterCommonIntentWorkflowsToTop(withDefaultSorting);

    return filtered.map(intent => ({
      label: intent.intent_name,
      value: `workflow_id.${intent.intent_workflow_id}`,
    }));
  }, [intentsData?.intents]);

  const filterOptions = useMemo(
    () =>
      getChatInsightsFilterOptions([
        // Hide workflows filter on workflow detail page:
        ...(tab === 'workflow'
          ? []
          : [
              {
                label: 'All workflows',
                options: intentsForFilter,
                value: 'workflow_id',
              },
            ]),
        ...(isDeflectionInsightsEnabled
          ? DEFLECTION_INSIGHT_FILTER_OPTIONS
          : []),
      ]),
    [intentsForFilter, isDeflectionInsightsEnabled, tab],
  );

  const searchOptions = useMemo(
    () =>
      [
        { text: 'Chat ID', value: 'conversation_id' },
        { text: 'Query', value: 'user_query' },
        { text: 'Chat', value: 'chat' },
      ] as const,
    [],
  );

  const fallbackWorkflowId = intentsData?.intents.find(
    intent => intent.intent_definition_id === CommonIntentWorkflowType.FALLBACK,
  )?.intent_workflow_id;

  const tableOptions = useMemo<MRT_TableOptions<InsightConversation>>(
    () => ({
      columns,
      data: rows,
      enableColumnActions: false,
      enableColumnFilters: false,
      enableExpanding: false,
      enableFullScreenToggle: false,
      enableGlobalFilter: false,
      enableRowActions: false,
      initialState: {
        columnPinning: {
          left: ['timestamp'],
        },
      },
      muiTableBodyRowProps: ({ row }) => ({
        onClick: () => {
          setSelectedConversationId(row.original.conversation_id);
          emitTrackingEventCallback('insight-table-row-clicked', {
            id: row.original.conversation_id,
            scope,
            tab,
            table: 'chat',
          });
        },
      }),
      renderTopToolbarCustomActions: () => (
        <Box
          alignItems='center'
          display='flex'
          gap='8px'
          paddingTop='4px'
          width='100%'
        >
          <Box width='360px'>
            <WithHelperText helperText={uiQueryError}>
              <SearchWithDropdown
                aria-label='Chat ID filter'
                fullWidth
                onChange={e => setUiQuery(e.target.value)}
                onClear={() => setUiQuery('')}
                options={[...searchOptions]}
                placeholder={`Enter ${searchOptions
                  .find(option => option.value === searchQueryMode)
                  ?.text.toLowerCase()}`}
                selectChange={value => {
                  setSearchQueryMode(value as SearchQueryMode);
                  setUiQuery('');
                }}
                selectedOption={searchQueryMode}
                value={uiQuery}
              />
            </WithHelperText>
          </Box>
          <DateRangeFilterButton
            explicitLabel={true}
            initialValue={initialInsightParams.date}
            onChange={value => {
              setDateRange(value);
              const timeStamp = dateRangeToTimestamp(value);
              emitTrackingEventCallback('insight-date-change', {
                from: timeStamp.start_timestamp,
                scope,
                tab,
                table: 'chat',
                to: timeStamp.end_timestamp,
              });
            }}
            options={datePickerRangeOptionsRevamp}
            value={dateRange}
          />
          <MultiSelectFilter
            icon={<img src={filterIcon} />}
            maxHeight={425}
            onChange={value => {
              setMultiSelected(value);
              emitTrackingEventCallback('insight-visible-columns-change', {
                scope,
                tab,
                table: 'chat',
                value,
              });
            }}
            options={filterOptions}
            placeholder='Filter by'
            value={multiSelected}
            variant='secondary'
          />
          {Boolean(fallbackWorkflowId) && (
            <Tooltip tooltipContent="Chats trigger ‘Fallback’ when they can't answer a question due to insufficient knowledge articles or workflow gaps">
              <Checkbox
                checked={isEqual(multiSelected, [
                  `workflow_id.${fallbackWorkflowId}`,
                ])}
                label='Gaps only'
                onChange={event => {
                  setMultiSelected(
                    event.target.checked
                      ? [`workflow_id.${fallbackWorkflowId}`]
                      : [],
                  );
                }}
              />
            </Tooltip>
          )}
          {areFiltersUsed && (
            <Button
              onClick={() => {
                setMultiSelected([]);
                setPeriodicalFilter &&
                  setPeriodicalFilter(initialInsightParams.period);
                handleFilterReset && handleFilterReset();
                emitTrackingEventCallback('insight-reset-filter', {
                  scope,
                  tab,
                  table: 'chat',
                });

                setUiQuery('');

                setSearchQueryMode('conversation_id', () =>
                  setSortColumn(initialInsightParams.chatColumnSort, () =>
                    setDateRange(initialInsightParams.date),
                  ),
                );
              }}
              size='medium'
              variant='ghost'
            >
              <Typography noWrap variant='font14Medium'>
                Reset filters
              </Typography>
            </Button>
          )}
          <Box
            sx={{
              display: 'flex',
              flex: 1,
              justifyContent: 'flex-end',
              paddingRight: 2,
            }}
          >
            <InsightDownloadCsv
              filename={'chats.csv'}
              requestData={{
                data_export_type: ExportableTableType.INSIGHT_CHATS_TABLE,
                end: backendReadyTimestamps.end_timestamp,
                start: backendReadyTimestamps.start_timestamp,
              }}
              url={getChatsTableDownloadUrl()}
            />
          </Box>
        </Box>
      ),
    }),
    [
      columns,
      rows,
      emitTrackingEventCallback,
      scope,
      tab,
      uiQueryError,
      searchOptions,
      searchQueryMode,
      uiQuery,
      dateRange,
      filterOptions,
      multiSelected,
      fallbackWorkflowId,
      areFiltersUsed,
      backendReadyTimestamps.end_timestamp,
      backendReadyTimestamps.start_timestamp,
      getChatsTableDownloadUrl,
      setUiQuery,
      setSearchQueryMode,
      setDateRange,
      setMultiSelected,
      setPeriodicalFilter,
      handleFilterReset,
      setSortColumn,
    ],
  );

  return (
    <>
      <ConversationDrawer
        isLoading={isLoadingConversation || isFetchingConversation}
        onClose={() => {
          setSelectedConversationId(null);
          emitTrackingEventCallback('insight-chat-drawer-close', {
            id: selectedConversation?.conversation_id,
            scope,
            tab,
          });
        }}
        openEventTrigger={() => {
          emitTrackingEventCallback('insight-chat-drawer-open', {
            id: selectedConversation?.conversation_id,
            scope,
            tab,
          });
        }}
        selectedConversation={selectedConversation}
      />
      <ChatsTableLayout
        table={
          <InfiniteTable
            hasNextPage={
              typeof conversationTableData?.metadata.next_page === 'number'
            }
            initialSorting={initialSorting}
            isError={isChatsTableError}
            isLoadingFirstPage={showLoadingSkeleton}
            isLoadingNextPage={isConversationTableFetching}
            onLoadMore={handleLoadMore}
            onSortCallback={handleSortCallback}
            stickyHeaderHeight={getTableHeight(view)}
            tableOptions={tableOptions}
          />
        }
      />
    </>
  );
};
export default ChatsTable;
