import '../common/datePicker.scss';
import '../common/tablePage.scss';
import './searchAnalyticsPage.scss';
import 'react-day-picker/lib/style.css';

import React from 'react';
import moment from 'moment';
import queryString from 'query-string';
import { CSVLink } from 'react-csv';
import DayPicker, { DateUtils } from 'react-day-picker';
import ReactPaginate from 'react-paginate';
import { connect } from 'react-redux';
import { Dispatch } from '@reduxjs/toolkit';

import DropDownMenu from '../../reusable-components/dropdown-menu/dropdownMenu';
import LoadingSpinner from '../../reusable-components/loading-spinner/loadingSpinner';
import TableHeader from '../../reusable-components/table-header/tableHeader';
import TableRow from '../../reusable-components/table-row/tableRow';
import SearchChart from './search-chart/searchChart';
import { getSearchAnalytics } from 'src/actions/analytics';
import { searchLoading } from 'src/actions/search';
import { sendTrackingEvent } from 'src/actions/search';
import NoDataView from 'src/components/reusable-components/no-data-view/NoDataView';
import {
  chartTimeframeFilters,
  dataViews,
  datePickerDropdownOptions,
} from 'src/utils/analyticsUtils';
import { cleanStr } from 'src/utils/cleanStr';
import { ModuleName, noDataViewProps } from 'src/utils/enums';
import { formatNToPercentage } from 'src/utils/formatToPercentage';
import { withRouter, WithRouterProps } from 'src/utils/withRouter';

type MyProps = {
  aggregate: any;
  breakdown: any;
  end_time: string;
  fetchingData: Function;
  finalPage: boolean;
  getSearchMetrics: Function;
  loading: boolean;
  location: any;
  nextPageAnalyticsMetrics: Function;
  sendTrackingEvent: Function;
  sortedProperty: string;
  start_time: string;
};

type MyState = {
  chartDataViewDropdownVisible: boolean;
  chartTimeFrameFilterDropdownVisible: boolean;
  chartTimeFrameFilterSelected: string;
  chartYAxisDisplayed: string;
  chartYAxisSelected: string;
  dataViewDropdownVisible: boolean;
  end_timestamp: any;
  fieldDropDownVisible: boolean;
  from: any;
  pageNumber: number;
  shareDropdownVisible: boolean;
  start_timestamp: any;
  tableView: boolean;
  timeframeDisplayed: string;
  timeFrameDropDownVisible: boolean;
  timeFrameSelected: string;
  to: any;
};

class SearchAnalyticsPage extends React.Component<
  MyProps & WithRouterProps,
  MyState
> {
  node: any;
  timeframeMenu: any;
  DataMenu: any;
  modelMenu: any;
  _isMounted = false;
  chartTimeframe: any;
  chartDataView: any;
  shareButton: any;
  constructor(props: MyProps & WithRouterProps) {
    super(props);
    this.handleDayClick = this.handleDayClick.bind(this);
    this.dataViewDropdown = this.dataViewDropdown.bind(this);
    this.chartTimeframeFilterMenu = this.chartTimeframeFilterMenu.bind(this);
    this.chartDataFilterMenu = this.chartDataFilterMenu.bind(this);
    this.resetPage = this.resetPage.bind(this);

    this.state = {
      chartDataViewDropdownVisible: false,
      chartTimeFrameFilterDropdownVisible: false,
      chartTimeFrameFilterSelected: 'Daily',
      chartYAxisDisplayed: 'open count',
      chartYAxisSelected: 'open_count',
      dataViewDropdownVisible: false,
      end_timestamp: undefined,
      fieldDropDownVisible: false,
      from: undefined,
      pageNumber: 0,
      shareDropdownVisible: false,
      start_timestamp: undefined,
      tableView: true,
      timeframeDisplayed: 'Last 30 days',
      timeFrameDropDownVisible: false,
      timeFrameSelected: 'Last 30 days',
      to: undefined,
    };
  }

  //adds event listener
  componentWillMount(): void {
    document.addEventListener(
      'mousedown',
      this.handleBodyClick.bind(this),
      false,
    );
  }

  //removes event listener
  componentWillUnmount(): void {
    document.removeEventListener(
      'mousedown',
      this.handleBodyClick.bind(this),
      false,
    );
    this._isMounted = false;
  }

  //if body clicked outside of node close dropdown
  handleBodyClick() {
    if (
      (this.node && this.node.contains((event as Event).target)) ||
      (this.chartDataView &&
        this.chartDataView.contains((event as Event).target)) ||
      (this.DataMenu && this.DataMenu.contains((event as Event).target)) ||
      (this.timeframeMenu &&
        this.timeframeMenu.contains((event as Event).target)) ||
      (this.chartTimeframe &&
        this.chartTimeframe.contains((event as Event).target)) ||
      (this.shareButton && this.shareButton.contains((event as Event).target))
    ) {
      return false;
    }
    if (this._isMounted) {
      this.setState({
        chartDataViewDropdownVisible: false,
        chartTimeFrameFilterDropdownVisible: false,
        dataViewDropdownVisible: false,
        shareDropdownVisible: false,
        timeFrameDropDownVisible: false,
      });
    }
  }

  resetPage() {
    this.setState({ pageNumber: 0 });
  }

  //resets days clicked on calendar
  handleResetClick() {
    this.setState({
      from: undefined,
      to: undefined,
    });
  }

  //handles clicks on the calendar
  handleDayClick(day: any) {
    const today = new Date();
    if (day <= today.setDate(today.getDate() + 1)) {
      const range = DateUtils.addDayToRange(day, this.state);
      this.setState(range);
    } else {
      return;
    }
  }

  //Share report dropdown on click action
  shareDropdown(e: any, option: any) {
    this.setState({ shareDropdownVisible: false });
    this.props.sendTrackingEvent(ModuleName.ANALYTICS, {
      end_timestamp: this.state.end_timestamp,
      event_type: 'search-analytics-share-report',
      start_timestamp: this.state.start_timestamp,
      type_of_share: option,
    });
  }

  //handles pagination
  async handlePageClick(data: any) {
    if (this.props.breakdown[data.selected] === undefined) {
      this.props.fetchingData(true);
      await this.props.nextPageAnalyticsMetrics(
        Math.floor(data.selected / 2),
        50,
      );
      this.props.fetchingData(false);
    }
    this.setState({
      pageNumber: data.selected,
    });
    this.constructQueryParams(
      true,
      this.state.start_timestamp,
      this.state.end_timestamp,
      data.selected + 1,
    );
  }

  //makes initial fetch call for analytics for the past 30 days and the model with highest count
  async componentDidMount() {
    let end_timestamp: any;
    let start_timestamp: any;
    let offset: any;
    this._isMounted = true;
    //extract values from query string
    const queryStr = queryString.parse(
      decodeURIComponent(this.props.location.search.replace(/\%20/g, '_')),
    );
    this.props.fetchingData(true);
    //if there are no values in query string make api call with default values
    if (Object.entries(queryStr).length === 0) {
      end_timestamp = moment(new Date()).unix();
      const endTime = new Date();
      const startTime = endTime.setDate(endTime.getDate() - 30);
      start_timestamp = moment(new Date(startTime)).unix();
      offset = 0;
      //constructs the url with the query strings with default values when it loads
      this.constructQueryParams(
        this.state.tableView,
        start_timestamp,
        end_timestamp,
        1,
      );
    } //if there are values in query string make api call with those values and save values to state
    else if (Object.entries(queryStr).length > 0) {
      end_timestamp = Number(queryStr.end);
      start_timestamp = Number(queryStr.start);
      offset =
        // @ts-expect-error Assume that the type is correct
        queryStr.view === 'chart' ? 1 : Math.floor(queryStr.page - 1 / 2);
      const chartTimeframe = queryStr.timeframe
        ? queryStr.timeframe
        : this.state.chartTimeFrameFilterSelected;
      if (queryStr.view === 'chart') {
        this.setState({
          // @ts-expect-error Assume that the type is correct
          chartTimeFrameFilterSelected: queryStr.timeframe,
          // @ts-expect-error Assume that the type is correct
          chartYAxisDisplayed: cleanStr(queryStr.y),
          // @ts-expect-error Assume that the type is correct
          chartYAxisSelected: queryStr.y,
        });
      }
      this.setState({
        // @ts-expect-error Assume that the type is correct
        chartTimeFrameFilterSelected: chartTimeframe,
        tableView: queryStr.view === 'chart' ? false : true,
        timeframeDisplayed: `${moment(new Date(start_timestamp * 1000)).format(
          'll',
        )} - ${moment(new Date(end_timestamp * 1000)).format('ll')} `,
        timeFrameSelected: `${moment(new Date(start_timestamp * 1000)).format(
          'll',
        )} - ${moment(new Date(end_timestamp * 1000)).format('ll')} `,
      });
    }
    await this.props.getSearchMetrics(
      start_timestamp,
      end_timestamp,
      offset,
      50,
    );
    this.setState({
      end_timestamp: end_timestamp,
      pageNumber: offset,
      start_timestamp: start_timestamp,
    });
    this.props.fetchingData(false);
  }

  //fetch call when timeframe is selected
  async submitFilterByTimeframe(e?: any) {
    e.preventDefault();
    this.setState({
      from: undefined,
      pageNumber: 0,
      timeframeDisplayed:
        this.state.from !== undefined
          ? `${moment(this.state.from).format('ll')} - ${moment(
              this.state.to,
            ).format('ll')} `
          : this.state.timeFrameSelected,
      timeFrameDropDownVisible: false,
      timeFrameSelected:
        this.state.from !== undefined
          ? `${moment(this.state.from).format('ll')} - ${moment(
              this.state.to,
            ).format('ll')} `
          : this.state.timeFrameSelected,
      to: undefined,
    });
    this.props.fetchingData(true);
    const start_timestamp =
      this.state.from !== undefined || null
        ? moment(this.state.from.setHours(0)).unix()
        : this.state.start_timestamp;
    const end_timestamp =
      this.state.to !== undefined || null
        ? moment(this.state.to.setHours(23, 59, 59)).unix()
        : this.state.end_timestamp;
    this.setState({
      end_timestamp: end_timestamp,
      start_timestamp: start_timestamp,
    });
    this.constructQueryParams(
      this.state.tableView,
      this.state.start_timestamp,
      this.state.end_timestamp,
      1,
    );
    await this.props.getSearchMetrics(start_timestamp, end_timestamp, 0, 50);
    this.props.fetchingData(false);
    this.props.sendTrackingEvent(ModuleName.ANALYTICS, {
      end_timestamp: end_timestamp,
      event_type: this.state.tableView
        ? 'search-analytics-table-timeframe-changed'
        : 'search-analytics-chart-timeframe-changed',
      start_timestamp: start_timestamp,
    });
  }

  // data view dropdown on click action
  dataViewDropdown(e: any, option: any) {
    this.setState({
      dataViewDropdownVisible: false,
      pageNumber: 0,
      tableView: option === 'Table' ? true : false,
    });
    this.props.sendTrackingEvent(ModuleName.ANALYTICS, {
      data_view: option,
      end_timestamp: this.state.end_timestamp,
      event_type: 'search-analytics-data-view-changed',
      start_timestamp: this.state.start_timestamp,
    });
    this.constructQueryParams(
      option === 'Table' ? true : false,
      this.state.start_timestamp,
      this.state.end_timestamp,
      1,
    );
  }

  //chart timeframe filter dropdown on click action
  chartTimeframeFilterMenu(e: any, option: any) {
    this.setState({
      chartTimeFrameFilterDropdownVisible: false,
      chartTimeFrameFilterSelected: option,
    });
    this.props.sendTrackingEvent(ModuleName.ANALYTICS, {
      end_timestamp: this.state.end_timestamp,
      event_type: 'search-analytics-chart-timeframe-changed',
      start_timestamp: this.state.start_timestamp,
      timeframe_filter: option,
      y_axis: this.state.chartYAxisSelected,
    });
  }

  //filter chart data by values dropdown on click action
  async chartDataFilterMenu(e: any, option: any) {
    this.setState({
      chartDataViewDropdownVisible: false,
      chartYAxisDisplayed: cleanStr(option),
      chartYAxisSelected: option,
    });
    this.props.sendTrackingEvent(ModuleName.ANALYTICS, {
      end_timestamp: this.state.end_timestamp,
      event_type: 'search-analytics-chart-yaxis-changed',
      start_timestamp: this.state.start_timestamp,
      timeframe_filter: this.state.chartTimeFrameFilterSelected,
      y_axis: option,
    });
  }

  //Constructs query parameters for URL
  constructQueryParams(
    tableView = this.state.tableView,
    start = this.state.start_timestamp,
    end = this.state.end_timestamp,
    page = this.state.pageNumber + 1,
  ) {
    const pathName =
      location.pathname === '/' ? '/assist-search' : window.location.pathname;
    if (tableView) {
      window.history.replaceState(
        null,
        null as any,
        `${pathName}?view=table${encodeURIComponent(
          `&start=${start}&end=${end}&page=${page}`,
        )}`,
      );
    }
  }
  //calculates in unix time the start timestamp and the end timestamp when timeframe option is selected
  async calculateTimeframe(timeframe: any) {
    let start_timestamp;
    let end_timestamp;
    let endTime;
    let days;
    if (timeframe === 'Last 7 days' || 'Last 14 days' || 'Last 30 days') {
      end_timestamp = moment(new Date()).unix();
      endTime = new Date();
      if (timeframe === 'Last 7 days') {
        days = 7;
      } else if (timeframe === 'Last 14 days') {
        days = 14;
      } else if (timeframe === 'Last 30 days') {
        days = 30;
      }
      const startTime = endTime.setDate(endTime.getDate() - (days as number));
      start_timestamp = moment(new Date(startTime)).unix();
      this.setState({
        end_timestamp: end_timestamp,
        start_timestamp: start_timestamp,
      });
    }
    if (timeframe === 'This month' || 'Previous month') {
      endTime = new Date();
      const currentYear = endTime.getFullYear();
      const currentMonth = endTime.getMonth();
      if (timeframe === 'This month') {
        start_timestamp = moment(
          new Date(currentYear, currentMonth + 0, 1),
        ).unix();
        end_timestamp = moment(
          new Date(currentYear, currentMonth + 1, 0),
        ).unix();
        this.setState({
          end_timestamp: end_timestamp,
          start_timestamp: start_timestamp,
        });
      } else if (timeframe === 'Previous month') {
        start_timestamp = moment(
          new Date(currentYear, currentMonth - 1, 1),
        ).unix();
        end_timestamp = moment(
          new Date(currentYear, currentMonth + 0, 0),
        ).unix();
        this.setState({
          end_timestamp: end_timestamp,
          start_timestamp: start_timestamp,
        });
      }
    }
  }

  render() {
    let metrics;
    const breakdown = this.props.breakdown;
    const timeFrameSelected = this.state.timeFrameSelected;
    const timeframeDisplayed = this.state.timeframeDisplayed;
    const sortedProperty = this.props.sortedProperty;
    const aggregate = this.props.aggregate;
    const { from, to } = this.state;
    const modifiers = { end: to, start: from };
    // analytics table data
    if (
      breakdown[this.state.pageNumber] &&
      breakdown[this.state.pageNumber].length > 0 &&
      breakdown.length > 0
    ) {
      metrics =
        breakdown &&
        breakdown[this.state.pageNumber].map(
          (tableData: any, index: number) => (
            <TableRow
              biggerTrailingRows
              breakdown={breakdown}
              cells={[
                tableData.field_value,
                tableData.frequency,
                formatNToPercentage(tableData.open_rate),
              ]}
              key={index}
            />
          ),
        );
    } else if (breakdown.length === 0 && this.props.loading === false) {
      //no results object to display in rows for no results UI
      const emptyBreakdown = {
        field_value: 'Query',
        frequency: 0,
        open_rate: 0,
      };
      //specifies we want an arr with 10 space and fill puts emptyBreakdown in there
      const noResultsBreakdown = Array(10).fill(emptyBreakdown);
      metrics = noResultsBreakdown.map((tableData: any, index: number) => (
        <TableRow
          biggerTrailingRows
          breakdown={breakdown}
          cells={[
            tableData.field_value,
            tableData.frequency,
            formatNToPercentage(tableData.open_rate),
          ]}
          key={index}
        />
      ));
    }

    //timeframe dropdown
    const datePickerDropdown = datePickerDropdownOptions.map(
      (timeFrame: any, index: any) => (
        <button
          aria-expanded='false'
          className={
            timeFrameSelected === timeFrame
              ? 'focused dropdown-option timeframe-button'
              : 'dropdown-option timeframe-button'
          }
          key={index + 'G'}
          onClick={() => {
            this.calculateTimeframe(timeFrame);
            this.setState({
              from: undefined,
              timeFrameSelected: timeFrame,
              to: undefined,
            });
          }}
          ref={node => (this.timeframeMenu = node)}
          type='button'
        >
          {timeFrame}
        </button>
      ),
    );

    // filter chart data view dropdown options
    const chartDataFilter = ['open_count', 'frequency', 'open_rate'];

    const csvData =
      this.props.breakdown &&
      this.props.breakdown.flat().map((data: any) => {
        return {
          Frequency: data.frequency,
          'Open Rate': formatNToPercentage(data.open_rate),
          Query: data.field_value,
        };
      });

    const searchData = [
      {
        Frequency: aggregate && aggregate.frequency,
        'Open Rate': aggregate && formatNToPercentage(aggregate.open_rate),
        Query: 'Totals & Averages',
      },
      ...csvData,
    ];

    return (
      <>
        <div className='analytics-page'>
          <div className='settings-container break-above'>
            <div className='heading-cont'>
              <div
                className='dropdown view-dropdown'
                ref={node => (this.chartDataView = node)}
              >
                <button
                  aria-expanded='false'
                  className='select'
                  onClick={() =>
                    this.setState({
                      chartDataViewDropdownVisible:
                        !this.state.chartDataViewDropdownVisible,
                      chartTimeFrameFilterDropdownVisible: false,
                      dataViewDropdownVisible: false,
                      fieldDropDownVisible: false,
                      timeFrameDropDownVisible: false,
                    })
                  }
                  type='button'
                >
                  <span
                    className={
                      !this.state.tableView
                        ? 'heading-y selected-option chart-filter-selected'
                        : 'heading-y chart-filter-selected'
                    }
                  >
                    <span className='dropdown-title'>
                      {this.state.tableView
                        ? 'Assist Search'
                        : this.state.chartYAxisDisplayed}
                    </span>
                  </span>
                  {!this.state.tableView && (
                    <div
                      className={
                        this.state.chartDataViewDropdownVisible
                          ? 'desc-selected -arrow'
                          : 'down-arrow'
                      }
                    ></div>
                  )}
                </button>
                {!this.state.tableView &&
                  this.state.chartDataViewDropdownVisible && (
                    <DropDownMenu
                      buttonClass={'dropdown-option answers-chart-data-view '}
                      data={chartDataFilter}
                      dropdownClass={'dropdown-menu view-menu'}
                      dropdownContClass={'v0-chart-data-menu'}
                      onClick={this.chartDataFilterMenu}
                      selectedOption={this.state.chartYAxisSelected}
                    />
                  )}
              </div>
            </div>
            <div className='settings-bar'>
              <div
                className='dropdown timeframe-dropdown'
                ref={node => (this.timeframeMenu = node)}
              >
                <button
                  className='select selected-timeframe'
                  onClick={() =>
                    this.setState({
                      chartDataViewDropdownVisible: false,
                      chartTimeFrameFilterDropdownVisible: false,
                      dataViewDropdownVisible: false,
                      fieldDropDownVisible: false,
                      timeFrameDropDownVisible:
                        !this.state.timeFrameDropDownVisible,
                    })
                  }
                  type='button'
                >
                  <span aria-expanded='false' className='selected-option'>
                    <span className='date-icon' />{' '}
                    {timeframeDisplayed !== ''
                      ? timeframeDisplayed
                      : datePickerDropdownOptions[0]}
                  </span>{' '}
                  <span className='down-arrow' />
                </button>
                {this.state.timeFrameDropDownVisible === true && (
                  <div className='timeframe-wrapper search-calendar'>
                    <div className='RangeExample'>
                      <div
                        aria-labelledby='dropdownMenu'
                        className='dropdown-menu timeframe-menu'
                      >
                        {datePickerDropdown}
                      </div>
                      <div
                        onClick={() =>
                          this.setState({
                            timeFrameSelected: 'Custom',
                          })
                        }
                      >
                        <DayPicker
                          className='Selectable'
                          disabledDays={{
                            after: new Date(),
                          }}
                          fromMonth={new Date(2018, 12)}
                          modifiers={modifiers}
                          numberOfMonths={2}
                          onDayClick={this.handleDayClick}
                          selectedDays={[from, { from, to }]}
                          toMonth={new Date()}
                        />
                      </div>
                      <div className='filter-bttn-container'>
                        <p className='time-range'>
                          {' '}
                          From{' '}
                          <span className='from>'>
                            {from && ` ${moment(from).format('MM-DD-YYYY')} `}
                          </span>
                          To{' '}
                          <span className='to>'>
                            {to && ` ${moment(to).format('MM-DD-YYYY')}`}
                          </span>
                        </p>
                        <div className='bttn-cont'>
                          <button
                            className='reset'
                            onClick={() => this.handleResetClick()}
                          >
                            Reset
                          </button>
                          <button
                            className='cancel-bttn'
                            onClick={() =>
                              this.setState({
                                from: undefined,
                                timeFrameDropDownVisible: false,
                                to: undefined,
                              })
                            }
                          >
                            CANCEL
                          </button>
                          <button
                            className={'apply-bttn'}
                            disabled={
                              this.state.timeFrameSelected === 'Custom' &&
                              this.state.from === undefined
                                ? true
                                : false
                            }
                            onClick={e => this.submitFilterByTimeframe(e)}
                            type='submit'
                          >
                            APPLY
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div
                className='dropdown  view-dropdown search-view'
                ref={node => (this.DataMenu = node)}
              >
                <button
                  aria-expanded='false'
                  className='select'
                  onClick={() =>
                    this.setState({
                      chartDataViewDropdownVisible: false,
                      chartTimeFrameFilterDropdownVisible: false,
                      dataViewDropdownVisible:
                        !this.state.dataViewDropdownVisible,
                      fieldDropDownVisible: false,
                      shareDropdownVisible: false,
                      timeFrameDropDownVisible: false,
                    })
                  }
                  type='button'
                >
                  <span className='selected-option'>
                    {' '}
                    <span
                      className={
                        this.state.tableView
                          ? 'table-view-icon'
                          : 'chart-view-icon'
                      }
                    />{' '}
                    {this.state.tableView === true ? 'Table' : 'Chart'}
                  </span>{' '}
                  <span className='down-arrow' />
                </button>
                {this.state.dataViewDropdownVisible === true && (
                  <DropDownMenu
                    buttonClass={'data-view-option dropdown-option'}
                    data={dataViews}
                    dropdownClass={'dropdown-menu view-menu'}
                    dropdownContClass={
                      'data-view-dropdown-menu search-menu-cont'
                    }
                    icon={true}
                    onClick={this.dataViewDropdown}
                    selectedOption={this.state.tableView ? 'Table' : 'Chart'}
                  />
                )}
              </div>
              {this.state.tableView === false && (
                <div
                  className='dropdown  view-dropdown'
                  ref={node => (this.chartTimeframe = node)}
                >
                  <button
                    aria-expanded='false'
                    className='select'
                    onClick={() =>
                      this.setState({
                        chartDataViewDropdownVisible: false,
                        chartTimeFrameFilterDropdownVisible:
                          !this.state.chartTimeFrameFilterDropdownVisible,
                        dataViewDropdownVisible: false,
                        fieldDropDownVisible: false,
                        timeFrameDropDownVisible: false,
                      })
                    }
                    type='button'
                  >
                    <span className='selected-option'>
                      <span className='time-icon' />{' '}
                      {this.state.chartTimeFrameFilterSelected}
                    </span>{' '}
                    <span className='down-arrow' />
                  </button>
                  {this.state.chartTimeFrameFilterDropdownVisible === true && (
                    <DropDownMenu
                      buttonClass={'dropdown-option'}
                      data={chartTimeframeFilters}
                      dropdownClass={'dropdown-menu view-menu'}
                      dropdownContClass={'search-field-menu-cont'}
                      onClick={this.chartTimeframeFilterMenu}
                      selectedOption={this.state.chartTimeFrameFilterSelected}
                    />
                  )}
                </div>
              )}
              {this.state.tableView && (
                <div
                  className='dropdown  view-dropdown share-dropdown'
                  ref={node => (this.shareButton = node)}
                >
                  <button
                    aria-expanded='false'
                    className='share-button'
                    onClick={() =>
                      this.setState({
                        chartDataViewDropdownVisible: false,
                        chartTimeFrameFilterDropdownVisible: false,
                        dataViewDropdownVisible: false,
                        fieldDropDownVisible: false,
                        shareDropdownVisible: !this.state.shareDropdownVisible,
                        timeFrameDropDownVisible: false,
                      })
                    }
                    type='button'
                  >
                    <span className='share-icon' />
                    Share
                  </button>
                  {this.state.shareDropdownVisible && (
                    <div className='share-menu-cont'>
                      <div
                        aria-labelledby='dropdownMenu'
                        className='dropdown-menu view-menu'
                      >
                        <CSVLink
                          className='dropdown-option share-opt'
                          data={searchData}
                          filename={'forethought-search-report.csv'}
                          onClick={(e: any) =>
                            this.shareDropdown(e, 'Download CSV')
                          }
                          target='_blank'
                        >
                          Download CSV
                        </CSVLink>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>

          {this.props.loading && <LoadingSpinner />}

          {this.props.loading === false && this.state.tableView === false && (
            <SearchChart
              end_timestamp={this.state.end_timestamp}
              start_timestamp={this.state.start_timestamp}
              timeFrameFilter={this.state.chartTimeFrameFilterSelected}
              x={'timestamp'}
              y={this.state.chartYAxisSelected}
            />
          )}

          {this.props.loading === false && this.state.tableView === true && (
            <div className='analytics-table'>
              {breakdown.length === 0 && (
                <NoDataView
                  message={noDataViewProps.ANALYTICS_TIME_RANGE}
                  noDataViewClass={noDataViewProps.DATE_ICON_CLASS}
                />
              )}
              <table className='analytics'>
                <tbody>
                  <tr className='table-header'>
                    <TableHeader
                      divClass={
                        sortedProperty === 'field_value'
                          ? 'sort-wrapper focused'
                          : 'sort-wrapper'
                      }
                      heading={'Query'}
                      headingClass={'ocurrence-heading search-table-heading'}
                      onClick={this.resetPage}
                      productSorted={'search_table'}
                      propertyName={'field_value'}
                    />
                    <TableHeader
                      divClass={
                        sortedProperty === 'frequency'
                          ? 'sort-wrapper focused'
                          : 'sort-wrapper'
                      }
                      heading={'FREQUENCY'}
                      headingClass={'heading-type search-table-heading'}
                      onClick={this.resetPage}
                      productSorted={'search_table'}
                      propertyName={'frequency'}
                      tooltipClass={'second-col'}
                      tooltipText={
                        'Number of times a specific query has been searched in Assist.'
                      }
                      tooltipTitle={'Frequency'}
                    />
                    <TableHeader
                      divClass={
                        sortedProperty === 'open_rate'
                          ? 'sort-wrapper focused'
                          : 'sort-wrapper'
                      }
                      heading={'OPEN RATE'}
                      headingClass={'heading-type search-table-heading'}
                      onClick={this.resetPage}
                      productSorted={'search_table'}
                      propertyName={'open_rate'}
                      tooltipClass={'second-col'}
                      tooltipText={
                        'Percentage of query searches that had a result that was clicked on.'
                      }
                      tooltipTitle={'Open Rate'}
                    />
                  </tr>
                  {this.props.breakdown && this.props.breakdown.length > 0 && (
                    <tr className='totals data-row'>
                      <td className='field-cell'>
                        <p className='field-p'>Totals & Averages</p>
                      </td>
                      <td className='cell'>
                        <p className='cell-p'>
                          {aggregate && aggregate.frequency}
                        </p>
                      </td>
                      <td className='cell'>
                        <p className='cell-p'>
                          {formatNToPercentage(
                            aggregate && aggregate.open_rate,
                          )}
                        </p>
                      </td>
                    </tr>
                  )}
                  {metrics}
                </tbody>
              </table>
              <ReactPaginate
                activeClassName={'active'}
                breakClassName={'break-me'}
                breakLabel={'...'}
                containerClassName={'pagination'}
                forcePage={this.state.pageNumber}
                marginPagesDisplayed={0}
                nextLabel={''}
                onPageChange={this.handlePageClick.bind(this)}
                pageCount={
                  Object.keys(this.props.breakdown).length > 0
                    ? Object.keys(this.props.breakdown).length
                    : 1
                }
                pageRangeDisplayed={2}
                previousLabel={''}
                subContainerClassName={'pages pagination'}
              />
            </div>
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state: any) => ({
  aggregate: state.analytics.searchAggregate,
  breakdown: state.analytics.searchBreakdown,
  end_time: state.analytics.searchMetricsEndTimestamp,
  finalPage: state.analytics.searchMetricsFinalPageReached,
  loading: state.search.loading,
  sortedProperty: state.analytics.sortType,
  start_time: state.analytics.searchMetricsStartTimestamp,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchingData: (isLoading: boolean) => dispatch(searchLoading(isLoading)),
  getSearchMetrics: (
    start_timestamp: any,
    end_timestamp: any,
    offset?: number,
    limit?: number,
  ) =>
    dispatch(getSearchAnalytics(start_timestamp, end_timestamp, offset, limit)),
  nextPageAnalyticsMetrics: (offset?: number, limit?: number) =>
    dispatch(getSearchAnalytics(offset, limit)),
  sendTrackingEvent: (moduleName: string, event: any) =>
    dispatch(sendTrackingEvent(moduleName, event)),
});

const ConnectedSearchAnalyticsPage = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(SearchAnalyticsPage));

export default ConnectedSearchAnalyticsPage;
