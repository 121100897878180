/* eslint-disable react/prop-types -- false warnings for column definitions */

import { useCallback, useEffect, useMemo, useState } from 'react';
import { createMRTColumnHelper } from 'material-react-table';
import { useLocation, useNavigate } from 'react-router-dom';
import { ValidationError } from 'yup';
import { Box, useTheme } from '@mui/material';
import { IconInfoCircle } from '@tabler/icons-react';

import {
  DateRangeFilterButton,
  FilterToggleButton,
  formatToDateAndTime,
  InfiniteTable as InfiniteTable2,
  MultiSelectFilter,
  Order,
  SearchBar,
  Tooltip,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { BreakdownPaletteTooltip } from '../workflow-builder/BreakdownPaletteTooltip';
import { useSolveMetricsQueryParams } from '../workflow-builder/intent-workflows-table/hooks/useSolveMetricsQueryParams';
import { HorizontalStackedBar } from '../workflow-builder/intent-workflows-table/HorizontalStackedBar';
import { getTimeRangeFromQueryParams } from '../workflow-builder/intent-workflows-table/utils';
import { BooleanCell } from './components/BooleanCell';
import { ConversationsTabLayout } from './components/ConversationsTabLayout';
import { DateCell } from './components/DateCell';
import { DeflectionInsightCell } from './components/DeflectionInsightCell';
import { IntentFilter } from './components/IntentFilter';
import useConversations from './hooks/useConversations';
import useConversationsAggregateMetrics from './hooks/useConversationsAggregateMetrics';
import useGetCSATColor from './hooks/useGetCSATColor';
import useIsCSATEnabled from './hooks/useIsCSATEnabled';
import useIsDeflectionInsightsEnabled from './hooks/useIsDeflectionInsightsEnabled';
import {
  ALL_INTENTS,
  ALLTIME_END,
  ALLTIME_START,
  DEFLECTION_INSIGHT_FILTER_OPTIONS,
  NA,
  SMALL_CELL_WIDTH,
  STICKY_HEADER_HEIGHT,
  TOTAL_STICKY_HEADERS_HEIGHT,
} from './constants';
import ConversationDrawer from './ConversationDrawer';
import ConversationTranscript from './ConversationTranscript';
import HeaderSection from './HeaderSection';
import { formatCSAT, multiIntentOption } from './helpers';
import { InfiniteTable } from './InfiniteTable';
import IntentBadge from './IntentBadge';
import OverallAnalytics from './OverallAnalytics';
import aaBadgeSrc from 'src/assets/images/autonomous-agent-badge.svg';
import filterIcon from 'src/assets/images/filter-analytic-icon.svg';
import { TableLabelContainer } from 'src/components/dashboard-pages/discover-dashboard-page/styles';
import { DateRange } from 'src/components/dashboard-pages/discover-dashboard-page/types';
import WithHelperText from 'src/components/with-helper-text/WithHelperText';
import { SHARED_METRIC_DEFINITIONS } from 'src/constants/solve';
import {
  useEmitTrackingEventCallback,
  useGetIntentsQueryWithProduct,
  useStateParams,
} from 'src/hooks/hooks';
import { Conversation } from 'src/services/apiInterfaces';
import { useGetFeatureFlagsQuery } from 'src/services/dashboard-api';
import {
  dateRangeToTimeFilter,
  genericSerializeAndDeserialize,
} from 'src/utils/discover/helpers';
import { Routes } from 'src/utils/enums';
import { isDeflectionInsightsProcessing } from 'src/utils/solve/deflectionInsightsUtils';
import {
  constructTimeRangeQueryParamsRevamped,
  datePickerRangeOptionsRevamp,
  last30DaysTimeRange,
} from 'src/utils/timeRangeHelpers';
import { uuidOrEmptySchema } from 'src/utils/validators';

const LabelWithTooltip = ({
  label,
  tooltipContent,
}: {
  label: string;
  tooltipContent: string;
}) => {
  return (
    <TableLabelContainer>
      {label}
      <Tooltip tooltipContent={tooltipContent}>
        <Box display='inline-flex'>
          <IconInfoCircle color='currentColor' height={16} width={16} />
        </Box>
      </Tooltip>
    </TableLabelContainer>
  );
};

const commonIntentIds = [
  'Bot_Control_Approve_Response',
  'Bot_Control_Reject_Response',
  'General_Agent_Capabilities',
  'General_Greetings',
];

type WidgetConversationSortDirection = 'asc' | 'desc';

function splitString(value: string) {
  return value.split(',');
}

function joinArray(value: string[]) {
  return value.join(',');
}

export const WidgetConversationsTab = ({
  scrollTop,
  scrollToTop,
}: {
  scrollTop: number;
  scrollToTop: (yOffset: number, smooth?: boolean) => void;
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const qParams = useSolveMetricsQueryParams();
  const { palette } = useTheme();
  const [selectedConversationId, setSelectedConversationId] = useState('');
  const [page, setPage] = useState(1);
  const isCSATEnabled = useIsCSATEnabled();
  const getCSATColor = useGetCSATColor();
  const { data: featureFlagsResponse } = useGetFeatureFlagsQuery();
  const isDeflectionInsightsEnabled = useIsDeflectionInsightsEnabled();
  const isAutoflowsEnabled = featureFlagsResponse?.feature_flags?.includes(
    'autonomous_agent_enabled',
  );
  const showOverallMetrics = featureFlagsResponse?.feature_flags?.includes(
    'chats_tab_show_overall_metrics',
  );
  const showOnlyCSAT = featureFlagsResponse?.feature_flags?.includes(
    'chats_tab_show_only_csat',
  );
  const hideCSAT = featureFlagsResponse?.feature_flags?.includes(
    'chats_tab_hide_overall_csat',
  );
  const isNewTableComponentEnabled =
    featureFlagsResponse?.feature_flags?.includes('new_table_component');
  const isQuickFeedbackEnabled =
    featureFlagsResponse?.feature_flags?.includes('quick_feedback');
  const isQuickFeedbackAnalyticsEnabled =
    featureFlagsResponse?.feature_flags?.includes(
      'show_quick_feedback_analytics',
    );
  const shouldShowQuickFeedbackAnalytics =
    isQuickFeedbackEnabled && isQuickFeedbackAnalyticsEnabled;

  // Filters
  const selectedDateRange = useMemo(() => {
    return getTimeRangeFromQueryParams({
      from: qParams.from,
      to: qParams.to,
    });
  }, [qParams]);
  const timeFilter = dateRangeToTimeFilter(selectedDateRange);
  const startDate = qParams.from ?? ALLTIME_START;
  const endDate = qParams.to ?? ALLTIME_END;
  const [intentId, setIntentId] = useStateParams<string>({
    deserialize: genericSerializeAndDeserialize,
    initialState: ALL_INTENTS,
    paramsName: 'intentId',
    serialize: genericSerializeAndDeserialize,
  });
  const [sortDirection, setSortDirection] =
    useStateParams<WidgetConversationSortDirection>({
      deserialize: (param: string) => param as WidgetConversationSortDirection,
      initialState: 'desc',
      paramsName: 'sort_direction',
      serialize: genericSerializeAndDeserialize,
    });
  const [sortColumn, setSortColumn] = useStateParams<keyof Conversation>({
    deserialize: (param: string) => param as keyof Conversation,
    initialState: 'start_datetime',
    paramsName: 'sort_column',
    serialize: genericSerializeAndDeserialize,
  });
  const [filterAutoflows, setFilterAutoflows] = useStateParams<boolean>({
    deserialize: value => value === 'true',
    initialState: false,
    paramsName: 'filter_autoflows',
    serialize: value => value.toString(),
  });

  const [multiSelected, setMultiSelected] = useStateParams<string[]>({
    deserialize: splitString,
    initialState: [],
    paramsName: 'filters',
    serialize: joinArray,
  });

  useEffect(() => {
    // Reset intentId when deflection insights is enabled
    // To be removed after SOL-6037 is resolved
    if (intentId !== ALL_INTENTS && isDeflectionInsightsEnabled) {
      setIntentId(ALL_INTENTS);
    }
  }, [intentId, setIntentId, isDeflectionInsightsEnabled]);

  const [filterConversationIdError, setFilterConversationIdError] = useState<
    string | null
  >(null);
  const [filterConversationId, setFilterConversationId] =
    useStateParams<string>({
      deserialize: genericSerializeAndDeserialize,
      initialState: '',
      paramsName: 'conversationId',
      serialize: genericSerializeAndDeserialize,
    });
  const filterByIntent = intentId !== ALL_INTENTS;
  const searchByConversationId =
    filterConversationId && !filterConversationIdError;

  // handler
  const handleUpdateFilterConversationId = (value: string) => {
    try {
      uuidOrEmptySchema.validateSync(value);
      setFilterConversationIdError(null);
    } catch (err) {
      if (err instanceof ValidationError) {
        setFilterConversationIdError(err.message);
      } else {
        setFilterConversationIdError('An unexpected error occurred');
      }
    }
    setFilterConversationId(value);
  };

  // Hooks
  const emitTrackingEventCallback = useEmitTrackingEventCallback();

  // Data
  const {
    data: conversationsData,
    isError: isConversationsDataError,
    isFetching: isConversationsDataFetching,
    isLoading: isConversationsDataLoading,
  } = useConversations(
    searchByConversationId
      ? {
          autonomousAgentUsed: false,
          conversationId: filterConversationId,
          endDate: ALLTIME_END,
          intentId: ALL_INTENTS,
          multiSelected,
          page: 1,
          sortColumn,
          sortDirection,
          startDate: ALLTIME_START,
        }
      : {
          autonomousAgentUsed: filterAutoflows,
          conversationId: null,
          endDate,
          intentId,
          multiSelected,
          page,
          sortColumn,
          sortDirection,
          startDate,
        },
  );
  const { data: aggregatedData, isFetching: isAggregatedDataFetching } =
    useConversationsAggregateMetrics({
      autonomousAgentUsed: filterAutoflows,
      endDate,
      intentId,
      multiSelected,
      startDate,
    });
  const { data, isLoading: intentsLoading } = useGetIntentsQueryWithProduct();
  const { intents = [] } = data ?? {};

  const selectedConversation = useMemo(() => {
    if (!selectedConversationId || !conversationsData) {
      return;
    }
    return conversationsData.conversations.find(
      conversation => conversation.conversation_id === selectedConversationId,
    );
  }, [conversationsData, selectedConversationId]);

  const columns = useMemo(() => {
    const initialColumns = [
      {
        cellRenderer: (data: Conversation) => {
          // NOTE: 'Z' specifies UTC time. Without it, moment consider the time to be local.
          return <DateCell dateString={data.start_datetime + 'Z'} />;
        },
        id: 'start_datetime',
        label: 'Timestamp',
        width: SMALL_CELL_WIDTH,
      },
      ...(isDeflectionInsightsEnabled
        ? []
        : [
            {
              cellRenderer: (data: Conversation) => {
                return (
                  <Box
                    sx={{
                      alignItems: 'center',
                      display: 'flex',
                      gap: '8px',
                      maxWidth: '540px',
                      position: 'relative',
                    }}
                  >
                    {data.autonomous_agent_used && (
                      <Box>
                        <Tooltip tooltipContent='AutoFlow performed'>
                          <img alt='' src={aaBadgeSrc} width='24px' />
                        </Tooltip>
                      </Box>
                    )}
                    <ConversationTranscript transcript={data.transcript} />
                  </Box>
                );
              },
              hideSortIcon: true,
              id: 'conversation',
              label: 'Chat',
            },
          ]),
      {
        cellRenderer: (data: Conversation) =>
          data.last_executed_intent_user_query ? (
            <i>
              <Typography color={palette.colors.blue[600]} variant='font14'>
                &quot;{data.last_executed_intent_user_query}&quot;
              </Typography>
            </i>
          ) : (
            <Typography color={palette.colors.grey[600]} variant='font14'>
              {NA}
            </Typography>
          ),
        hideSortIcon: true,
        id: 'query',
        label: 'User query',
      },
    ];
    if (!filterByIntent) {
      initialColumns.push({
        cellRenderer: (data: Conversation) => {
          const intentName = commonIntentIds.includes(
            data.last_executed_intent_definition_id,
          )
            ? ''
            : data.last_executed_intent_title;

          return (
            <IntentBadge
              intentDefinitionId={data.last_executed_intent_definition_id}
              intentName={intentName}
            />
          );
        },
        hideSortIcon: true,
        id: 'intent',
        label: 'Intent detected',
      });
    }
    return [
      ...initialColumns,
      ...[
        {
          cellRenderer: (data: Conversation) => (
            <Box display='flex' width={SMALL_CELL_WIDTH}>
              <BooleanCell value={data.deflected} />
            </Box>
          ),
          id: 'deflected',
          label: 'Deflected',
          width: SMALL_CELL_WIDTH,
        },
        ...(isCSATEnabled
          ? [
              {
                cellRenderer: (data: Conversation) => (
                  <Box display='flex' width={SMALL_CELL_WIDTH}>
                    <Typography
                      color={getCSATColor(data.csat_score)}
                      variant='font14Medium'
                    >
                      {formatCSAT(data.csat_score)}
                    </Typography>
                  </Box>
                ),
                id: 'csat_score',
                label: 'CSAT',
                width: SMALL_CELL_WIDTH,
              },
            ]
          : []),
        ...(isDeflectionInsightsEnabled
          ? [
              {
                cellRenderer: (data: Conversation) => (
                  <DeflectionInsightCell
                    isProcessing={isDeflectionInsightsProcessing({
                      hasDeflectionInsights: !!data.relevance_rating,
                      isDeflected: data.deflected,
                      modifiedDate: data.modified_date,
                    })}
                    metric={data.relevance_rating}
                  />
                ),
                id: 'relevance_rating',
                label: (
                  <LabelWithTooltip
                    label='Relevance'
                    tooltipContent={SHARED_METRIC_DEFINITIONS.relevance.tooltip}
                  />
                ),
                width: SMALL_CELL_WIDTH,
              },
              {
                cellRenderer: (data: Conversation) => (
                  <DeflectionInsightCell
                    isProcessing={isDeflectionInsightsProcessing({
                      hasDeflectionInsights: !!data.relevance_rating,
                      isDeflected: data.deflected,
                      modifiedDate: data.modified_date,
                    })}
                    metric={data.dropoff_rating}
                  />
                ),
                id: 'dropoff_rating',
                label: (
                  <LabelWithTooltip
                    label='User engagement'
                    tooltipContent={
                      SHARED_METRIC_DEFINITIONS.userEngagement.tooltip
                    }
                  />
                ),
                width: SMALL_CELL_WIDTH,
              },
            ]
          : []),
      ],
    ];
  }, [
    filterByIntent,
    isCSATEnabled,
    isDeflectionInsightsEnabled,
    palette.colors.blue,
    palette.colors.grey,
    getCSATColor,
  ]);

  const columnHelper = createMRTColumnHelper<Conversation>();

  const materialReactTableColumns = useMemo(() => {
    return [
      columnHelper.accessor('start_datetime', {
        Cell: ({ cell }) => {
          // NOTE: 'Z' specifies UTC time. Without it, moment consider the time to be local.
          return <DateCell dateString={cell.getValue() + 'Z'} />;
        },
        grow: false,
        header: 'Timestamp',
        size: SMALL_CELL_WIDTH,
      }),
      columnHelper.accessor('transcript', {
        Cell: ({ cell, row }) => {
          return (
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                gap: '8px',
                maxWidth: '540px',
                position: 'relative',
              }}
            >
              {row.original.autonomous_agent_used && (
                <Box>
                  <Tooltip tooltipContent='AutoFlow performed'>
                    <img alt='' src={aaBadgeSrc} width='24px' />
                  </Tooltip>
                </Box>
              )}
              <ConversationTranscript transcript={cell.getValue()} />
            </Box>
          );
        },
        enableSorting: false,
        header: 'Chat',
      }),
      columnHelper.accessor('last_executed_intent_user_query', {
        Cell: ({ cell }) =>
          cell.getValue() ? (
            <i>
              <Typography color={palette.colors.blue[600]} variant='font14'>
                &quot;
                {cell.getValue()}
                &quot;
              </Typography>
            </i>
          ) : (
            <Typography color={palette.colors.grey[600]} variant='font14'>
              {NA}
            </Typography>
          ),
        enableSorting: false,
        header: 'User query',
      }),
      ...(filterByIntent
        ? []
        : [
            columnHelper.accessor('last_executed_intent_title', {
              Cell: ({ cell, row }) => {
                const intentName = commonIntentIds.includes(
                  row.original.last_executed_intent_definition_id,
                )
                  ? ''
                  : cell.getValue();

                return (
                  <IntentBadge
                    intentDefinitionId={
                      row.original.last_executed_intent_definition_id
                    }
                    intentName={intentName}
                  />
                );
              },
              enableSorting: false,
              header: 'Intent detected',
            }),
          ]),
      columnHelper.accessor('deflected', {
        Cell: ({ cell }) => (
          <Box display='flex' width={SMALL_CELL_WIDTH}>
            <BooleanCell value={cell.getValue()} />
          </Box>
        ),
        grow: false,
        header: 'Deflected',
        size: SMALL_CELL_WIDTH,
      }),
      ...(shouldShowQuickFeedbackAnalytics
        ? [
            columnHelper.accessor('useful_count', {
              Cell: ({ row }) => {
                const {
                  not_useful_count = 0,
                  unanswered_count = 0,
                  useful_count = 0,
                } = row.original || {};
                const totalFeedback =
                  not_useful_count + useful_count + unanswered_count;

                if (totalFeedback < 1) {
                  return (
                    <Typography
                      color={palette.colors.grey[600]}
                      variant='font14'
                    >
                      {NA}
                    </Typography>
                  );
                }

                return (
                  <Box flex={1} maxWidth={140} padding='16px 16px 0 0'>
                    <Tooltip
                      fullWidth
                      maxWidth={320}
                      tooltipContent={
                        <BreakdownPaletteTooltip
                          values={[
                            {
                              color: palette.colors.green[500],
                              label: 'Positive',
                              value: useful_count,
                            },
                            {
                              color: palette.colors.red[500],
                              label: 'Negative',
                              value: not_useful_count,
                            },
                            {
                              color: palette.colors.slate[100],
                              label: 'Not answered',
                              value: unanswered_count,
                            },
                          ]}
                        />
                      }
                    >
                      <HorizontalStackedBar
                        data={[
                          {
                            color: palette.colors.green[500],
                            value: useful_count,
                          },
                          {
                            color: palette.colors.red[500],
                            value: not_useful_count,
                          },
                        ]}
                      />
                    </Tooltip>
                  </Box>
                );
              },
              enableSorting: false,
              header: 'Quick feedback',
            }),
          ]
        : []),
      ...(isCSATEnabled
        ? [
            columnHelper.accessor('csat_score', {
              Cell: ({ cell }) => (
                <Box display='flex' width={SMALL_CELL_WIDTH}>
                  <Typography
                    color={getCSATColor(cell.getValue())}
                    variant='font14Medium'
                  >
                    {formatCSAT(cell.getValue())}
                  </Typography>
                </Box>
              ),
              grow: false,
              header: 'CSAT',
              size: SMALL_CELL_WIDTH,
            }),
          ]
        : []),
      ...(isDeflectionInsightsEnabled
        ? [
            columnHelper.accessor('relevance_rating', {
              Cell: ({ cell, row }) => {
                return (
                  <DeflectionInsightCell
                    isProcessing={isDeflectionInsightsProcessing({
                      hasDeflectionInsights: !!row.original.relevance_rating,
                      isDeflected: row.original.deflected,
                      modifiedDate: row.original.modified_date,
                    })}
                    metric={cell.getValue()}
                  />
                );
              },
              grow: false,
              Header: (
                <LabelWithTooltip
                  label='Relevance'
                  tooltipContent={SHARED_METRIC_DEFINITIONS.relevance.tooltip}
                />
              ),
              header: 'Relevance',
              size: SMALL_CELL_WIDTH,
            }),
            columnHelper.accessor('dropoff_rating', {
              Cell: ({ cell, row }) => (
                <DeflectionInsightCell
                  isProcessing={isDeflectionInsightsProcessing({
                    hasDeflectionInsights: !!row.original.relevance_rating,
                    isDeflected: row.original.deflected,
                    modifiedDate: row.original.modified_date,
                  })}
                  metric={cell.getValue()}
                />
              ),
              grow: false,
              Header: (
                <LabelWithTooltip
                  label='User engagement'
                  tooltipContent='User engagement uses AI to determine whether the user remained engaged through the chat or dropped off mid-way through the conversation.'
                />
              ),
              header: 'User engagement',
              size: SMALL_CELL_WIDTH,
            }),
          ]
        : []),
    ];
  }, [
    columnHelper,
    filterByIntent,
    getCSATColor,
    isCSATEnabled,
    isDeflectionInsightsEnabled,
    shouldShowQuickFeedbackAnalytics,
    palette.colors.blue,
    palette.colors.green,
    palette.colors.grey,
    palette.colors.red,
    palette.colors.slate,
  ]);

  const handleDateRangeOnChange = (dateRange: DateRange) => {
    // Reset page number
    setPage(1);
    // Update date range
    const existingQueryParams = new URLSearchParams(location.search);
    const newSearchParams = constructTimeRangeQueryParamsRevamped(
      dateRange,
      qParams.channel,
    );

    const mergedSearchParams = new URLSearchParams({
      ...Object.fromEntries(existingQueryParams),
      ...Object.fromEntries(newSearchParams),
    });

    navigate(
      {
        pathname: Routes.WORKFLOW_BUILDER,
        search: mergedSearchParams.toString(),
      },
      { replace: true },
    );

    scrollToTop(STICKY_HEADER_HEIGHT);
  };

  const { intent_name: intentName } =
    intents?.find(intent => intent.intent_definition_id === intentId) ?? {};
  const showLoadingSkeleton =
    isConversationsDataLoading || (page === 1 && isConversationsDataFetching);

  const handleLoadMore = useCallback(
    () => setPage(prevPage => prevPage + 1),
    [],
  );

  const handleSortCallback = useCallback(
    (property: keyof Conversation, order: Order) => {
      setPage(1);
      setSortColumn(property, () => setSortDirection(order));
      scrollToTop(STICKY_HEADER_HEIGHT);
    },
    [scrollToTop, setSortColumn, setSortDirection],
  );

  return (
    <>
      <ConversationsTabLayout
        aggregateMetrics={
          (showOverallMetrics || showOnlyCSAT) && (
            <OverallAnalytics
              data={aggregatedData}
              hideCSAT={hideCSAT ?? false}
              isLoading={isAggregatedDataFetching}
              showOnlyCSAT={showOnlyCSAT ?? false}
            />
          )
        }
        header={
          <HeaderSection
            dateLabel={timeFilter.label}
            intentDefinitionId={intentId}
            intentName={intentName}
            isLoading={!!(intentId && intentsLoading)}
            lastUpdated={
              aggregatedData?.last_modified_time &&
              formatToDateAndTime(aggregatedData.last_modified_time)
            }
            overviewText='All chats'
          />
        }
        table={
          <>
            {isNewTableComponentEnabled ? (
              <InfiniteTable2
                hasNextPage={!!conversationsData?.nextPage}
                initialSorting={[
                  {
                    desc: sortDirection === 'desc',
                    id: sortColumn,
                  },
                ]}
                isError={isConversationsDataError}
                isLoadingFirstPage={showLoadingSkeleton}
                isLoadingNextPage={isConversationsDataFetching}
                onLoadMore={handleLoadMore}
                onSortCallback={handleSortCallback}
                stickyHeaderHeight={TOTAL_STICKY_HEADERS_HEIGHT}
                tableOptions={{
                  columns: materialReactTableColumns,
                  data: conversationsData?.conversations || [],
                  enableColumnActions: false,
                  enableColumnFilters: false,
                  enableExpanding: false,
                  enableFullScreenToggle: false,
                  enableGlobalFilter: false,
                  enableRowActions: false,
                  muiTableBodyRowProps: ({ row }) => ({
                    onClick: () => {
                      setSelectedConversationId(row.original.conversation_id);
                    },
                    sx: {
                      cursor: 'pointer',
                    },
                  }),
                  renderTopToolbarCustomActions: () => (
                    <Box display='flex' gap='8px' paddingTop='4px'>
                      <Box width='360px'>
                        <WithHelperText helperText={filterConversationIdError}>
                          <SearchBar
                            aria-label='Chat ID filter'
                            fullWidth
                            onChange={({ target }) =>
                              handleUpdateFilterConversationId(target.value)
                            }
                            onClear={() => setFilterConversationId('')}
                            placeholder='Search by Chat ID'
                            size='small'
                            value={filterConversationId}
                          />
                        </WithHelperText>
                      </Box>
                      <DateRangeFilterButton
                        initialValue={last30DaysTimeRange}
                        onChange={dateRange =>
                          handleDateRangeOnChange(dateRange)
                        }
                        options={datePickerRangeOptionsRevamp}
                        value={selectedDateRange}
                      />
                      {isDeflectionInsightsEnabled ? (
                        <MultiSelectFilter
                          icon={<img src={filterIcon} />}
                          maxHeight={400}
                          onChange={setMultiSelected}
                          options={[
                            ...DEFLECTION_INSIGHT_FILTER_OPTIONS,
                            multiIntentOption(intents),
                          ]}
                          placeholder='Filter by'
                          value={multiSelected}
                          variant='secondary'
                        />
                      ) : (
                        <IntentFilter
                          intents={intents}
                          onChange={value => {
                            setIntentId(value);
                            setPage(1);
                            scrollToTop(STICKY_HEADER_HEIGHT);
                          }}
                          selectedIntentId={intentId}
                        />
                      )}
                      {isAutoflowsEnabled && (
                        <FilterToggleButton
                          handleChange={() => {
                            setFilterAutoflows(!filterAutoflows);
                            setPage(1);
                            scrollToTop(STICKY_HEADER_HEIGHT);
                          }}
                          isChecked={filterAutoflows}
                          label='Show only AutoFlows'
                        />
                      )}
                    </Box>
                  ),
                }}
              />
            ) : (
              <InfiniteTable
                hasNextPage={!!conversationsData?.nextPage}
                isLoadingFirstPage={showLoadingSkeleton}
                isLoadingNextPage={isConversationsDataFetching}
                onLoadMore={handleLoadMore}
                scrollTop={scrollTop}
                scrollToTop={scrollToTop}
                stickyHeaderHeight={STICKY_HEADER_HEIGHT}
                tableProps={{
                  columns,
                  defaultOrder: sortDirection,
                  defaultOrderBy: sortColumn,
                  onClickRow: row => {
                    setSelectedConversationId(row.conversation_id);
                  },
                  onSortCallback: handleSortCallback,
                  rows: conversationsData?.conversations || [],
                }}
              />
            )}
          </>
        }
      />
      <ConversationDrawer
        onClose={() => {
          setSelectedConversationId('');
          emitTrackingEventCallback('chat-drawer-close', {
            id: selectedConversation?.conversation_id,
          });
        }}
        openEventTrigger={() => {
          emitTrackingEventCallback('chat-drawer-open', {
            id: selectedConversation?.conversation_id,
          });
        }}
        selectedConversation={selectedConversation}
      />
    </>
  );
};
