import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { AutomationTabComponentProps } from '../types';
import DiscoverRealizedImpactTable from './DiscoverRealizedImpactTable';
import debounce from 'lodash/fp/debounce';
import { DEBOUNCED_AUTOMATION_TRACKING_EVENT_TIMEOUT } from 'src/constants/discover';
import { useEmitTrackingEventCallback } from 'src/hooks/hooks';
import { selectDiscoverAutomations } from 'src/slices/data/dataSlice';

const DiscoverRealizedImpactTab = ({
  setHasScrolled,
}: AutomationTabComponentProps) => {
  const { data, error, loading } = useSelector(selectDiscoverAutomations);
  const emitTrackingEventCallback = useEmitTrackingEventCallback();

  useEffect(() => {
    setHasScrolled(false);
  }, [setHasScrolled]);

  const handleMouseEnter = useMemo(() => {
    return debounce(
      DEBOUNCED_AUTOMATION_TRACKING_EVENT_TIMEOUT,
      (automationName: string) =>
        emitTrackingEventCallback('discover-realized-impact-hovered', {
          'automation-name': automationName,
          page: 'Automation',
          'page-area': 'Realized impact',
        }),
    );
  }, [emitTrackingEventCallback]);

  const solveWorkflowAutomationData = data.find(
    ({ type }) => type === 'solve_workflow',
  );

  const handleScroll = (scroll: number) => {
    setHasScrolled(Boolean(scroll));
  };

  return (
    <>
      <DiscoverRealizedImpactTable
        data={solveWorkflowAutomationData?.automations}
        isError={Boolean(error)}
        loading={loading}
        onMouseEnter={handleMouseEnter}
        onScroll={handleScroll}
      />
    </>
  );
};

export default DiscoverRealizedImpactTab;
