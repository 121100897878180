import React from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { styled } from '@mui/material';
import { Box } from '@mui/material';

import { Colors } from '@forethought-technologies/forethought-elements';
import {
  CanvasWorkflowBuilderState,
  selectIsDraft,
} from '../../../slices/canvas-workflow-builder/workflowBuilderSlice';
import { ForethoughtEditStepMenu } from '../edit-menu/ForethoughtEditStepMenu';
import { useGetBuilderQueryParams } from '../hooks';
import UndefinedContextVariablesTooltip from '../UndefinedContextVariablesTooltip';
import ActiveIndicator from '../workflow-builder/business-logic/ActiveIndicator';
import StepContainer from '../workflow-builder/revamped/StepContainer';
import StepHeader from '../workflow-builder/revamped/StepHeader';
import { Container } from './GoToStepComponents';
import { useGetIntentsQueryWithProduct } from 'src/hooks/hooks';
import { getWorkflowIdByChannel } from 'src/pages/workflow-builder/intent-workflows-table/helper';
import { useGetHandoffConfigurationsQuery } from 'src/services/workflow-builder-metrics';
import { textBoldStyle } from 'src/styles/styledMixin';
import { GoToIntentStepFields } from 'src/types/workflowBuilderAPITypes';
import { StepTypes } from 'src/utils/enums';
import { isIntentActiveForChannel } from 'src/utils/solve/intentUtils';

interface GoToIntentStepProps {
  canvasData: CanvasWorkflowBuilderState;
  isCanvasDisabled: boolean;
  setShouldShowEditMenu: (shouldShow: boolean) => void;
  shouldShowEditMenu: boolean;
  stepId: string;
}

const GoToIntentStep: React.FC<
  React.PropsWithChildren<GoToIntentStepProps>
> = ({
  canvasData,
  isCanvasDisabled,
  setShouldShowEditMenu,
  shouldShowEditMenu,
  stepId,
}) => {
  const { search } = useLocation();
  const { data } = useGetIntentsQueryWithProduct();
  const { view } = useGetBuilderQueryParams();

  const { intents = [] } = data ?? {};
  const isDraft = useSelector(selectIsDraft);
  const { data: handoffConfigurationsResponse } =
    useGetHandoffConfigurationsQuery();
  const handoffConfigurations =
    handoffConfigurationsResponse?.configurations ?? [];

  const stepFields = canvasData.steps[stepId]
    .step_fields as GoToIntentStepFields;

  const intent = intents.find(
    intent =>
      getWorkflowIdByChannel(view, intent) === stepFields.intent_workflow_id,
  );

  const isCustomHandoffIntent = handoffConfigurations.some(
    handoffConfiguration =>
      handoffConfiguration.intent_workflow_id === stepFields.intent_workflow_id,
  );

  const mergedParams = new URLSearchParams({
    ...Object.fromEntries(new URLSearchParams(search)),
    ...{
      intentId: intent?.intent_definition_id ?? '',
      workflowId: stepFields.intent_workflow_id,
    },
  }).toString();

  return (
    <Container
      onMouseEnter={() => setShouldShowEditMenu(true)}
      onMouseLeave={() => setShouldShowEditMenu(false)}
    >
      {
        <StepContainer>
          <StepHeader
            stepType={
              isCustomHandoffIntent
                ? StepTypes.GO_TO_HANDOFF
                : StepTypes.GO_TO_INTENT
            }
          >
            <Box display='flex' flex={1} justifyContent='flex-end'>
              <UndefinedContextVariablesTooltip stepId={stepId} />
            </Box>
          </StepHeader>
          <Box alignItems='center' display='flex' width='100%'>
            {!isCustomHandoffIntent && (
              <ActiveIndicator
                isActive={
                  intent
                    ? isIntentActiveForChannel(
                        intent.active_workflow_types,
                        view === 'email' ? 'interactive_email' : view,
                      )
                    : false
                }
                margin='0 8px 0 0'
              />
            )}
            <IntentNameRevamped target='_blank' to={{ search: mergedParams }}>
              {intent?.intent_name}
            </IntentNameRevamped>
          </Box>
          {!isCanvasDisabled && isDraft && (
            <ForethoughtEditStepMenu
              isDeletionDisabled={false}
              isVisible={shouldShowEditMenu}
              setIsVisible={setShouldShowEditMenu}
              stepId={stepId}
              stepType={StepTypes.GO_TO_INTENT}
            />
          )}
        </StepContainer>
      }
    </Container>
  );
};

const IntentNameRevamped = styled(Link)`
  ${textBoldStyle({
    color: Colors.ui.text.primary,
    fontSize: '16px',
  })};
  cursor: pointer;
`;

export default GoToIntentStep;
