import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { SelectChangeEvent, Typography } from '@mui/material';
import { LinearProgress } from '@mui/material';
import Box from '@mui/material/Box';

import { useGetCurrentDataSourcePredictedField } from '../../triage-config-detail-page/hooks/useGetCurrentDataSourcePredictedField';
import { useGetCurrentFieldPredictorOrigins } from '../../triage-config-detail-page/hooks/useGetCurrentFieldPredictorOrigins';
import { useGetDataSourcePredictedFields } from '../../triage-config-detail-page/hooks/useGetDataSourcePredictedFields';
import { useGetHelpdesk } from '../../triage-config-detail-page/hooks/useGetHelpdesk';
import HelpDesk from '../../triage-models-overview-page/HelpDesk';
import { isModelReadOnly } from '../helpers';
import { IntegrationChangeParams } from '../types';
import ConfigureInstructions from './ConfigureInstructions';
import DefaultLabelField from './DefaultLabelField';
import GroundTruth from './GroundTruth';
import HelpdeskObjectOptionDropdown from './HelpdeskObjectOptionDropdown';
import IntegrationSalesforce from './IntegrationSalesforce';
import IntegrationZendesk from './IntegrationZendesk';
import Spinner from 'src/components/spinner';
import useSelfServeEvents from 'src/hooks/triage/useSelfServeEvents';
import { getFieldPredictorOutputField } from 'src/reducers/triageSettingsReducer/helpers';
import { selectIntegrationSelectedState } from 'src/reducers/triageSettingsReducer/triageSettingsReducer';
import { AccuracyCheckMethod } from 'src/reducers/triageSettingsReducer/types';
import {
  useGetModelByIdQuery,
  useGetModelVersionQuery,
} from 'src/services/triage/triageApi';
import { TRIAGE_LLM_TRACKING_EVENTS } from 'src/utils/constants';

const IntegrationTab = ({
  isPatching,
  modelId,
  onChange,
  openLabelMapping,
}: {
  isPatching?: boolean;
  modelId?: string;
  onChange: (params: IntegrationChangeParams) => void;
  openLabelMapping: () => void;
}) => {
  const { data: currentTriageModel, isLoading: isModelLoading } =
    useGetModelByIdQuery({ modelId: modelId || '' });

  const [searchParams] = useSearchParams();
  const version = searchParams.get('version');
  const helpdesk = useGetHelpdesk();
  const { data: selectedVersion, isLoading: isVersionLoading } =
    useGetModelVersionQuery(
      { modelId: modelId || '', versionId: version || '' },
      { skip: !version },
    );
  const triageModel = selectedVersion || currentTriageModel;
  const emitTrackingEventCallback = useSelfServeEvents({
    model: triageModel,
    modelId: modelId,
  });

  const integrationSelectedState = useSelector(selectIntegrationSelectedState);

  // Dropdown options
  const { origins, outputFields, preselectedOrigins } =
    useGetDataSourcePredictedFields();

  const { predictedFieldValue, setPredictedFieldValue } =
    useGetCurrentDataSourcePredictedField();

  const {
    originFieldValue,
    selectedOrigins, // used on api body
    setOriginFieldValue,
  } = useGetCurrentFieldPredictorOrigins(origins, preselectedOrigins);

  useEffect(() => {
    if (!triageModel?.model?.outputs?.length) {
      return;
    }
    const modelOutput =
      helpdesk?.name && triageModel.model
        ? getFieldPredictorOutputField(helpdesk.name, triageModel.model) || {}
        : {};
    const currentOutputFields = Object.keys(modelOutput);
    if (!currentOutputFields || currentOutputFields.length === 0) {
      return;
    }
    const currentOutputField = currentOutputFields[0];
    setPredictedFieldValue(currentOutputField);
  });

  const isHelpdeskDataAvailable = Boolean(helpdesk?.name);
  const isPageLoaded =
    !isModelLoading && isHelpdeskDataAvailable && triageModel;

  if (!helpdesk?.name) {
    return <Spinner />;
  }

  if (!isPageLoaded) {
    return null;
  }

  const handleChange = (changeValue: IntegrationChangeParams) => {
    if (changeValue.select) {
      setPredictedFieldValue(changeValue.select);
    }
    emitTrackingEventCallback(TRIAGE_LLM_TRACKING_EVENTS.UPDATE_CONFIGURATION);
    onChange({
      ...changeValue,
      origins: selectedOrigins,
    });
  };

  const handleOriginsChange = (originValues: string[]) => {
    setOriginFieldValue(originValues);
    onChange({
      integrationType: 'salesforce',
      origins: origins.filter(item => originValues.includes(item.value)),
      select: predictedFieldValue,
    });
  };

  const handleAccuracyCheckChange = (event: SelectChangeEvent<string>) => {
    onChange({
      accuracyCheckValue: event.target.value as AccuracyCheckMethod,
      integrationType: helpdesk.name,
      predictedFieldValueName: predictedFieldValue,
    });
  };

  const handleGroundTruthChange = (event: SelectChangeEvent<string>) => {
    onChange({
      groundTruthValue: event.target.value,
      integrationType: helpdesk.name,
      predictedFieldValueName: predictedFieldValue,
    });
  };

  const isZendesk = helpdesk.name === 'zendesk';
  const isSalesforce = helpdesk.name === 'salesforce';
  const isReadOnly = isModelReadOnly(triageModel);

  const isLoading =
    integrationSelectedState === 'loading' ||
    isModelLoading ||
    isVersionLoading ||
    isPatching;
  return (
    <Box display='grid' gridTemplateColumns='1fr 1fr'>
      <Box
        display='flex'
        flexDirection='column'
        rowGap={4}
        sx={{
          '&>div': {
            opacity: isLoading ? '0.5' : undefined,
          },
          pointerEvents: isLoading ? 'none' : undefined,
        }}
        width='450px'
      >
        {isLoading && <LinearProgress />}
        <Typography variant='font16Bold'>Helpdesk Integration</Typography>
        <HelpDesk />
        {isZendesk && (
          <>
            <HelpdeskObjectOptionDropdown />
            <IntegrationZendesk
              integrationSelectedState={integrationSelectedState}
              isDisabled={isReadOnly}
              onChange={handleChange}
              options={outputFields}
              predictedFieldValue={predictedFieldValue}
            />
          </>
        )}
        {isSalesforce && (
          <IntegrationSalesforce
            integrationSelectedState={integrationSelectedState}
            isDisabled={isReadOnly}
            onChange={handleChange}
            options={outputFields}
            originFieldValue={originFieldValue}
            origins={origins}
            predictedFieldValue={predictedFieldValue}
            setOriginFieldValue={handleOriginsChange}
          />
        )}
        <GroundTruth
          accuracyCheckValue={
            currentTriageModel?.model?.model_output_formatter
              .accuracy_check_method || 'exact_match'
          }
          groundTruthValue={
            currentTriageModel?.model?.true_value[0]?.field_name || ''
          }
          isDisabled={isReadOnly}
          onAccuracyCheckChange={handleAccuracyCheckChange}
          onGroundTruthChange={handleGroundTruthChange}
          options={outputFields}
          outputFieldValue={predictedFieldValue}
        />
        <DefaultLabelField
          helpdesk={helpdesk}
          isDisabled={isReadOnly}
          isLoading={isLoading}
          onChange={onChange}
          placeholder='select an existing label'
        />
      </Box>
      <Box display='flex' justifyContent='center'>
        <ConfigureInstructions openLabelMapping={openLabelMapping} />
      </Box>
    </Box>
  );
};

export default IntegrationTab;
