import { useState } from 'react';
import Box from '@mui/material/Box';

import { Typography } from '@forethought-technologies/forethought-elements';
import { MAX_REALIZED_IMPACT_TOPIC_BADGES } from '../constants';
import DiscoverRealizedImpactTopicMenu from './DiscoverRealizedImpactTopicMenu';
import PillButton from 'src/components/pill-button/PillButton';
import RealizedImpactBadge from 'src/components/realized-impact-badge/RealizedImpactBadge';
import { DiscoverTopic } from 'src/reducers/discoverReducer/types';

interface DiscoverRealizedImpactTopicRowProps {
  topics: DiscoverTopic[];
}

const DiscoverRealizedImpactTopicRow = ({
  topics,
}: DiscoverRealizedImpactTopicRowProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const hasRealizedImpactBadgeOverflow =
    topics.length > MAX_REALIZED_IMPACT_TOPIC_BADGES;

  return (
    <>
      <Box display='flex' flexWrap='wrap' mt={0.5} rowGap={1}>
        {topics.slice(0, MAX_REALIZED_IMPACT_TOPIC_BADGES).map(topic => (
          <Box key={topic.topic_id} mr={1}>
            <RealizedImpactBadge topic={topic} />
          </Box>
        ))}
        {hasRealizedImpactBadgeOverflow && (
          <PillButton
            onClick={e => setAnchorEl(e.currentTarget)}
            variant='topic'
          >
            <Typography noWrap variant='font12Medium'>
              Show all {topics.length}
            </Typography>
          </PillButton>
        )}
      </Box>
      <DiscoverRealizedImpactTopicMenu
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        title='Discover topics'
        topics={topics}
      />
    </>
  );
};

export default DiscoverRealizedImpactTopicRow;
