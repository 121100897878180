import React, { useCallback, useEffect, useState } from 'react';
import Pusher from 'pusher-js';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useLocation, useNavigate } from 'react-router';
import { styled } from '@mui/material';

import { theme, Toast } from '@forethought-technologies/forethought-elements';
import {
  cancelAddingAction,
  CanvasWorkflowBuilderState,
  clearActionSettings,
  selectCanvasErrors,
  selectIsDuplicatedPendingCustomization,
  selectIsSqueezingStep,
  selectIsWorkflowActive,
  selectShouldSqueezeIntoEntry,
  selectSqueezeStepParentId,
  setErrors,
  setIsSqueezingStep,
  setMode,
  setShouldSqueezeIntoEntry,
} from '../../../slices/canvas-workflow-builder/workflowBuilderSlice';
import {
  discardDraftWorkflow,
  getIntent,
  getLatestWorkflowVersions,
  loadDraftWorkflow,
  updateOnboardingFlags,
} from '../../../slices/canvas-workflow-builder/workflowBuilderSlice.thunks';
import BuilderTopBar from '../builder-top-bar/BuilderTopbar';
import WorkflowPublishButtons from '../builder-top-bar/WorkflowPublishButtons';
import ForethoughtCanvasRight from '../ForethoughtCanvasRight';
import ForethoughtWidget from '../ForethoughtWidget';
import {
  useCheckForToasts,
  useGetAutoflowWorkflowOrAutoflowTemplate,
  useGetBuilderQueryParams,
  useGoBackToLandingPage,
} from '../hooks';
import { useRedirectToLandingPageOnError } from '../hooks/useRedirectToLandingPageOnError';
import { BuilderView, WorkflowBuilderProps } from '../types';
import WorkflowConflictMessage from '../WorkflowConflictMessage';
import BusinessLogic from './business-logic';
import { WidgetModeButtonGroup } from './WidgetModeButtonGroup';
import {
  getActionBuilderActions,
  setCustomizableActionId,
} from 'src/actions/action-builder-actions/actionBuilderActions';
import {
  getModelTrainingData,
  setIsOverlayVisible,
} from 'src/actions/workflow-builder/workflowBuilderActions';
import Overlay from 'src/components/dashboard-pages/answers-analytics-page/SettingsBar/Overlay';
import UnsavedChangesModal from 'src/components/unsaved-changes-modal';
import { activeAssertionErrorMessage } from 'src/constants/apiErrors';
import {
  useFlamethrowerTrackingEventAction,
  useListenPreviewPostMessageEvents,
} from 'src/hooks/hooks';
import {
  useCanvasActiveWorkflowStatusEventSubscription,
  useDeleteIntentEventSubscription,
  useModelTrainingStatus,
  useReloadWorkflowEventSubscription,
  useUpdateIntentEventSubscription,
} from 'src/hooks/PusherEventHooks';
import { useGetIsAutoflowCapableWorkflow } from 'src/hooks/solve';
import { useGetContextVariables } from 'src/hooks/useGetContextVariables';
import useDemoNewTab from 'src/pages/intent-conversation-analytics/hooks/useDemoNewTab';
import {
  CanvasModes,
  CIRCULAR_PATH_MESSAGE,
} from 'src/pages/workflow-builder-edit/constants';
import VersionHistory from 'src/pages/workflow-builder-edit/version-history/VersionHistory';
import { selectNonDeprecatedActionBuilderActions } from 'src/reducers/actionBuilderReducer/actionBuilderReducer';
import {
  selectIsUserSuperAdmin,
  selectUserCan,
} from 'src/reducers/userReducer/userReducer';
import {
  selectCanvasWorkflowBuilder,
  selectIntentTitle,
  selectIsOverlayVisible,
  selectMode,
  selectVersion,
} from 'src/reducers/workflowBuilderReducer/workflowBuilderReducer';
import {
  useGetFeatureFlagsQuery,
  useGetPaidPlanDetailsQuery,
} from 'src/services/dashboard-api';
import { selectDiscoverTopicsByIntentId } from 'src/slices/data/dataSlice';
import { getDiscoverAutomations } from 'src/slices/data/thunks';
import { openGlobalIntentDrawer } from 'src/slices/ui/uiSlice';
import { ActionPanelMode } from 'src/types/actionBuilderApiTypes';
import { CHANNEL_TO_PRODUCT_MAP } from 'src/utils/constants';
import {
  CommonIntentWorkflowType,
  FlamethrowerTrackingApplications,
  FlamethrowerTrackingEventTypes,
  Routes,
} from 'src/utils/enums';
import { getUserOrgCode } from 'src/utils/getUserOrgCode';

const toastProps = {
  anchorOrigin: { horizontal: 'center', vertical: 'top' },
  autoHideDuration: 2000,
  width: '416px',
} as const;

const WorkflowBuilder = ({ onTabClick, view }: WorkflowBuilderProps) => {
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const location = useLocation();

  const canvasData: CanvasWorkflowBuilderState = useSelector(
    selectCanvasWorkflowBuilder,
  );

  const { contextVariables } = useGetContextVariables();

  const { workflowId: selectedWorkflowId } = useGetBuilderQueryParams();
  const version = useSelector(selectVersion);
  const isOverlayVisible = useSelector(selectIsOverlayVisible);
  const userCanUpdateWorkflow = useSelector(selectUserCan('update_workflow'));
  const userCanDiscardDraft = useSelector(
    selectUserCan('discard_draft_workflow'),
  );
  const isSuperAdmin = useSelector(selectIsUserSuperAdmin);
  const intentTitle = useSelector(selectIntentTitle);
  const { data: featureFlagsData } = useGetFeatureFlagsQuery();
  const { feature_flags: featureFlags = [] } = featureFlagsData ?? {};

  const actionBuilderActions = useSelector(state =>
    selectNonDeprecatedActionBuilderActions(state, featureFlags),
  );

  const discoverTopicsByIntentId = useSelector(selectDiscoverTopicsByIntentId);
  const { data: paidPlanData } = useGetPaidPlanDetailsQuery();
  const errors = useSelector(selectCanvasErrors);
  const isDuplicatedPendingCustomization = useSelector(
    selectIsDuplicatedPendingCustomization,
  );
  const isDiscoverEnabled = paidPlanData?.enablement_info.discover;
  const isWorkflowActive = useSelector(selectIsWorkflowActive);
  const mode = useSelector(selectMode);

  const { autoflowWorkflow } = useGetAutoflowWorkflowOrAutoflowTemplate(
    canvasData.intent_workflow_id ?? '',
  );

  const isAutochainWorkflow = autoflowWorkflow?.status === 'enabled';
  const isAutoflowCapable = useGetIsAutoflowCapableWorkflow();

  const shouldSqueezeIntoEntry = useSelector(selectShouldSqueezeIntoEntry);
  const isSqueezingStep = useSelector(selectIsSqueezingStep);
  const squeezeStepParentId = useSelector(selectSqueezeStepParentId);
  const [actionPanelVisibilityParameters, setActionPanelVisibilityParameters] =
    useState<ActionPanelMode>('hidden');
  const [shouldShowHandoffPanelDiscard, setShouldShowHandoffPanelDiscard] =
    useState(false);
  const [areActionSettingsUnsaved, setAreActionSettingsUnsaved] =
    useState(false);
  const [hasWorkflowConflict, setHasWorkflowConflict] = useState(false);
  const [shouldShowVersionHistory, setShouldShowVersionHistory] =
    useState(false);

  const [isIntentDeleted, setIsIntentDeleted] = useState(false);

  const { setToastMessage, toastMessage } = useCheckForToasts();

  const shouldDisableHeaderInteraction = canvasData.loading;

  const isActiveAssertionErrorMessage = errors.includes(
    activeAssertionErrorMessage,
  );

  const isWorkflowConflictModalVisible =
    hasWorkflowConflict || isActiveAssertionErrorMessage;

  const isGeneralHandoffWorkflow = canvasData.intent_id === 'general-handoff';

  const goBackToLandingPage = useGoBackToLandingPage();

  const dispatchTrackingAction = useFlamethrowerTrackingEventAction(
    FlamethrowerTrackingApplications.WORKFLOW_BUILDER_CANVAS,
  );

  const onDiscard = () => {
    dispatch(setMode({ contextVariables, mode: CanvasModes.MESSAGE }));
    setActionPanelVisibilityParameters('hidden');
    dispatch(setIsOverlayVisible(false));
    setShouldShowHandoffPanelDiscard(false);
    setAreActionSettingsUnsaved(false);
    dispatch(setCustomizableActionId(''));
    dispatch(cancelAddingAction());
    dispatch(clearActionSettings());
    if (isSqueezingStep) {
      dispatch(setIsSqueezingStep(false));
      dispatch(setShouldSqueezeIntoEntry(false));
    }
  };

  const ActionSettingsDiscardBtnCallback = () => {
    if (areActionSettingsUnsaved) {
      dispatch(setIsOverlayVisible(true));
      setShouldShowHandoffPanelDiscard(true);
    } else {
      onDiscard();
    }
  };

  const isHandoffRevampEnabled = featureFlags.includes('handoff_revamp');

  useModelTrainingStatus(getUserOrgCode());
  useListenPreviewPostMessageEvents();
  useRedirectToLandingPageOnError();

  useDemoNewTab('widget');

  useEffect(() => {
    if (canvasData?.intent_id && isDiscoverEnabled) {
      dispatch(getDiscoverAutomations());
    }
  }, [dispatch, canvasData?.intent_id, isDiscoverEnabled]);

  useEffect(() => {
    if (!actionBuilderActions.length) {
      dispatch(getActionBuilderActions());
    }
  }, [actionBuilderActions.length, dispatch]);

  useEffect(() => {
    dispatch(getModelTrainingData());
    if (!window.pusher) {
      window.pusher = new Pusher(PUSHER_KEY, {
        cluster: PUSHER_CLUSTER,
      });
    }
    return () => {
      dispatch(setCustomizableActionId(''));
    };
  }, [dispatch, isHandoffRevampEnabled]);

  useCanvasActiveWorkflowStatusEventSubscription({
    intentWorkflowId: selectedWorkflowId,
    onEvent: useCallback(() => {
      setHasWorkflowConflict(true);
    }, []),
    orgCode: getUserOrgCode(),
    version,
  });
  useDeleteIntentEventSubscription({
    onEvent: useCallback(() => {
      setIsIntentDeleted(true);
    }, []),
    orgCode: getUserOrgCode(),
  });

  useUpdateIntentEventSubscription({
    onEvent: useCallback(() => {
      setHasWorkflowConflict(true);
    }, []),
    orgCode: getUserOrgCode(),
  });

  useReloadWorkflowEventSubscription({
    intentWorkflowId: selectedWorkflowId,
    onEvent: useCallback(() => setHasWorkflowConflict(true), []),
    orgCode: getUserOrgCode(),
    version,
  });

  const handlePreviewWidget = () => {
    if (canvasData.intent_id) {
      const previewPath = generatePath(Routes.WORKFLOW_BUILDER_PREVIEW, {
        channel: 'widget',
      });
      navigate({
        pathname: previewPath,
        search: `?intentId=${
          canvasData.intent_id
        }&mode=classic-workflow&product=${
          CHANNEL_TO_PRODUCT_MAP[view || 'widget']
        }`,
      });
    }
  };

  const handlePreviewInteractiveEmailEmail = () => {
    if (canvasData.intent_id) {
      const previewPath = generatePath(Routes.WORKFLOW_BUILDER_PREVIEW, {
        channel: 'email',
      });
      navigate({
        pathname: previewPath,
        search: `?intentId=${canvasData?.intent_id}&workflowId=${canvasData?.intent_workflow_id}`,
      });
    }
  };

  const handlePreviewApi = () => {
    if (canvasData.intent_id) {
      const previewPath = generatePath(Routes.WORKFLOW_BUILDER_PREVIEW, {
        channel: 'api',
      });
      navigate({
        pathname: previewPath,
        search: `?intentId=${canvasData.intent_id}`,
      });
    }
  };

  const resetCanvasModes = () => {
    onDiscard();
    dispatch(setMode({ contextVariables, mode: CanvasModes.MESSAGE }));
  };

  const reloadWorkflowData = () => {
    if (!selectedWorkflowId) {
      return;
    }
    dispatch(loadDraftWorkflow(selectedWorkflowId));
    dispatch(getLatestWorkflowVersions(selectedWorkflowId));
    if (canvasData?.intent_id && isDiscoverEnabled) {
      dispatch(getDiscoverAutomations());
    }
    dispatch(getActionBuilderActions());

    dispatch(getModelTrainingData());
  };

  const isOnboardingExperienceEnabled = featureFlags.includes(
    'onboarding_experience',
  );

  useEffect(() => {
    if (
      isOnboardingExperienceEnabled &&
      canvasData.intent_id === CommonIntentWorkflowType.HANDOFF
    ) {
      dispatch(updateOnboardingFlags({ is_handoff_visited: true }));
    }
  }, [dispatch, isOnboardingExperienceEnabled, canvasData.intent_id]);

  const cancelSqueeze = useCallback(() => {
    dispatch(setIsSqueezingStep(false));
    dispatch(setShouldSqueezeIntoEntry(false));
  }, [dispatch]);

  return (
    <>
      <WorkflowConflictMessage
        buttonMessage='Reload'
        message=' Reload this page to continue editing this workflow from the last
            updated version.'
        onButtonClick={() => {
          reloadWorkflowData();
          setHasWorkflowConflict(false);
        }}
        open={isWorkflowConflictModalVisible}
        title='Workflow has been updated by another user in your organization'
      />

      <Container>
        <Overlay displayOverlay={isOverlayVisible} isDarkest />
        <BuilderTopBar
          contentRight={
            <WorkflowPublishButtons
              areChangesUnsaved={canvasData.unsaved_changes}
              isAutochainWorkflow={isAutochainWorkflow}
              isCanvasTaggerVisible={
                !isGeneralHandoffWorkflow &&
                view !== 'email' &&
                view !== 'slack'
              }
              isDiscardButtonDisabled={
                !userCanDiscardDraft ||
                !canvasData.intent_workflow_id ||
                !canvasData.unsaved_changes ||
                hasWorkflowConflict ||
                shouldDisableHeaderInteraction
              }
              isDraftVersion
              isPreviewButtonVisible={
                (isSuperAdmin || view !== 'email') && view !== 'slack'
              }
              isSolveDiscoverTopicsButtonVisible={
                discoverTopicsByIntentId.length > 0
              }
              isToggleButtonOn={isWorkflowActive}
              isUpdateButtonDisabled={
                !userCanUpdateWorkflow ||
                !canvasData.intent_workflow_id ||
                !canvasData.unsaved_changes ||
                hasWorkflowConflict ||
                shouldDisableHeaderInteraction
              }
              isVersionHistoryButtonVisible={!!selectedWorkflowId}
              isVersionHistoryPanelOpen={shouldShowVersionHistory}
              isWorkflowBuilderDisabled={shouldDisableHeaderInteraction}
              onCanvasTaggerClick={() => {
                setShouldShowVersionHistory(false);
              }}
              onDiscardButtonClick={() => {
                canvasData.intent_workflow_id &&
                  dispatch(discardDraftWorkflow(canvasData.intent_workflow_id));
              }}
              onPreviewButtonClick={() => {
                if (view === 'email') {
                  handlePreviewInteractiveEmailEmail();
                } else if (view === 'api') {
                  handlePreviewApi();
                } else {
                  handlePreviewWidget();
                }
              }}
              onSolveDiscoverTopicButonClick={async () => {
                await dispatch(getIntent(canvasData.intent_id as string));
                dispatch(
                  openGlobalIntentDrawer({
                    type: 'widget',
                  }),
                );
              }}
              onVersionHistoryButtonClick={() => {
                dispatchTrackingAction(
                  FlamethrowerTrackingEventTypes.WORKFLOW_VERSION_CONTROL_PANEL_OPENED,
                  {
                    intent_id: selectedWorkflowId || '',
                    intent_name: intentTitle,
                    org_id: canvasData.org_id,
                  },
                );
                setShouldShowVersionHistory(true);
              }}
              previewButtonText={
                view === 'email' || view === 'api'
                  ? 'Preview'
                  : 'Preview Intent'
              }
              shouldUseWorkflowPublishButton
              view={view}
            />
          }
          onTabClick={(view: BuilderView) => onTabClick && onTabClick(view)}
          view={view}
        />
        <ToastContainer>
          <Toast
            onClose={() => {
              setToastMessage(null);

              // Remove `from` query param from URL to prevent toast from
              // reappearing after page refresh:
              const query = new URLSearchParams(location.search);
              query.delete('from');
              navigate({ search: `?${query}` }, { replace: true });
            }}
            open={!!toastMessage}
            title={toastMessage || ''}
            variant='main'
            {...toastProps}
          />
          <Toast
            onClose={() => dispatch(setErrors([]))}
            open={canvasData?.errors?.[0] === CIRCULAR_PATH_MESSAGE}
            title='Cannot add or delete step because a circular path was found'
            variant='danger'
            {...toastProps}
          />
        </ToastContainer>
        <DndProvider backend={HTML5Backend}>
          <ContentWithDragAndDrop>
            {isAutoflowCapable && (
              <WidgetModeButtonGroupContainer>
                <WidgetModeButtonGroup />
              </WidgetModeButtonGroupContainer>
            )}
            <ForethoughtWidget
              actionPanelVisibilityParameters={actionPanelVisibilityParameters}
              hasWorkflowConflict={hasWorkflowConflict}
              setActionPanelVisibilityParameters={
                setActionPanelVisibilityParameters
              }
              shouldSqueezeIntoEntry={shouldSqueezeIntoEntry}
              squeezeStepParentId={squeezeStepParentId}
            />
            <BusinessLogic
              cancelSqueeze={cancelSqueeze}
              hasWorkflowConflict={hasWorkflowConflict}
              isDisabled={
                shouldDisableHeaderInteraction ||
                (isDuplicatedPendingCustomization &&
                  (view === 'email' || view === 'api'))
              }
              setActionPanelVisibilityParameters={
                setActionPanelVisibilityParameters
              }
              shouldSqueezeIntoEntry={shouldSqueezeIntoEntry}
              squeezeStepParentId={squeezeStepParentId}
            />
            {mode === CanvasModes.ACTION_EDIT && (
              <ForethoughtCanvasRight
                actionPanelVisibilityParameters={
                  actionPanelVisibilityParameters
                }
                actions={actionBuilderActions}
                hasWorkflowConflict={hasWorkflowConflict}
                onDiscard={ActionSettingsDiscardBtnCallback}
                setActionPanelVisibilityParameters={
                  setActionPanelVisibilityParameters
                }
                setAreActionSettingsUnsaved={setAreActionSettingsUnsaved}
                shouldSqueezeIntoEntry={shouldSqueezeIntoEntry}
                squeezeStepParentId={squeezeStepParentId}
              />
            )}
            {shouldShowVersionHistory && (
              <VersionHistory
                resetCanvasModes={resetCanvasModes}
                setShouldShowVersionHistory={setShouldShowVersionHistory}
              />
            )}
          </ContentWithDragAndDrop>
        </DndProvider>
        {shouldShowHandoffPanelDiscard && (
          <UnsavedChangesModal
            onCancel={() => {
              dispatch(setIsOverlayVisible(false));
              setShouldShowHandoffPanelDiscard(false);
            }}
            onDiscard={() => onDiscard()}
          />
        )}
      </Container>
      <WorkflowConflictMessage
        buttonMessage='Go to the landing page'
        message='Go to the landing page to see the list of existing intents'
        onButtonClick={goBackToLandingPage}
        open={isIntentDeleted}
        title='The current intent has been deleted by another user in your organization'
      />
    </>
  );
};

const Container = styled('main')`
  font-weight: var(--font-weight-regular);
  width: 100%;
  overflow-x: hidden;
`;

const ContentWithDragAndDrop = styled('div')`
  display: flex;
  flex: 1;
  justify-content: space-between;
`;

export const HistoryButtonWrapper = styled('div')<{
  shouldShowVersionHistory: boolean;
}>`
  svg {
    [fill] {
      fill: ${props =>
        props.shouldShowVersionHistory
          ? theme.palette.colors.purple[500]
          : 'auto'};
    }
    [stroke] {
      stroke: ${props =>
        props.shouldShowVersionHistory
          ? theme.palette.colors.purple[500]
          : 'auto'};
    }
  }
`;

const ToastContainer = styled('div')`
  position: fixed;
  top: 50px;
  left: 50%;
  transform: translate(-50%);
  z-index: 10;
`;

const WidgetModeButtonGroupContainer = styled('div')`
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 150px;
  margin-top: 24px;
  align-items: flex-start;
`;

export const MemoizedWorkflowBuilder = React.memo(WorkflowBuilder);
