import React, { FC } from 'react';
import { Field, FieldProps } from 'formik';
import { Box } from '@mui/material';

import AddPreChatFieldLink from './AddPreChatFieldLink';
import RemoveFieldButton from './RemoveFieldButton';
import ContextVariableAutocomplete from 'src/components/context-variable-autocomplete';
import {
  Label,
  Row,
  StyledInput,
} from 'src/pages/workflow-builder-edit/handoff-configuration/styles';
import { useGetUndefinedContextVariableIdsOnCurrentAction } from 'src/pages/workflow-builder-edit/hooks/useGetUndefinedContextVariableIdsOnCurrentAction';
import {
  ExtraPrechatFormDetails,
  SalesforceChatHandoffCustomization,
} from 'src/types/workflowBuilderAPITypes';

interface TicketCustomFieldsProps {
  customizationData: Pick<
    SalesforceChatHandoffCustomization,
    'agent_chat_handoff_settings'
  >;
}

const ExtraPreChatValues: FC<
  React.PropsWithChildren<TicketCustomFieldsProps>
> = ({ customizationData }) => {
  const undefinedContextVariableIds =
    useGetUndefinedContextVariableIdsOnCurrentAction();
  const preChatValueArray = [
    ...customizationData.agent_chat_handoff_settings
      ?.extra_prechat_form_details,
  ];

  const handleArrayCopy = () => [...preChatValueArray];

  const handleSaveValue = (
    idx: number,
    fieldName: string,
    updatedObj: ExtraPrechatFormDetails,
    updatedValueArray: ExtraPrechatFormDetails[],
    setFieldValue: (field: string, value: ExtraPrechatFormDetails[]) => void,
  ) => {
    updatedValueArray[idx] = updatedObj;
    setFieldValue(fieldName, updatedValueArray);
  };

  return (
    <>
      <Box display='flex' gap={0.5}>
        <Label>Extra Pre-Chat Form Details</Label>
        <Label>-</Label>
        <a
          href='https://developer.salesforce.com/docs/atlas.en-us.240.0.snapins_web_dev.meta/snapins_web_dev/snapins_web_prechat_details.htm'
          rel='noreferrer'
          target='_blank'
        >
          Documentation
        </a>
      </Box>
      <Field name='agent_chat_handoff_settings.extra_prechat_form_details'>
        {({ field, form: { setFieldValue } }: FieldProps) => (
          <>
            {preChatValueArray.map(
              ({ label, transcript_fields, value }, idx) => {
                return (
                  <div key={`field-row-${idx}`}>
                    <Row>
                      <Box display='flex' flex={1} gap={1}>
                        <StyledInput
                          onChange={e => {
                            const updatedValueArray = handleArrayCopy();
                            const updatedObj = { ...preChatValueArray[idx] };
                            updatedObj.label = e.target.value;
                            handleSaveValue(
                              idx,
                              field.name,
                              updatedObj,
                              updatedValueArray,
                              setFieldValue,
                            );
                          }}
                          placeholder='Label'
                          required
                          value={label}
                        />
                        <Box minWidth='200px'>
                          <ContextVariableAutocomplete
                            aria-label='Value'
                            onChange={fieldValue => {
                              const updatedValueArray = handleArrayCopy();
                              const updatedObj = { ...preChatValueArray[idx] };
                              updatedObj.value = fieldValue;
                              handleSaveValue(
                                idx,
                                field.name,
                                updatedObj,
                                updatedValueArray,
                                setFieldValue,
                              );
                            }}
                            placeholder='Value'
                            required
                            shouldIncludeSystemContextVariables
                            undefinedContextVariables={
                              undefinedContextVariableIds
                            }
                            value={value}
                          />
                        </Box>

                        <StyledInput
                          onChange={e => {
                            const updatedValueArray = handleArrayCopy();
                            const updatedObj = {
                              ...preChatValueArray[idx],
                            };
                            updatedObj.transcript_fields = [e.target.value];
                            handleSaveValue(
                              idx,
                              field.name,
                              updatedObj,
                              updatedValueArray,
                              setFieldValue,
                            );
                          }}
                          placeholder='Transcript field'
                          required
                          value={transcript_fields[0]}
                        />
                      </Box>
                      <RemoveFieldButton
                        fieldName={field.name}
                        idx={idx}
                        preChatValueArray={preChatValueArray}
                        setFieldValue={setFieldValue}
                      />
                    </Row>
                    <Box mt={1} />
                  </div>
                );
              },
            )}
            <AddPreChatFieldLink
              fieldName={field.name}
              preChatValueArray={preChatValueArray}
              setFieldValue={setFieldValue}
            />
          </>
        )}
      </Field>
    </>
  );
};

export default ExtraPreChatValues;
