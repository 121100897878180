import { dashboardApi } from '../dashboard-api';
import { EmailSettings, UpdateEmailSettingsRequest } from './types';

const BASE_URL = 'solve/email';

const emailSettingsApi = dashboardApi.injectEndpoints({
  endpoints: builder => ({
    getEmailSettings: builder.query<EmailSettings, void>({
      providesTags: ['EmailSettings'],
      query: () => ({
        method: 'GET',
        url: `${BASE_URL}/settings`,
      }),
    }),
    updateEmailSettings: builder.mutation<
      EmailSettings,
      UpdateEmailSettingsRequest
    >({
      invalidatesTags: ['EmailSettings'],
      query: body => ({
        body,
        method: 'PUT',
        url: `${BASE_URL}/settings`,
      }),
    }),
  }),
});

export const { useGetEmailSettingsQuery, useUpdateEmailSettingsMutation } =
  emailSettingsApi;
