import React, { FC } from 'react';
import { Field, FieldProps } from 'formik';
import { styled } from '@mui/material';

import { SelectDropdown } from '@forethought-technologies/forethought-elements';
import get from 'lodash/fp/get';
import keyBy from 'lodash/fp/keyBy';
import {
  Label,
  Row,
  Spacer,
} from 'src/pages/workflow-builder-edit/handoff-configuration/styles';
import { SalesforceChatHandoffCustomization } from 'src/types/workflowBuilderAPITypes';

interface StoreTranscriptDropdownProps {
  customizationData: Pick<
    SalesforceChatHandoffCustomization,
    'agent_chat_handoff_settings'
  >;
}

const StoreTranscriptDropdown: FC<
  React.PropsWithChildren<StoreTranscriptDropdownProps>
> = ({ customizationData }) => {
  const availableTextAreaFields =
    customizationData.agent_chat_handoff_settings
      ?.chat_transcript_text_area_fields;
  const selectedChatTranscriptFieldId =
    customizationData.agent_chat_handoff_settings
      ?.store_transcript_to_chat_transcript_field_id ?? '';
  const idFieldMap = keyBy('id', availableTextAreaFields);

  if (!availableTextAreaFields) {
    return null;
  }

  return (
    <Field name='agent_chat_handoff_settings.store_transcript_to_chat_transcript_field_id'>
      {({ field, form: { setFieldValue } }: FieldProps) => (
        <>
          <Row>
            <Label>Store transcript to a SF Chat Transcript field</Label>
            <Spacer width='4px' />
          </Row>
          <Container>
            <SelectDropdown
              aria-label='Select a Field...'
              id='salesforce-chat-transcript-field-dropdown'
              isMenuSearchable
              menuMaxHeight={180}
              onChange={e => {
                if (e.target.value) {
                  setFieldValue(field.name, e.target.value);
                }
              }}
              options={availableTextAreaFields.map(
                (customField: { id: string; title: string }) => ({
                  label: customField.title,
                  value: customField.id,
                }),
              )}
              value={get([selectedChatTranscriptFieldId, 'id'], idFieldMap)}
            />
          </Container>
        </>
      )}
    </Field>
  );
};

const Container = styled('div')`
  flex: 1 1 50%;
`;

export default StoreTranscriptDropdown;
