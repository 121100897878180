import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

import {
  Button,
  theme,
  Tooltip,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { useEmitTrackingEventCallback } from 'src/hooks/hooks';
import {
  selectHasMinimalPermission,
  selectHelpdesk,
  selectIsHelpdeskSupported,
  selectSupportedHelpdesks,
} from 'src/reducers/triageSettingsReducer/triageSettingsReducer';
import { Routes } from 'src/utils/enums';

interface TriageConfigDetailSettingEmptyProps {
  setIsEditing?: React.Dispatch<React.SetStateAction<boolean>>;
}

const TriageConfigDetailSettingEmpty = ({
  setIsEditing,
}: TriageConfigDetailSettingEmptyProps) => {
  const isHelpdeskSupported = useSelector(selectIsHelpdeskSupported);
  const helpdeskDetail = useSelector(selectHelpdesk);
  const triageSupportedHelpdesks = useSelector(selectSupportedHelpdesks);
  const hasMinimalPermission = useSelector(selectHasMinimalPermission);
  const isDataSourceEmpty = !setIsEditing;
  const emitTrackingEventCallback = useEmitTrackingEventCallback();

  const toolTipElement = (
    <Tooltip
      tooltipContent={
        <>
          <div>Supported helpdesk integrations:</div>
          {triageSupportedHelpdesks.map((helpdesk, index) => (
            <CapitalizeText key={helpdesk}>
              {triageSupportedHelpdesks.length > 1 && index > 0 ? ', ' : ''}
              {helpdesk}
            </CapitalizeText>
          ))}
        </>
      }
    >
      <InfoOutlinedIcon
        htmlColor={theme.palette.common.black}
        sx={{ fontSize: '15px' }}
      />
    </Tooltip>
  );

  const handleDataSourceSupportRender = () => {
    if (!hasMinimalPermission) {
      return (
        <>
          <ImageTextContainer>
            <Typography variant='font16Bold'>
              <Box alignItems='center' component='span' display='flex'>
                Your
                <AvatarIcon src={helpdeskDetail?.avatar} />
                {`${helpdeskDetail?.display_name} integration does not have the required permissions`}
              </Box>
            </Typography>
          </ImageTextContainer>
          <Box sx={{ mb: 1 }} />
          <ContentContainer>
            <Typography color={theme.palette.colors.grey[700]} variant='font14'>
              Reach out to{' '}
              <Typography variant='font14Medium'>
                <a
                  href='mailto:support@forethought.ai'
                  rel='noreferrer'
                  target='_blank'
                >
                  support@forethought.ai
                </a>
              </Typography>{' '}
              for help
            </Typography>
          </ContentContainer>
        </>
      );
    }
    // No integration configured scenario
    if (isDataSourceEmpty) {
      if (isHelpdeskSupported) {
        return (
          <>
            <ImageTextContainer>
              <AvatarIcon src={helpdeskDetail?.avatar} />
              <Typography variant='font16Bold'>
                {helpdeskDetail?.display_name} integration not configured
              </Typography>
            </ImageTextContainer>
          </>
        );
      }

      return (
        <>
          <Typography variant='font16Bold'>
            Helpdesk integration not configured
          </Typography>
          <Box sx={{ mb: 1 }} />
          <ImageTextContainer>
            <Typography color={theme.palette.colors.grey[700]} variant='font14'>
              Configure a supported helpdesk integration in settings to use this
              model
            </Typography>
            <Box sx={{ pl: '6px' }} />
            {toolTipElement}
          </ImageTextContainer>
        </>
      );
    } else {
      // Integration configured scenario
      if (isHelpdeskSupported) {
        return (
          <>
            <ImageTextContainer>
              <Typography variant='font16Bold'>Get started with</Typography>
              {helpdeskDetail?.avatar && (
                <AvatarIcon src={helpdeskDetail?.avatar} />
              )}
              <Typography variant='font16Bold'>
                {helpdeskDetail?.display_name}
              </Typography>
            </ImageTextContainer>
            <Box sx={{ mb: 1 }} />
            <Typography color={theme.palette.colors.grey[700]} variant='font14'>
              {`Set up your ${helpdeskDetail?.display_name} integration for this model`}
            </Typography>
          </>
        );
      }

      return (
        <>
          <ImageTextContainer>
            {helpdeskDetail?.avatar && (
              <AvatarIcon src={helpdeskDetail?.avatar} />
            )}
            <Typography variant='font16Bold'>
              {`${helpdeskDetail?.display_name} integration not supported for Triage Quickstart`}
            </Typography>
          </ImageTextContainer>
          <Box sx={{ mb: 1 }} />
          <ContentContainer>
            <Typography color={theme.palette.colors.grey[700]} variant='font14'>
              The team is working hard to support more helpdesk integrations for
              Triage Quickstart!
            </Typography>
          </ContentContainer>
        </>
      );
    }
  };

  const handleButtonRender = () => {
    if (!hasMinimalPermission) {
      return null;
    }
    // Supported integrations
    if (isHelpdeskSupported) {
      if (isDataSourceEmpty) {
        return (
          <Link
            to={`${Routes.INTEGRATIONS}?integrationSlug=${helpdeskDetail?.slug}`}
          >
            <Button
              id='configure-integration-directly-btn'
              onClick={() => {
                emitTrackingEventCallback(
                  'triage-directed-to-integrations-page',
                  { scope: 'self-serve' },
                );
              }}
              size='large'
              variant='main'
            >
              Configure {helpdeskDetail?.display_name}
            </Button>
          </Link>
        );
      }

      return (
        <Button
          id='get-started-btn'
          onClick={() => setIsEditing(true)}
          size='large'
          variant='main'
        >
          Get started
        </Button>
      );
    }

    // Not supported integrations
    return null;
  };

  return (
    <DataSourceEmptyContainer>
      {handleDataSourceSupportRender()}
      <Box sx={{ mb: 2 }} />
      {handleButtonRender()}
    </DataSourceEmptyContainer>
  );
};

const DataSourceEmptyContainer = styled('div')`
  margin-top: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const ContentContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;

  a {
    cursor: pointer;
    text-decoration: none;
  }
`;

const ImageTextContainer = styled('div')`
  display: flex;
  align-items: center;
`;

const AvatarIcon = styled('img')`
  height: 20px;
  width: 20px;
  margin: 0px 6px;
`;

const CapitalizeText = styled('span')`
  text-transform: capitalize;
`;

export default TriageConfigDetailSettingEmpty;
