import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/system';
import { Box } from '@mui/system';

import {
  Button,
  SearchBar,
} from '@forethought-technologies/forethought-elements';
import useActionBuilderActions from '../../hooks/useActionBuilderActions';
import DraggableListItem from './DraggableListItem';
import { useGetContextVariables } from 'src/hooks/useGetContextVariables';
import VariableBox from 'src/pages/workflow-builder-edit/workflow-builder/revamped/VariableBox';
import { Action } from 'src/types/actionBuilderApiTypes';
import { getVariablesFromAction } from 'src/utils/actionBuilder/helpers';
import { Routes } from 'src/utils/enums';

export default function ActionList() {
  const { actions } = useActionBuilderActions();
  const navigate = useNavigate();

  const [searchText, setSearchText] = useState('');

  const filteredAndSortedActions = useMemo(() => {
    const lowerCaseSearchText = searchText.trim().toLowerCase();
    return actions
      .filter(action =>
        action.action_name.toLowerCase().includes(lowerCaseSearchText),
      )
      .toSorted((a, b) => a.action_name.localeCompare(b.action_name));
  }, [actions, searchText]);

  return (
    <Box display='flex' flexDirection='column' gap={2}>
      <Box mb='4px'>
        <Typography variant='font16Bold'>Actions</Typography>
      </Box>
      <Box>
        <Button
          fullWidth
          onClick={() => navigate(Routes.ACTION_BUILDER)}
          variant='secondary'
        >
          Create new action in Action Builder
        </Button>
      </Box>
      <SearchBar
        onChange={e => setSearchText(e.target.value)}
        placeholder='Search actions'
        value={searchText}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '8px',
        }}
      >
        {filteredAndSortedActions.map(action => (
          <ActionItem action={action} key={action.action_id} />
        ))}
      </Box>
    </Box>
  );
}

const ActionItem = ({ action }: { action: Action }) => {
  const { contextVariables } = useGetContextVariables();
  const {
    dynamicListOutputVariables = [],
    inputVariables,
    outputVariables,
  } = useMemo(() => {
    return getVariablesFromAction(action, contextVariables);
  }, [action, contextVariables]);

  return (
    <div aria-label={action.action_name}>
      <DraggableListItem
        componentMetadata={{ action }}
        componentType='action'
        description={
          <Typography
            color={theme => theme.palette.colors.grey[600]}
            variant='font12'
          >
            {action.action_description}
          </Typography>
        }
        title={
          <Typography
            component={Link}
            href={Routes.ACTION_BUILDER_EDIT_V2.replace(
              ':actionId?',
              action.action_id,
            )}
            rel='noreferrer'
            sx={{ textDecoration: 'underline' }}
            target='_blank'
            variant='font14Bold'
          >
            {action.action_name}
          </Typography>
        }
      >
        <VariableBox contextVariables={inputVariables} label='Input:' />
        <VariableBox contextVariables={outputVariables} label='Output:' />
        <VariableBox
          contextVariables={dynamicListOutputVariables}
          label='Dynamic list output:'
        />
      </DraggableListItem>
    </div>
  );
};

export const Link = styled('a')`
  color: ${props => props.theme.palette.colors.black};
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;
