import { sendAuthRequest } from '../api';
import { SOLVE_V2_BASE_URL } from '../constants';
import { dashboardApi } from '../dashboard-api';
import {
  ActionBuilderAction,
  ActionBuilderActionRequest,
  ActionBuilderCollectionTemplates,
  ActionUsagesResponse,
  ActiveIntegrationsInterface,
  AddOrUpdateActionInterface,
  AuthorizationConfig,
  ContextVariable,
  ContextVariableUsagesResponse,
  CreateContextVariableRequest,
  ProxyTestRequest,
  ValidateJmesPathRequest,
  ValidateJmesPathResponse,
} from 'src/types/actionBuilderApiTypes';
import { normalizeResponseErrors } from 'src/utils/normalizeResponse';

export const getActionBuilderActionsAPI: () => Promise<ActionBuilderAction> =
  async () => {
    return sendAuthRequest(
      `${API_URL}dashboard-controls/solve/v2/workflow-builder/actions`,
      null,
      'GET',
    )
      .then((res: Response | { error: string }) => normalizeResponseErrors(res))
      .then((res: Response) => res.json());
  };

export const getActionBuilderActiveIntegrationsAPI: () => Promise<ActiveIntegrationsInterface> =
  async () => {
    return sendAuthRequest(
      `${API_URL}dashboard-controls/solve/v2/action-builder/integrations`,
      null,
      'GET',
    )
      .then((res: Response | { error: string }) => normalizeResponseErrors(res))
      .then((res: Response) => res.json());
  };

export const postActionBuilderProxyTestAPI = (
  data: ProxyTestRequest,
): Promise<Response> => {
  return sendAuthRequest(
    `${API_URL}dashboard-controls/solve/v2/action-builder/proxy`,
    data,
    'POST',
  ).then((res: Response | { error: string }) => normalizeResponseErrors(res));
};

export const addActionAPI = (
  action: AddOrUpdateActionInterface,
): Promise<ActionBuilderAction> => {
  return sendAuthRequest(
    `${API_URL}dashboard-controls/solve/v2/workflow-builder/action`,
    action,
    'POST',
  )
    .then((res: Response | { error: string }) => normalizeResponseErrors(res))
    .then((res: Response) => res.json());
};

export const updateActionAPI = (
  action: AddOrUpdateActionInterface,
  actionId: string,
): Promise<ActionBuilderAction> => {
  return sendAuthRequest(
    `${API_URL}dashboard-controls/solve/v2/workflow-builder/action/${actionId}`,
    action,
    'PUT',
    { action_id: actionId },
  )
    .then((res: Response | { error: string }) => normalizeResponseErrors(res))
    .then((res: Response) => res.json());
};

export const validateJmesPath = async (
  data: ValidateJmesPathRequest,
): Promise<ValidateJmesPathResponse> => {
  const res = await sendAuthRequest(
    `${API_URL}dashboard-controls/solve/v2/action-builder/jmespath/validate`,
    data,
    'POST',
  );
  const normalizedResponse = await normalizeResponseErrors(res);
  return normalizedResponse.json();
};

const actionBuilderApi = dashboardApi.injectEndpoints({
  endpoints: builder => ({
    createAction: builder.mutation<
      ActionBuilderAction,
      AddOrUpdateActionInterface
    >({
      invalidatesTags: ['Actions', 'Tools'],
      query: body => ({
        body,
        method: 'POST',
        url: SOLVE_V2_BASE_URL + '/workflow-builder/action',
      }),
    }),
    createContextVariable: builder.mutation<
      ContextVariable,
      CreateContextVariableRequest
    >({
      invalidatesTags: ['ContextVariables'],
      query: body => ({
        body,
        method: 'POST',
        url: SOLVE_V2_BASE_URL + '/workflow-builder/settings/context-variable',
      }),
    }),
    deleteAction: builder.mutation<
      ActionBuilderAction,
      { action_ids: string[] }
    >({
      invalidatesTags: ['Actions', 'Tools', 'ContextVariables'],
      query: body => ({
        body,
        method: 'POST',
        url: SOLVE_V2_BASE_URL + '/workflow-builder/action/delete',
      }),
    }),
    getActionBuilderActions: builder.query<
      ActionBuilderAction,
      ActionBuilderActionRequest
    >({
      providesTags: ['Actions'],
      query: ({ product, shouldReturnAll }) => ({
        method: 'GET',
        params: {
          product,
          return_all: shouldReturnAll,
        },
        url: SOLVE_V2_BASE_URL + '/workflow-builder/actions',
      }),
    }),
    getActionBuilderIntegrations: builder.query<
      ActiveIntegrationsInterface,
      void
    >({
      query: () => ({
        method: 'GET',
        url: SOLVE_V2_BASE_URL + '/action-builder/integrations',
      }),
    }),
    getActionUsages: builder.query<ActionUsagesResponse, void>({
      providesTags: ['ActionUsages'],
      query: () => ({
        method: 'GET',
        url: SOLVE_V2_BASE_URL + '/workflow-builder/actions/usages',
      }),
    }),
    getAuthConfigs: builder.query<AuthorizationConfig[], void>({
      providesTags: ['AuthConfigs'],
      query: () => ({
        method: 'GET',
        url: SOLVE_V2_BASE_URL + '/workflow-builder/settings/auth-configs',
      }),
    }),
    getCollectionTemplates: builder.query<
      ActionBuilderCollectionTemplates,
      void
    >({
      query: () => ({
        method: 'GET',
        url: `${SOLVE_V2_BASE_URL}/action-builder/collections/templates`,
      }),
    }),
    getContextVariableUsages: builder.query<
      ContextVariableUsagesResponse,
      void
    >({
      providesTags: ['ContextVariableUsages'],
      query: () => ({
        method: 'GET',
        url: SOLVE_V2_BASE_URL + '/workflow-builder/context-variables/usages',
      }),
    }),
    updateAction: builder.mutation<
      ActionBuilderAction,
      { actionId: string; body: AddOrUpdateActionInterface }
    >({
      invalidatesTags: ['Actions', 'Tools'],
      query: ({ actionId, body }) => ({
        body,
        method: 'PUT',
        url: SOLVE_V2_BASE_URL + '/workflow-builder/action/' + actionId,
      }),
    }),
    updateAuthConfigs: builder.mutation<
      { success: boolean },
      AuthorizationConfig[]
    >({
      invalidatesTags: ['AuthConfigs'],
      query: body => ({
        body,
        method: 'PUT',
        url: SOLVE_V2_BASE_URL + '/workflow-builder/settings/auth-configs',
      }),
    }),
    updateContextVariable: builder.mutation<
      ContextVariable,
      { body: CreateContextVariableRequest; contextVariableId: string }
    >({
      invalidatesTags: ['ContextVariables'],
      query: ({ body, contextVariableId }) => ({
        body,
        method: 'PUT',
        url:
          SOLVE_V2_BASE_URL +
          `/workflow-builder/settings/context-variable/${contextVariableId}`,
      }),
    }),
    updateContextVariables: builder.mutation<
      { success: boolean },
      ContextVariable[]
    >({
      invalidatesTags: ['ContextVariables'],
      query: body => ({
        body,
        method: 'PUT',
        url: SOLVE_V2_BASE_URL + '/workflow-builder/context-variables',
      }),
    }),
  }),
});

export const {
  useCreateActionMutation,
  useCreateContextVariableMutation,
  useDeleteActionMutation,
  useGetActionBuilderActionsQuery,
  useGetActionBuilderIntegrationsQuery,
  useGetActionUsagesQuery,
  useGetAuthConfigsQuery,
  useGetCollectionTemplatesQuery,
  useGetContextVariableUsagesQuery,
  useUpdateActionMutation,
  useUpdateAuthConfigsMutation,
  useUpdateContextVariableMutation,
  useUpdateContextVariablesMutation,
} = actionBuilderApi;
