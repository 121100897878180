import { useMemo, useState } from 'react';
import { useDrag } from 'react-dnd';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import { IconPlus } from '@tabler/icons-react';

import {
  Button,
  FilterButton,
  SearchBar,
  Skeleton,
  Tooltip,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { CanvasModes } from '../../constants';
import { useGetBuilderQueryParams } from '../../hooks';
import {
  getStepTypeFromActionType,
  linkifyActionName,
} from '../revamped/helpers';
import VariableBox from '../revamped/VariableBox';
import BaseDraggableCard from './BaseDraggableCard';
import { BusinessLogicProps } from './types';
import filterIcon from 'src/assets/images/action-type-filter.svg';
import { conversationChannelToProductMap } from 'src/constants/solve';
import { useGetContextVariables } from 'src/hooks/useGetContextVariables';
import { transformActionsForCanvas } from 'src/reducers/actionBuilderReducer/actionBuilderReducer';
import {
  selectChatOrder,
  selectChatOrderLastStepId,
  selectIsBranching,
} from 'src/reducers/workflowBuilderReducer/workflowBuilderReducer';
import { useGetActionBuilderActionsQuery } from 'src/services/action-builder/actionBuilderApi';
import {
  dashboardApi,
  useGetFeatureFlagsQuery,
} from 'src/services/dashboard-api';
import {
  CanvasWorkflowBuilderState,
  selectActionFilter,
  setActionFilter,
  setMode,
} from 'src/slices/canvas-workflow-builder/workflowBuilderSlice';
import {
  addAction,
  startAddingActionThunk,
} from 'src/slices/canvas-workflow-builder/workflowBuilderSlice.thunks';
import { useAppDispatch } from 'src/store/hooks';
import {
  Action,
  ActionPanelMode,
  isApiActionType,
  isHandoffActionType,
  isOtherActionType,
} from 'src/types/actionBuilderApiTypes';
import {
  getVariablesFromAction,
  replaceActionIdInRoute,
} from 'src/utils/actionBuilder/helpers';
import {
  ActionBuilderActionTypes,
  RevampedDragNDrops,
  StepTypes,
} from 'src/utils/enums';
import {
  actionTooltips,
  actionTypesNotTreatedAsActions,
  formRequiredActions,
  isDropValid,
  nonSqueezingActions,
} from 'src/utils/solve/stepUtils';

const actionFilterOptions: {
  label: CanvasWorkflowBuilderState['actionFilter'];
  value: CanvasWorkflowBuilderState['actionFilter'];
}[] = [
  {
    label: 'All',
    value: 'All',
  },
  {
    label: 'API',
    value: 'API',
  },
  {
    label: 'Handoffs',
    value: 'Handoffs',
  },
  {
    label: 'Others',
    value: 'Others',
  },
];

const ActionTabContent = (props: BusinessLogicProps) => {
  const navigate = useNavigate();
  const { palette } = useTheme();
  const dispatch = useAppDispatch();

  const [searchText, setSearchText] = useState('');

  const actionFilter = useSelector(selectActionFilter);

  return (
    <Box>
      <Box borderBottom={'1px solid ' + palette.colors.slate[200]} pb={3}>
        <Button
          fullWidth
          onClick={() => {
            navigate(replaceActionIdInRoute(''));
          }}
          size='large'
          startIcon={<IconPlus />}
          variant='secondary'
        >
          <Typography variant='font14Bold'>Create new Action</Typography>
        </Button>
      </Box>
      <Box
        alignItems='center'
        display='flex'
        justifyContent='space-between'
        my={3}
      >
        <Typography variant='font16Bold'>Available Actions</Typography>
        <FilterButton
          aria-label='Action type'
          initialValue='All'
          onChange={newValue => {
            dispatch(
              setActionFilter(
                newValue as CanvasWorkflowBuilderState['actionFilter'],
              ),
            );
          }}
          options={actionFilterOptions}
          startAdornment={<img alt='' src={filterIcon} />}
          value={actionFilter}
        />
      </Box>
      <SearchBar
        onChange={e => setSearchText(e.target.value)}
        placeholder='Search'
        value={searchText}
      />
      <ActionsList searchText={searchText} {...props} />
    </Box>
  );
};

interface ActionsListProps extends BusinessLogicProps {
  isDisabled: boolean;
  searchText: string;
}

const ActionsList = ({ searchText, ...props }: ActionsListProps) => {
  const { view } = useGetBuilderQueryParams();
  const { data, isLoading } = useGetActionBuilderActionsQuery({
    product: conversationChannelToProductMap[view],
  });
  const { actions = [] } = data ?? {};
  const { data: ffData } = useGetFeatureFlagsQuery();
  const actionFilter = useSelector(selectActionFilter);

  const filteredActions = useMemo(() => {
    const transformedActions = transformActionsForCanvas(
      actions.filter(
        ({ action_type: actionType }) =>
          !actionTypesNotTreatedAsActions.includes(actionType),
      ),
      ffData?.feature_flags,
    );

    const filteredList = transformedActions
      .filter(({ action_type: actionType }) => actionType !== 'dynamic_card')
      .filter(
        ({ action_type: actionType }) =>
          actionFilter === 'All' ||
          (actionFilter === 'API' && isApiActionType(actionType)) ||
          (actionFilter === 'Handoffs' && isHandoffActionType(actionType)) ||
          (actionFilter === 'Others' && isOtherActionType(actionType)),
      )
      .filter(({ action_name: actionName }) => {
        const lowerCaseSearchText = searchText.toLowerCase();
        return actionName.toLowerCase().includes(lowerCaseSearchText);
      });

    return filteredList;
  }, [actionFilter, actions, ffData?.feature_flags, searchText]);

  return (
    <Box display='flex' flexDirection='column' gap={1} mt={2}>
      {isLoading
        ? Array(10)
            .fill(null)
            .map((_, index) => <LoadingItem key={index} />)
        : filteredActions.map(action => (
            <ActionItem action={action} key={action.action_id} {...props} />
          ))}
    </Box>
  );
};

interface ActionItemProps extends BusinessLogicProps {
  action: Action;
}

const ActionItem = ({
  action,
  cancelSqueeze,
  isDisabled,
  setActionPanelVisibilityParameters,
  squeezeStepParentId,
}: ActionItemProps) => {
  const dispatch = useAppDispatch();

  const chatOrder = useSelector(selectChatOrder);
  const lastStepId = useSelector(selectChatOrderLastStepId);
  const isBranching = useSelector(selectIsBranching);
  const isNonSqueezingAction = nonSqueezingActions.includes(action.action_type);

  const { contextVariables } = useGetContextVariables();

  const [{ opacity }, drag] = useDrag(
    () => ({
      canDrag: !isDisabled,
      collect: monitor => ({
        opacity: monitor.isDragging() ? 0.3 : 1,
      }),
      end: (_, monitor) => {
        const shouldReturnEarly = !isDropValid(monitor.getDropResult());

        if (shouldReturnEarly) {
          return;
        }

        if (monitor.didDrop()) {
          const payload = {
            action,
            data: {
              action_id: action.action_id,
            },
          };

          if (formRequiredActions.includes(action.action_type)) {
            dispatch(startAddingActionThunk(payload));
            dispatch(
              setMode({ contextVariables, mode: CanvasModes.ACTION_EDIT }),
            );
            setActionPanelVisibilityParameters(
              action.action_type as ActionPanelMode,
            );
          } else {
            dispatch(
              addAction({
                data: {
                  action_id: action.action_id,
                },
              }),
            ).then(() => {
              dispatch(dashboardApi.util.invalidateTags(['ActionUsages']));
            });
            dispatch(setMode({ contextVariables, mode: CanvasModes.MESSAGE }));
            cancelSqueeze();
          }
        }
      },
      type: isNonSqueezingAction
        ? RevampedDragNDrops.REVAMPED_FT_NON_SQUEEZE_ACTION
        : RevampedDragNDrops.REVAMPED_FT_ACTION,
    }),
    [
      squeezeStepParentId,
      chatOrder.length,
      lastStepId,
      isNonSqueezingAction,
      isDisabled,
    ],
  );

  const {
    dynamicListOutputVariables = [],
    inputVariables,
    outputVariables,
  } = useMemo(() => {
    return getVariablesFromAction(action, contextVariables);
  }, [action, contextVariables]);

  const TooltipContainer = ({
    children,
    hasTooltip,
  }: {
    children: JSX.Element;
    hasTooltip: boolean;
  }) => {
    if (hasTooltip) {
      return (
        <Tooltip tooltipContent='Select a condition or button option to continue'>
          {children}
        </Tooltip>
      );
    }
    return children;
  };

  const getBadgeText = (actionType: Action['action_type']) => {
    if (isHandoffActionType(actionType)) {
      return 'Handoff';
    }

    if (isApiActionType(actionType)) {
      return 'API';
    }

    return 'Other';
  };

  return (
    <TooltipContainer hasTooltip={isNonSqueezingAction && isBranching}>
      <BaseDraggableCard
        badgeText={getBadgeText(action.action_type)}
        description={action.action_description}
        drag={isNonSqueezingAction && isBranching ? undefined : drag}
        iconUrl={
          // TODO: Replace this with action.icon_url, Will need more BE work to make icon url correct for all actions
          action.action_type === ActionBuilderActionTypes.SUNCO_LIVE_CHAT
            ? action.icon_url
            : undefined
        }
        label={linkifyActionName(<>{action.action_name}</>, action)}
        opacity={opacity}
        stepType={getStepTypeFromActionType(action.action_type)}
        tooltipContent={actionTooltips[action.action_type]}
      >
        <VariableBox contextVariables={inputVariables} label='Input:' />
        <VariableBox contextVariables={outputVariables} label='Output:' />
        <VariableBox
          contextVariables={dynamicListOutputVariables}
          label='Dynamic list output:'
        />
      </BaseDraggableCard>
    </TooltipContainer>
  );
};

const LoadingItem = () => {
  return (
    <BaseDraggableCard stepType={StepTypes.FLAMETHROWER_API_CALL}>
      <Skeleton />
    </BaseDraggableCard>
  );
};

export default ActionTabContent;
