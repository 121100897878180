import { useState } from 'react';

import LabelsTable from './LabelsTable';
import { VersionedTriageModel } from 'src/reducers/triageSettingsReducer/types';

export interface LabelsTabProps {
  isModelLoading: boolean;
  selectedVersion: VersionedTriageModel;
}

const LabelsTab = ({ isModelLoading, selectedVersion }: LabelsTabProps) => {
  const [selectedTagId, selectTagId] = useState<string | null>(null);

  return (
    <LabelsTable
      labelControlOptions={{
        selectTagId,
        tagId: selectedTagId,
      }}
      loading={isModelLoading}
      model={selectedVersion}
    />
  );
};

export default LabelsTab;
