import { useMemo } from 'react';

import {
  NONE_RTE_INPUT_DEBOUNCE_DELAY,
  RTE_INPUT_DEBOUNCE_DELAY,
} from '../constants';
import debounce from 'lodash/fp/debounce';
import { useSolveConfigTrackingEventAction } from 'src/hooks/hooks';
import { updateWidgetConfiguration } from 'src/slices/solve-config/solveConfigSlice';
import { WidgetConfiguration } from 'src/slices/solve-config/types';
import { useAppDispatch } from 'src/store/hooks';
import {
  SolveConfigTrackingEventTypes,
  SolveConfigurationTypes,
} from 'src/utils/enums';

const useDebouncedSubmitConfig = (delay: number) => {
  const dispatch = useAppDispatch();
  const dispatchTrackingAction = useSolveConfigTrackingEventAction();

  const debouncedSubmitConfig = useMemo(
    () =>
      debounce(
        delay,
        async (
          config: WidgetConfiguration,
          type: string,
          value: string,
          callback?: () => void,
        ) => {
          await dispatch(updateWidgetConfiguration(config));
          dispatchTrackingAction(
            SolveConfigTrackingEventTypes.CONFIGURATION_UPDATE,
            {
              configuration_type: SolveConfigurationTypes.WIDGET,
              type: type,
              value: value,
            },
          );
          callback?.();
        },
      ),
    [dispatch, dispatchTrackingAction, delay],
  );

  return debouncedSubmitConfig;
};

export const useDebouncedSubmitConfigForRTE = () => {
  return useDebouncedSubmitConfig(RTE_INPUT_DEBOUNCE_DELAY);
};

export const useDebouncedSubmitConfigForNonRTE = () => {
  return useDebouncedSubmitConfig(NONE_RTE_INPUT_DEBOUNCE_DELAY);
};
