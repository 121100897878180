import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import { IconEyeOff } from '@tabler/icons-react';

import {
  Tooltip,
  Typography,
} from '@forethought-technologies/forethought-elements';
import TopicBadges from './TopicBadges';
import TopicCount from './TopicCount';
import { DiscoverTopic } from 'src/reducers/discoverReducer/types';
import { deriveTopicName } from 'src/utils/discover/helpers';

type OnNavigateToTopicProps = {
  displayName: string;
  id: string;
  name: string;
};

export type TopicNameProps = {
  isChild?: boolean;
  numberOfSubTopics?: number;
  onClickArticleButton: (topic: DiscoverTopic) => void;
  onNavigateToTopic: (props: OnNavigateToTopicProps) => void;
  parentTopicName?: string;
  searchText: string;
  topic: DiscoverTopic | null;
};

const HighlightedTopicName = ({
  color,
  searchText,
  topic,
}: Partial<TopicNameProps> & { color: string }) => {
  const topicName = topic?.topic_name ?? '';
  const displayTopicName = deriveTopicName(
    topic?.topic_display_name,
    topicName,
  );

  if (!searchText?.trim()) {
    return <>{displayTopicName}</>;
  }

  if (!displayTopicName) {
    return null;
  }

  const text = searchText.trim().toLowerCase();
  const start = displayTopicName.toLowerCase().indexOf(text);
  const stop = start + text.length;

  if (start === -1) {
    return <>{displayTopicName}</>;
  }

  return (
    <>
      {displayTopicName.split('').map((char, idx) => (
        <span
          key={idx}
          style={{
            backgroundColor: idx >= start && idx < stop ? color : 'transparent',
          }}
        >
          {char}
        </span>
      ))}
    </>
  );
};

const TopicName = (props: TopicNameProps) => {
  const { palette } = useTheme();

  if (!props.topic) {
    return null;
  }
  const { topic } = props;
  const handleNavigateToTopic = () =>
    props.onNavigateToTopic({
      displayName: topic.topic_display_name as string,
      id: topic.topic_id,
      name: topic.topic_name,
    });
  const variant =
    props.isChild || props.parentTopicName ? 'font14' : 'font16Bold';
  const fullTopicName = props.parentTopicName
    ? `${props.topic.topic_name} (${props.parentTopicName})`
    : props.topic.topic_name;

  return (
    <Box
      alignItems='center'
      display='grid'
      gridTemplateColumns='auto auto auto'
      onClick={handleNavigateToTopic}
      sx={{ columnGap: 1, cursor: 'pointer' }}
    >
      {topic.is_hidden && (
        <Box alignItems='center' display='flex' mr={1.5}>
          <IconEyeOff color={palette.colors.grey[600]} size={15} />
        </Box>
      )}
      <Box alignItems='center' columnGap={0.5} display='flex' overflow='hidden'>
        <Tooltip
          tooltipContent={
            <Typography variant='font12'>{fullTopicName}</Typography>
          }
        >
          <Typography noWrap variant={variant}>
            <HighlightedTopicName
              color={palette.colors.blue[200]}
              searchText={props.searchText}
              topic={topic}
            />
            {Boolean(props.parentTopicName) && (
              <Typography
                color={palette.colors.grey[600]}
                noWrap
                variant='font14'
              >
                &nbsp;({props.parentTopicName})
              </Typography>
            )}
          </Typography>
        </Tooltip>
        <TopicCount count={props.numberOfSubTopics} variant='font16Bold' />
      </Box>
      <TopicBadges
        onClickArticleButton={props.onClickArticleButton}
        topic={topic}
      />
    </Box>
  );
};

export default TopicName;
