import { useSolveMetricsQueryParams } from './useSolveMetricsQueryParams';
import { useGetWidgetAggregateMetricsQuery } from 'src/services/workflow-builder-metrics';

export function useWidgetAggregateData() {
  const { channel, from, to } = useSolveMetricsQueryParams();

  const { data: mongoData, isFetching: mongoIsFetching } =
    useGetWidgetAggregateMetricsQuery({ channel, from, to });

  if (mongoIsFetching) {
    return {
      data: null,
      isLoading: true,
    };
  }

  return {
    data: mongoData,
    isLoading: false,
  };
}
