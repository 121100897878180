import React, { ButtonHTMLAttributes, FC, ReactNode } from 'react';
import { styled } from '@mui/material';

import { Colors, theme } from '@forethought-technologies/forethought-elements';
import { textMediumStyle } from 'src/styles/styledMixin';

export interface StyledButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {
  /** Children to render inside button */
  children: ReactNode;
  /** Other props that are inherited are: onClick, name, type, disabled, etc. More info: https://developer.mozilla.org/en-US/docs/Web/API/HTMLButtonElement */
}

export const StyledButton: FC<React.PropsWithChildren<StyledButtonProps>> = ({
  children,
  ...props
}) => {
  return <Button {...props}>{children}</Button>;
};

const Button = styled('button')`
  ${textMediumStyle({ fontSize: '15px' })}
  color: ${theme.palette.colors.white};
  background-color: ${theme.palette.colors.purple[500]};
  border: 0;
  text-align: center;
  border-radius: var(--border-radius-button);
  height: 32px;
  margin-right: 15px;
  padding: 6px 11px;
  cursor: pointer;
  align-items: center;

  :disabled {
    opacity: 0.5;
    cursor: default;
  }

  &:hover {
    background-color: ${theme.palette.colors.purple[600]};
  }
`;

export const SecondaryButton = styled(StyledButton)`
  background-color: transparent;
  color: ${Colors.ui.text.primary};
  margin-right: 5px;
  cursor: pointer;
  border-radius: 4px;

  &:hover {
    background-color: ${theme.palette.colors.grey[100]};
  }
`;
