import { Box, styled } from '@mui/material';

export const EllipsizedTableCell = styled(Box)`
  line-height: 1.6;
  /* ellipsis when overflowing: */
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;
