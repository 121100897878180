import { DiscoverAutomationResponse } from 'src/reducers/discoverReducer/types';

export const TABLE_COLUMNS: {
  key: keyof DiscoverAutomationResponse | 'value.volume';
  size: number;
  target?: string;
  title: string;
}[] = [
  {
    key: 'automated_object',
    size: 300,
    target: 'name',
    title: 'Workflow',
  },
  {
    key: 'topics',
    size: 300,
    title: 'Topics',
  },
  {
    key: 'value.volume',
    size: 260,
    target: 'volume',
    title: 'Ticket reduction/last 30 days',
  },
  {
    key: 'value',
    size: 250,
    target: 'cost',
    title: 'Cost reduction/last 30 days',
  },
  {
    key: 'automated_object',
    size: 180,
    target: 'status',
    title: 'Status',
  },
];
