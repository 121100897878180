import { useMemo } from 'react';
import { useLocation } from 'react-router';

import {
  ConversationChannel,
  WorkflowBuilderHeaderChannel,
} from 'src/types/workflowBuilderAPITypes';
import {
  constructTimeRangeQueryParamsRevamped,
  last30DaysTimeRange,
} from 'src/utils/timeRangeHelpers';

export function useSolveMetricsQueryParams() {
  const { search } = useLocation();

  return useMemo(() => {
    const defaultTimeRangeParams = constructTimeRangeQueryParamsRevamped(
      last30DaysTimeRange,
      '',
    );

    const qParams = new URLSearchParams(search);
    const to = qParams.get('end') || defaultTimeRangeParams.get('end');
    const from = qParams.get('start') || defaultTimeRangeParams.get('start');
    const headerChannel = (qParams.get('header') ||
      'workflows') as WorkflowBuilderHeaderChannel;
    const channel = (qParams.get('channel') || 'widget') as ConversationChannel;

    return { channel, from, headerChannel, to };
  }, [search]);
}
