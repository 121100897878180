import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';

import { Typography } from '@forethought-technologies/forethought-elements';
import BranchStepValidIndicator from '../../BranchStepValidIndicator';
import {
  type CompositeCondition,
  CompositeConditionsConfig,
  Condition,
} from '../../types/canvasComponentTypes';
import { extractCvIdFromDynamicCv } from './helpers';
import StepContainer from './StepContainer';
import StepHeader from './StepHeader';
import TextBox from './TextBox';
import {
  selectEditingConditionStepId,
  selectSelectedConditions,
  selectUndefinedContextVariablesInStep,
} from 'src/reducers/workflowBuilderReducer/workflowBuilderReducer';
import {
  selectEditingStepFields,
  setSelectedCondition,
} from 'src/slices/canvas-workflow-builder/workflowBuilderSlice';
import { useAppDispatch } from 'src/store/hooks';
import { StepTypes } from 'src/utils/enums';
import { isConditionStepFields } from 'src/utils/solve/stepUtils';

const compositeConditionToString = (compositeCondition: CompositeCondition) => {
  const { booleanOperator, conditions } = compositeCondition;

  const conditionToString = (condition: Condition) => {
    const { contextVariable, operator, value } = condition;

    return `${
      contextVariable ? `{{${contextVariable}}}` : ''
    } ${operator} ${value}`;
  };

  return conditions.map(conditionToString).join(` ${booleanOperator} `);
};

interface ConditionStepProps {
  fields?: CompositeConditionsConfig;
  stepId?: string;
}

const ConditionStep = ({ fields, stepId }: ConditionStepProps) => {
  const dispatch = useAppDispatch();
  const { palette } = useTheme();

  const editingFields = useSelector(selectEditingStepFields);
  const editingStepId = useSelector(selectEditingConditionStepId);
  const undefinedContextVariablesInStep = useSelector(
    selectUndefinedContextVariablesInStep,
  );
  const selectedConditionIndex = useSelector(selectSelectedConditions)[
    stepId ?? ''
  ];

  const isBeingEdited = editingStepId === stepId || !stepId;

  const toShow = isBeingEdited ? editingFields : fields;

  if (!toShow || !isConditionStepFields(toShow)) {
    return null;
  }

  const undefinedCvs = undefinedContextVariablesInStep[stepId ?? ''] ?? [];

  const { compositeConditions, conditionName, otherwiseCondition } = toShow;

  const onClick = (index: number) => {
    if (!stepId) {
      return;
    }

    dispatch(
      setSelectedCondition({
        conditionIndex: index,
        stepId,
      }),
    );
  };

  return (
    <StepContainer isBeingEdited={isBeingEdited}>
      <StepHeader stepType={StepTypes.CONDITION} />
      <Box mb={1}>
        <Typography
          color={conditionName ? undefined : palette.text.secondary}
          variant='font14Bold'
        >
          {conditionName || 'Give it a name'}
        </Typography>
      </Box>
      <Box display='flex' flexDirection='column' gap={1}>
        {compositeConditions.map((compositeCondition, index) => (
          <CompositeCondition
            compositeCondition={compositeCondition}
            index={index}
            key={index}
            onClick={onClick}
            selectedConditionIndex={selectedConditionIndex}
            undefinedContextVariableIds={undefinedCvs}
          />
        ))}
        {otherwiseCondition.isOtherwiseSelected && (
          <TextBox
            isRichText
            isSelected={selectedConditionIndex === compositeConditions.length}
            onClick={() => onClick(compositeConditions.length)}
            placeholder='Empty message'
            validIndicator={
              <BranchStepValidIndicator
                transitionBranchStepId={otherwiseCondition.stepId}
                undefinedTransitionCvs={undefinedCvs.map(cv =>
                  extractCvIdFromDynamicCv(cv),
                )}
              />
            }
            value='Otherwise...'
          />
        )}
      </Box>
    </StepContainer>
  );
};

interface CompositeConditionProps {
  compositeCondition: CompositeCondition;
  index: number;
  onClick: (index: number) => void;
  selectedConditionIndex: number;
  undefinedContextVariableIds: string[];
}

const CompositeCondition = ({
  compositeCondition,
  index,
  onClick,
  selectedConditionIndex,
  undefinedContextVariableIds,
}: CompositeConditionProps) => {
  const usedCvs = compositeCondition.conditions.map(
    condition => condition.contextVariable,
  );
  const undefinedCvsInConditionsToDisplay = usedCvs.filter(cv =>
    undefinedContextVariableIds.includes(extractCvIdFromDynamicCv(cv)),
  );

  return (
    <>
      <TextBox
        index={index}
        isRichText
        isSelected={selectedConditionIndex === index}
        onClick={() => onClick(index)}
        placeholder='Empty message'
        undefinedContextVariableIds={undefinedCvsInConditionsToDisplay}
        validIndicator={
          <BranchStepValidIndicator
            transitionBranchStepId={compositeCondition.stepId}
            undefinedTransitionCvs={undefinedCvsInConditionsToDisplay.map(cv =>
              extractCvIdFromDynamicCv(cv),
            )}
          />
        }
        value={compositeConditionToString(compositeCondition)}
      />
    </>
  );
};
export default ConditionStep;
