import { BadgedRoutes, NavDataItemWithChildren } from './types';
import iconAssist from 'src/assets/images/icon-assist.svg';
import iconCustomAnalytics from 'src/assets/images/icon-custom-analytics.svg';
import iconDiscover from 'src/assets/images/icon-discover.svg';
import iconSettings from 'src/assets/images/icon-settings.svg';
import iconSolve from 'src/assets/images/icon-solve.svg';
import iconTriage from 'src/assets/images/icon-triage.svg';
import {
  ROUTE_ACCOUNT_SETTINGS,
  ROUTE_ACTION_BUILDER,
  ROUTE_ANSWERS,
  ROUTE_ASSIST_AUTOMATIONS,
  ROUTE_ASSIST_NOTES,
  ROUTE_CUSTOM_ANALYTICS,
  ROUTE_DISCOVER_AUTOMATION,
  ROUTE_DISCOVER_DASHBOARD,
  ROUTE_DISCOVER_TOPICS,
  ROUTE_INTEGRATIONS,
  ROUTE_LOG,
  ROUTE_PLAN_PAGE,
  ROUTE_PREDICT_INTENTS,
  ROUTE_PREDICTION_SETTINGS,
  ROUTE_PREDICTIONS,
  ROUTE_SEARCH,
  ROUTE_SECURITY,
  ROUTE_SOLVE,
  ROUTE_SOLVE_ANALYTICS_V2,
  ROUTE_SOLVE_CONFIGURATION,
  ROUTE_SOLVE_INSIGHTS_MAIN,
  ROUTE_SOLVE_LITE,
  ROUTE_SSO_SETTINGS,
  ROUTE_TEAM,
  ROUTE_TRIAGE_SIMULATIONS,
  ROUTE_WORKFLOW_BUILDER,
} from 'src/components/app/routes/routeDefinitions';
import { Tabs } from 'src/utils/enums';

// True means the route will be shown to the user as new
export const initialBadgedRoutes: BadgedRoutes = {};

const navData: NavDataItemWithChildren[] = [
  {
    children: [
      {
        name: Tabs.DISCOVER_DASHBOARD,
        route: ROUTE_DISCOVER_DASHBOARD,
      },

      {
        name: Tabs.DISCOVER_AUTOMATION,
        route: ROUTE_DISCOVER_AUTOMATION,
      },
      { name: Tabs.DISCOVER_TOPICS, route: ROUTE_DISCOVER_TOPICS },
    ],
    icon: iconDiscover,
    name: Tabs.DISCOVER,
  },
  {
    children: [
      {
        name: Tabs.SOLVE_INSIGHT,
        route: ROUTE_SOLVE_INSIGHTS_MAIN,
      },
      {
        name: Tabs.SOLVE_DASHBOARD,
        route: ROUTE_SOLVE,
      },
      {
        name: Tabs.WORKFLOW_BUILDER,
        route: ROUTE_WORKFLOW_BUILDER,
      },
      {
        badgeLabel: 'Internal',
        name: Tabs.PREDICT_INTENTS,
        route: ROUTE_PREDICT_INTENTS,
      },
      {
        name: Tabs.ACTION_BUILDER,
        route: ROUTE_ACTION_BUILDER,
      },
      {
        name: Tabs.SOLVE_ANALYTICS_V2,
        route: ROUTE_SOLVE_ANALYTICS_V2,
      },
      {
        name: Tabs.SOLVE_CONFIGURATION,
        route: ROUTE_SOLVE_CONFIGURATION,
      },
    ],
    icon: iconSolve,
    name: Tabs.SOLVE,
  },

  {
    icon: iconSolve,
    name: Tabs.SOLVE_LITE,
    route: ROUTE_SOLVE_LITE,
  },
  {
    children: [
      {
        name: Tabs.PREDICTIONS_DASHBOARD,
        route: ROUTE_PREDICTIONS,
      },
      {
        name: Tabs.PREDICTIONS_SETTINGS,
        route: ROUTE_PREDICTION_SETTINGS,
      },
      {
        name: Tabs.SIMULATION_RUNS,
        route: ROUTE_TRIAGE_SIMULATIONS,
      },
    ],
    icon: iconTriage,
    name: Tabs.PREDICTIONS,
  },
  {
    children: [
      {
        name: Tabs.ASSIST_ANALYTICS,
        route: ROUTE_ANSWERS,
      },
      {
        name: Tabs.SEARCH,
        route: ROUTE_SEARCH,
      },
      {
        name: Tabs.ASSIST_AUTOMATIONS,
        route: ROUTE_ASSIST_AUTOMATIONS,
      },
      {
        name: Tabs.ASSIST_NOTES,
        route: ROUTE_ASSIST_NOTES,
      },
    ],
    icon: iconAssist,
    name: Tabs.ANSWERS,
  },
  {
    icon: iconCustomAnalytics,
    name: Tabs.CUSTOM_ANALYTICS,
    route: ROUTE_CUSTOM_ANALYTICS,
  },
  {
    children: [
      { name: Tabs.PLAN, route: ROUTE_PLAN_PAGE },
      {
        name: Tabs.SECURITY,
        route: ROUTE_SECURITY,
      },
      {
        name: Tabs.ACCOUNT_SETTINGS,
        route: ROUTE_ACCOUNT_SETTINGS,
      },
      {
        name: Tabs.INTEGRATIONS,
        route: ROUTE_INTEGRATIONS,
      },
      {
        name: Tabs.TEAM,
        route: ROUTE_TEAM,
      },

      {
        name: Tabs.LOG,
        route: ROUTE_LOG,
      },

      {
        name: Tabs.SSO_SETTINGS,
        route: ROUTE_SSO_SETTINGS,
      },
    ],
    icon: iconSettings,
    name: Tabs.SETTINGS,
  },
];

export default navData;
