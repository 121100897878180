import { Box } from '@mui/material';

import {
  Button,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { getInstallUrl } from 'src/services/solve-config/solveSlackConfigApi';
import { useGetSlackInstallationQuery } from 'src/services/workflow-builder-canvas/workflowBuilderCanvasApi';

export const SlackIntegrationTabContent = () => {
  const { data } = useGetSlackInstallationQuery();
  const onIntegrateClick = async () => {
    const url = await getInstallUrl();

    window.open(url, '_blank', 'noopener,noreferrer');
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        whiteSpace: 'pre-wrap',
      }}
    >
      <Typography variant='font16Bold'>Integrate Slack</Typography>
      {data?.has_active_installation ? (
        <Typography variant='font14'>
          Forethought is already integrated in your workspace. {'\n'}Need
          additional assistance? Reach out and a team member will be happy to
          assist you
        </Typography>
      ) : (
        <>
          <Typography variant='font14'>
            Click the button below to integrate Forethought in your Slack
            workspace
          </Typography>
          <Button fullWidth onClick={onIntegrateClick} variant='main'>
            Integrate
          </Button>
        </>
      )}
    </Box>
  );
};
