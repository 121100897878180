import { useMemo } from 'react';
import { Box, styled } from '@mui/system';
import { useTheme } from '@mui/system';

import conditionIconSrc from 'src/assets/images/condition.svg';
import emailIconSrc from 'src/assets/images/white-email.svg';
import questionBubbleIconSrc from 'src/assets/images/white-question-bubble.svg';
import regularActionIcon from 'src/pages/workflow-builder-edit/assets/icons/filled-action.svg';

export default function StepIcon({ nodeType }: { nodeType: string }) {
  const { palette } = useTheme();

  const iconConfig = useMemo(() => {
    // defining this within the component to access colors with useTheme
    const config = {
      action: {
        backgroundColor: palette.colors.blue[500],
        iconSrc: regularActionIcon,
        title: 'Action',
      },
      intent: {
        backgroundColor: palette.colors.grey[700],
        iconSrc: questionBubbleIconSrc,
        title: 'Customer intent',
      },
      response: {
        backgroundColor: palette.colors.blue[500],
        iconSrc: emailIconSrc,
        title: 'Send email',
      },
      rule: {
        backgroundColor: palette.colors.yellow[400],
        iconSrc: conditionIconSrc,
        title: 'Condition',
      },
    }[nodeType];

    return config;
  }, [palette, nodeType]);

  if (!iconConfig) {
    return null;
  }

  return (
    <Box
      sx={{
        alignItems: 'center',
        backgroundColor: iconConfig.backgroundColor,
        borderRadius: '50%',
        display: 'flex',
        height: '32px',
        justifyContent: 'center',
        width: '32px',
      }}
    >
      <Img alt='' src={iconConfig.iconSrc} />
    </Box>
  );
}

const Img = styled('img')`
  height: 20px;
  width: 20px;
`;
