import { Box, useTheme } from '@mui/material';

import { Typography } from '@forethought-technologies/forethought-elements';
import { TriagePredictionLabel } from 'src/services/triage/types';

const TestTicketStatusTableValue = ({
  status,
}: {
  status: TriagePredictionLabel['status'];
}) => {
  const { palette } = useTheme();

  const valueMap = {
    failure: {
      background: palette.colors.red[100],
      color: palette.colors.red[500],
      text: 'Failure',
    },
    pending: {
      background: palette.colors.slate[100],
      color: palette.colors.grey[700],
      text: 'Pending',
    },
    success: {
      background: palette.colors.green[100],
      color: palette.colors.green[600],
      text: 'Success',
    },
  };

  const item = valueMap[status];

  return (
    <Box bgcolor={item.background} borderRadius={1.5} px={1} py={0.5}>
      <Typography color={item.color} variant='font11'>
        {item.text}
      </Typography>
    </Box>
  );
};

export default TestTicketStatusTableValue;
