import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';

import { DEFAULT_LABEL_TOOLTIP } from '../constants';
import { IntegrationChangeParams } from '../types';
import EditableSelectField from './EditableSelectField';
import { CurrentOrgHelpdeskDetail } from 'src/reducers/triageSettingsReducer/types';
import {
  useGetModelByIdQuery,
  useGetModelVersionQuery,
} from 'src/services/triage/triageApi';

interface DefaultLabelFieldProps {
  helpdesk: CurrentOrgHelpdeskDetail | null;
  isDisabled: boolean;
  isLoading?: boolean;
  onChange: (params: IntegrationChangeParams) => void;
  placeholder?: string;
}

const DefaultLabelField: React.FC<DefaultLabelFieldProps> = ({
  helpdesk,
  isDisabled,
  isLoading,
  onChange,
  placeholder,
}) => {
  const { modelId } = useParams<{ modelId: string }>();
  const { data: currentTriageModel, isLoading: isModelLoading } =
    useGetModelByIdQuery({ modelId: modelId || '' });
  const [searchParams] = useSearchParams();
  const version = searchParams.get('version');
  const { data: selectedVersion } = useGetModelVersionQuery(
    { modelId: modelId || '', versionId: version || '' },
    { skip: !version },
  );

  const triageModel = selectedVersion || currentTriageModel;
  const [selectedDefaultLabel, setSelectedDefaultLabel] = useState('');
  const labelOptions = useMemo(() => {
    return (
      triageModel?.labels.map(label => ({
        label: label.title,
        value: label.tag_id,
      })) || []
    );
  }, [triageModel]);

  useEffect(() => {
    const existingDefaultLabel =
      triageModel?.model.model_output_formatter.not_predicted_value;
    const relatedValue = labelOptions.find(
      option => option.label === existingDefaultLabel,
    )?.value;
    if (relatedValue) {
      setSelectedDefaultLabel(relatedValue);
    } else {
      setSelectedDefaultLabel('');
    }
  }, [triageModel, labelOptions]);

  const handleDefaultChange = ({ selectValue }: { selectValue: string }) => {
    if (!helpdesk) {
      return;
    }
    const value = selectValue;
    setSelectedDefaultLabel(value);
    const matchingLabel = labelOptions?.find(
      option => option.value === selectValue,
    )?.label;
    // matching label is undefined if the select value isn't
    // in the label options, so in that case we don't want
    // to save it. if selectValue is empty, we want to clear
    // the default label
    if (matchingLabel !== undefined || selectValue === '') {
      onChange({
        defaultLabel: matchingLabel || selectValue,
        integrationType: helpdesk.name,
      });
    }
  };

  const disableDefault =
    isDisabled || isLoading || isModelLoading || labelOptions.length === 0;

  return (
    <EditableSelectField
      allowNew={false}
      disabled={disableDefault}
      isClearable={true}
      label='Default label (optional)'
      onChange={handleDefaultChange}
      onToggleCallback={() => undefined}
      options={labelOptions}
      placeholder={placeholder}
      tooltip={DEFAULT_LABEL_TOOLTIP}
      value={selectedDefaultLabel}
    />
  );
};

export default DefaultLabelField;
