import { KnowledgeGapArticleRecommendation } from 'src/reducers/discoverReducer/types';

export const TABLE_COLUMNS: {
  key: keyof Pick<
    KnowledgeGapArticleRecommendation,
    | 'generated_title'
    | 'generated_body'
    | 'topic_name'
    | 'relevant_articles'
    | 'num_of_tickets_in_article'
  >;
  size: number;
  title: string;
}[] = [
  {
    key: 'generated_title',
    size: 300,
    title: 'Title',
  },
  {
    key: 'generated_body',
    size: 300,
    title: 'Body',
  },
  {
    key: 'topic_name',
    size: 200,
    title: 'Related topics',
  },
  {
    key: 'relevant_articles',
    size: 300,
    title: 'Related articles',
  },
  {
    key: 'num_of_tickets_in_article',
    size: 220,
    title: 'Ticket coverage/year',
  },
];
