import React, { FC } from 'react';
import { Field, FieldProps } from 'formik';
import { Box } from '@mui/material';

import get from 'lodash/fp/get';
import getOr from 'lodash/fp/getOr';
import keyBy from 'lodash/fp/keyBy';
import ContextVariableSelectDropdown from 'src/components/context-variable-select-dropdown';
import AddFieldLink from 'src/pages/workflow-builder-edit/handoff-configuration/salesforce/ticket-creation/custom-fields/AddFieldLink';
import CVTextField from 'src/pages/workflow-builder-edit/handoff-configuration/salesforce/ticket-creation/custom-fields/CVTextField';
import DropdownFields from 'src/pages/workflow-builder-edit/handoff-configuration/salesforce/ticket-creation/custom-fields/DropdownFields';
import RemoveFieldButton from 'src/pages/workflow-builder-edit/handoff-configuration/salesforce/ticket-creation/custom-fields/RemoveFieldButton';
import {
  Label,
  Row,
  Spacer,
} from 'src/pages/workflow-builder-edit/handoff-configuration/styles';
import { ContextVariable } from 'src/types/actionBuilderApiTypes';
import { SalesforceCommonCustomization } from 'src/types/workflowBuilderAPITypes';

type fieldType = 'string' | 'picklist';

interface TicketCustomFieldsProps {
  contextVariables: ContextVariable[];
  customizationData: SalesforceCommonCustomization;
}

const TicketCustomFields: FC<
  React.PropsWithChildren<TicketCustomFieldsProps>
> = ({ contextVariables, customizationData }) => {
  const fieldArr =
    customizationData.ticket_creation_settings?.ticket_creation_config
      ?.ticket_fields || [];

  const idFieldMap = keyBy('id', fieldArr);
  const idValueArr =
    customizationData.ticket_creation_settings?.ticket_creation_config
      ?.fields_and_values;

  return (
    <>
      <Label>Case Field</Label>
      <Field name='ticket_creation_settings.ticket_creation_config.fields_and_values'>
        {({ field, form: { setFieldValue } }: FieldProps) => (
          <>
            {idValueArr.map(({ id, value }, idx) => {
              const type: fieldType = get(
                [id, 'type'],
                idFieldMap,
              ) as fieldType;
              const isCustomField = get([id, 'is_custom'], idFieldMap);

              return (
                <div key={`field-row-${idx}`}>
                  <Row>
                    <DropdownFields
                      fieldArr={fieldArr}
                      fieldId={id}
                      fieldName={field.name}
                      idFieldMap={idFieldMap}
                      idValueArr={idValueArr}
                      idx={idx}
                      setFieldValue={setFieldValue}
                    />
                    <Spacer width='8px' />
                    {/* Initially, if user hasn't selected a field type, we wouldn't know what field value
                      input type to render. So we default it to text field. */}
                    {['string', 'textarea', 'date', 'email'].includes(type) && (
                      <CVTextField
                        contextVariables={contextVariables}
                        fieldName={field.name}
                        fieldValue={String(value)}
                        idValueArr={idValueArr}
                        idx={idx}
                        isCustomField={isCustomField}
                        setFieldValue={setFieldValue}
                      />
                    )}
                    {['picklist', 'reference', 'boolean'].includes(type) && (
                      <Box sx={{ flex: '1 1 50%' }}>
                        <ContextVariableSelectDropdown
                          additionalOptions={getOr(
                            [],
                            [id, 'field_options'],
                            idFieldMap,
                          ).map(
                            ({
                              name,
                              value,
                            }: {
                              name: string;
                              value: string;
                            }) => ({
                              category: 'Field Options',
                              label: name,
                              value,
                            }),
                          )}
                          aria-label='Salesforce custom field'
                          id={`salesforce-custom-field-${id}`}
                          onChange={value => {
                            const updatedFieldValues = idValueArr
                              .slice(0, idx)
                              .concat({
                                id: idValueArr[idx].id,
                                is_custom: isCustomField,
                                value: value,
                              })
                              .concat(idValueArr.slice(idx + 1));

                            setFieldValue(field.name, updatedFieldValues);
                          }}
                          shouldIncludeSystemContextVariables
                          shouldProvideCVIdFormatting
                          value={String(value)}
                        />
                      </Box>
                    )}
                    <RemoveFieldButton
                      fieldName={field.name}
                      idValueArr={idValueArr}
                      idx={idx}
                      setFieldValue={setFieldValue}
                    />
                  </Row>
                  <Spacer height='8px' />
                </div>
              );
            })}
            <AddFieldLink
              fieldArr={fieldArr}
              fieldName={field.name}
              idValueArr={idValueArr}
              setFieldValue={setFieldValue}
            />
          </>
        )}
      </Field>
    </>
  );
};

export default TicketCustomFields;
