import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import styled from '@mui/material/styles/styled';
import Typography from '@mui/material/Typography';

import {
  Checkbox,
  TextField,
} from '@forethought-technologies/forethought-elements';
import { useDebouncedSubmitConfigForRTE } from '../hooks/useDebouncedSubmitConfig';
import {
  AgentMessage,
  AgentMessageContainer,
  ChatContainer,
  CustomImage,
} from '../styledComponents';
import capitalize from 'lodash/fp/capitalize';
import agentChatImageDefaultIcon from 'src/assets/images/agent-chat-image-default.svg';
import { useToneOfVoiceTestQuery } from 'src/services/solve-config/solveConfigApi';
import {
  selectIsSaving,
  selectWidgetConfiguration,
  setToneOfVoiceConfig,
} from 'src/slices/solve-config/solveConfigSlice';
import {
  PredefinedTones,
  predefinedTones,
  ToneOfVoiceConfig,
} from 'src/slices/solve-config/types';
import { useAppDispatch } from 'src/store/hooks';

export const ToneTabContent = () => {
  return (
    <Box display='flex' flexDirection='column' gap={2}>
      <Typography variant='font14'>
        A consistent tone of voice builds brand identity and strengthens
        customer connections. Keeping it uniform across channels enhances
        response quality and personalization. AI uses a neutral tone by default.
      </Typography>
      <Typography variant='font16Bold'>
        Describe your own or select from the suggestions
      </Typography>
      <ToneConfiguration />
    </Box>
  );
};
const DEFAULT_TONE_OF_VOICE_INPUT =
  "I'm sorry to hear that you're experiencing issues with your account. To assist you effectively, could you please provide your account number?";
const getToneOfVoiceStatus = (toneOfVoiceConfig: ToneOfVoiceConfig) => {
  if (
    !toneOfVoiceConfig.should_use_predefined_tones &&
    toneOfVoiceConfig.custom_prompt
  ) {
    return 'custom';
  }

  if (
    toneOfVoiceConfig.predefined_tones.length > 0 &&
    toneOfVoiceConfig.should_use_predefined_tones
  ) {
    return 'predefined';
  }

  return 'default';
};
const ToneConfiguration = () => {
  const { tone_of_voice_config: toneOfVoiceConfig, ...rest } = useSelector(
    selectWidgetConfiguration,
  );
  const { refetch } = useToneOfVoiceTestQuery(
    {
      input: DEFAULT_TONE_OF_VOICE_INPUT,
    },
    { skip: getToneOfVoiceStatus(toneOfVoiceConfig) === 'default' },
  );
  const dispatch = useAppDispatch();
  const debouncedSubmitConfig = useDebouncedSubmitConfigForRTE();

  const selectedPredefinedTonesSet = new Set(
    toneOfVoiceConfig.predefined_tones,
  );

  const updateToneOfVoiceConfig = (newFields: ToneOfVoiceConfig) => {
    dispatch(setToneOfVoiceConfig(newFields));
    debouncedSubmitConfig(
      {
        ...rest,
        tone_of_voice_config: newFields,
      },
      'tone_of_voice_config',
      JSON.stringify(newFields),
      refetch,
    );
  };

  return (
    <>
      <Box>
        <Checkbox
          checked={!toneOfVoiceConfig.should_use_predefined_tones}
          label='Describe tone of voice'
          onChange={() => {
            const newFields = {
              ...toneOfVoiceConfig,
              should_use_predefined_tones: false,
            };
            updateToneOfVoiceConfig(newFields);
          }}
        />
        {!toneOfVoiceConfig.should_use_predefined_tones && (
          <TextField
            aria-label='Describe tone of voice'
            fullWidth
            multiline
            onChange={e => {
              const newFields = {
                ...toneOfVoiceConfig,
                custom_prompt: e.target.value,
              };

              updateToneOfVoiceConfig(newFields);
            }}
            placeholder='e.g. warm and approachable, and end with a smiley face emoji'
            rows={3}
            value={toneOfVoiceConfig.custom_prompt ?? ''}
          />
        )}
      </Box>
      <Box>
        <Checkbox
          checked={toneOfVoiceConfig.should_use_predefined_tones}
          label='Select suggestions'
          onChange={async () => {
            const newFields = {
              ...toneOfVoiceConfig,
              should_use_predefined_tones: true,
            };
            updateToneOfVoiceConfig(newFields);
          }}
        />
        <Box display='flex' flexWrap='wrap' gap={1} mt={1.5}>
          {predefinedTones.map(tone => (
            <ToneChip
              isActive={selectedPredefinedTonesSet.has(tone)}
              key={tone}
              label={capitalize(tone)}
              onClick={() => {
                if (selectedPredefinedTonesSet.has(tone)) {
                  selectedPredefinedTonesSet.delete(tone);
                } else {
                  selectedPredefinedTonesSet.add(tone);
                }
                const newFields = {
                  ...toneOfVoiceConfig,
                  predefined_tones: Array.from(selectedPredefinedTonesSet),
                  should_use_predefined_tones: true,
                };
                updateToneOfVoiceConfig(newFields);
              }}
            />
          ))}
        </Box>
      </Box>
    </>
  );
};

const ToneChip = ({
  isActive,
  label,
  onClick,
}: {
  isActive?: boolean;
  label: string;
  onClick: () => void;
}) => {
  return (
    <Chip
      label={
        <Typography
          color={theme => theme.palette.colors.grey[700]}
          sx={{ transition: 'all 150ms ease-in-out 100ms' }}
          variant='font14Medium'
        >
          {label}
        </Typography>
      }
      onClick={onClick}
      sx={[
        theme => ({
          '& .MuiChip-label': { padding: 0 },
          '&:hover': {
            '& .MuiTypography-root': {
              color: theme.palette.colors.purple[500],
            },
            border: '1px solid ' + theme.palette.colors.purple[500],
          },
          border: '1px solid ' + theme.palette.colors.slate[200],
          borderRadius: '20px',
          cursor: 'pointer',
          padding: '6px 16px',
          transition: 'all 150ms ease-in-out 100ms',
        }),
        theme =>
          isActive
            ? {
                '& .MuiTypography-root': {
                  color: theme.palette.colors.purple[500],
                },
                border: '1px solid ' + theme.palette.colors.purple[500],
              }
            : {},
      ]}
      variant='outlined'
    />
  );
};

export const ToneTabWidgetContent = () => {
  const isSaving = useSelector(selectIsSaving);
  const { agent_chat_image, tone_of_voice_config: toneOfVoiceConfig } =
    useSelector(selectWidgetConfiguration);
  const toneOfVoiceStatus = getToneOfVoiceStatus(toneOfVoiceConfig);
  const { data, isFetching, isLoading } = useToneOfVoiceTestQuery(
    {
      input: DEFAULT_TONE_OF_VOICE_INPUT,
    },
    { skip: toneOfVoiceStatus === 'default' },
  );
  const shouldShowLoadingDots = isLoading || isFetching || isSaving;

  return (
    <ChatContainer>
      <Typography sx={{ mb: '18px' }} variant='font14Bold'>
        <ToneOfVoiceLabel
          predefinedTones={toneOfVoiceConfig.predefined_tones}
          toneOfVoiceStatus={toneOfVoiceStatus}
        />
      </Typography>
      <AgentMessageContainer>
        <CustomImage
          alt=''
          src={agent_chat_image ? agent_chat_image : agentChatImageDefaultIcon}
        />
        {shouldShowLoadingDots ? (
          <LoadingDots />
        ) : (
          <AgentMessage>
            <Typography
              component='div'
              sx={{ py: '14px' }}
              variant='font14Medium'
            >
              {data?.response && toneOfVoiceStatus !== 'default'
                ? data.response
                : DEFAULT_TONE_OF_VOICE_INPUT}
            </Typography>
          </AgentMessage>
        )}
      </AgentMessageContainer>
    </ChatContainer>
  );
};

const ToneOfVoiceLabel = ({
  predefinedTones,
  toneOfVoiceStatus,
}: {
  predefinedTones: PredefinedTones[];
  toneOfVoiceStatus: 'custom' | 'predefined' | 'default';
}) => {
  switch (toneOfVoiceStatus) {
    case 'custom':
      return 'Your tone of voice';
    case 'predefined':
      return predefinedTones.map(capitalize).join(', ');
    default:
      return 'Neutral (default)';
  }
};

const LoadingDots = (): JSX.Element => {
  return (
    <LoadingDotsWrapper>
      <div></div>
      <div></div>
      <div></div>
    </LoadingDotsWrapper>
  );
};

const LoadingDotsWrapper = styled('div')`
  display: flex;
  justify-content: center;
  margin-top: 12px;

  div {
    width: 8px;
    height: 8px;
    margin: 0 2px;
    border-radius: 50%;
    background-color: #000;
    opacity: 0.3;
    animation: bouncing-loader 0.8s infinite alternate;
    animation-timing-function: cubic-bezier(1, 0, 0.8, 0);
  }

  @keyframes bouncing-loader {
    to {
      transform: translateY(-10px);
      opacity: 1;
    }
  }

  & > div:nth-of-type(2) {
    animation-delay: 0.2s;
  }

  & > div:nth-of-type(3) {
    animation-delay: 0.4s;
  }
`;
