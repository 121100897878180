import { sendAuthRequest } from '../api';
import {
  AddEmailWorkflowRuleStepParams,
  AddEmailWorkflowStepParams,
  AddIntentEmailConfigurationComponentParams,
  AvailableArticleSourcesResponse,
  CreatePreviewResponse,
  DeleteIntentEmailConfigurationComponentParams,
  DeleteIntentEmailConfigurationParams,
  DeletePathParams,
  EmailPreviewParams,
  EmailWorkflowPublishedResponse,
  EmailWorkflowResponse,
  EmailWorkflowTestFieldsResponse,
  GetIntentEmailConfigurationParams,
  GetIntentEmailConfigurationPreviewParams,
  GetPreviewInteractiveEmailResponse,
  IntentEmailConfigurationPreviewResponse,
  IntentEmailConfigurationResponse,
  IntentEmailConfigurationsResponse,
  ObtainDefaultEmailWorkflowParams,
  ObtainIntentEmailConfigurationParams,
  PublishEmailWorkflowParams,
  PublishIntentEmailConfigurationParams,
  PublishIntentEmailConfigurationResponse,
  RemoveEmailWorkflowStepParams,
  ReorderIntentEmailConfigurationComponentParams,
  TestJourneyParams,
  UpdateEmailWorkflowStepParams,
  UpdateEmailWorkflowStepTransitionsParams,
  UpdateIntentEmailConfigurationComponentParams,
  UpdateIntentEmailConfigurationNameParams,
  ValidateWorkflowParams,
  ValidateWorkflowResponse,
} from 'src/pages/workflow-builder-edit/types';
import { GenerateResponseRequest } from 'src/types/workflowBuilderAPITypes';
import { normalizeResponseErrors } from 'src/utils/normalizeResponse';

const BASE_URL = `${API_URL}solve/email/email-builder`;

export const obtainIntentEmailConfiguration = ({
  intentDefinitionId,
}: ObtainIntentEmailConfigurationParams): Promise<IntentEmailConfigurationResponse> => {
  return sendAuthRequest(
    `${BASE_URL}/intent-email-configuration/default`,
    { intent_definition_id: intentDefinitionId },
    'POST',
  )
    .then(normalizeResponseErrors)
    .then(res => res.json());
};

export const getEmailConfigurationsPerIntent = ({
  intentDefinitionId,
}: ObtainIntentEmailConfigurationParams): Promise<IntentEmailConfigurationsResponse> => {
  return sendAuthRequest(
    `${BASE_URL}/intent_definition_id/${intentDefinitionId}/email-configurations`,
    null,
    'GET',
  )
    .then(normalizeResponseErrors)
    .then(res => res.json());
};

export const createIntentEmailConfiguration = ({
  intentDefinitionId,
}: ObtainIntentEmailConfigurationParams): Promise<IntentEmailConfigurationResponse> => {
  return sendAuthRequest(
    `${BASE_URL}/intent-email-configuration`,
    { intent_definition_id: intentDefinitionId },
    'POST',
  )
    .then(normalizeResponseErrors)
    .then(res => res.json());
};

export const updateIntentEmailConfigurationCompononent = ({
  component,
  configurationId,
  lastModifiedDate,
  version,
}: UpdateIntentEmailConfigurationComponentParams): Promise<IntentEmailConfigurationResponse> => {
  const { component_fields, component_id } = component;

  return sendAuthRequest(
    `${BASE_URL}/intent-email-configuration/${configurationId}/version/${version}/component/${component_id}`,
    {
      component: {
        component_fields,
      },
      last_modified_date: lastModifiedDate,
    },
    'PUT',
  )
    .then(normalizeResponseErrors)
    .then(res => res.json());
};

export const addIntentEmailConfigurationComponent = ({
  component,
  configurationId,
  lastModifiedDate,
  position,
  version,
}: AddIntentEmailConfigurationComponentParams): Promise<IntentEmailConfigurationResponse> => {
  return sendAuthRequest(
    `${BASE_URL}/intent-email-configuration/${configurationId}/version/${version}/component`,
    { component, last_modified_date: lastModifiedDate, position },
    'POST',
  )
    .then(normalizeResponseErrors)
    .then(res => res.json());
};

export const deleteIntentEmailConfigurationComponent = ({
  componentId,
  configurationId,
  lastModifiedDate,
  version,
}: DeleteIntentEmailConfigurationComponentParams): Promise<IntentEmailConfigurationResponse> => {
  return sendAuthRequest(
    `${BASE_URL}/intent-email-configuration/${configurationId}/version/${version}/component/${componentId}`,
    { last_modified_date: lastModifiedDate },
    'DELETE',
  )
    .then(normalizeResponseErrors)
    .then(res => res.json());
};

export const reorderIntentEmailConfigurationComponent = ({
  componentId,
  configurationId,
  lastModifiedDate,
  position,
  version,
}: ReorderIntentEmailConfigurationComponentParams): Promise<IntentEmailConfigurationResponse> => {
  return sendAuthRequest(
    `${BASE_URL}/intent-email-configuration/${configurationId}/version/${version}/component/${componentId}/position`,
    { last_modified_date: lastModifiedDate, position },
    'PUT',
  )
    .then(normalizeResponseErrors)
    .then(res => res.json());
};

export const publishIntentEmailConfiguration = ({
  configurationId,
  isPublishing,
  lastModifiedDate,
  version,
}: PublishIntentEmailConfigurationParams): Promise<PublishIntentEmailConfigurationResponse> => {
  return sendAuthRequest(
    `${BASE_URL}/intent-email-configuration/${configurationId}/version/${version}/published`,
    { last_modified_date: lastModifiedDate, published: isPublishing },
    'PUT',
  )
    .then(normalizeResponseErrors)
    .then(res => res.json());
};

export const getIntentEmailConfigurationPreview = ({
  configurationId,
  version,
}: GetIntentEmailConfigurationPreviewParams): Promise<IntentEmailConfigurationPreviewResponse> => {
  return sendAuthRequest(
    `${BASE_URL}/intent-email-configuration/${configurationId}/version/${version}/preview`,
    null,
    'GET',
  )
    .then(normalizeResponseErrors)
    .then(res => res.json());
};

export const getAvailableArticleSources =
  (): Promise<AvailableArticleSourcesResponse> => {
    return sendAuthRequest(`${BASE_URL}/available-article-sources`, null, 'GET')
      .then(normalizeResponseErrors)
      .then(res => res.json());
  };

export const obtainDefaultEmailWorkflow = ({
  intentDefinitionId,
}: ObtainDefaultEmailWorkflowParams): Promise<EmailWorkflowResponse> => {
  return sendAuthRequest(
    `${BASE_URL}/email-workflow/default`,
    { intent_definition_id: intentDefinitionId },
    'POST',
  )
    .then(normalizeResponseErrors)
    .then(res => res.json());
};

export const publishEmailWorkflow = ({
  emailWorkflowId,
  isPublishing,
  lastModifiedDate,
  version,
}: PublishEmailWorkflowParams): Promise<EmailWorkflowPublishedResponse> => {
  return sendAuthRequest(
    `${BASE_URL}/email-workflow/${emailWorkflowId}/version/${version}/published`,
    { last_modified_date: lastModifiedDate, published: isPublishing },
    'PUT',
  )
    .then(normalizeResponseErrors)
    .then(res => res.json());
};

export const addEmailWorkflowStep = ({
  emailWorkflowId,
  lastModifiedDate,
  parentStepId,
  parentTransitionId,
  stepFields,
  stepId,
  stepType,
  version,
}: AddEmailWorkflowStepParams): Promise<EmailWorkflowResponse> => {
  return sendAuthRequest(
    `${BASE_URL}/email-workflow/${emailWorkflowId}/version/${version}/step`,
    {
      last_modified_date: lastModifiedDate,
      parent_step_id: parentStepId,
      parent_transition_id: parentTransitionId,
      step_fields: stepFields,
      step_id: stepId,
      step_type: stepType,
    },
    'POST',
  )
    .then(normalizeResponseErrors)
    .then(res => res.json());
};

export const addEmailWorkflowRuleStep = ({
  emailWorkflowId,
  lastModifiedDate,
  parentStepId,
  parentTransitionId,
  stepFields,
  stepId,
  transitions,
  version,
}: AddEmailWorkflowRuleStepParams): Promise<EmailWorkflowResponse> => {
  return sendAuthRequest(
    `${BASE_URL}/email-workflow/${emailWorkflowId}/version/${version}/step/rule`,
    {
      last_modified_date: lastModifiedDate,
      parent_step_id: parentStepId,
      parent_transition_id: parentTransitionId,
      step_fields: stepFields,
      step_id: stepId,
      transitions,
    },
    'POST',
  )
    .then(normalizeResponseErrors)
    .then(res => res.json());
};

export const updateEmailWorkflowStep = ({
  emailWorkflowId,
  lastModifiedDate,
  stepFields,
  stepId,
  version,
}: UpdateEmailWorkflowStepParams) => {
  return sendAuthRequest(
    `${BASE_URL}/email-workflow/${emailWorkflowId}/version/${version}/step/${stepId}`,
    {
      last_modified_date: lastModifiedDate,
      step_fields: stepFields,
    },
    'PUT',
  )
    .then(normalizeResponseErrors)
    .then(res => res.json());
};

export const updateEmailWorkflowStepTransitions = ({
  emailWorkflowId,
  lastModifiedDate,
  stepId,
  transitions,
  version,
}: UpdateEmailWorkflowStepTransitionsParams) => {
  return sendAuthRequest(
    `${BASE_URL}/email-workflow/${emailWorkflowId}/version/${version}/step/${stepId}/transitions`,
    {
      lastModifiedDate,
      transitions,
    },
    'PUT',
  )
    .then(normalizeResponseErrors)
    .then(res => res.json());
};

export const removeEmailWorkflowStep = ({
  emailWorkflowId,
  lastModifiedDate,
  stepId,
  version,
}: RemoveEmailWorkflowStepParams) => {
  return sendAuthRequest(
    `${BASE_URL}/email-workflow/${emailWorkflowId}/version/${version}/step/${stepId}`,
    { last_modified_date: lastModifiedDate },
    'DELETE',
  )
    .then(normalizeResponseErrors)
    .then(res => res.json());
};

export const getIntentEmailConfiguration = ({
  configurationId,
}: GetIntentEmailConfigurationParams) => {
  return sendAuthRequest(
    `${BASE_URL}/intent-email-configuration/${configurationId}/draft`,
    null,
    'GET',
  )
    .then(normalizeResponseErrors)
    .then(res => res.json());
};

export const updateIntentEmailConfigurationName = ({
  configurationId,
  name,
  version,
}: UpdateIntentEmailConfigurationNameParams): Promise<IntentEmailConfigurationResponse> => {
  return sendAuthRequest(
    `${BASE_URL}/intent-email-configuration/${configurationId}/version/${version}/name`,
    { name },
    'PUT',
  )
    .then(normalizeResponseErrors)
    .then(res => res.json());
};

export const deleteIntentEmailConfiguration = ({
  configurationId,
  lastModifiedDate,
  version,
}: DeleteIntentEmailConfigurationParams): Promise<IntentEmailConfigurationResponse> => {
  return sendAuthRequest(
    `${BASE_URL}/intent-email-configuration/${configurationId}/version/${version}`,
    { last_modified_date: lastModifiedDate },
    'DELETE',
  )
    .then(normalizeResponseErrors)
    .then(res => res.json());
};

export const testJourney = (
  testJourneyParams: TestJourneyParams,
): Promise<void> => {
  return sendAuthRequest(
    `${API_URL}solve/email/test`,
    testJourneyParams,
    'POST',
  )
    .then(normalizeResponseErrors)
    .then(res => res.json());
};

export const createEmailPreviewTemplate = (
  createPreviewParams: EmailPreviewParams,
): Promise<CreatePreviewResponse> => {
  return sendAuthRequest(
    `${API_URL}solve/email/preview`,
    createPreviewParams,
    'POST',
  )
    .then(normalizeResponseErrors)
    .then(res => res.json());
};

export const getWidgetConversationForInteractiveEmailPreview = (
  token: string,
): Promise<GetPreviewInteractiveEmailResponse> => {
  return sendAuthRequest(
    `${API_URL}dashboard-controls/solve/v2/preview/interactive-email-widget/${token}`,
    null,
    'GET',
  )
    .then(normalizeResponseErrors)
    .then(res => res.json());
};

export const validateEmailWorkflow = ({
  emailWorkflowId,
  version,
}: ValidateWorkflowParams): Promise<ValidateWorkflowResponse> => {
  return sendAuthRequest(
    `${BASE_URL}/email-workflow/${emailWorkflowId}/version/${version}/validate`,
    {},
    'POST',
  )
    .then(normalizeResponseErrors)
    .then(res => res.json());
};

export const getEmailWorkflowTestFields = (
  emailWorkflowId: string,
): Promise<EmailWorkflowTestFieldsResponse> => {
  return sendAuthRequest(
    `${BASE_URL}/email-workflow/${emailWorkflowId}/draft/test-fields`,
    null,
    'GET',
  )
    .then(normalizeResponseErrors)
    .then(res => res.json());
};

export const deletePath = ({
  emailWorkflowId,
  keepParentTransition,
  lastModifiedDate,
  stepId,
  version,
}: DeletePathParams): Promise<EmailWorkflowResponse> => {
  return sendAuthRequest(
    `${BASE_URL}/email-workflow/${emailWorkflowId}/version/${version}/step/${stepId}/path`,
    {
      keep_parent_transition: keepParentTransition,
      last_modified_date: lastModifiedDate,
    },
    'DELETE',
  )
    .then(normalizeResponseErrors)
    .then(res => res.json());
};

const getGeneratedResponse = ({ action, text }: GenerateResponseRequest) => {
  return sendAuthRequest(
    `${API_URL}solve/email/generate`,
    {
      action,
      text,
    },
    'POST',
  ).then(normalizeResponseErrors);
};

export async function executeStreamRequest(
  request: GenerateResponseRequest,
  chunkCallback: (chunk: string) => void,
  finishCallback: () => void,
) {
  const res = await getGeneratedResponse(request);
  const reader = res?.body?.pipeThrough(new TextDecoderStream()).getReader();

  if (reader) {
    while (true) {
      const { done, value } = await reader.read();
      if (done) {
        finishCallback();
        return;
      }
      if (value.includes('{"status":40')) {
        chunkCallback('Error generating response');
        finishCallback();
        return;
      }
      chunkCallback(value);
    }
  }
}
