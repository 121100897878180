import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Alert } from '@forethought-technologies/forethought-elements';
import BuilderTopBar from '../builder-top-bar/BuilderTopbar';
import { emailConfigurationSubVersionConflictException } from '../constants';
import {
  useEmailMode,
  useGetBuilderQueryParams,
  useIntentEmailConfiguration,
} from '../hooks';
import { BuilderView, WorkflowBuilderProps } from '../types';
import WorkflowConflictMessage from '../WorkflowConflictMessage';
import EmailPublishButtons from './EmailPublishButtons';
import IntentEmailBuilder from './intent-email-builder';
import IntentEmailJourneyMap from './intent-email-journey-map';
import { getActionBuilderActions } from 'src/actions/action-builder-actions/actionBuilderActions';
import ActivateWorkflowModal from 'src/components/activate-workflow-modal/ActivateWorkflowModal';
import RequiredPhrasesModal from 'src/components/required-phrases-modal/RequiredPhrasesModal';
import useDemoNewTab from 'src/pages/intent-conversation-analytics/hooks/useDemoNewTab';
import { AlertModalContainer } from 'src/pages/workflow-builder/landing-page/LandingPage';
import { selectIsIntentEnabledForEmail } from 'src/reducers/workflowBuilderReducer/workflowBuilderReducer';
import { getIntent } from 'src/slices/canvas-workflow-builder/workflowBuilderSlice.thunks';
import {
  cleanErrors,
  getAvailableArticleSources,
  getEmailConfigurationsPerIntent,
  getIntentEmailConfiguration,
  getThemeColor,
  obtainIntentEmailConfiguration,
  selectEmailBuilderError,
} from 'src/slices/email-builder/emailBuilderSlice';
import {
  obtainDefaultEmailWorkflow,
  publishEmailWorkflow,
  selectEmailWorkflowState,
} from 'src/slices/email-workflow/emailWorkflowSlice';
import { getTicketFieldMappings } from 'src/slices/ticket-field-mappings/ticketFieldMappingsSlice';
import { openGlobalIntentDrawer } from 'src/slices/ui/uiSlice';
import { useAppDispatch } from 'src/store/hooks';

const EmailBuilderPage = ({ onTabClick, view }: WorkflowBuilderProps) => {
  const dispatch = useAppDispatch();
  useDemoNewTab('email');

  const [
    isActivateConfigurationDialogVisible,
    setIsActivateConfigurationDialogVisible,
  ] = useState(false);

  const [isRequiredPhrasesModalVisible, setIsRequiredPhrasesModalVisible] =
    useState(false);
  const [isUpdateJourneyModalVisible, setIsUpdateJourneyModalVisible] =
    useState(false);

  const { configurationId, intentId, workflowId } = useGetBuilderQueryParams();
  const { emailMode } = useEmailMode();

  const { intentEmailConfiguration } = useIntentEmailConfiguration({
    configurationId,
    intentId,
    isEmailBuilderView: emailMode === 'Response',
  });

  const isIntentActive = useSelector(selectIsIntentEnabledForEmail);
  const error = useSelector(selectEmailBuilderError);

  const { emailWorkflow } = useSelector(selectEmailWorkflowState);

  const isEmailConfigurationSubVersionConflictException =
    error === emailConfigurationSubVersionConflictException;

  const reloadIntentEmailBuilderData = () => {
    if (!intentId) {
      return;
    }

    if (configurationId) {
      dispatch(getIntentEmailConfiguration({ configurationId }));
      dispatch(
        getEmailConfigurationsPerIntent({
          intentDefinitionId: intentId,
        }),
      );
    } else {
      dispatch(
        obtainIntentEmailConfiguration({ intentDefinitionId: intentId }),
      );
    }
    dispatch(getAvailableArticleSources());
  };

  useEffect(() => {
    dispatch(getTicketFieldMappings());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getThemeColor());
    dispatch(getAvailableArticleSources());
    dispatch(getActionBuilderActions());
  }, [dispatch]);

  const journeyMap = emailMode === 'Journey';

  useEffect(() => {
    if (!intentId) {
      return;
    }
    if (journeyMap) {
      dispatch(obtainDefaultEmailWorkflow({ intentDefinitionId: intentId }));
    }
  }, [dispatch, intentId, journeyMap]);

  return (
    <>
      <WorkflowConflictMessage
        buttonMessage='Reload'
        message=' Reload this page to continue editing this workflow from the last
            updated version.'
        onButtonClick={() => {
          reloadIntentEmailBuilderData();
        }}
        open={isEmailConfigurationSubVersionConflictException}
        title='The current email configuration has been updated by another user in your organization'
      />

      {error && !isEmailConfigurationSubVersionConflictException && (
        <AlertModalContainer>
          <Alert
            onClose={() => dispatch(cleanErrors())}
            title={'An error ocurred, please refresh the page or try again'}
            variant={'danger'}
          />
        </AlertModalContainer>
      )}
      <BuilderTopBar
        contentRight={
          <EmailPublishButtons
            emailWorkflow={emailWorkflow}
            intentEmailConfiguration={intentEmailConfiguration}
            isIntentActive={isIntentActive}
            isJourneyMapTab={!!journeyMap}
            onUpdateButtonClick={() => {
              if (!emailWorkflow) {
                return;
              }
              if (emailWorkflow?.has_modified_responses) {
                setIsUpdateJourneyModalVisible(true);
              } else {
                dispatch(
                  publishEmailWorkflow({
                    emailWorkflowId: emailWorkflow.email_workflow_id,
                    isPublishing: true,
                    lastModifiedDate: emailWorkflow.last_modified_date,
                    version: emailWorkflow.version,
                  }),
                );
              }
            }}
            setIsActivateConfigurationDialogVisible={
              setIsActivateConfigurationDialogVisible
            }
            setIsRequiredPhrasesModalVisible={setIsRequiredPhrasesModalVisible}
          />
        }
        onTabClick={(view: BuilderView) => onTabClick && onTabClick(view)}
        view={view}
      />
      {journeyMap ? (
        <IntentEmailJourneyMap
          isUpdateJourneyModalVisible={isUpdateJourneyModalVisible}
          setIsUpdateJourneyModalVisible={isVisible =>
            setIsUpdateJourneyModalVisible(isVisible)
          }
        />
      ) : (
        <IntentEmailBuilder
          intentEmailConfiguration={intentEmailConfiguration}
          intentId={intentId}
        />
      )}
      <ActivateWorkflowModal
        intentWorkflowId={workflowId}
        isActive={isIntentActive}
        isDialogVisible={isActivateConfigurationDialogVisible}
        message={`This will make your configuration ${
          isIntentActive ? 'go offline' : 'go live'
        }`}
        onClose={() => setIsActivateConfigurationDialogVisible(false)}
        onSuccess={() => dispatch(getIntent(intentId))}
        workflowTypes={['email']}
      />
      {isRequiredPhrasesModalVisible && (
        <AlertModalContainer>
          <RequiredPhrasesModal
            onActivateOptionClick={() => {
              dispatch(openGlobalIntentDrawer({ type: 'email' }));
              setIsRequiredPhrasesModalVisible(false);
            }}
            onLaterOptionClick={() => setIsRequiredPhrasesModalVisible(false)}
          />
        </AlertModalContainer>
      )}
    </>
  );
};

export default EmailBuilderPage;
