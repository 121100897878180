import React from 'react';
import { Typography } from '@mui/material';
import Box, { BoxProps } from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import { IconInfoCircle } from '@tabler/icons-react';

import { Tooltip } from '@forethought-technologies/forethought-elements';
import { QuickstartFieldType } from '../types';
import TriageConfigDetailLinkText from './TriageConfigDetailLinkText';

interface TriageConfigDetailReadInputProps extends BoxProps {
  children: React.ReactNode;
  label: 'Integration' | QuickstartFieldType;
  onClickCreateNewFieldLink?: () => void;
  shouldShowCreateNewFieldLink?: boolean;
  view: 'read' | 'edit';
}

const TriageConfigDetailInputContainer = ({
  children,
  label,
  onClickCreateNewFieldLink,
  shouldShowCreateNewFieldLink,
  view,
  ...props
}: TriageConfigDetailReadInputProps) => {
  const { palette } = useTheme();

  const getTooltipContent = () => {
    switch (label) {
      case 'Integration':
        return 'The model will be applied to data from the selected integration';
      case 'Output field':
        return 'The model will output to this field in the selected integration';
      case 'Origins':
        return 'Only cases coming from these origins will be classified';
      case 'Object':
        return 'Represents a case, which is a customer issue or problem in Salesforce';
      default:
        return '';
    }
  };

  return (
    <Box
      sx={theme => ({
        maxWidth: '450px',
        [theme.breakpoints.up('lg')]: {
          width: '66.666%',
        },
      })}
      {...props}
    >
      <Box
        alignItems='center'
        display='flex'
        justifyContent='space-between'
        mb={1.25}
      >
        <Box alignItems='center' display='flex'>
          <Typography
            color={
              view === 'read' ? palette.colors.black : palette.colors.grey[800]
            }
            variant='font14Bold'
          >
            {label}{' '}
          </Typography>
          <Box alignItems='center' display='flex' ml={0.5}>
            <Tooltip tooltipContent={getTooltipContent()}>
              <IconInfoCircle color={palette.colors.grey[800]} size={17.5} />
            </Tooltip>
          </Box>
        </Box>
        {shouldShowCreateNewFieldLink && (
          <TriageConfigDetailLinkText onClick={onClickCreateNewFieldLink}>
            Create new field instead
          </TriageConfigDetailLinkText>
        )}
      </Box>
      {children}
    </Box>
  );
};

export default TriageConfigDetailInputContainer;
