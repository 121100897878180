import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Box, useTheme } from '@mui/material';
import { IconArrowNarrowLeft } from '@tabler/icons-react';

import {
  Tabs,
  Typography,
} from '@forethought-technologies/forethought-elements';
import TopicArticlesTable from '../discover-topic-detail-page/TopicArticlesTable';
import ArticlesTable from '../solve-insights/articles/ArticlesTable';
import InsightArticleDrawer from '../solve-insights/articles/InsightArticleDrawer';
import ChatsTable from '../solve-insights/chats/ChatsTable';
import { dateRangeToTimestamp } from '../solve-insights/helpers';
import { useGetGeneratedArticles } from '../solve-insights/hooks/useGetGeneratedArticles';
import useTabs from '../solve-insights/hooks/useTabs';
import { initialInsightTopicParams } from '../solve-insights/topics/constants';
import { InsightPeriod } from '../solve-insights/types';
import TabHeader from './TabHeader';
import TopicContent from './TopicContent';
import NavbarV2 from 'src/components/app/navbar/navbarV2';
import { periodicalFilterOptions } from 'src/constants/discover';
import { useEmitTrackingEventCallback, useStateParams } from 'src/hooks/hooks';
import { NA } from 'src/pages/intent-conversation-analytics/constants';
import { useGetPermissionsQuery } from 'src/services/dashboard-api';
import { useGetTopicInformationQuery } from 'src/services/insights';
import { useGetOrgConfigQuery } from 'src/services/settings/settingsApi';
import { selectGeneratedArticles } from 'src/slices/solve-insights/solveInsightsSlice';
import {
  dateRangeDeserialize,
  dateRangeSerialize,
  dateRangeToTimeFilter,
  genericParameterValidator,
  genericSerializeAndDeserialize,
  timeFilterParameterValidator,
} from 'src/utils/discover/helpers';
import { Permission, Routes } from 'src/utils/enums';

const SolveInsightsTopicDetail = () => {
  const { palette } = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const isParentTopic = location.pathname.includes('parent');
  const { topicId = '' } = useParams<'topicId'>();
  const { data: orgConfigData } = useGetOrgConfigQuery();
  const { data: permissions = {} } = useGetPermissionsQuery();
  const isDiscoverEnabled = orgConfigData?.discover_status === 'enabled';
  const isDiscoverPermissionEnabled = Boolean(permissions[Permission.DISCOVER]);
  const shouldIncludeGeneratedDiscoverArticles =
    isDiscoverEnabled && isDiscoverPermissionEnabled;

  //selectors
  const generatedArticles = useSelector(selectGeneratedArticles);

  // state params
  const [activeArticleTab, setActiveArticleTab] = useStateParams<number>({
    deserialize: param => parseInt(param, 10),
    initialState: 0,
    paramsName: 'tableTab',
    serialize: state => state.toString(),
  });
  const [dateRange, setDateRange] = useStateParams({
    deserialize: dateRangeDeserialize,
    initialState: initialInsightTopicParams.date,
    paramsName: 'date',
    serialize: dateRangeSerialize,
    validator: timeFilterParameterValidator([]),
  });
  const [periodicalFilter, setPeriodicalFilter] = useStateParams<InsightPeriod>(
    {
      deserialize: genericSerializeAndDeserialize as (
        str: string,
      ) => InsightPeriod,
      initialState: initialInsightTopicParams.period,
      paramsName: 'period',
      serialize: genericSerializeAndDeserialize,
      validator: genericParameterValidator(periodicalFilterOptions),
    },
  );
  // end state params
  const [visibleColumns, setVisibleColumns] = React.useState<string[]>(
    initialInsightTopicParams.topicColumns,
  );

  // Hooks
  const emitTrackingEventCallback = useEmitTrackingEventCallback();
  const { isLoadingGeneratedArticles } = useGetGeneratedArticles({
    isParentTopic,
    shouldIncludeGeneratedDiscoverArticles,
    topicId,
  });

  // header detail data
  const backendReadyTimestamps = dateRangeToTimestamp(dateRange);
  const timeFilter = dateRangeToTimeFilter(dateRange);

  // scroll logic
  const [scrollTop, setScrollTop] = useState(0);
  const containerRef = useRef<HTMLDivElement>();
  const scrollToTop = useCallback(
    (yOffset: number, smooth = false) => {
      if (!containerRef.current) {
        return;
      }
      if (containerRef.current.scrollTop > yOffset) {
        containerRef.current.scrollTo({
          behavior: smooth ? 'smooth' : 'auto',
          top: yOffset,
        });
        emitTrackingEventCallback('insight-scroll-to-top-clicked', {
          scope: 'detail',
          tab: 'topic',
        });
      }
    },
    [emitTrackingEventCallback],
  );

  useEffect(() => {
    emitTrackingEventCallback('insight-detail-initial-page', {
      scope: 'detail',
      tab: 'topic',
    });
  }, [emitTrackingEventCallback]);

  const handleFilterReset = () => {
    setDateRange(initialInsightTopicParams.date);
    setVisibleColumns(initialInsightTopicParams.topicColumns);
    setPeriodicalFilter(initialInsightTopicParams.period);
  };

  const initialTimeFilter = dateRangeToTimeFilter(
    initialInsightTopicParams.date,
  );

  const areFiltersUsed = Boolean(
    timeFilter.key !== initialTimeFilter.key ||
      visibleColumns.toString() !==
        initialInsightTopicParams.topicColumns.toString() ||
      (periodicalFilter &&
        periodicalFilter !== initialInsightTopicParams.period),
  );

  const { data: topicInformation } = useGetTopicInformationQuery({
    end: backendReadyTimestamps.end_timestamp,
    is_parent_topic: isParentTopic,
    start: backendReadyTimestamps.start_timestamp,
    topic_id: topicId,
  });

  const { tabIdToIndexMap } = useTabs();

  const tabs = useMemo(() => {
    const tabs = ['Chats', 'Knowledge articles'];
    if (shouldIncludeGeneratedDiscoverArticles) {
      tabs.push('Knowledge gap');
    }
    return tabs;
  }, [shouldIncludeGeneratedDiscoverArticles]);

  const getTabSubtitle = () => {
    const labelToSubtitleMap: { [key: string]: string } = {
      Chats: `Chats categorized under “${
        topicInformation?.cluster_name ?? NA
      }”`,
      'Knowledge articles': `Knowledge articles surfaced in the chats categorized under “${
        topicInformation?.cluster_name ?? NA
      }”`,
      'Knowledge gap': '',
    };

    return labelToSubtitleMap[tabs[activeArticleTab]];
  };

  const buildSearchParams = () => {
    const params = new URLSearchParams(location.search);
    if (!location.search.includes('tab')) {
      params.set('tab', tabIdToIndexMap.topic.toString());
    }

    return params;
  };

  return (
    <>
      <NavbarV2
        currentTabOverride={
          <Box
            display='flex'
            onClick={() => {
              navigate({
                pathname: Routes.SOLVE_INSIGHTS_MAIN,
                search: `?${buildSearchParams()}`,
              });
              emitTrackingEventCallback('insight-detail-back-button-clicked', {
                scope: 'detail',
                tab: 'topic',
              });
            }}
            sx={{
              '&:hover': {
                cursor: 'pointer',
              },
            }}
          >
            <IconArrowNarrowLeft
              color={palette.colors.purple[500]}
              size='20px'
            />
            <Typography color={palette.colors.purple[500]} variant='font14Bold'>
              All chat topics
            </Typography>
          </Box>
        }
      />
      <Box
        bgcolor={palette.colors.white}
        flex='1'
        height='100%'
        onScroll={e => {
          setScrollTop(e.currentTarget.scrollTop);
        }}
        overflow='auto'
        ref={containerRef}
        width='100%'
      >
        <Box
          bgcolor={palette.colors.white}
          display='flex'
          flexDirection='column'
          gap='24px'
          px='40px'
          py='24px'
          zIndex={5}
        >
          <TabHeader
            dateRange={dateRange}
            isParentTopic={isParentTopic}
            periodicalFilter={periodicalFilter}
            tabName={topicInformation?.cluster_name ?? 'Topic'}
            topicId={topicId}
          />
          {/* <Banner scope='detail' /> */}
          <TopicContent
            areFiltersUsed={areFiltersUsed}
            dateRange={dateRange}
            handleFilterReset={handleFilterReset}
            isParentTopic={isParentTopic}
            periodicalFilter={periodicalFilter}
            setDateRange={setDateRange}
            setPeriodicalFilter={setPeriodicalFilter}
            topicId={topicId}
            topicInformation={topicInformation}
          />
          <Box>
            <Box
              sx={{
                alignItems: 'center',
                backgroundColor: palette.colors.white,
                display: 'flex',
                gap: 1,
                position: 'sticky',
                top: 0,
                width: '100%',
                zIndex: 5,
              }}
            >
              <Tabs
                a11yProps={index => ({
                  'aria-controls': `tabpanel-${index}`,
                  id: `tab-${index}`,
                })}
                aria-label='tabs to pick data table below'
                onChange={(_, tab) => {
                  setActiveArticleTab(tab);
                  emitTrackingEventCallback('insight-table-tab-change', {
                    scope: 'detail',
                    tab: 'topic',
                    value: tab === 0 ? 'chat' : 'article',
                  });
                }}
                tabs={tabs}
                typographyVariant='font18'
                value={activeArticleTab}
              />
            </Box>
            <Box paddingTop='16px'>
              <Typography color={palette.colors.grey[600]} variant='font16'>
                {getTabSubtitle()}
              </Typography>
            </Box>
            {activeArticleTab === 0 ? (
              <ChatsTable
                dateRange={dateRange}
                handleFilterReset={handleFilterReset}
                isDetail={true}
                period={periodicalFilter}
                scope='detail'
                scrollTop={scrollTop}
                scrollToTop={scrollToTop}
                setDateRange={setDateRange}
                setPeriodicalFilter={setPeriodicalFilter}
                view='topics'
              />
            ) : null}
            {activeArticleTab === 1 ? (
              <ArticlesTable
                dateRange={dateRange}
                handleFilterReset={handleFilterReset}
                period={periodicalFilter}
                scope='detail'
                setDateRange={setDateRange}
                setPeriodicalFilter={setPeriodicalFilter}
              />
            ) : null}
            {activeArticleTab === 2 ? (
              <TopicArticlesTable
                articleData={generatedArticles}
                emitArticleClickedTrackingEvent={({ value }) =>
                  emitTrackingEventCallback(
                    'insights-clicked-on-generated-article',
                    {
                      isParentTopic,
                      page: `Insights ${
                        isParentTopic ? 'parent topic' : 'topic'
                      }  detail view`,
                      'page-area': 'Knowledge gap',
                      topicId,
                      topicName: topicInformation?.cluster_name ?? '',
                      value: value,
                    },
                  )
                }
                emitSortedTrackingEvent={({ value }) =>
                  emitTrackingEventCallback(
                    'insights-sorted-generated-articles',
                    {
                      isParentTopic,
                      page: `Insights ${
                        isParentTopic ? 'Parent topic' : 'topic'
                      }  detail view`,
                      'page-area': 'Knowledge gap',
                      topicId,
                      topicName: topicInformation?.cluster_name ?? '',
                      value: value,
                    },
                  )
                }
                isLoading={isLoadingGeneratedArticles}
                timeFilter={timeFilter}
              />
            ) : null}
            <InsightArticleDrawer scope='detail' />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default SolveInsightsTopicDetail;
