import { useCallback, useEffect, useMemo, useState } from 'react';
import Pusher from 'pusher-js';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import { css, styled } from '@mui/material';
import { Box } from '@mui/material';

import {
  Button,
  DateRangeFilterButton,
  formatToDateAndTime,
  theme,
} from '@forethought-technologies/forethought-elements';
import {
  clearIntentData,
  setEditingStepId,
  setIntentWorkflowId,
} from '../../../slices/canvas-workflow-builder/workflowBuilderSlice';
import EmailAggregateMetricsContainer from '../aggregate-metrics/EmailAggregateMetricsContainer';
import WidgetAggregateMetricsContainer from '../aggregate-metrics/WidgetAggregateMetricsContainer';
import EmailIntentWorkflowsTableContainer from '../intent-workflows-table/containers/EmailIntentWorkflowsTableContainer';
import WidgetIntentWorkflowsTableContainer from '../intent-workflows-table/containers/WidgetIntentWorkflowsTableContainer';
import { useSolveMetricsQueryParams } from '../intent-workflows-table/hooks/useSolveMetricsQueryParams';
import { useWidgetAggregateData } from '../intent-workflows-table/hooks/useWidgetAggregateData';
import { getTimeRangeFromQueryParams } from '../intent-workflows-table/utils';
import LandingPageSubTabs from './components/LandingPageSubTabs';
import OnboardingCards from './components/OnboardingCards';
import {
  clearWorkflowBuilderSuggestedIntents,
  getModelTrainingData,
  getWorkflowBuilderSuggestedIntents,
  getWorkflowBuilderWidgetConfig,
  setIsOverlayVisible,
} from 'src/actions/workflow-builder/workflowBuilderActions';
import improvementsBtn from 'src/assets/images/btn-improvement.svg';
import Overlay from 'src/components/dashboard-pages/answers-analytics-page/SettingsBar/Overlay';
import RequiredPhrasesModal from 'src/components/required-phrases-modal/RequiredPhrasesModal';
import ErrorBanner from 'src/components/reusable-components/error-banner/ErrorBanner';
import {
  useGetIntentsQueryWithProduct,
  useListenPreviewPostMessageEvents,
  useSetDefaultTimeRangeQueryParams,
} from 'src/hooks/hooks';
import {
  useDeleteIntentEventSubscription,
  useIntentStatusEventSubscription,
  useModelTrainingStatus,
  useUpdateIntentEventSubscription,
} from 'src/hooks/PusherEventHooks';
import HeaderSection from 'src/pages/intent-conversation-analytics/HeaderSection';
import useDemoNewTab from 'src/pages/intent-conversation-analytics/hooks/useDemoNewTab';
import SuggestedImprovements from 'src/pages/workflow-builder/suggested-improvements';
import {
  selectIsOverlayVisible,
  selectWorkflowBuilderSuggestedGapIntents,
} from 'src/reducers/workflowBuilderReducer/workflowBuilderReducer';
import { useGetFeatureFlagsQuery } from 'src/services/dashboard-api';
import { getIntentAPI } from 'src/services/workflow-builder/workflowBuilderApi';
import {
  closeGlobalIntentDrawer,
  openGlobalIntentDrawer,
  setIntentToEdit,
} from 'src/slices/ui/uiSlice';
import { dateRangeToTimeFilter } from 'src/utils/discover/helpers';
import { Routes } from 'src/utils/enums';
import { getUserOrgCode } from 'src/utils/getUserOrgCode';
import {
  constructTimeRangeQueryParamsRevamped,
  formatTimeForSafari,
  last30DaysTimeRange,
} from 'src/utils/timeRangeHelpers';
import { datePickerRangeOptionsRevamp } from 'src/utils/timeRangeHelpers';

const emptyIntent = {
  intent_id: '',
  is_custom: true,
  phrases: [],
  title: '',
};

export const LandingPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const qParams = useSolveMetricsQueryParams();
  const { data: getIntentsResponse, refetch: refetchIntents } =
    useGetIntentsQueryWithProduct();

  const isOverlayVisible = useSelector(selectIsOverlayVisible);
  const { data: featureFlagsData } = useGetFeatureFlagsQuery();
  const { feature_flags: featureFlags = [] } = featureFlagsData ?? {};

  const [intentIdToEdit, setIntentIdToEdit] = useState('');
  const [isRequiredPhrasesModalVisible, setIsRequiredPhrasesModalVisible] =
    useState(false);
  const [workflowTagFilterValue, setWorkflowTagFilterValue] = useState<
    string[]
  >([]);
  const { data: aggregateMetricData } = useWidgetAggregateData();
  const isOnboardingExperienceEnabled = featureFlags.includes(
    'onboarding_experience',
  );
  const gapDetectedSuggestions = useSelector(
    selectWorkflowBuilderSuggestedGapIntents,
  );

  // states
  const [shouldShowImprovements, setShouldShowImprovements] = useState(false);

  const isSafari =
    navigator.userAgent.search('Safari') >= 0 &&
    navigator.userAgent.search('Chrome') < 0;

  const lastUpdated = aggregateMetricData?.last_updated_at
    ? formatToDateAndTime(
        isSafari
          ? formatTimeForSafari(aggregateMetricData.last_updated_at)
          : aggregateMetricData.last_updated_at,
      )
    : null;

  useEffect(() => {
    dispatch(getWorkflowBuilderWidgetConfig());
    dispatch(getWorkflowBuilderSuggestedIntents());

    dispatch(setIntentWorkflowId(null));
    dispatch(clearIntentData());
    dispatch(getModelTrainingData());

    if (!window.pusher) {
      window.pusher = new Pusher(PUSHER_KEY, {
        cluster: PUSHER_CLUSTER,
      });
    }
    dispatch(setEditingStepId(''));
    return () => {
      dispatch(setIsOverlayVisible(false));
      dispatch(clearWorkflowBuilderSuggestedIntents());
    };
  }, [dispatch]);

  useIntentStatusEventSubscription({
    onEvent: useCallback(() => {
      refetchIntents();
      dispatch(getWorkflowBuilderSuggestedIntents());
    }, [dispatch, refetchIntents]),
    orgCode: getUserOrgCode(),
  });

  useDeleteIntentEventSubscription({
    onEvent: refetchIntents,
    orgCode: getUserOrgCode(),
  });

  const onCloseIntentForm = useCallback(
    () => dispatch(closeGlobalIntentDrawer()),
    [dispatch],
  );
  const onOpenIntentForm = () =>
    dispatch(openGlobalIntentDrawer({ type: 'landing' }));

  useUpdateIntentEventSubscription({
    onEvent: useCallback(() => {
      refetchIntents();
      onCloseIntentForm();
    }, [onCloseIntentForm, refetchIntents]),
    orgCode: getUserOrgCode(),
  });

  useListenPreviewPostMessageEvents();
  useModelTrainingStatus(getUserOrgCode());
  useSetDefaultTimeRangeQueryParams();

  useDemoNewTab('landing');

  const selectedDateRange = useMemo(() => {
    return getTimeRangeFromQueryParams({
      from: qParams.from,
      to: qParams.to,
    });
  }, [qParams]);

  const timeFilter = dateRangeToTimeFilter(selectedDateRange);

  // TODO: clean up, split out by channel
  const getIsIntentActive = (intentId: string) =>
    getIntentsResponse?.intents
      .find(intent => intent.intent_definition_id === intentId)
      ?.active_workflow_types.includes('flamethrower-live');

  const TableContainer =
    qParams.channel === 'email'
      ? EmailIntentWorkflowsTableContainer
      : WidgetIntentWorkflowsTableContainer;

  return (
    <Container>
      <ErrorBannerContainer>
        <ErrorBanner message='Connection error. Please try again or refresh the page.' />
      </ErrorBannerContainer>
      <BodyContainer>
        <BodyLeftContainer>
          <HeaderSection
            dateLabel={timeFilter.label}
            extraItems={
              gapDetectedSuggestions.length > 0 ? (
                <Button
                  aria-label='Improvements'
                  onClick={() => {
                    setShouldShowImprovements(currentValue => !currentValue);
                  }}
                  startIcon={
                    <Box marginTop='2px'>
                      <ReactSVG src={improvementsBtn} />
                    </Box>
                  }
                  variant='ghost'
                />
              ) : undefined
            }
            lastUpdated={lastUpdated}
            overviewText='Workflows overview'
          />
          {qParams.channel === 'widget' && isOnboardingExperienceEnabled && (
            <OnboardingCards />
          )}

          {qParams.channel === 'email' ? (
            <EmailAggregateMetricsContainer />
          ) : (
            <WidgetAggregateMetricsContainer />
          )}
          <LandingPageSubTabs />
          <TableContainer
            dateFilter={
              <DateRangeFilterButton
                initialValue={last30DaysTimeRange}
                onChange={timeRange => {
                  const existingQueryParams = new URLSearchParams(
                    location.search,
                  );

                  const newSearchParams = constructTimeRangeQueryParamsRevamped(
                    timeRange,
                    qParams.channel,
                  );

                  const mergedSearchParams = new URLSearchParams({
                    ...Object.fromEntries(existingQueryParams),
                    ...Object.fromEntries(newSearchParams),
                  });

                  navigate(
                    {
                      pathname: Routes.WORKFLOW_BUILDER,
                      search: mergedSearchParams.toString(),
                    },
                    { replace: true },
                  );
                }}
                options={datePickerRangeOptionsRevamp}
                value={selectedDateRange}
              />
            }
            onEditButtonClick={async intentId => {
              const intent = await getIntentAPI(intentId);
              dispatch(
                setIntentToEdit({
                  ...intent,
                  isActive: getIsIntentActive(intentId),
                }),
              );

              onOpenIntentForm();
            }}
            onNewCustomIntentButtonClick={() => {
              onOpenIntentForm();
              dispatch(setIntentToEdit(emptyIntent));
            }}
            setWorkflowTagFilterValue={setWorkflowTagFilterValue}
            showMinPhrasesDialog={intentId => {
              setIsRequiredPhrasesModalVisible(true);
              setIntentIdToEdit(intentId);
            }}
            workflowTagFilterValue={workflowTagFilterValue}
          />
        </BodyLeftContainer>
        <SuggestionContainer isExpanded={shouldShowImprovements}>
          <SuggestedImprovements
            isExpanded={shouldShowImprovements}
            onClose={() => {
              setShouldShowImprovements(false);
            }}
          />
        </SuggestionContainer>
      </BodyContainer>
      <Overlay displayOverlay={isOverlayVisible} isDarkest />
      {isRequiredPhrasesModalVisible && (
        <AlertModalContainer>
          <RequiredPhrasesModal
            onActivateOptionClick={async () => {
              const intent = await getIntentAPI(intentIdToEdit);
              dispatch(
                setIntentToEdit({
                  ...intent,
                  isActive: getIsIntentActive(intentIdToEdit),
                }),
              );
              onOpenIntentForm();
              setIsRequiredPhrasesModalVisible(false);
            }}
            onLaterOptionClick={() => {
              setIsRequiredPhrasesModalVisible(false);
            }}
          />
        </AlertModalContainer>
      )}
    </Container>
  );
};

const Container = styled('div')`
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;
  background-color: ${theme.palette.colors.white};
  height: 100%;
`;

const BodyContainer = styled('div')`
  display: flex;
  margin: 0 40px;
  height: 100%;

  @media screen and (max-width: 600px) {
    margin: 0 30px;
  }
`;

const BodyLeftContainer = styled('div')`
  width: stretch;
  display: flex;
  flex-direction: column;
`;

const ErrorBannerContainer = styled('div')`
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: calc(50% - 200px);
  top: 8px;
  z-index: 10000;
`;

const SuggestionContainer = styled('div')<{ isExpanded: boolean }>`
  height: 100%;
  max-height: 978px;
  border-left: 1px solid ${theme.palette.colors.grey[100]};
  flex: 0 0 auto;
  ${props =>
    props.isExpanded
      ? css`
          width: 330px;
          opacity: 1;
          transition: opacity 1000ms, width 300ms;
          margin-left: 40px;
        `
      : css`
          width: 0;
          opacity: 0;
          transition: opacity 50ms, width 300ms;
        `};
`;

export const DatePickerContainer = styled('div')`
  border: 1px solid ${theme.palette.colors.slate[200]};
  border-radius: 4px;

  .RangeExample {
    transform: translate(-30px);
  }
`;

export const AlertModalContainer = styled('div')`
  display: flex;
  align-items: center;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  top: 85px;
  z-index: 9;
`;
