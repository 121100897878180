import { useMemo } from 'react';

import { useGetIntentsQueryWithProduct } from '../hooks';
import { CommonIntentWorkflowType } from 'src/utils/enums';

const useGetHasOtherQuestions = () => {
  const { data } = useGetIntentsQueryWithProduct();

  const hasOtherQuestions = useMemo(
    () =>
      Boolean(
        data?.intents.find(
          intent =>
            intent.intent_definition_id ===
            CommonIntentWorkflowType.GENERAL_HANDOFF,
        ),
      ),
    [data?.intents],
  );

  return hasOtherQuestions;
};

export default useGetHasOtherQuestions;
