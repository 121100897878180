import sanitizeHtml from 'sanitize-html';

import { theme } from '@forethought-technologies/forethought-elements';
import unescape from 'lodash/fp/unescape';
import { ContextVariable } from 'src/types/actionBuilderApiTypes';
import { ProductMentionField } from 'src/types/workflowBuilderAPITypes';

const DYNAMIC_CONTEXT_VARIABLE_REGEX = /{{(\w+)([\/\.\w]+)?}}/;

export function removeUnderscores(str: string) {
  return str.replace(/[_-]/g, ' ');
}

export function cleanStr(str: string | number | undefined): string {
  return str ? sanitizeHtml(removeUnderscores(String(str))) : '';
}

export const formatEntities = (str: string) => {
  // 'sanitize-html' parses entities with no way to turn it off.
  // see https://github.com/apostrophecms/sanitize-html/blob/3cdc262ac6ae54497eff8fe9d5817333137ba41f/index.js#L603
  return str
    .replace(/&lt;/g, '<')
    .replace(/&gt;/g, '>')
    .replace(/&quot;/g, '"')
    .replace(/&apos;/g, "'");
};

export const extractCvIdFromCurlyBraces = (value: string) => value.slice(2, -2);

export const extractCvIdsFromFieldValue = (
  value: string | undefined,
): string[] => {
  const contextVariableRegex = /{{(.+?)}}/gm;

  return (
    value
      ?.toString()
      ?.match(contextVariableRegex)
      ?.map(matched => extractCvIdFromCurlyBraces(matched)) || []
  );
};

type ReplaceValueFn = ({
  id,
  isDefined,
  name,
}: {
  id: string;
  isDefined: boolean;
  mentionName: string;
  name: string;
}) => string;

/**
 * Recursively replaces all context variable ids found in the text content
 * of an HTML string.
 */
export const contextVariableAliasHtml = ({
  additionalMentions = [],
  contextVariables,
  html,
  replaceValueFn = ({ isDefined, name }) => {
    const color = isDefined
      ? theme.palette.colors.purple[500]
      : theme.palette.colors.red[500];
    return `<span style="color: ${color};">${name}</span>`;
  },
  undefinedContextVariableIds,
}: {
  additionalMentions?: ProductMentionField[];
  contextVariables: Pick<
    ContextVariable,
    'context_variable_id' | 'context_variable_name'
  >[];
  html: string;
  replaceValueFn?: ReplaceValueFn;
  undefinedContextVariableIds: string[];
}) => {
  const contextVariableMap = new Map<string, string>();
  for (const ctxVar of contextVariables) {
    contextVariableMap.set(
      ctxVar.context_variable_id,
      ctxVar.context_variable_name,
    );
  }

  try {
    const parser = new DOMParser();
    const htmlDoc = parser.parseFromString(html, 'text/html');

    // recursively alias html text content of all nodes
    for (const node of htmlDoc.body.childNodes) {
      replaceTextContent({
        additionalMentions,
        contextVariableMap,
        node,
        replaceValueFn,
        undefinedContextVariableIds,
      });
    }

    const serializedHtml = new XMLSerializer().serializeToString(htmlDoc);
    return unescape(serializedHtml);
  } catch (e) {
    return regexReplaceContextVariables({
      additionalMentions,
      contextVariableMap,
      replaceValueFn,
      text: html,
      undefinedContextVariableIds,
    });
  }
};

/**
 * Recursively calls regexReplaceContextVariables for all child nodes
 * that are of type Node.TEXT_NODE
 */
const replaceTextContent = ({
  additionalMentions,
  contextVariableMap,
  node,
  replaceValueFn,
  undefinedContextVariableIds,
}: {
  additionalMentions: ProductMentionField[];
  contextVariableMap: Map<string, string>;
  node: Node;
  replaceValueFn: ReplaceValueFn;
  undefinedContextVariableIds: string[];
}) => {
  // Check if the node is a text node
  if (node.nodeType === Node.TEXT_NODE) {
    node.textContent = regexReplaceContextVariables({
      additionalMentions,
      contextVariableMap,
      replaceValueFn,
      text: node.textContent || '',
      undefinedContextVariableIds,
    });
  }
  // If the node has child nodes, recursively call the function for each child node
  else if (node.childNodes.length > 0) {
    for (const childNode of node.childNodes) {
      replaceTextContent({
        additionalMentions,
        contextVariableMap,
        node: childNode,
        replaceValueFn,
        undefinedContextVariableIds,
      });
    }
  }
};

/**
 * Use regex to replace all {{context_variable_id}} with $context_variable_name
 * in a string
 *
 * @example '{{context_variable_id}}' -> '$context_variable_name'
 * @example '{{context_variable_id/./subpath}}' -> '$context_variable_name.subpath'
 */
export const regexReplaceContextVariables = ({
  additionalMentions = [],
  contextVariableMap,
  replaceValueFn,
  text,
  undefinedContextVariableIds,
}: {
  additionalMentions?: ProductMentionField[];
  contextVariableMap: Map<string, string>;
  replaceValueFn: ReplaceValueFn;
  text: string;
  undefinedContextVariableIds: string[];
}) => {
  let match: RegExpExecArray | null;
  let result = text;
  const re = new RegExp(DYNAMIC_CONTEXT_VARIABLE_REGEX, 'g');

  while ((match = re.exec(text)) !== null) {
    const [fullMatch, contextVariableId, suffix] = match;
    const contextVariableIsDefined =
      !undefinedContextVariableIds.includes(contextVariableId);
    const contextVariableName = contextVariableMap.get(contextVariableId);
    const contextVariableNameWithSuffix = `${contextVariableName}${
      suffix ? suffix.replace(/\/\.\//g, '.') : ''
    }`;

    if (contextVariableName) {
      result = result.replace(
        fullMatch,
        replaceValueFn({
          id: `${contextVariableId}${suffix ? suffix : ''}`,
          isDefined: contextVariableIsDefined,
          mentionName: 'context-variable',
          name: '$' + contextVariableNameWithSuffix,
        }),
      );
    }
  }

  for (const mention of additionalMentions) {
    const { mention_format, name, options } = mention;
    const dynamicRegex = new RegExp(
      `${mention_format.prefix}(\\w+)${mention_format.suffix}`,
      'g',
    );

    while ((match = dynamicRegex.exec(text)) !== null) {
      const [fullMatch, id] = match;
      const foundOption = options.find(option => option.value === id);

      if (foundOption) {
        result = result.replace(
          fullMatch,
          replaceValueFn({
            id,
            isDefined: true,
            mentionName: name,
            name: foundOption.label, // No suffix handling needed here as the dynamicRegex already captures the whole variable
          }),
        );
      }
    }
  }

  return result;
};
