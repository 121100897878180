import { channelToWorkflowTypes } from 'src/pages/workflow-builder-edit/constants';
import { WorkflowTypes } from 'src/pages/workflow-builder-edit/types';
import { TranslationChannel } from 'src/types/workflowBuilderAPITypes';
import { CommonIntentWorkflowType } from 'src/utils/enums';

export function isIntentDuplicable(intentId: string) {
  return (
    intentId !== CommonIntentWorkflowType.KNOWLEDGE_ARTICLE &&
    intentId !== CommonIntentWorkflowType.FALLBACK
  );
}

export function isIntentEditable(intentId: string) {
  return !(
    intentId === CommonIntentWorkflowType.GENERAL_HANDOFF ||
    intentId === CommonIntentWorkflowType.KNOWLEDGE_ARTICLE ||
    intentId === CommonIntentWorkflowType.FALLBACK
  );
}

export function isOnlyInteractiveEmail(intentId: string) {
  return (
    intentId === CommonIntentWorkflowType.HANDOFF ||
    intentId === CommonIntentWorkflowType.FALLBACK
  );
}

export function isIntentActiveForChannel(
  activeWorkflowTypes: WorkflowTypes[],
  channel: TranslationChannel,
) {
  const liveWorkflowTypesForChannel = channelToWorkflowTypes[channel];

  return activeWorkflowTypes.some(activeWorkflowType =>
    liveWorkflowTypesForChannel.includes(activeWorkflowType),
  );
}

export const getWorkflowActivationCopy = ({
  channel,
  hasOtherQuestions,
  intentName,
  isActive,
  isKnowledgeArticle,
}: {
  channel: string;
  hasOtherQuestions: boolean;
  intentName?: string;
  isActive: boolean;
  isKnowledgeArticle: boolean;
}) => {
  const knowledgeArticleMessage = `. ${
    isActive
      ? 'Disabling Knowledge Retrieval activates Other Questions.'
      : 'Enabling Knowledge Retrieval replaces Other Questions.'
  }`;

  const label = `${isActive ? 'Deactivate' : 'Activate'} ${
    intentName ? `${intentName} ` : ' '
  }workflow`;

  const confirmationMessage = `This will make your workflow ${
    isActive ? 'go offline' : 'go live'
  } for the ${channel} channel`;

  return {
    confirmationMessage:
      isKnowledgeArticle && hasOtherQuestions
        ? confirmationMessage + knowledgeArticleMessage
        : confirmationMessage,
    label,
  };
};
