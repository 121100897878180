import { useMemo, useState } from 'react';
import { createMRTColumnHelper, MRT_Row } from 'material-react-table';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';

import {
  formatUnixTimestampToDateAndTime,
  SearchBar,
} from '@forethought-technologies/forethought-elements';
import {
  InfiniteTable,
  Tooltip,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { TABLE_COLUMNS, TEST_RUN_STATUS_TOOLTIP } from './constants';
import TestTicketStatusTableValue from './TestTicketStatusTableValue';
import TriageCreateRun from './TriageCreateRun';
import { NA } from 'src/constants/solve';
import {
  TriagePredictionLabel,
  TriagePredictionRun,
} from 'src/services/triage/types';
import { formatReadableDate } from 'src/utils/dateUtils';
import { Routes } from 'src/utils/enums';

interface TriageSimulationsTableProps {
  data?: TriagePredictionRun[];
  isLoading: boolean;
}

const TriageSimulationsTable = ({
  data,
  isLoading,
}: TriageSimulationsTableProps) => {
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState('');
  const columnHelper = createMRTColumnHelper<TriagePredictionLabel>();

  const columns = useMemo(
    () =>
      TABLE_COLUMNS.map(colItem =>
        columnHelper.accessor(colItem.key as keyof TriagePredictionLabel, {
          Cell: ({ row }: { row: MRT_Row<TriagePredictionLabel> }) => {
            if (colItem.key === 'created_at') {
              const date = new Date(row.original.created_at);
              const value = formatUnixTimestampToDateAndTime(
                Number(date) / 1000,
              );

              return <Typography variant='font14'>{value}</Typography>;
            }
            if (colItem.key === 'test_set_criteria') {
              const tickets = row.original.ticket_ids;
              const hasTickets = tickets.length > 0;
              const endDate = row.original.end_datetime;
              const startDate = row.original.start_datetime;
              if (hasTickets) {
                const value = `Ticket IDs - ${tickets.join(', ')}`;
                return (
                  <Tooltip tooltipContent={value}>
                    <Typography noWrap variant='font14'>
                      {value}
                    </Typography>
                  </Tooltip>
                );
              }
              if (startDate && endDate) {
                const value = `Date range - ${formatReadableDate(
                  new Date(startDate),
                )} - ${formatReadableDate(new Date(endDate))}`;
                return <Typography variant='font14'>{value}</Typography>;
              }
              return <Typography variant='font14'>{NA}</Typography>;
            }
            if (colItem.key === 'status') {
              const status = row.original.status;
              return (
                <Tooltip tooltipContent={TEST_RUN_STATUS_TOOLTIP[status]}>
                  {TestTicketStatusTableValue({
                    status,
                  })}
                </Tooltip>
              );
            }
            return (
              <Typography variant='font14'>
                {row.original[colItem.key as keyof TriagePredictionLabel]}
              </Typography>
            );
          },
          header: colItem.title,
          size: colItem.size,
        }),
      ),
    [columnHelper],
  );

  const rows = useMemo(() => {
    if (!data) {
      return [];
    }
    if (!searchText) {
      return data;
    }
    return data.filter(item => {
      return item.name.toLowerCase().includes(searchText);
    });
  }, [data, searchText]);

  const handleTableRowClick = (id: string, modelId: string) => {
    navigate(`${Routes.TRIAGE_SIMULATION_RUNS}/${id}?model_id=${modelId}`);
  };

  return (
    <InfiniteTable
      hasNextPage={false}
      initialSorting={[{ desc: true, id: 'created_at' }]}
      isError={false}
      isLoadingFirstPage={isLoading}
      isLoadingNextPage={false}
      onLoadMore={() => undefined}
      onSortCallback={() => undefined}
      stickyHeaderHeight={100}
      tableOptions={{
        columns,
        data: rows,
        enableBottomToolbar: false,
        enableColumnActions: false,
        enableColumnFilters: false,
        enableExpanding: false,
        enableFilters: false,
        enableFullScreenToggle: false,
        enableGlobalFilter: false,
        enableRowActions: false,
        initialState: {
          columnPinning: {
            left: ['name'],
          },
        },
        manualSorting: false,
        muiTableBodyRowProps: ({ row }) => ({
          onClick: () => {
            const runStatus = row.original.status;
            if (runStatus === 'success') {
              handleTableRowClick(
                row.original.prediction_test_id,
                row.original.model_id,
              );
            }
          },
          sx: {
            cursor: row.original.status === 'success' ? 'pointer' : undefined,
          },
        }),
        renderTopToolbarCustomActions: () => (
          <Box display='flex' justifyContent='space-between' width='100%'>
            <SearchBar
              onChange={event => setSearchText(event.target.value)}
              placeholder='Search'
              size='small'
              sx={{ width: 'auto' }}
              value={searchText}
            />
            <TriageCreateRun />
          </Box>
        ),
      }}
    />
  );
};

export default TriageSimulationsTable;
