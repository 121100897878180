import { sendAuthRequestWithErrorHandling } from '../api';
import { Field, Fields, Filters, FilterValueResponse } from '../apiInterfaces';
import { SSEL_BASE_URL } from '../constants';
import { dashboardApi } from '../dashboard-api';
import { createOption } from 'src/components/analytic-filter/helper';
import { FilterOption } from 'src/components/analytic-filter/types';
import {
  TopicPeriodicalFilter,
  TopicTimeFilter,
} from 'src/components/app/types';
import {
  ArticleIntegrations,
  AutomateTopicApi,
  DiscoverAllTopicsResponse,
  DiscoverArticle,
  DiscoverArticlePublishRequest,
  DiscoverDashboardResponse,
  DiscoverMetadata,
  DiscoverRecommendationDetailsResponse,
  DiscoverRecommendationsResponse,
  DiscoverReportedTicket,
  DiscoverTicket,
  DiscoverTicketDetailsResponse,
  DiscoverTopic,
  DiscoverTopicDetailsResponse,
  DiscoverTopicTicketsResponse,
  KnowledgeBaseSections,
  KnowledgeGapArticleRecommendations,
  PatchArticleRequest,
  PatchTopicRequest,
  PostDiscoverReportedTicketRequest,
} from 'src/reducers/discoverReducer/types';
import {
  DiscoverAutomationsByTypeResponse,
  DiscoverRecommendationsPoliciesResponse,
} from 'src/types/DiscoverTypes';
import { CanvasIntentsResponse } from 'src/types/workflowBuilderAPITypes';
import {
  addKeywordSearch,
  constructQueryParamWithTimeFilter,
} from 'src/utils/discover/helpers';

export const getAllTopicsApi = async ({
  dataFilterQuery,
  timeFilter,
}: {
  dataFilterQuery: Filters[];
  timeFilter: TopicTimeFilter;
}): Promise<DiscoverAllTopicsResponse> => {
  const params = constructQueryParamWithTimeFilter(timeFilter);
  let body: null | { filters: Filters[] } = null;
  if (dataFilterQuery.length > 0) {
    body = { filters: dataFilterQuery };
  }
  return await sendAuthRequestWithErrorHandling(
    `${API_URL}ssel/discover/v1/topics?${params}`,
    body,
    'POST',
  );
};

export const getDashboardMetricsApi = async ({
  dataFilterQuery,
  interval,
  timeFilter,
}: {
  dataFilterQuery: Filters[];
  interval: TopicPeriodicalFilter;
  timeFilter: TopicTimeFilter;
}): Promise<DiscoverDashboardResponse> => {
  const params = constructQueryParamWithTimeFilter(timeFilter);
  return await sendAuthRequestWithErrorHandling(
    `${API_URL}ssel/discover/v1/dashboard-metrics?${params}&interval=${interval}`,
    dataFilterQuery.length ? { filters: dataFilterQuery } : null,
    'POST',
  );
};

export const getTicketDetailsByIdApi = async ({
  ticketId,
}: {
  ticketId: string;
}): Promise<DiscoverTicketDetailsResponse> => {
  return await sendAuthRequestWithErrorHandling(
    `${API_URL}ssel/discover/v1/tickets/${ticketId}`,
    {},
    'GET',
  );
};

export const getTopicMetricsByIdApi = async ({
  dataFilterQuery,
  interval,
  timeFilter,
  topicId,
}: {
  dataFilterQuery: Filters[];
  interval: TopicPeriodicalFilter;
  timeFilter: TopicTimeFilter;
  topicId: string;
}): Promise<DiscoverTopicDetailsResponse> => {
  const params = constructQueryParamWithTimeFilter(timeFilter);
  let body: null | { filters: Filters[] } = null;
  if (dataFilterQuery.length > 0) {
    body = { filters: dataFilterQuery };
  }
  return await sendAuthRequestWithErrorHandling(
    `${API_URL}ssel/discover/v1/topics/${topicId}?${params}&interval=${interval}`,
    body,
    'POST',
  );
};

export const getTopicTicketsApi = async ({
  dataFilterQuery,
  searchText,
  timeFilter,
  topicId,
}: {
  dataFilterQuery: Filters[];
  searchText?: string;
  timeFilter: TopicTimeFilter;
  topicId: string;
}): Promise<DiscoverTopicTicketsResponse> => {
  const params = constructQueryParamWithTimeFilter(timeFilter);
  let body: null | { filters?: Filters[]; keyword_search?: string } = null;
  if (dataFilterQuery.length > 0) {
    body = { filters: dataFilterQuery };
  }
  const queryParams = addKeywordSearch(params, searchText);
  return await sendAuthRequestWithErrorHandling(
    `${API_URL}ssel/discover/v1/topics/${topicId}/tickets?${queryParams}`,
    body,
    'POST',
  );
};

export const getDiscoverRecommendationsApi =
  async (): Promise<DiscoverRecommendationsResponse> => {
    return await sendAuthRequestWithErrorHandling(
      `${API_URL}ssel/discover/recommendations`,
      {},
      'GET',
    );
  };

export const getDiscoverRecommendationDetailsApi = async (
  topicId: string,
): Promise<DiscoverRecommendationDetailsResponse> => {
  return await sendAuthRequestWithErrorHandling(
    `${API_URL}ssel/discover/recommendations/${topicId}`,
    {},
    'GET',
  );
};

export const automateTopicApi: AutomateTopicApi = async data => {
  return await sendAuthRequestWithErrorHandling(
    `${API_URL}ssel/discover/topics/automate`,
    data,
    'POST',
  );
};

export const getDiscoverAutomationsApi = async (
  include_automation_value = true,
): Promise<DiscoverAutomationsByTypeResponse[]> => {
  const params = new URLSearchParams();
  params.append(
    'include_automation_value',
    include_automation_value ? 'true' : 'false',
  );
  return await sendAuthRequestWithErrorHandling(
    `${API_URL}ssel/discover/automations?${params.toString()}`,
    {},
    'GET',
  );
};

export const interestedButtonClickedApi = async (
  body: Partial<DiscoverTopic> = {},
): Promise<void> => {
  return await sendAuthRequestWithErrorHandling(
    `${API_URL}ssel/discover/interested-button-clicked`,
    body,
    'POST',
  );
};

export const patchTopicApi = async (
  topicId: string,
  body: PatchTopicRequest = {},
): Promise<DiscoverTopic> => {
  return await sendAuthRequestWithErrorHandling(
    `${API_URL}ssel/discover/topics/${topicId}`,
    body,
    'PATCH',
  );
};

export const patchArticleApi = async (
  articleId: string,
  body: PatchArticleRequest = {},
): Promise<DiscoverArticle> => {
  return await sendAuthRequestWithErrorHandling(
    `${API_URL}ssel/discover/v1/article/${articleId}`,
    body,
    'PATCH',
  );
};

export const getCustomIntentsApi = async (): Promise<CanvasIntentsResponse> => {
  return await sendAuthRequestWithErrorHandling(
    `${API_URL}dashboard-controls/solve/v2/workflow-builder/intents?intent_type=custom&exclude_general_handoff=True`,
    {},
    'GET',
  );
};

export const postReportedTopicApi = async (
  topicId: string,
  body: PostDiscoverReportedTicketRequest,
): Promise<DiscoverReportedTicket> => {
  return await sendAuthRequestWithErrorHandling(
    `${API_URL}ssel/discover/topics/${topicId}/tickets/reported`,
    body,
    'POST',
  );
};

export const getMetadataApi = async (): Promise<DiscoverMetadata> => {
  return await sendAuthRequestWithErrorHandling(
    `${API_URL}ssel/discover/metadata`,
    {},
    'GET',
  );
};

export const getDiscoverFilterValuesAPI = (
  category: string,
  fieldId: string,
  searchParameter?: string,
): Promise<FilterValueResponse> => {
  const filterField = {
    field_category: category?.toLowerCase(),
    field_id: fieldId,
    partial_field_value: searchParameter,
  };

  return sendAuthRequestWithErrorHandling(
    `${API_URL}analytics/filter-values`,
    filterField,
    'POST',
  );
};

export const getDiscoverFilterFieldsAPI = async (): Promise<Fields> => {
  return await sendAuthRequestWithErrorHandling(
    `${API_URL}analytics/filter-fields?curate_fields=True`,
    null,
    'GET',
  );
};

export const getKnowledgeBaseSectionsApi =
  async (): Promise<KnowledgeBaseSections | null> => {
    return await sendAuthRequestWithErrorHandling(
      `${API_URL}ssel/discover/v1/articles/sections`,
      null,
      'GET',
    );
  };

export const getArticleIntegrationsApi =
  async (): Promise<ArticleIntegrations> => {
    return await sendAuthRequestWithErrorHandling(
      `${API_URL}ssel/discover/v1/articles/integrations`,
      null,
      'GET',
    );
  };

export const publishArticleApi = async (
  articleId: string,
  body: DiscoverArticlePublishRequest,
): Promise<DiscoverArticle> => {
  return await sendAuthRequestWithErrorHandling(
    `${API_URL}ssel/discover/v1/article/${articleId}/publish`,
    body,
    'PUT',
  );
};

export const getKnowledgeGapRecommendationsApi =
  async (): Promise<KnowledgeGapArticleRecommendations> => {
    return await sendAuthRequestWithErrorHandling(
      `${API_URL}ssel/discover/v1/articles/recommendations`,
      null,
      'GET',
    );
  };

const discoverApi = dashboardApi.injectEndpoints({
  endpoints: builder => ({
    getArticleTickets: builder.query<{ tickets: DiscoverTicket[] }, string>({
      query: id => ({
        method: 'GET',
        url: `${SSEL_BASE_URL}/discover/v1/article/${id}/tickets`,
      }),
    }),
    getAutomations: builder.query<
      DiscoverAutomationsByTypeResponse[],
      { includeAutomationValue: boolean }
    >({
      query: ({ includeAutomationValue }) => ({
        method: 'GET',
        params: { include_automation_value: includeAutomationValue },
        url: SSEL_BASE_URL + '/discover/automations',
      }),
    }),
    getFilterOptions: builder.query<FilterOption[], void>({
      queryFn: async () => {
        const filterFields = await getDiscoverFilterFieldsAPI();

        // flatten object to a single list and remove non STRING field_types
        const flattenedFilterFields = Object.entries(filterFields)
          .flatMap(([key, value]: [string, (Field & { category: string })[]]) =>
            value.map(value => ({ ...value, category: key })),
          )
          .filter(field => field.field_type === 'STRING');

        const results = await Promise.all(
          flattenedFilterFields.map(async field => {
            const fieldValueChoices = await getDiscoverFilterValuesAPI(
              field.category,
              field.field_id,
            );
            const values = fieldValueChoices?.values ?? [];
            if (values.length) {
              return createOption(field.category, field, values);
            } else {
              return null;
            }
          }),
        );

        return {
          data: results.filter(
            (result): result is FilterOption => result !== null,
          ),
        };
      },
    }),
    getRecommendedPolicies: builder.query<
      DiscoverRecommendationsPoliciesResponse,
      void
    >({
      query: () => ({
        method: 'POST',
        url: `${SSEL_BASE_URL}/discover/v1/policies/recommendations`,
      }),
    }),
  }),
});

export const {
  useGetArticleTicketsQuery,
  useGetAutomationsQuery,
  useGetFilterOptionsQuery,
  useGetRecommendedPoliciesQuery,
} = discoverApi;
