import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';

import { Colors, theme } from '@forethought-technologies/forethought-elements';
import {
  CanvasWorkflowBuilderState,
  clearActionSettings,
  selectIsSqueezingStep,
  setEditingConditionStepId,
  setIsSqueezingStep,
  setMode,
  setShouldSqueezeIntoEntry,
  unsetSelectedCondition,
} from '../../slices/canvas-workflow-builder/workflowBuilderSlice';
import {
  addCondition,
  updateWorkflowStep,
} from '../../slices/canvas-workflow-builder/workflowBuilderSlice.thunks';
import { ConditionEditor } from './conditions/ConditionEditor';
import {
  getCompositeConditionsConfigFromStep,
  getTransitionsFromConfig,
} from './conditions/conditionEditorHelpers';
import { ContextVariableMappingCustomizationPanel } from './customization-panel/ContextVariableMappingCustomizationPanel';
import { DynamicCardSettingsPanel } from './customization-panel/dynamic-card-settings-panel';
import ForethoughtLiveChatHandoffCustomizationPanel from './customization-panel/ForethoughtLiveChatHandoffCustomizationPanel';
import FreshchatChatHandoffCustomizationPanel from './customization-panel/FreshchatChatHandoffCustomizationPanel';
import FreshdeskTicketAndContactCreationCustomizationPanel from './customization-panel/FreshdeskTicketAndContactCreationPanel';
import FreshdeskTicketCreationCustomizationPanel from './customization-panel/FreshdeskTicketCreationCustomizationPanel';
import GorgiasChatHandoffCustomizationPanel from './customization-panel/GorgiasChatHandoffCustomizationPanel';
import GranularArticleSuggestionV2SettingsPanel from './customization-panel/granular-article-suggestion-v2-settings-panel';
import LiveChatChatHandoffCustomizationPanel from './customization-panel/LiveChatChatHandoffCustomizationPanel';
import ParseJwtCustomizationPanel from './customization-panel/ParseJwtCustomizationPanel';
import SalesforceCaseCreationCustomizationPanel from './customization-panel/SalesforceCaseCreationCustomizationPanel';
import SalesforceChatHandoffCustomizationPanel from './customization-panel/SalesforceChatHandoffCustomizationPanel';
import SalesforceMessagingHandoffCustomizationPanel from './customization-panel/SalesforceMessagingHandoffCustomizationPanel';
import SetContextVariableCustomizationPanel from './customization-panel/set-context-variable-settings-panel/SetContextVariableCustomizationPanel';
import SnapEngageChatHandoffCustomizationPanel from './customization-panel/SnapEngageChatHandoffCustomizationPanel';
import SuncoLiveChatCustomizationPanel from './customization-panel/SuncoLiveChatCustomizationPanel';
import TriggerEventCustomizationPanel from './customization-panel/TriggerEventCustomizationPanel';
import { ZendeskAttachmentUploadCustomizationPanel } from './customization-panel/ZendeskAttachmentUploadCustomizationPanel';
import ZendeskMessagingHandoffCustomizationPanel from './customization-panel/ZendeskMessagingHandoffCustomizationPanel';
import ZendeskTicketCreationCustomizationPanel from './customization-panel/ZendeskTicketCreationCustomizationPanel';
import { EmbedVideo } from './embed-video/EmbedVideo';
import { ImagePicker } from './image-picker/ImagePicker';
import ArticlePicker from './article-picker';
import ForethoughtActionsList from './ForethoughtActionsList';
import StyledButton from 'src/components/styled-button';
import { useFlamethrowerTrackingEventAction } from 'src/hooks/hooks';
import { useGetContextVariables } from 'src/hooks/useGetContextVariables';
import { CanvasModes } from 'src/pages/workflow-builder-edit/constants';
import {
  ArticleSuggestionSettingsPanel,
  HyperlinkRedirectCustomizationPanel,
  IntercomHandoffCustomizationPanel,
  JiraServiceManagementCreateRequestCustomizationPanel,
  SalesforceHandoffCustomizationPanel,
  ZendeskChatHandoffCustomizationPanel,
  ZendeskHandoffCustomizationPanel,
} from 'src/pages/workflow-builder-edit/customization-panel';
import CatchAllHandoffCustomizationPanel from 'src/pages/workflow-builder-edit/customization-panel/CatchAllHandoffCustomizationPanel';
import KustomerChatHandoffCustomizationPanel from 'src/pages/workflow-builder-edit/customization-panel/KustomerChatHandoffCustomizationPanel';
import KustomerConversationCreationCustomizationPanel from 'src/pages/workflow-builder-edit/customization-panel/KustomerConversationCreationCustomizationPanel';
import KustomerHandoffCustomizationPanel from 'src/pages/workflow-builder-edit/customization-panel/KustomerHandoffCustomizationPanel';
import {
  CompositeConditionsConfig,
  type ForethoughtCanvasRight,
} from 'src/pages/workflow-builder-edit/types/canvasComponentTypes';
import {
  selectCanvasWorkflowBuilder,
  selectEditingConditionStepId,
  selectIsEditingCondition,
  selectMode,
  selectUndefinedContextVariablesInStep,
} from 'src/reducers/workflowBuilderReducer/workflowBuilderReducer';
import { textMediumStyle } from 'src/styles/styledMixin';
import {
  ActionBuilderActionTypes,
  FlamethrowerTrackingApplications,
  FlamethrowerTrackingEventTypes,
  StepTypes,
} from 'src/utils/enums';

const ForethoughtCanvasRight: React.FC<
  React.PropsWithChildren<ForethoughtCanvasRight>
> = ({
  actionPanelVisibilityParameters,
  actions,
  hasWorkflowConflict,
  onDiscard,
  setActionPanelVisibilityParameters,
  setAreActionSettingsUnsaved,
  shouldSqueezeIntoEntry,
  squeezeStepParentId,
}) => {
  const { palette } = useTheme();

  const dispatch = useDispatch();

  const editingConditionStepId = useSelector(selectEditingConditionStepId);
  const isEditingCondition = useSelector(selectIsEditingCondition);
  const canvasData: CanvasWorkflowBuilderState = useSelector(
    selectCanvasWorkflowBuilder,
  );
  const isSqueezingStep = useSelector(selectIsSqueezingStep);
  const { contextVariables } = useGetContextVariables({
    shouldIncludeSystemContextVariables: true,
  });
  const undefinedCvs =
    useSelector(selectUndefinedContextVariablesInStep)[
      editingConditionStepId
    ] || [];
  const dispatchTrackingAction = useFlamethrowerTrackingEventAction(
    FlamethrowerTrackingApplications.WORKFLOW_BUILDER_CANVAS,
  );
  const mode = useSelector(selectMode);

  const isEditingConditionStep =
    canvasData.steps[editingConditionStepId]?.step_type === StepTypes.CONDITION;

  const isSqueezingConditionMode =
    isSqueezingStep && mode === CanvasModes.CONDITION;
  const onDismiss = () => {
    dispatch(setMode({ contextVariables, mode: CanvasModes.MESSAGE }));

    dispatch(clearActionSettings());

    if (isSqueezingStep) {
      dispatch(setIsSqueezingStep(false));
      dispatch(setShouldSqueezeIntoEntry(false));
    }
  };

  const renderWithContainer = (children: React.ReactNode) => {
    return (
      <Box
        aria-label='Customization panel'
        bgcolor={palette.background.paper}
        height='calc(100vh - 113px)'
        overflow='auto'
        p={3}
        role='dialog'
        sx={[hasWorkflowConflict && { cursor: 'none' }]}
        width='700px'
      >
        {children}
      </Box>
    );
  };

  return renderWithContainer(
    <>
      {actionPanelVisibilityParameters ===
        ActionBuilderActionTypes.ZENDESK_HANDOFF && (
        <ZendeskHandoffCustomizationPanel
          hasWorkflowConflict={hasWorkflowConflict}
          onDiscard={onDiscard}
          onDismiss={onDismiss}
          setActionPanelVisibilityParameters={
            setActionPanelVisibilityParameters
          }
          setAreActionSettingsUnsaved={setAreActionSettingsUnsaved}
        />
      )}
      {/* v1 chat handoff is deprecated, but we still support editing old instances.*/}
      {actionPanelVisibilityParameters ===
        ActionBuilderActionTypes.ZENDESK_CHAT_HANDOFF && (
        <ZendeskChatHandoffCustomizationPanel
          hasWorkflowConflict={hasWorkflowConflict}
          onDiscard={onDiscard}
          onDismiss={onDismiss}
          setActionPanelVisibilityParameters={
            setActionPanelVisibilityParameters
          }
          setAreActionSettingsUnsaved={setAreActionSettingsUnsaved}
        />
      )}
      {actionPanelVisibilityParameters ===
        ActionBuilderActionTypes.ZENDESK_CHAT_HANDOFF_V2 && (
        <ZendeskChatHandoffCustomizationPanel
          hasWorkflowConflict={hasWorkflowConflict}
          onDiscard={onDiscard}
          onDismiss={onDismiss}
          setActionPanelVisibilityParameters={
            setActionPanelVisibilityParameters
          }
          setAreActionSettingsUnsaved={setAreActionSettingsUnsaved}
        />
      )}
      {actionPanelVisibilityParameters ===
        ActionBuilderActionTypes.SUNCO_LIVE_CHAT && (
        <SuncoLiveChatCustomizationPanel
          hasWorkflowConflict={hasWorkflowConflict}
          onDiscard={onDiscard}
          onDismiss={onDismiss}
          setActionPanelVisibilityParameters={
            setActionPanelVisibilityParameters
          }
          setAreActionSettingsUnsaved={setAreActionSettingsUnsaved}
        />
      )}
      {actionPanelVisibilityParameters ===
        ActionBuilderActionTypes.DYNAMIC_CARD && (
        <DynamicCardSettingsPanel
          hasWorkflowConflict={hasWorkflowConflict}
          onDiscard={onDiscard}
          onDismiss={onDismiss}
          setActionPanelVisibilityParameters={
            setActionPanelVisibilityParameters
          }
          setAreActionSettingsUnsaved={setAreActionSettingsUnsaved}
        />
      )}
      {actionPanelVisibilityParameters ===
        ActionBuilderActionTypes.ZENDESK_TICKET_CREATION && (
        <ZendeskTicketCreationCustomizationPanel
          hasWorkflowConflict={hasWorkflowConflict}
          onDiscard={onDiscard}
          onDismiss={onDismiss}
          setActionPanelVisibilityParameters={
            setActionPanelVisibilityParameters
          }
          setAreActionSettingsUnsaved={setAreActionSettingsUnsaved}
        />
      )}
      {actionPanelVisibilityParameters ===
        ActionBuilderActionTypes.SALESFORCE_HANDOFF && (
        <SalesforceHandoffCustomizationPanel
          hasWorkflowConflict={hasWorkflowConflict}
          onDiscard={onDiscard}
          onDismiss={onDismiss}
          setActionPanelVisibilityParameters={
            setActionPanelVisibilityParameters
          }
          setAreActionSettingsUnsaved={setAreActionSettingsUnsaved}
        />
      )}
      {actionPanelVisibilityParameters ===
        ActionBuilderActionTypes.SALESFORCE_CASE_CREATION && (
        <SalesforceCaseCreationCustomizationPanel
          hasWorkflowConflict={hasWorkflowConflict}
          onDiscard={onDiscard}
          onDismiss={onDismiss}
          setActionPanelVisibilityParameters={
            setActionPanelVisibilityParameters
          }
          setAreActionSettingsUnsaved={setAreActionSettingsUnsaved}
        />
      )}
      {actionPanelVisibilityParameters ===
        ActionBuilderActionTypes.SALESFORCE_CHAT_HANDOFF && (
        <SalesforceChatHandoffCustomizationPanel
          hasWorkflowConflict={hasWorkflowConflict}
          onDiscard={onDiscard}
          onDismiss={onDismiss}
          setActionPanelVisibilityParameters={
            setActionPanelVisibilityParameters
          }
          setAreActionSettingsUnsaved={setAreActionSettingsUnsaved}
        />
      )}
      {actionPanelVisibilityParameters ===
        ActionBuilderActionTypes.CATCH_ALL_HANDOFF && (
        <CatchAllHandoffCustomizationPanel
          hasWorkflowConflict={hasWorkflowConflict}
          onDiscard={onDiscard}
          onDismiss={onDismiss}
          setActionPanelVisibilityParameters={
            setActionPanelVisibilityParameters
          }
          setAreActionSettingsUnsaved={setAreActionSettingsUnsaved}
        />
      )}
      {actionPanelVisibilityParameters ===
        ActionBuilderActionTypes.ARTICLE_SUGGESTION && (
        <ArticleSuggestionSettingsPanel
          actionType={ActionBuilderActionTypes.ARTICLE_SUGGESTION}
          onDiscard={onDiscard}
          onDismiss={onDismiss}
          setActionPanelVisibilityParameters={
            setActionPanelVisibilityParameters
          }
          setAreActionSettingsUnsaved={setAreActionSettingsUnsaved}
        />
      )}
      {actionPanelVisibilityParameters ===
        ActionBuilderActionTypes.ARTICLE_SUGGESTION_GRANULAR && (
        <ArticleSuggestionSettingsPanel
          actionType={ActionBuilderActionTypes.ARTICLE_SUGGESTION_GRANULAR}
          onDiscard={onDiscard}
          onDismiss={onDismiss}
          setActionPanelVisibilityParameters={
            setActionPanelVisibilityParameters
          }
          setAreActionSettingsUnsaved={setAreActionSettingsUnsaved}
        />
      )}
      {actionPanelVisibilityParameters ===
        ActionBuilderActionTypes.GRANULAR_ARTICLE_SUGGESTION_V2 && (
        <GranularArticleSuggestionV2SettingsPanel
          hasWorkflowConflict={hasWorkflowConflict}
          onDiscard={onDiscard}
          onDismiss={onDismiss}
          setActionPanelVisibilityParameters={
            setActionPanelVisibilityParameters
          }
          setAreActionSettingsUnsaved={setAreActionSettingsUnsaved}
        />
      )}
      {actionPanelVisibilityParameters ===
        ActionBuilderActionTypes.INTERCOM_HANDOFF && (
        <IntercomHandoffCustomizationPanel
          hasWorkflowConflict={hasWorkflowConflict}
          onDiscard={onDiscard}
          onDismiss={onDismiss}
          setActionPanelVisibilityParameters={
            setActionPanelVisibilityParameters
          }
          setAreActionSettingsUnsaved={setAreActionSettingsUnsaved}
        />
      )}
      {actionPanelVisibilityParameters ===
        ActionBuilderActionTypes.JIRA_SERVICE_MANAGEMENT_CREATE_REQUEST && (
        <JiraServiceManagementCreateRequestCustomizationPanel
          hasWorkflowConflict={hasWorkflowConflict}
          onDiscard={onDiscard}
          onDismiss={onDismiss}
          setActionPanelVisibilityParameters={
            setActionPanelVisibilityParameters
          }
          setAreActionSettingsUnsaved={setAreActionSettingsUnsaved}
        />
      )}
      {actionPanelVisibilityParameters ===
        ActionBuilderActionTypes.HYPERLINK_REDIRECT && (
        <HyperlinkRedirectCustomizationPanel
          hasWorkflowConflict={hasWorkflowConflict}
          onDiscard={onDiscard}
          onDismiss={onDismiss}
          setActionPanelVisibilityParameters={
            setActionPanelVisibilityParameters
          }
          setAreActionSettingsUnsaved={setAreActionSettingsUnsaved}
        />
      )}
      {actionPanelVisibilityParameters ===
        ActionBuilderActionTypes.KUSTOMER_HANDOFF && (
        <KustomerHandoffCustomizationPanel
          hasWorkflowConflict={hasWorkflowConflict}
          onDiscard={onDiscard}
          onDismiss={onDismiss}
          setActionPanelVisibilityParameters={
            setActionPanelVisibilityParameters
          }
          setAreActionSettingsUnsaved={setAreActionSettingsUnsaved}
        />
      )}
      {actionPanelVisibilityParameters ===
        ActionBuilderActionTypes.KUSTOMER_CHAT_HANDOFF && (
        <KustomerChatHandoffCustomizationPanel
          hasWorkflowConflict={hasWorkflowConflict}
          onDiscard={onDiscard}
          onDismiss={onDismiss}
          setActionPanelVisibilityParameters={
            setActionPanelVisibilityParameters
          }
          setAreActionSettingsUnsaved={setAreActionSettingsUnsaved}
        />
      )}
      {actionPanelVisibilityParameters ===
        ActionBuilderActionTypes.KUSTOMER_CONVERSATION_CREATION && (
        <KustomerConversationCreationCustomizationPanel
          hasWorkflowConflict={hasWorkflowConflict}
          onDiscard={onDiscard}
          onDismiss={onDismiss}
          setActionPanelVisibilityParameters={
            setActionPanelVisibilityParameters
          }
          setAreActionSettingsUnsaved={setAreActionSettingsUnsaved}
        />
      )}
      {actionPanelVisibilityParameters ===
        ActionBuilderActionTypes.TRIGGER_EVENT && (
        <TriggerEventCustomizationPanel
          hasWorkflowConflict={hasWorkflowConflict}
          onDiscard={onDiscard}
          onDismiss={onDismiss}
          setActionPanelVisibilityParameters={
            setActionPanelVisibilityParameters
          }
          setAreActionSettingsUnsaved={setAreActionSettingsUnsaved}
        />
      )}
      {actionPanelVisibilityParameters ===
        ActionBuilderActionTypes.FRESHDESK_TICKET_CREATION && (
        <FreshdeskTicketCreationCustomizationPanel
          hasWorkflowConflict={hasWorkflowConflict}
          onDiscard={onDiscard}
          onDismiss={onDismiss}
          setActionPanelVisibilityParameters={
            setActionPanelVisibilityParameters
          }
          setAreActionSettingsUnsaved={setAreActionSettingsUnsaved}
        />
      )}
      {actionPanelVisibilityParameters ===
        ActionBuilderActionTypes.FRESHDESK_TICKET_AND_CONTACT_CREATION && (
        <FreshdeskTicketAndContactCreationCustomizationPanel
          hasWorkflowConflict={hasWorkflowConflict}
          onDiscard={onDiscard}
          onDismiss={onDismiss}
          setActionPanelVisibilityParameters={
            setActionPanelVisibilityParameters
          }
          setAreActionSettingsUnsaved={setAreActionSettingsUnsaved}
        />
      )}
      {actionPanelVisibilityParameters ===
        ActionBuilderActionTypes.SNAPENGAGE_CHAT_HANDOFF && (
        <SnapEngageChatHandoffCustomizationPanel
          hasWorkflowConflict={hasWorkflowConflict}
          onDiscard={onDiscard}
          onDismiss={onDismiss}
          setActionPanelVisibilityParameters={
            setActionPanelVisibilityParameters
          }
          setAreActionSettingsUnsaved={setAreActionSettingsUnsaved}
        />
      )}
      {actionPanelVisibilityParameters ===
        ActionBuilderActionTypes.GORGIAS_CHAT_HANDOFF && (
        <GorgiasChatHandoffCustomizationPanel
          hasWorkflowConflict={hasWorkflowConflict}
          onDiscard={onDiscard}
          onDismiss={onDismiss}
          setActionPanelVisibilityParameters={
            setActionPanelVisibilityParameters
          }
          setAreActionSettingsUnsaved={setAreActionSettingsUnsaved}
        />
      )}
      {actionPanelVisibilityParameters ===
        ActionBuilderActionTypes.FRESHCHAT_CHAT_HANDOFF && (
        <FreshchatChatHandoffCustomizationPanel
          hasWorkflowConflict={hasWorkflowConflict}
          onDiscard={onDiscard}
          onDismiss={onDismiss}
          setActionPanelVisibilityParameters={
            setActionPanelVisibilityParameters
          }
          setAreActionSettingsUnsaved={setAreActionSettingsUnsaved}
        />
      )}
      {actionPanelVisibilityParameters ===
        ActionBuilderActionTypes.ZENDESK_MESSAGING_HANDOFF && (
        <ZendeskMessagingHandoffCustomizationPanel
          hasWorkflowConflict={hasWorkflowConflict}
          onDiscard={onDiscard}
          onDismiss={onDismiss}
          setActionPanelVisibilityParameters={
            setActionPanelVisibilityParameters
          }
          setAreActionSettingsUnsaved={setAreActionSettingsUnsaved}
        />
      )}
      {actionPanelVisibilityParameters ===
        ActionBuilderActionTypes.SALESFORCE_MESSAGING_HANDOFF && (
        <SalesforceMessagingHandoffCustomizationPanel
          hasWorkflowConflict={hasWorkflowConflict}
          onDiscard={onDiscard}
          onDismiss={onDismiss}
          setActionPanelVisibilityParameters={
            setActionPanelVisibilityParameters
          }
          setAreActionSettingsUnsaved={setAreActionSettingsUnsaved}
        />
      )}
      {actionPanelVisibilityParameters ===
        ActionBuilderActionTypes.CONTEXT_VARIABLE_MAPPING && (
        <ContextVariableMappingCustomizationPanel
          hasWorkflowConflict={hasWorkflowConflict}
          onDiscard={onDiscard}
          onDismiss={onDismiss}
          setActionPanelVisibilityParameters={
            setActionPanelVisibilityParameters
          }
          setAreActionSettingsUnsaved={setAreActionSettingsUnsaved}
        />
      )}
      {actionPanelVisibilityParameters ===
        ActionBuilderActionTypes.ZENDESK_ATTACHMENT_UPLOAD && (
        <ZendeskAttachmentUploadCustomizationPanel
          hasWorkflowConflict={hasWorkflowConflict}
          onDiscard={onDiscard}
          onDismiss={onDismiss}
          setActionPanelVisibilityParameters={
            setActionPanelVisibilityParameters
          }
          setAreActionSettingsUnsaved={setAreActionSettingsUnsaved}
        />
      )}
      {actionPanelVisibilityParameters ===
        ActionBuilderActionTypes.LIVE_CHAT_CHAT_HANDOFF && (
        <LiveChatChatHandoffCustomizationPanel
          hasWorkflowConflict={hasWorkflowConflict}
          onDiscard={onDiscard}
          onDismiss={onDismiss}
          setActionPanelVisibilityParameters={
            setActionPanelVisibilityParameters
          }
          setAreActionSettingsUnsaved={setAreActionSettingsUnsaved}
        />
      )}
      {actionPanelVisibilityParameters ===
        ActionBuilderActionTypes.SET_CONTEXT_VARIABLE && (
        <SetContextVariableCustomizationPanel
          hasWorkflowConflict={hasWorkflowConflict}
          onDiscard={onDiscard}
          onDismiss={onDismiss}
          setActionPanelVisibilityParameters={
            setActionPanelVisibilityParameters
          }
          setAreActionSettingsUnsaved={setAreActionSettingsUnsaved}
        />
      )}
      {actionPanelVisibilityParameters ===
        ActionBuilderActionTypes.FORETHOUGHT_LIVE_CHAT_HANDOFF && (
        <ForethoughtLiveChatHandoffCustomizationPanel
          hasWorkflowConflict={hasWorkflowConflict}
          onDiscard={onDiscard}
          onDismiss={onDismiss}
          setActionPanelVisibilityParameters={
            setActionPanelVisibilityParameters
          }
          setAreActionSettingsUnsaved={setAreActionSettingsUnsaved}
        />
      )}
      {mode === CanvasModes.ACTION && (
        <ForethoughtActionsList
          actions={actions}
          hasWorkflowConflict={hasWorkflowConflict}
          setActionPanelVisibilityParameters={
            setActionPanelVisibilityParameters
          }
          squeezeStepParentId={squeezeStepParentId}
        />
      )}
      {mode === CanvasModes.ARTICLE_PICKER && (
        <>
          <ArticlePicker
            hasWorkflowConflict={hasWorkflowConflict}
            isDisabled={canvasData.loading}
            setActionPanelVisibilityParameters={
              setActionPanelVisibilityParameters
            }
            shouldSqueezeIntoEntry={shouldSqueezeIntoEntry}
            squeezeStepParentId={squeezeStepParentId}
          />
          <Separator />
          <CancelButtonWrapper>
            <CancelButton
              onClick={() => {
                dispatch(
                  setMode({ contextVariables, mode: CanvasModes.MESSAGE }),
                );
                dispatch(setIsSqueezingStep(false));
              }}
              type='button'
            >
              Cancel
            </CancelButton>
          </CancelButtonWrapper>
        </>
      )}
      {mode === CanvasModes.CONDITION && !isEditingCondition && (
        <ConditionEditor
          contextVariables={contextVariables}
          hasWorkflowConflict={hasWorkflowConflict}
          onCancel={() => {
            dispatch(setMode({ contextVariables, mode: CanvasModes.MESSAGE }));
            dispatch(setIsSqueezingStep(false));
          }}
          onSave={compositeConditionsConfig => {
            dispatchTrackingAction(
              FlamethrowerTrackingEventTypes.WORKFLOW_ADDED_CONDITIONS_STEP,
              { workflow_id: canvasData.intent_workflow_id ?? '' },
            );
            if (isSqueezingConditionMode) {
              const conditionData: {
                compositeConditionsConfig: CompositeConditionsConfig;
                shouldSqueezeIntoEntry: boolean;
                squeezeStepParentId: string;
              } = {
                compositeConditionsConfig: compositeConditionsConfig,
                shouldSqueezeIntoEntry: shouldSqueezeIntoEntry,
                squeezeStepParentId: squeezeStepParentId,
              };
              dispatch(addCondition(conditionData));
              dispatch(setIsSqueezingStep(false));
            } else {
              dispatch(
                addCondition({
                  compositeConditionsConfig: compositeConditionsConfig,
                }),
              );
            }
            dispatch(setMode({ contextVariables, mode: CanvasModes.MESSAGE }));
          }}
          undefinedCvs={undefinedCvs}
        />
      )}
      {isEditingCondition && (
        <ConditionEditor
          compositeConditionsConfig={getCompositeConditionsConfigFromStep(
            canvasData.steps[editingConditionStepId],
            contextVariables,
            isEditingConditionStep,
          )}
          contextVariables={contextVariables}
          hasWorkflowConflict={hasWorkflowConflict}
          onCancel={() => {
            dispatch(setEditingConditionStepId(''));
            dispatch(setMode({ contextVariables, mode: CanvasModes.MESSAGE }));
          }}
          onSave={compositeConditionsConfig => {
            dispatchTrackingAction(
              FlamethrowerTrackingEventTypes.WORKFLOW_STEP_EDITED,
              {
                stepId: editingConditionStepId,
                stepType: StepTypes.CONDITION,
              },
            );
            dispatch(
              updateWorkflowStep({
                condition_name: isEditingConditionStep
                  ? null
                  : compositeConditionsConfig.conditionName,

                step_fields: isEditingConditionStep
                  ? { condition_name: compositeConditionsConfig.conditionName }
                  : canvasData.steps[editingConditionStepId].step_fields,

                stepId: editingConditionStepId,

                transitions: getTransitionsFromConfig(
                  compositeConditionsConfig,
                ),
              }),
            );

            // Unselect the selected condition if the amount of conditions was
            // changed:
            if (
              canvasData.steps[editingConditionStepId].transitions.length !==
              compositeConditionsConfig.compositeConditions.length
            ) {
              dispatch(unsetSelectedCondition(editingConditionStepId));
            }

            dispatch(setEditingConditionStepId(''));
            dispatch(setMode({ contextVariables, mode: CanvasModes.MESSAGE }));
          }}
          undefinedCvs={undefinedCvs}
        />
      )}
      {mode === CanvasModes.IMAGE && (
        <ImagePicker
          hasWorkflowConflict={hasWorkflowConflict}
          onDiscard={onDiscard}
          shouldSqueezeIntoEntry={shouldSqueezeIntoEntry}
          squeezeStepParentId={squeezeStepParentId}
        />
      )}
      {mode === CanvasModes.EMBED && (
        <EmbedVideo
          hasWorkflowConflict={hasWorkflowConflict}
          onDiscard={onDiscard}
          shouldSqueezeIntoEntry={shouldSqueezeIntoEntry}
          squeezeStepParentId={squeezeStepParentId}
        />
      )}
      {actionPanelVisibilityParameters ===
        ActionBuilderActionTypes.PARSE_JWT && (
        <ParseJwtCustomizationPanel
          hasWorkflowConflict={hasWorkflowConflict}
          onDiscard={onDiscard}
          onDismiss={onDismiss}
          setActionPanelVisibilityParameters={
            setActionPanelVisibilityParameters
          }
          setAreActionSettingsUnsaved={setAreActionSettingsUnsaved}
        />
      )}
    </>,
  );
};

export default ForethoughtCanvasRight;

export const CancelButtonWrapper = styled('div')`
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
`;

export const CancelButton = styled(StyledButton)`
  ${textMediumStyle({ fontSize: '15px' })}
  background-color: transparent;
  color: ${Colors.ui.text.primary};
  font-size: 16px;
  cursor: pointer;
  padding: 6px 12px;
  margin-right: 5px;
  &:hover {
    background: ${theme.palette.colors.grey[100]};
  }
`;

export const Separator = styled('div')`
  width: 100%;
  height: 1px;
  background: ${theme.palette.colors.grey[100]};
`;
