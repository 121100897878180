import React from 'react';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { IconInfoCircle } from '@tabler/icons-react';

import { Tooltip } from '@forethought-technologies/forethought-elements';
import { NA } from 'src/constants/solve';

interface InnerDataOverviewCardProps {
  appCuesTarget?: string;
  bottomRow?: React.ReactNode;
  cardLabel: React.ReactNode;
  cardValue: React.ReactNode;
  isChild?: boolean;
  isParent?: boolean;
  labelColor?: string;
  smallTitle?: boolean;
  tooltipContent?: string;
}

export default function InnerDataOverviewCard({
  appCuesTarget,
  bottomRow,
  cardLabel,
  cardValue,
  isChild = false,
  isParent = false,
  labelColor,
  tooltipContent,
}: InnerDataOverviewCardProps) {
  const getTitleTypography = () => {
    if (isChild) {
      return 'font12Medium';
    }

    return 'font14Bold';
  };

  const label = (
    <LabelContainer>
      <Box
        sx={theme => ({
          ...theme.typography[getTitleTypography()],
          color: isChild
            ? theme.palette.colors.grey[700]
            : theme.palette.colors.grey[800],
          overflow: 'hidden',
          'text-overflow': 'ellipsis',
          'text-transform': isChild ? undefined : 'capitalize',
          textWrap: isChild ? 'wrap' : undefined,
        })}
        title={typeof cardLabel === 'string' ? cardLabel : undefined}
      >
        {cardLabel}
      </Box>
      {tooltipContent && (
        <Tooltip tooltipContent={tooltipContent}>
          <IconInfoCircle color='currentColor' height={16} width={16} />
        </Tooltip>
      )}
    </LabelContainer>
  );

  return (
    <Box
      data-appcues-target={appCuesTarget}
      sx={{
        alignItems: 'flex-start',
        display: 'flex',
        flex: isChild ? 1 : undefined,
        flexDirection: 'column',
        gap: '4px',
        padding: isChild ? 0 : '4px 16px',
      }}
    >
      {!isChild && label}
      {isParent ? (
        <Box display='flex' gap={3}>
          {cardValue}
        </Box>
      ) : (
        <>
          {/* TODO: replace this with typography */}
          <Box
            sx={
              bottomRow
                ? {}
                : {
                    '.MuiTypography-root': {
                      fontSize: '24px',
                      lineHeight: '32px',
                    },
                  }
            }
          >
            <Typography
              color={theme =>
                cardValue === NA ? theme.palette.colors.grey[600] : labelColor
              }
              variant='font24'
            >
              <LineHeightSpan>{cardValue}</LineHeightSpan>
            </Typography>
            {isChild && label}
          </Box>
        </>
      )}

      {bottomRow}
    </Box>
  );
}

const LabelContainer = styled('div')`
  display: flex;
  align-items: center;
  gap: 4px;
  white-space: nowrap;
  max-width: 100%;
`;

const LineHeightSpan = styled('span')`
  line-height: 44px;
`;
