import { useCallback, useMemo, useRef } from 'react';
import { Box, styled } from '@mui/material';

import {
  Tooltip,
  Typography,
} from '@forethought-technologies/forethought-elements';
import NodeCvDisplayerRow from './NodeCvDisplayerRow';
import { useGetContextVariables } from 'src/hooks/useGetContextVariables';

interface NodeContextVariableDisplayerProps {
  inputContextVariableIds: Array<string>;
  nodeName: string;
  nodeType: string;
  outputContextVariableIds: Array<string>;
}

const NodeContextVariableDisplayer = ({
  inputContextVariableIds,
  nodeName,
  nodeType,
  outputContextVariableIds,
}: NodeContextVariableDisplayerProps) => {
  const inputCvsRef = useRef<HTMLDivElement>(null);
  const outputCvsRef = useRef<HTMLDivElement>(null);

  const { contextVariables } = useGetContextVariables({
    shouldIncludeSystemContextVariables: true,
    shouldIncludeTriageContextVariables: true,
  });

  const getContextVariableName = useCallback(
    (contextVariableId: string) => {
      return contextVariables.find(
        cv =>
          cv.context_variable_id === contextVariableId ||
          `[[${cv.context_variable_id}]]` === contextVariableId,
      )?.context_variable_name;
    },
    [contextVariables],
  );

  const getFormattedInput = useCallback(
    (contextVariableIds: Array<string>) => {
      let formattedText = '';
      contextVariableIds.forEach((cv, index) => {
        const isLastItem = index === contextVariableIds.length - 1;
        const isArrayConformedByOneItem = contextVariableIds.length === 1;
        const shouldAddComa = !isLastItem && !isArrayConformedByOneItem;

        formattedText = `${formattedText}$${getContextVariableName(cv)}${
          shouldAddComa ? ', ' : ''
        }`;
      });
      return formattedText;
    },
    [getContextVariableName],
  );

  const formattedInput = useMemo(() => {
    return getFormattedInput(inputContextVariableIds);
  }, [inputContextVariableIds, getFormattedInput]);

  const formattedOutput = useMemo(() => {
    return getFormattedInput(outputContextVariableIds);
  }, [outputContextVariableIds, getFormattedInput]);

  const getToolTipContent = () => {
    const {
      clientHeight: inputCvsClientHeight = 0,
      scrollHeight: inputCvsScrollHeight = 0,
    } = inputCvsRef?.current || {};

    const {
      clientHeight: outputCvsClientHeight = 0,
      scrollHeight: outputCvsScrollHeight = 0,
    } = outputCvsRef?.current || {};

    const shouldReturnContent =
      inputCvsClientHeight < inputCvsScrollHeight ||
      outputCvsClientHeight < outputCvsScrollHeight;

    const content = shouldReturnContent ? (
      <Box display='flex' flexDirection='column' gap='10px'>
        <Typography variant='font12'>
          <CapitalizedText>{nodeType}:</CapitalizedText>
          {` ${nodeName}`}
        </Typography>
        {!!inputContextVariableIds.length && (
          <NodeCvDisplayerRow
            message={formattedInput}
            shouldAddDefaultTitleColor
            shouldAddLineClamp={false}
            title='Input'
          />
        )}
        {!!outputContextVariableIds.length && (
          <NodeCvDisplayerRow
            message={formattedOutput}
            shouldAddDefaultTitleColor
            shouldAddLineClamp={false}
            title='Output'
          />
        )}
      </Box>
    ) : (
      ''
    );

    return content;
  };

  return (
    <Tooltip placement='left' tooltipContent={getToolTipContent()}>
      <Box display='flex' flexDirection={'column'} gap='4px'>
        {!!inputContextVariableIds.length && (
          <NodeCvDisplayerRow
            message={formattedInput}
            ref={inputCvsRef}
            title='Input'
          />
        )}
        {!!outputContextVariableIds.length && (
          <NodeCvDisplayerRow
            message={formattedOutput}
            ref={outputCvsRef}
            title='Output'
          />
        )}
      </Box>
    </Tooltip>
  );
};

export default NodeContextVariableDisplayer;

const CapitalizedText = styled('span')`
  text-transform: capitalize;
`;
