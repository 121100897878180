import { useCallback, useMemo, useState } from 'react';
import { MRT_TableOptions, MRT_VisibilityState } from 'material-react-table';
import { useNavigate } from 'react-router';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';

import {
  Button,
  DateRangeFilterButton,
  InfiniteTable,
  Order,
  SearchBar,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { DateRange } from '../../discover-dashboard-page/types';
import InsightDownloadCsv from '../common/InsightDownloadCsv';
import { initialInsightParams } from '../constants';
import {
  dateRangeToTimestamp,
  sortDirectionToInsightSortDirection,
} from '../helpers';
import { useGetSearchQuery } from '../hooks/useGetSearchQuery';
import { InsightSortDirection, Scope } from '../types';
import {
  buildEmailArticleColumns,
  EmailArticleTableDataFilter,
} from './helpers';
import { useStateParams } from 'src/hooks/hooks';
import { TOTAL_STICKY_HEADERS_HEIGHT_ARTICLES } from 'src/pages/intent-conversation-analytics/constants';
import { useGetEmailArticleTableQuery } from 'src/services/insights';
import {
  InsightEmailArticle,
  InsightEmailArticleSortColumns,
} from 'src/services/insights/types';
import { setSelectedArticleId } from 'src/slices/solve-insights/solveInsightsSlice';
import { useAppDispatch } from 'src/store/hooks';
import {
  dateRangeToTimeFilter,
  genericSerializeAndDeserialize,
} from 'src/utils/discover/helpers';
import { ExportableTableType, Routes } from 'src/utils/enums';
import { datePickerRangeOptionsRevamp } from 'src/utils/timeRangeHelpers';

interface EmailArticlesTableProps {
  dateRange: DateRange;
  scope: Scope;
  setDateRange: (dateRange: DateRange) => void;
}

const EmailArticlesTable = ({
  dateRange,
  scope,
  setDateRange,
}: EmailArticlesTableProps) => {
  const { palette } = useTheme();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [page, setPage] = useState(1);

  const deserializeSortDirection = useCallback(
    (param: string) => (param === 'ASC' ? 'ASC' : 'DESC'),
    [],
  );

  const deserializeSortColumn = useCallback(
    (param: string) => param as InsightEmailArticleSortColumns,
    [],
  );

  // state params
  const [sortDirection, setSortDirection] =
    useStateParams<InsightSortDirection>({
      deserialize: deserializeSortDirection,
      initialState: 'DESC',
      paramsName: 'article_sort_direction',
      serialize: String,
    });
  const [searchQuery, setSearchQuery] = useStateParams<string>({
    deserialize: genericSerializeAndDeserialize,
    initialState: '',
    paramsName: 'articles_search',
    serialize: genericSerializeAndDeserialize,
  });
  const [sortColumn, setSortColumn] =
    useStateParams<InsightEmailArticleSortColumns>({
      deserialize: deserializeSortColumn,
      initialState: initialInsightParams.emailArticleColumnSort,
      paramsName: 'articles_sort_column',
      serialize: String,
    });

  // end state params
  const { setUiQuery, uiQuery } = useGetSearchQuery({
    scope,
    searchQuery,
    setSearchQuery,
    tab: 'article',
    type: 'article',
  });

  const replaceId = (path: string, id: string) => {
    return path.replace(':articleId', id);
  };

  const handleSetSelectedArticleId = useCallback(
    (articleId: string) => dispatch(setSelectedArticleId(articleId)),
    [dispatch],
  );

  const columns = useMemo(
    () =>
      buildEmailArticleColumns(palette, handleSetSelectedArticleId, navigate),
    [palette, handleSetSelectedArticleId, navigate],
  );

  const backendReadyTimestamps = dateRangeToTimestamp(dateRange);

  const {
    data: articleTableData,
    isError: isArticleTableError,
    isFetching: isArticleTableFetching,
    isLoading: isArticleTableLoading,
  } = useGetEmailArticleTableQuery({
    data_export_type: ExportableTableType.INSIGHT_EMAIL_ARTICLES_TABLE,
    end: backendReadyTimestamps.end_timestamp,
    page,
    sort_column: sortColumn,
    sort_direction: sortDirection,
    start: backendReadyTimestamps.start_timestamp,
  });

  const rows: InsightEmailArticle[] = useMemo(() => {
    if (!articleTableData?.data) {
      return [];
    }

    const filter = new EmailArticleTableDataFilter(articleTableData?.data);
    return filter.filterByKeyword(uiQuery);
  }, [uiQuery, articleTableData?.data]);

  const timeFilter = dateRangeToTimeFilter(dateRange);
  const initialTimeFilter = dateRangeToTimeFilter(initialInsightParams.date);
  const showLoadingSkeleton =
    isArticleTableLoading || (page === 1 && isArticleTableFetching);

  const areFiltersUsed = Boolean(
    uiQuery.length || timeFilter.key !== initialTimeFilter.key,
  );

  const getArticleTableDownloadUrl = useCallback(() => {
    const url = new URL(`${API_URL}data-export`);
    return url.href;
  }, []);

  const handleLoadMore = useCallback(() => {
    const nextPage = page + 1;
    setPage(nextPage);
  }, [page]);

  const handleSortCallback = useCallback(
    (property: keyof InsightEmailArticle, order: Order) => {
      setPage(1);
      setSortDirection(sortDirectionToInsightSortDirection(order));
      setSortColumn(
        property === 'user_feedback_positive'
          ? 'user_feedback'
          : (property as InsightEmailArticleSortColumns),
      );
    },
    [setSortColumn, setSortDirection],
  );

  const initialSorting = useMemo(
    () => [
      {
        desc: sortDirection === 'DESC',
        id: sortColumn,
      },
    ],
    [sortColumn, sortDirection],
  );

  const [columnVisibility, setColumnVisibility] = useState<MRT_VisibilityState>(
    Object.fromEntries(
      initialInsightParams.emailArticleColumns.map(column => [column, true]),
    ),
  );

  const tableOptions = useMemo<MRT_TableOptions<InsightEmailArticle>>(
    () => ({
      columns: columns,
      data: rows,
      enableColumnActions: false,
      enableColumnFilters: false,
      enableExpanding: false,
      enableFullScreenToggle: false,
      enableGlobalFilter: false,
      enableRowActions: false,
      initialState: {
        columnPinning: {
          left: ['title'],
        },
      },
      muiTableBodyRowProps: ({ row }) => ({
        onClick: () => {
          navigate({
            pathname: replaceId(
              Routes.SOLVE_INSIGHTS_ARTICLE_DETAIL,
              row.original.id,
            ),
            search: '?tab=article',
          });
        },
        sx: {
          cursor: 'pointer',
        },
      }),
      onColumnVisibilityChange: setColumnVisibility,
      renderTopToolbarCustomActions: () => (
        <Box display='flex' gap='8px' paddingTop='4px' width='100%'>
          <Box key={1} width='200px'>
            <SearchBar
              onChange={e => setUiQuery(e.target.value)}
              placeholder='Search article'
              size='small'
              value={uiQuery}
            />
          </Box>
          <DateRangeFilterButton
            explicitLabel={true}
            initialValue={initialInsightParams.date}
            onChange={value => setDateRange(value)}
            options={datePickerRangeOptionsRevamp}
            value={dateRange}
          />
          {areFiltersUsed && (
            <Button
              onClick={() => {
                setUiQuery('');
                setDateRange(initialInsightParams.date);
                setSortColumn(initialInsightParams.emailArticleColumnSort);
              }}
              size='medium'
              variant='ghost'
            >
              <Typography noWrap variant='font14Medium'>
                Reset filters
              </Typography>
            </Button>
          )}
          <Box
            sx={{
              display: 'flex',
              flex: 1,
              justifyContent: 'flex-end',
            }}
          >
            <InsightDownloadCsv
              filename='email_articles.csv'
              requestData={{
                data_export_type:
                  ExportableTableType.INSIGHT_EMAIL_ARTICLES_TABLE,
                end: backendReadyTimestamps.end_timestamp,
                start: backendReadyTimestamps.start_timestamp,
              }}
              url={getArticleTableDownloadUrl()}
            />
          </Box>
        </Box>
      ),
      state: {
        columnVisibility,
      },
    }),
    [
      columns,
      rows,
      columnVisibility,
      navigate,
      uiQuery,
      dateRange,
      areFiltersUsed,
      getArticleTableDownloadUrl,
      setUiQuery,
      setDateRange,
      setSortColumn,
      backendReadyTimestamps.end_timestamp,
      backendReadyTimestamps.start_timestamp,
    ],
  );

  return (
    <InfiniteTable
      hasNextPage={typeof articleTableData?.metadata.next_page === 'number'}
      initialSorting={initialSorting}
      isError={isArticleTableError}
      isLoadingFirstPage={showLoadingSkeleton}
      isLoadingNextPage={isArticleTableFetching}
      onLoadMore={handleLoadMore}
      onSortCallback={handleSortCallback}
      stickyHeaderHeight={TOTAL_STICKY_HEADERS_HEIGHT_ARTICLES}
      tableOptions={tableOptions}
    />
  );
};

export default EmailArticlesTable;
