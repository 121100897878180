import { useMemo, useState } from 'react';
import { styled } from '@mui/material';
import { IconPlus } from '@tabler/icons-react';

import {
  Button,
  SelectDropdown,
} from '@forethought-technologies/forethought-elements';
import { getCreateIntentUrl, getWorkflowBuilderUrl, openTab } from './helpers';
import { useGetIntentsQueryWithProduct } from 'src/hooks/hooks';
import { isIntentEditable } from 'src/utils/solve/intentUtils';

export const UpdateIntentDialog = ({ userQuery }: { userQuery: string }) => {
  // fetch all intents for dropdown
  const { data: intentsResponse } = useGetIntentsQueryWithProduct();
  const [selectedIntentId, setSelectedIntentId] = useState('');

  const intentOptions = useMemo(() => {
    if (!intentsResponse) {
      return [];
    }

    return intentsResponse.intents
      .filter(intent => isIntentEditable(intent.intent_definition_id))
      .map(intent => ({
        label: intent.intent_name,
        value: intent.intent_definition_id,
      }));
  }, [intentsResponse]);

  const handleCreateIntent = () => {
    if (!userQuery) {
      return;
    }
    const url = getCreateIntentUrl(userQuery, 'widget');
    openTab(url);
  };

  const assignPhraseToSelectedIntent = () => {
    const intent = intentsResponse?.intents.find(
      intent => intent.intent_definition_id === selectedIntentId,
    );
    if (!intent || !userQuery) {
      return;
    }
    const url = getWorkflowBuilderUrl({
      channel: 'widget',
      initialIntentPhrase: userQuery,
      intentDefinitionId: intent.intent_definition_id,
      intentWorkflowId: intent.intent_workflow_id,
    });
    openTab(url);
  };

  return (
    <>
      <Button
        fullWidth
        onClick={handleCreateIntent}
        startIcon={<IconPlus size={16} />}
        variant='secondary'
      >
        Define a New Intent
      </Button>
      <Form
        onSubmit={e => {
          e.preventDefault();
          assignPhraseToSelectedIntent();
        }}
      >
        <SelectDropdown
          id='assign-intent-selector'
          isMenuSearchable
          menuMaxHeight={360}
          onChange={e => setSelectedIntentId(e.target.value)}
          options={intentOptions}
          placeholder='Assign to an existing intent'
          value={selectedIntentId}
        />
        <Button
          disabled={!selectedIntentId}
          fullWidth
          type='submit'
          variant='main'
        >
          Assign
        </Button>
      </Form>
    </>
  );
};

const Form = styled('form')`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
