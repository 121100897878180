import { useState } from 'react';
import { Add } from '@mui/icons-material';
import Box from '@mui/material/Box';

import { Button, Drawer } from '@forethought-technologies/forethought-elements';
import ManageModelForm from '../triage-model-detail-page/drawers-and-dialogs/ManageModelForm';
import { TRIAGE_SUPPORTED_HELPDESKS } from './constants';
import useSelfServeEvents from 'src/hooks/triage/useSelfServeEvents';
import { triageApi } from 'src/services/triage/triageApi';
import { TRIAGE_LLM_TRACKING_EVENTS } from 'src/utils/constants';

const CreateModel = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { data: helpdeskData } = triageApi.useGetHelpdeskQuery();
  const emitTrackingEventCallback = useSelfServeEvents({});

  const handleButtonClick = () => {
    setIsOpen(true);
    emitTrackingEventCallback(TRIAGE_LLM_TRACKING_EVENTS.CREATE_MODEL_CLICKED);
  };

  const handleClose = () => {
    setIsOpen(false);
  };
  const helpdeskName = helpdeskData?.helpdesk;

  if (!helpdeskName || !TRIAGE_SUPPORTED_HELPDESKS.includes(helpdeskName)) {
    return null;
  }

  return (
    <>
      <Box display='flex' justifyContent='flex-end'>
        <Button
          color='primary'
          onClick={handleButtonClick}
          size='large'
          startIcon={<Add />}
          variant='main'
        >
          Create Model
        </Button>
      </Box>
      <Drawer isOpen={isOpen} onClose={handleClose}>
        <ManageModelForm onClose={handleClose} />
      </Drawer>
    </>
  );
};

export default CreateModel;
