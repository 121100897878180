import { useMemo } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { IconInfoCircle } from '@tabler/icons-react';

import { Tooltip } from '@forethought-technologies/forethought-elements';
import { DateRange } from '../../discover-dashboard-page/types';
import { EmptyStateCard } from '../articles/EmptyStateCard';
import { CHAT_TRENDS_TOOLTIP_COPY, DONUT_SIZE } from '../constants';
import {
  dateRangeToTimestamp,
  numbersToStringWithCommas,
  percentageValueAsString,
  stringifyDateRange,
} from '../helpers';
import GraphCenterElement from '../overview/GraphCenterElement';
import { OverviewCardInformationSection } from '../overview/OverviewCardInformationSection';
import { InsightsTab, ProductMode } from '../types';
import InsightCard from './InsightCard';
import { BarGraphWrapper } from 'src/components/solve-insights-charts/bar-graph/barGraphUtils';
import DonutGraph from 'src/components/solve-insights-charts/donut-graph.tsx/DonutGraph';
import { useGetFeatureFlagsQuery } from 'src/services/dashboard-api';
import { useGetQuickFeedbackQuery } from 'src/services/insights';
import { QuickFeedbackResponse } from 'src/services/insights/types';

interface QuickFeedbackCardProps {
  dateRange: DateRange;
  productMode?: ProductMode;
  tab?: InsightsTab;
}

const QuickFeedbackCard = ({
  dateRange,
  productMode = 'widget',
  tab,
}: QuickFeedbackCardProps) => {
  const isTopicsPage = tab === 'topic';
  const isWorkflowPage = tab === 'workflow';
  const isEmail = productMode === 'email';
  const showComparison = tab === 'overview';
  const { data: featureFlagsData } = useGetFeatureFlagsQuery();
  const { feature_flags: featureFlags = [] } = featureFlagsData ?? {};
  const isQuickFeedbackEnabled = featureFlags.includes('quick_feedback');
  const isQuickFeedbackAnalyticsEnabled = featureFlags.includes(
    'show_quick_feedback_analytics',
  );
  const shouldShowQuickFeedbackAnalytics =
    isQuickFeedbackEnabled && isQuickFeedbackAnalyticsEnabled;
  const theme = useTheme();
  const { palette } = theme;
  const colors = [palette.colors.green[500], palette.colors.red[500]];
  const timestamps = dateRangeToTimestamp(dateRange);
  const { data, isFetching, isLoading } = useGetQuickFeedbackQuery({
    ...timestamps,
    is_topics_page: isTopicsPage || false,
    is_workflow_page: isWorkflowPage || false,
    product: productMode,
    to_compare: showComparison || isEmail,
  });

  const { negativeValues, notAnsweredValues, positiveValues } = useMemo(() => {
    return {
      negativeValues: data?.misc?.negative || 0,
      notAnsweredValues: data?.misc?.not_answered || 0,
      positiveValues: data?.misc?.positive || 0,
    };
  }, [data]);

  const quickFeedbackValues = useMemo(
    () => [
      {
        label: 'positive',
        value: positiveValues,
      },
      { label: 'negative', value: negativeValues },
      { label: 'not answered', value: notAnsweredValues },
    ],
    [positiveValues, negativeValues, notAnsweredValues],
  );

  const totalFeedback = positiveValues + negativeValues;

  const isQuickFeedbackDataAvailable = useMemo(
    () => data && totalFeedback > 0,
    [data, totalFeedback],
  );
  const donutValues = useMemo(() => data?.donut.values, [data]);

  const donutGraphValues = useMemo(() => {
    return {
      ...data?.donut,
      values:
        data?.donut?.values
          ?.filter?.(value => value.label !== 'not answered')
          ?.map?.(value => {
            const quickFeedbackData = quickFeedbackValues.find(
              quickFeedbackValue => quickFeedbackValue.label === value.label,
            );
            const quickFeedbackValue = quickFeedbackData?.value || 0;

            return {
              ...value,
              value:
                totalFeedback === 0
                  ? 0
                  : (quickFeedbackValue * 100) / totalFeedback,
            };
          }) || [],
    };
  }, [data, quickFeedbackValues, totalFeedback]);

  if (!shouldShowQuickFeedbackAnalytics) {
    return null;
  }

  return (
    <InsightCard
      headerRightText={
        showComparison ? stringifyDateRange(dateRange) : undefined
      }
      isFetching={isFetching}
      isLoading={isLoading}
      title={data?.misc?.title}
      tooltip={
        <Tooltip
          maxWidth={320}
          tooltipContent={CHAT_TRENDS_TOOLTIP_COPY.quick_feedback}
        >
          <IconInfoCircle color='currentColor' height={16} width={16} />
        </Tooltip>
      }
    >
      {isQuickFeedbackDataAvailable ? (
        <Box alignItems='center' display='flex'>
          {!showComparison && (
            <Box
              display='flex'
              flex={1}
              flexDirection='column'
              gap='16px'
              pt='20px'
            >
              {donutValues?.map((donutValue, index) => (
                <Box
                  display='flex'
                  flexDirection='column'
                  gap='2px'
                  key={index}
                >
                  <Typography variant='font16Bold'>
                    {numbersToStringWithCommas({
                      number: quickFeedbackValues[index].value,
                    })}
                  </Typography>
                  <Typography variant='font14'>
                    {donutValue.label.toLowerCase()}
                  </Typography>
                </Box>
              ))}
            </Box>
          )}
          <Box display='flex' flex={showComparison ? 0 : 1}>
            <DonutGraph
              centerElement={
                <GraphCenterElement
                  theme={theme}
                  value={data?.misc?.delta_value}
                />
              }
              centerSubText={data?.misc?.positive_label?.toLowerCase() || ''}
              centerText={numbersToStringWithCommas({
                number:
                  totalFeedback === 0 ? 0 : positiveValues / totalFeedback,
                style: 'percent',
              })}
              colors={colors}
              containerProps={{
                style: { height: `${DONUT_SIZE}px`, width: `${DONUT_SIZE}px` },
              }}
              data={donutGraphValues}
              disableTooltip
            />
          </Box>
          {showComparison && (
            <Box display='flex' flex={1} flexDirection='column' gap='20px'>
              <Box alignItems='center' display='flex' gap='16px'>
                <Box display='flex' flexDirection='column' gap='16px'>
                  <Typography variant='font14'>Total responses</Typography>
                  <Typography variant='font14'>Not answered</Typography>
                </Box>
                <Box display='flex' flexDirection='column' gap='16px'>
                  <Box alignItems='flex-end' display='flex' gap='16px'>
                    <Typography variant='font16Bold'>
                      {numbersToStringWithCommas({
                        number: totalFeedback,
                      })}
                    </Typography>
                    {typeof data?.misc?.delta_total_response === 'number' && (
                      <Typography
                        color={
                          data?.misc?.delta_total_response >= 0
                            ? palette.colors.green[500]
                            : palette.colors.red[500]
                        }
                        variant='font14Medium'
                      >
                        {percentageValueAsString(
                          data.misc.delta_total_response,
                        )}
                      </Typography>
                    )}
                  </Box>
                  <Typography variant='font16Bold'>
                    {numbersToStringWithCommas({
                      number: notAnsweredValues,
                    })}
                  </Typography>
                </Box>
              </Box>
              <BarGraphWrapper
                data={data as QuickFeedbackResponse}
                isComparing={showComparison}
                palette={palette}
                showTooltip={showComparison}
              />
            </Box>
          )}
        </Box>
      ) : (
        <EmptyStateCard variant='feedback' />
      )}
      {showComparison && <OverviewCardInformationSection />}
    </InsightCard>
  );
};

export default QuickFeedbackCard;
