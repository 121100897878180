import { DiscoverTopic } from 'src/reducers/discoverReducer/types';

export const TABLE_COLUMNS: {
  key: keyof DiscoverTopic;
  size: number;
  target?: 'cost' | 'volume';
  title: string;
}[] = [
  {
    key: 'topic_name',
    size: 300,
    title: 'Topic',
  },
  {
    key: 'actionable_value',
    size: 200,
    target: 'volume',
    title: 'Ticket reduction/year',
  },
  {
    key: 'actionable_value',
    size: 200,
    target: 'cost',
    title: 'Cost reduction/year',
  },
];
