import { useSolveMetricsQueryParams } from '../intent-workflows-table/hooks/useSolveMetricsQueryParams';
import AggregateMetrics, { AggregateMetric } from './AggregateMetrics';
import { EMAIL_AGGREGATE_METRICS } from './constants';
import {
  EMAIL_METRIC_LABELS,
  EMAIL_METRIC_TOOLTIPS,
  formatNumberHelper,
  getEmailCostSavingsTooltip,
} from 'src/components/reusable-components/data-overview-card/helpers';
import { useEmailBreakdownMetricsRow } from 'src/pages/intent-conversation-analytics/hooks/useEmailBreakdownMetricsRow';
import { useGetEmailAggregateMetricsQuery } from 'src/services/workflow-builder-metrics';
import { SolveEmailMetricsBase } from 'src/types/workflowBuilderAPITypes';

// For some ids, combine overall count with percentage
const formatValue = (
  id: (typeof EMAIL_AGGREGATE_METRICS)[number],
  data: SolveEmailMetricsBase,
): string => {
  const formattedValue = formatNumberHelper(id, data[id]);

  if (id === 'attempted') {
    return (
      formattedValue +
      ` (${formatNumberHelper('response_rate', data.response_rate)})`
    );
  } else if (id === 'self_served') {
    return (
      formattedValue +
      ` (${formatNumberHelper(
        'self_served_rate',
        data.responded_deflection_rate,
      )})`
    );
  }

  return formattedValue;
};

function formatMetrics(data: SolveEmailMetricsBase): AggregateMetric[] {
  return EMAIL_AGGREGATE_METRICS.map(id => {
    const value = formatValue(id, data);
    const tooltip =
      id === 'cost_savings'
        ? getEmailCostSavingsTooltip(data.cost_per_ticket ?? 0)
        : EMAIL_METRIC_TOOLTIPS[id];

    return {
      label: EMAIL_METRIC_LABELS[id],
      tooltip,
      value,
    };
  });
}

export function FilteredEmailAggregateMetricsContainer({
  intentId,
}: {
  intentId: string;
}) {
  const { from, to } = useSolveMetricsQueryParams();
  const { data, isFetching } = useEmailBreakdownMetricsRow({
    from,
    intentId,
    to,
  });

  return (
    <AggregateMetrics
      metrics={data ? formatMetrics(data) : null}
      numLoadingSkeletons={isFetching ? EMAIL_AGGREGATE_METRICS.length : 0}
    />
  );
}

export default function EmailAggregateMetricsContainer() {
  const { from, to } = useSolveMetricsQueryParams();
  const { data, isFetching } = useGetEmailAggregateMetricsQuery({ from, to });

  return (
    <AggregateMetrics
      metrics={data ? formatMetrics(data) : null}
      numLoadingSkeletons={isFetching ? EMAIL_AGGREGATE_METRICS.length : 0}
    />
  );
}
