export const DISCOVER_TEXT = {
  automatedBadgeTooltip: 'Topic has been automated',
  automationNoExistingWorkflows:
    'There are no existing workflows. Create a workflow in Solve Workflow Builder.',
  automationTicketTopicSummary:
    'Based on your help center ticket, we have identified gaps in your workflows. We recommend creating this workflow using the new policy generated from the agents&apos; responses. By implementing this workflow, you could achieve annual savings: ',
  automationTooFewPhrases:
    'This topic has too few phrases to be automated with a new workflow. Choose an existing workflow instead.',
  postAutomationModalAddedToWorkflowSingularTitle:
    'Your topic has been added to this Workflow!',
  postAutomationModalCompletedSubtitleEnding:
    'Would you like to discover more automatable topics or continue building your workflow in Solve?',
  postAutomationModalInterestedSubtitle:
    'A team member will reach out via email to help you get set up',
  postAutomationModalInterestedTitle: 'We have received your request',
  postAutomationModalWorkflowTitle:
    'You are ready to get started with a new workflow!',
  recommendedBadgeTooltip: 'Topic has recommended automation',
};

export const DISCOVER_MODAL_TEXT = {
  costDescription:
    'Significant cost savings for your support organization based on $15 per support ticket',
  costTitle: 'Potential savings',
  defaultCostTooltip:
    'Cost per ticket is based on the industry average. Contact us to set up custom calculations.',
  resolutionTimeDescription:
    'Instantaneous first response and fast track to resolution',
  resolutionTimeTitle: 'Potential avg. full resolution time',
  volumeDescription: 'Automatically resolve customer issues',
  volumeTitle: 'Potential ticket coverage',
};

export const DISCOVER_CARD_TEXT = {
  articlesGenerated: 'Articles generated for identified gaps',
  automateButtonTooltip:
    'Automate this topic with a workflow using generated key phrases',
  automationPageBannerTitle:
    'By automating these topics with workflows, you could annually achieve:',
  costDescription:
    'Significant cost savings for your support organization based on $15 per support ticket',
  costTitle: 'Reduction in cost',
  defaultCostTooltip:
    'Cost per ticket is based on the industry average. Contact us to set up custom calculations.',
  lackingArticleCoverage: 'Tickets lacking article coverage',
  postAutomationPageBannerTitle:
    'Impact of this automation over the last 30 days:',
  resolutionTimeDescription:
    'Instantaneous first response and fast track to resolution',
  resolutionTimeTitle: 'Reduction in average full resolution time',
  ticketsCovered: 'Potential ticket coverage',
  ticketsCoveredByArticle: '# of tickets covered by this article',
  volumeDescription: 'Automatically resolve customer issues',
  volumeTitle: 'Reduction in ticket volume',
};

export const CREATE_INTENT_WITH_LESS_THAN_FIVE_PHRASES_MIN_REQUIRED_PHRASES = 1;

export const HEADLINE_REGEX = /^\s*Headline:\s/;
export const INSTRUCTION_REGEX = /^\s*Instructions:\s?\n?/;

export const ARTICLE_DRAWER_TABS = ['Generated Article', 'Ticket sources'];
