import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { styled } from '@mui/material';
import { Box } from '@mui/material';

import ContentHeader from '../ContentHeader';
import { deriveInitVariablesState } from '../helpers';
import { useHandleTicketFieldMappings, useLoadData } from './hooks';
import isEqual from 'lodash/fp/isEqual';
import Spinner from 'src/components/spinner/Spinner';
import { useGetContextVariables } from 'src/hooks/useGetContextVariables';
import SharedContextVariableSettings from 'src/pages/action-builder/context-variables-settings';
import { CreateTicketFieldMappingRequest } from 'src/pages/workflow-builder-edit/types';
import { selectContextVariablesSettingsErrorState } from 'src/reducers/actionBuilderReducer/actionBuilderReducer';
import {
  useGetContextVariableUsagesQuery,
  useUpdateContextVariablesMutation,
} from 'src/services/action-builder/actionBuilderApi';
import { useGetHelpdeskQuery } from 'src/services/dashboard-api';
import { useGetFeatureFlagsQuery } from 'src/services/dashboard-api';
import { useGetTriageModelsQuery } from 'src/services/triage/triageApi';
import { updateTicketFieldMappings } from 'src/slices/ticket-field-mappings/ticketFieldMappingsSlice';
import { useAppDispatch } from 'src/store/hooks';
import {
  ContextVariable,
  SettingsInterface,
} from 'src/types/actionBuilderApiTypes';
import { ActionBuilderSettingsVariablesDict } from 'src/utils/actionBuilder/types';
import { ContextVariableTypeKeys } from 'src/utils/enums';

export interface ContextVariablesTableData
  extends ActionBuilderSettingsVariablesDict {
  ticketMappingData?: { isInUse: boolean; value: string };
}

export interface UpdateFieldMappingParams {
  context_variable_id: string;
  selectedValue: string;
}

interface ContextVariablePageProps {
  isOnNavbar?: boolean;
  setHasUnsavedChanges: (hasUnsavedChanged: boolean) => void;
}

const ContextVariablesPage: React.FC<
  React.PropsWithChildren<ContextVariablePageProps>
> = ({ isOnNavbar = false, setHasUnsavedChanges }) => {
  const dispatch = useAppDispatch();
  const { isFetching, isLoading: isInitialLoadContextVariableUsages } =
    useGetContextVariableUsagesQuery(undefined, {
      refetchOnMountOrArgChange: true,
    });
  const isLoadingContextVariablesWithUsages =
    isFetching || isInitialLoadContextVariableUsages;

  const {
    contextVariables,
    isLoading: isLoadingContextVariablesWithoutUsages,
  } = useGetContextVariables({
    shouldIncludeSystemContextVariables: true,
    shouldIncludeTriageContextVariables: true,
  });

  const [updateContextVariables, { isLoading }] =
    useUpdateContextVariablesMutation();
  useGetHelpdeskQuery();

  const isContextVariablesError = useSelector(
    selectContextVariablesSettingsErrorState,
  );
  const { data: featureFlagsData } = useGetFeatureFlagsQuery();
  const { feature_flags: featureFlags = [] } = featureFlagsData ?? {};

  const [variables, setVariables] = useState<
    ActionBuilderSettingsVariablesDict[]
  >([]);

  const { isAnyVariableEmpty, isInitVariablesState } = useMemo(() => {
    const initVariablesState = deriveInitVariablesState(contextVariables);
    const isInitVariablesState = isEqual(initVariablesState, variables);
    const isAnyVariableEmpty = variables.some(
      ({ displayName }) => !displayName.length,
    );

    return { isAnyVariableEmpty, isInitVariablesState };
  }, [variables, contextVariables]);

  const { customTicketFields, isLoadingData } = useLoadData();
  const {
    isLoading: isLoadingTicketFieldMappings,
    localTicketFieldMappings,
    setTicketFieldMappings,
    ticketFieldMappings,
  } = useHandleTicketFieldMappings();

  const isTicketFieldMappingsInitialState = useMemo(() => {
    return isEqual(ticketFieldMappings, localTicketFieldMappings);
  }, [ticketFieldMappings, localTicketFieldMappings]);

  const isSolveEmailEnabled = featureFlags.includes('solve_email_enabled');
  const isTriageMappingEnabled = featureFlags.includes('triage_model_mapping');

  const { data: triageModels = [] } = useGetTriageModelsQuery(undefined, {
    skip: !isSolveEmailEnabled || !isTriageMappingEnabled,
  });

  const isInitialState = isSolveEmailEnabled
    ? isTicketFieldMappingsInitialState && isInitVariablesState
    : isInitVariablesState;

  useEffect(() => {
    if (contextVariables.length > 0) {
      setVariables(deriveInitVariablesState(contextVariables));
    }
  }, [dispatch, contextVariables]);

  useEffect(() => {
    setHasUnsavedChanges(!isInitialState);
  }, [dispatch, isInitialState, setHasUnsavedChanges]);

  const isSubmitDisabled =
    isAnyVariableEmpty ||
    isContextVariablesError ||
    isInitialState ||
    isLoadingContextVariablesWithUsages ||
    isLoadingContextVariablesWithoutUsages ||
    isLoadingData;

  const updateTicketFieldMapping = useCallback(
    (updatedMapping: CreateTicketFieldMappingRequest) => {
      setTicketFieldMappings(mappings =>
        mappings.map(mapping => {
          const {
            context_variable_id,
            external_ticket_field_id,
            mapping_data,
            mapping_type,
          } = updatedMapping;

          const isCvIdEqual =
            mapping.context_variable_id === context_variable_id;

          if (isCvIdEqual) {
            return {
              ...mapping,
              external_ticket_field_id,
              mapping_data,
              mapping_type,
            };
          }
          return mapping;
        }),
      );
    },
    [setTicketFieldMappings],
  );

  const deleteTicketFieldMapping = useCallback(
    (contextVariableId: string) => {
      setTicketFieldMappings(mappings =>
        mappings.filter(
          mapping => mapping.context_variable_id !== contextVariableId,
        ),
      );
    },
    [setTicketFieldMappings],
  );

  const saveData = async (
    contextVariables: SettingsInterface['context_variables'],
  ) => {
    if (isAnyVariableEmpty) {
      return;
    }
    if (!isInitVariablesState) {
      await updateContextVariables(contextVariables);
    }
    if (!isTicketFieldMappingsInitialState) {
      await dispatch(updateTicketFieldMappings(localTicketFieldMappings));
      updateContextVariables(contextVariables);
    }
  };

  const updateFieldMappingData = useCallback(
    (value: UpdateFieldMappingParams) => {
      const addNewTicketFieldMapping = (
        newMapping: CreateTicketFieldMappingRequest,
      ) => {
        setTicketFieldMappings(mappings => [...mappings, { ...newMapping }]);
      };

      const getIsTriageModel = (modelName: string) =>
        triageModels.some(model => model.name === modelName);

      const applyListTypeOptions = (value: UpdateFieldMappingParams) => {
        const customTicketField = customTicketFields.find(
          field => field.id === value.selectedValue,
        );
        setVariables(variables => {
          return variables.map(variable => {
            if (variable.id === value.context_variable_id) {
              return {
                ...variable,
                listTypeOptions: customTicketField?.field_options || [],
              };
            }
            return variable;
          });
        });
      };

      const getFormattedSelectedFieldMappingValue = (
        value: UpdateFieldMappingParams,
      ): CreateTicketFieldMappingRequest => {
        const isTriageModel = getIsTriageModel(value.selectedValue);

        if (isTriageModel) {
          return {
            context_variable_id: value.context_variable_id,
            mapping_data: { model_name: value.selectedValue },
            mapping_type: 'triage',
          };
        }
        return {
          context_variable_id: value.context_variable_id,
          external_ticket_field_id: value.selectedValue,
        };
      };

      const shouldAddNewMapping = !localTicketFieldMappings.find(
        mapping => mapping.context_variable_id === value.context_variable_id,
      );

      const isListType =
        variables.find(variable => variable.id === value.context_variable_id)
          ?.type === ContextVariableTypeKeys.LIST;

      const isTriageModel = getIsTriageModel(value.selectedValue);

      if (isListType && !isTriageModel) {
        applyListTypeOptions(value);
      }

      const formattedValue = getFormattedSelectedFieldMappingValue(value);

      if (shouldAddNewMapping) {
        addNewTicketFieldMapping(formattedValue);
      } else {
        updateTicketFieldMapping(formattedValue);
      }
    },
    // we dont want to rerender everything unless triageModels are added
    // or new variables or added, so we will check .length
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      customTicketFields,
      localTicketFieldMappings,
      setTicketFieldMappings,
      triageModels.length,
      variables.length,
      updateTicketFieldMapping,
    ],
  );

  return (
    <Container>
      <Box
        sx={{
          paddingRight: isOnNavbar ? '68px' : '0px',
          paddingTop: isOnNavbar ? '5px' : '0px',
        }}
      >
        <ContentHeader
          buttonDataAttribute={{
            'data-appcues-target': 'ContextVariablesPageSaveButton',
          }}
          isButtonDisabled={isSubmitDisabled}
          isButtonLoading={isLoading}
          onClickHandler={async () => {
            const contextVariables: ContextVariable[] = variables
              .filter(variable => !variable.isSystemContext)
              .map(
                ({
                  configurationFields,
                  createdDate,
                  displayName,
                  id,
                  isUniversal,
                  isWorkflowContext,
                  listTypeOptions,
                  type,
                }) => ({
                  configuration_fields: configurationFields
                    ? {
                        dynamic_list_config:
                          configurationFields.dynamicListConfig,
                        is_alphanumeric: configurationFields.isAlphanumeric,
                        max_length: configurationFields.maxLength,
                        min_length: configurationFields.minLength,
                      }
                    : undefined,
                  context_variable_id: id,
                  context_variable_name: displayName,
                  context_variable_type: type,
                  created_date: createdDate,
                  is_universal_context_variable: isUniversal,
                  is_workflow_context: !!isWorkflowContext,
                  list_type_options: listTypeOptions ?? [],
                }),
              );

            if (isSolveEmailEnabled) {
              saveData(contextVariables);
            } else {
              updateContextVariables(
                isAnyVariableEmpty ? [] : contextVariables,
              );
            }
          }}
          title='Context Variables'
        />
      </Box>

      {isLoadingContextVariablesWithoutUsages ||
      isLoadingTicketFieldMappings ? (
        <Spinner />
      ) : (
        <SharedContextVariableSettings
          customTicketFields={customTicketFields}
          deleteTicketFieldMapping={deleteTicketFieldMapping}
          height='auto'
          setVariables={setVariables}
          shouldShowSkeletons={
            isLoadingContextVariablesWithUsages || isLoadingData
          }
          ticketFieldMappings={localTicketFieldMappings}
          updateTicketFieldMappings={updateFieldMappingData}
          variables={variables}
        />
      )}
    </Container>
  );
};

export default ContextVariablesPage;

const Container = styled('div')`
  width: 100%;
  height: 100%;
`;
