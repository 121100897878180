import { useCallback, useEffect, useState } from 'react';
import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import isPropValid from '@emotion/is-prop-valid';
import { Box, styled, useTheme } from '@mui/material';

import { Typography } from '@forethought-technologies/forethought-elements';
import {
  useConstructUrlOnIntetWorkflowCreation,
  useGetBuilderQueryParams,
  useGetHandoffConfigurationForWorkflow,
  useGetIntentTitle,
  useGetIsTemplateIntentWorkflow,
  useGoBackToLandingPage,
  useInitPusher,
} from '../hooks';
import WorkflowConflictMessage from '../WorkflowConflictMessage';
import { duplicateIntentWorkflow } from 'src/actions/workflow-builder/workflowBuilderActions';
import BackIcon from 'src/assets/images/actions-back-icon.svg';
import BackIconWhite from 'src/assets/images/actions-back-icon-white.svg';
import { useDeleteIntentEventSubscription } from 'src/hooks/PusherEventHooks';
import { DeleteWorkflowDialog } from 'src/pages/workflow-builder/DeleteWorkflowDialog';
import { WorkflowDropdownMenu } from 'src/pages/workflow-builder/DropdownMenu';
import { useWidgetMetricBreakdownById } from 'src/pages/workflow-builder/intent-workflows-table/hooks/useWidgetMetricBreakdownById';
import { selectUserCan } from 'src/reducers/userReducer/userReducer';
import { selectCanvasWorkflowBuilder } from 'src/reducers/workflowBuilderReducer/workflowBuilderReducer';
import {
  dashboardApi,
  useGetPaidPlanDetailsQuery,
} from 'src/services/dashboard-api';
import { useGetHandoffConfigurationsQuery } from 'src/services/workflow-builder-metrics';
import { CanvasWorkflowBuilderState } from 'src/slices/canvas-workflow-builder/workflowBuilderSlice';
import { getIntent } from 'src/slices/canvas-workflow-builder/workflowBuilderSlice.thunks';
import { getDiscoverAutomations } from 'src/slices/data/thunks';
import {
  getWidgetConfiguration,
  selectWidgetConfiguration,
} from 'src/slices/solve-config/solveConfigSlice';
import {
  openGlobalIntentDrawer,
  setGlobalHandoffDrawerOptions,
} from 'src/slices/ui/uiSlice';
import { useAppDispatch } from 'src/store/hooks';
import { CommonIntentWorkflowType, Routes } from 'src/utils/enums';
import { getUserOrgCode } from 'src/utils/getUserOrgCode';
import {
  isIntentDuplicable,
  isIntentEditable,
} from 'src/utils/solve/intentUtils';

interface WorkflowBuilderEditHeader {
  isDarkMode?: boolean;
  isFromNavbar?: boolean;
}

const WorkflowBuilderEditHeader = ({
  isDarkMode = false,
  isFromNavbar = false,
}: WorkflowBuilderEditHeader) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { palette } = useTheme();

  const [isDeleteDialogVisible, setIsDeleteDialogVisible] = useState(false);

  const [isIntentDeleted, setIsIntentDeleted] = useState(false);

  const { intentId, view } = useGetBuilderQueryParams();

  const { breakdownById } = useWidgetMetricBreakdownById();

  const [params] = useSearchParams();
  const startDate = params.get('start') ?? '';
  const endDate = params.get('end') ?? '';

  const intentTitle = useGetIntentTitle(intentId);
  const isTemplateIntentWorkflow = useGetIsTemplateIntentWorkflow();
  const workflowBuilderData: CanvasWorkflowBuilderState = useSelector(
    selectCanvasWorkflowBuilder,
  );
  const handoffConfiguration = useGetHandoffConfigurationForWorkflow();
  const isHandoff = handoffConfiguration !== undefined;
  const { refetch: refetchHandoffConfigurations } =
    useGetHandoffConfigurationsQuery();

  const userCanDeleteAction = useSelector(
    selectUserCan('delete_custom_intent'),
  );
  const { data: paidPlanData } = useGetPaidPlanDetailsQuery();
  const widgetConfiguration = useSelector(selectWidgetConfiguration);

  const goBackToLandingPage = useGoBackToLandingPage();

  const workflowId = workflowBuilderData?.intent_workflow_id || '';
  const isDiscoverEnabled = paidPlanData?.enablement_info.discover;

  useConstructUrlOnIntetWorkflowCreation();

  useInitPusher();

  useDeleteIntentEventSubscription({
    onEvent: useCallback(() => {
      setIsIntentDeleted(true);
    }, []),
    orgCode: getUserOrgCode(),
  });

  useEffect(() => {
    dispatch(getWidgetConfiguration());
  }, [dispatch]);

  const duplicateWorkflow = async () => {
    try {
      const data = await dispatch(
        duplicateIntentWorkflow({
          workflowId,
        }),
      ).unwrap();
      await refetchHandoffConfigurations();
      const newWorkflowId = data.canvas.intent_workflow_id;
      await dispatch(getIntent(data.canvas.intent_id));
      navigate({
        pathname: Routes.WORKFLOW_BUILDER_EDIT,
        search: `workflowId=${newWorkflowId}&intentId=${data.canvas.intent_id}&view=${view}`,
      });
      if (!isHandoff) {
        dispatch(openGlobalIntentDrawer({ type: 'widget' }));
      }
      // because we don't yet use rtk query for all intent apis,
      // manually invalidate the cache tag to trigger a refetch
      dispatch(dashboardApi.util.invalidateTags(['Intents']));
    } catch (e) {
      console.error(e);
    }
  };

  const onEditHandoffClicked = () => {
    dispatch(
      setGlobalHandoffDrawerOptions({
        handoffToEdit: {
          handoffName: intentTitle,
          intentId: intentId,
          intentWorkflowId: workflowId,
          isDefaultHandoff: handoffConfiguration?.is_default_handoff ?? false,
        },
        isCreateHandoffDrawerOpen: true,
        isEditing: true,
      }),
    );
  };

  const onEditIntentClicked = async () => {
    await dispatch(
      getIntent(
        (workflowBuilderData.intent_id ||
          workflowBuilderData.intent?.intent_id) as string,
      ),
    );
    if (isDiscoverEnabled) {
      await dispatch(getDiscoverAutomations());
    }
    dispatch(
      openGlobalIntentDrawer({
        type: view,
      }),
    );
  };

  const shouldEnableViewInsight = React.useMemo(() => {
    const fallbackEntry = Object.values(breakdownById || {}).find(
      entry => entry.intent_id === workflowBuilderData.intent_id,
    );
    return Boolean(fallbackEntry?.inquiries);
  }, [breakdownById, workflowBuilderData]);

  const onViewInsightClick = (intentId: string) => {
    const start = parseInt(startDate) * 1000;
    const end = parseInt(endDate) * 1000;
    const search = `?tableTab=1&articles_sort_column=times_surfaced&date=${start}_${end}&article_sort_direction=DESC`;
    const pathName = Routes.SOLVE_INSIGHTS_WORKFLOW_DETAIL.replace(
      ':workflowId',
      intentId,
    );

    navigate({
      pathname: pathName,
      search: search,
    });
  };

  return (
    <>
      <Header isDarkMode={isDarkMode} isFromNavbar={isFromNavbar}>
        <BackButton
          disabled={workflowBuilderData.loading}
          onClick={() => {
            goBackToLandingPage();
          }}
        >
          <img alt='' src={isDarkMode ? BackIconWhite : BackIcon} />
        </BackButton>
        {intentTitle && (
          <>
            <Typography
              color={isDarkMode ? palette.colors.white : palette.colors.black}
              variant='font24'
            >
              {intentTitle}
            </Typography>
            {!isTemplateIntentWorkflow && (
              <Box height='30px' marginLeft='10px' width='30px'>
                <WorkflowDropdownMenu
                  intentId={intentId}
                  intentName={intentTitle}
                  isDarkMode={isDarkMode}
                  isDisabled={!!workflowBuilderData?.loading}
                  isHandoff={isHandoff}
                  onDeleteClicked={() => setIsDeleteDialogVisible(true)}
                  onDuplicateClicked={duplicateWorkflow}
                  onEditClicked={() => {
                    isHandoff ? onEditHandoffClicked() : onEditIntentClicked();
                  }}
                  onViewInsightClick={() =>
                    onViewInsightClick(
                      workflowBuilderData.intent_workflow_id || '',
                    )
                  }
                  shouldEnableDelete={
                    userCanDeleteAction &&
                    !Object.values(CommonIntentWorkflowType).includes(
                      intentId as CommonIntentWorkflowType,
                    ) &&
                    workflowId !==
                      widgetConfiguration.quick_feedback_config
                        ?.negative_routing_intent_workflow_id
                  }
                  shouldEnableViewInsight={shouldEnableViewInsight}
                  shouldShowChats={view === 'widget'}
                  shouldShowDuplicate={
                    ['widget', 'email'].includes(view) &&
                    isIntentDuplicable(intentId)
                  }
                  shouldShowEdit={isIntentEditable(intentId)}
                  shouldShowPreview={false}
                />
              </Box>
            )}
          </>
        )}
      </Header>
      <DeleteWorkflowDialog
        intentIdToDelete={workflowBuilderData?.intent_id || ''}
        isOpen={isDeleteDialogVisible}
        onClose={() => setIsDeleteDialogVisible(false)}
        onSuccess={goBackToLandingPage}
      />
      <WorkflowConflictMessage
        buttonMessage='Go to the landing page'
        message='Go to the landing page to see the list of existing intents'
        onButtonClick={() => goBackToLandingPage()}
        open={isIntentDeleted}
        title='The current intent has been deleted by another user in your organization'
      />
    </>
  );
};

export default WorkflowBuilderEditHeader;

const Header = styled('div', {
  shouldForwardProp: prop =>
    isPropValid(String(prop)) &&
    prop !== 'isDarkMode' &&
    prop !== 'isFromNavbar',
})<{ isDarkMode: boolean; isFromNavbar: boolean }>`
  display: flex;
  justify-content: flex-start;
  padding: ${props => (props.isFromNavbar ? '0px' : '16px 24px 16px 40px')};
  background: ${props =>
    props.isDarkMode
      ? props.theme.palette.colors.slate[800]
      : props.theme.palette.background.default};
  border-bottom: ${props =>
    props.isFromNavbar
      ? '0px'
      : `1px solid ${props.theme.palette.colors.slate[200]}`};
  align-items: center;
  transition: inherit;
  flex: 1;
`;

const BackButton = styled('button')`
  margin-right: 20px;
  cursor: pointer;
  padding: 1px 2px 2px 2px;
  &:disabled {
    cursor: auto;
  }
  height: 26px;
  &:hover {
    background-color: transparent !important;
  }
`;

BackButton.defaultProps = {
  'aria-label': 'Back',
};
