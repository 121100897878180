import AnswersAnalyticsV1Page from '../dashboard-pages/answers-analytics-page/answersAnalyticsPageV1';
import AssistNotesPage from '../dashboard-pages/assist-notes-page';
import PredictionsPage from '../dashboard-pages/predictions-page/predictionsPage';
import SearchAnalyticsPage from '../dashboard-pages/search-analytics-page/searchAnalyticsPage';
import SolveInsightsMain from '../dashboard-pages/solve-insights/SolveInsightsMain';
import AssistAutomationsPage from 'src/components/dashboard-pages/assist-automations-page';
import DiscoverPage from 'src/components/dashboard-pages/discover-dashboard-page';
import EmbeddableDashboardsPage from 'src/components/dashboard-pages/embeddable-dashboards';
import LookerDashboardPage from 'src/components/dashboard-pages/embeddable-dashboards/LookerDashboard';
import SolveAnalyticsEmbedPage from 'src/components/dashboard-pages/solve-analytics-embed-page';
import ActionBuilderPage from 'src/pages/action-builder';
import BuilderParentPage from 'src/pages/builder-parent-page';
import EventsPage from 'src/pages/events/EventsPage';
import PredictIntentsPage from 'src/pages/predict-intents/PredictIntentsPage';
import SolveAnalyticsPageV2 from 'src/pages/solve-analytics';
import SolveConfigurationPage from 'src/pages/solve-config';
import SolvePreviewPage from 'src/pages/solve-config/preview/preview';
import { Permission, Routes, Tabs } from 'src/utils/enums';

interface PermissionsInterface {
  [key: string]: {
    component: any;
    path: Routes;
    tab: Tabs;
  };
}

interface MappingsInterface {
  [key: string]: {
    navItem?: Routes;
    permission: Permission;
    tab: Tabs;
  };
}

export const permissionsArray: PermissionsInterface = {
  [Permission.PREDICTIONS]: {
    component: PredictionsPage,
    path: Routes.PREDICTIONS,
    tab: Tabs.PREDICTIONS,
  },
  [Permission.SEARCH]: {
    component: SearchAnalyticsPage,
    path: Routes.SEARCH,
    tab: Tabs.SEARCH,
  },
  [Permission.SOLVE]: {
    component: SolveAnalyticsPageV2,
    path: Routes.SOLVE,
    tab: Tabs.SOLVE,
  },
  [Permission.SOLVE_ANALYTICS_V2]: {
    component: SolveAnalyticsEmbedPage,
    path: Routes.SOLVE_ANALYTICS_V2,
    tab: Tabs.SOLVE,
  },
  [Permission.ANSWERS]: {
    component: AnswersAnalyticsV1Page,
    path: Routes.ANSWERS,
    tab: Tabs.ANSWERS,
  },
  [Permission.MACRO_CONTROLS]: {
    component: SolveAnalyticsPageV2,
    path: Routes.MACRO_CONTROLS,
    tab: Tabs.MACRO_CONTROLS,
  },
  [Permission.WORKFLOW_BUILDER]: {
    component: SolveAnalyticsPageV2,
    path: Routes.WORKFLOW_BUILDER,
    tab: Tabs.WORKFLOW_BUILDER,
  },
  [Permission.FLAMETHROWER]: {
    component: ActionBuilderPage,
    path: Routes.ACTION_BUILDER,
    tab: Tabs.ACTION_BUILDER,
  },
  [Permission.FLAMETHROWER_WORKFLOW_BUILDER_EDIT]: {
    component: BuilderParentPage,
    path: Routes.WORKFLOW_BUILDER_EDIT,
    tab: Tabs.WORKFLOW_BUILDER_EDIT,
  },
  [Permission.CUSTOM_ANALYTICS]: {
    component: EmbeddableDashboardsPage,
    path: Routes.CUSTOM_ANALYTICS,
    tab: Tabs.CUSTOM_ANALYTICS,
  },
  [Permission.LOOKER_ANALYTICS]: {
    component: LookerDashboardPage,
    path: Routes.LOOKER_ANALYTICS,
    tab: Tabs.LOOKER_ANALYTICS,
  },
  [Permission.ASSIST_AUTOMATIONS]: {
    component: AssistAutomationsPage,
    path: Routes.ASSIST_AUTOMATIONS,
    tab: Tabs.ASSIST_AUTOMATIONS,
  },
  [Permission.DISCOVER]: {
    component: DiscoverPage,
    path: Routes.DISCOVER_DASHBOARD,
    tab: Tabs.DISCOVER,
  },
  [Permission.SOLVE_LITE]: {
    component: SolveConfigurationPage,
    path: Routes.SOLVE_LITE,
    tab: Tabs.SOLVE_LITE,
  },
  [Permission.SOLVE_PREVIEW]: {
    component: SolvePreviewPage,
    path: Routes.SOLVE_PREVIEW,
    tab: Tabs.SOLVE_PREVIEW,
  },
  [Permission.ASSIST]: {
    component: AssistNotesPage,
    path: Routes.ASSIST_NOTES,
    tab: Tabs.ASSIST_NOTES,
  },
  [Permission.PREDICT_INTENTS]: {
    component: PredictIntentsPage,
    path: Routes.PREDICT_INTENTS,
    tab: Tabs.PREDICT_INTENTS,
  },
  [Permission.SOLVE_INSIGHTS]: {
    component: SolveInsightsMain,
    path: Routes.SOLVE_INSIGHTS_MAIN,
    tab: Tabs.SOLVE_INSIGHT,
  },
  [Permission.LOG]: {
    component: EventsPage,
    path: Routes.LOG,
    tab: Tabs.LOG,
  },
};

export const appSelectedMappings: MappingsInterface = {
  [Routes.DISCOVER_DASHBOARD]: {
    navItem: Routes.DISCOVER_DASHBOARD,
    permission: Permission.DISCOVER,
    tab: Tabs.DISCOVER_DASHBOARD,
  },
  [Routes.DISCOVER_AUTOMATION]: {
    navItem: Routes.DISCOVER_AUTOMATION,
    permission: Permission.DISCOVER,
    tab: Tabs.DISCOVER_AUTOMATION,
  },
  [Routes.DISCOVER_TOPIC_DETAIL]: {
    navItem: Routes.DISCOVER_TOPIC_DETAIL,
    permission: Permission.DISCOVER,
    tab: Tabs.DISCOVER,
  },
  [Routes.DISCOVER_TOPICS]: {
    navItem: Routes.DISCOVER_TOPICS,
    permission: Permission.DISCOVER,
    tab: Tabs.DISCOVER_TOPICS,
  },
  [Routes.SEARCH]: {
    permission: Permission.SEARCH,
    tab: Tabs.SEARCH,
  },
  [Routes.PREDICTIONS]: {
    permission: Permission.PREDICTIONS,
    tab: Tabs.PREDICTIONS,
  },
  [Routes.ANSWERS]: {
    permission: Permission.ANSWERS,
    tab: Tabs.ANSWERS,
  },
  [Routes.ASSIST_NOTES]: {
    permission: Permission.ASSIST,
    tab: Tabs.ASSIST_NOTES,
  },
  [Routes.SOLVE]: {
    permission: Permission.SOLVE,
    tab: Tabs.SOLVE,
  },
  [Routes.SOLVE_ANALYTICS_V2]: {
    permission: Permission.SOLVE_ANALYTICS_V2,
    tab: Tabs.SOLVE,
  },
  [Routes.MACRO_CONTROLS]: {
    permission: Permission.MACRO_CONTROLS,
    tab: Tabs.MACRO_CONTROLS,
  },
  [Routes.WORKFLOW_BUILDER]: {
    permission: Permission.FLAMETHROWER,
    tab: Tabs.WORKFLOW_BUILDER,
  },
  [Routes.WORKFLOW_BUILDER_EDIT]: {
    permission: Permission.FLAMETHROWER_WORKFLOW_BUILDER_EDIT,
    tab: Tabs.WORKFLOW_BUILDER,
  },
  [Routes.ACTION_BUILDER]: {
    permission: Permission.FLAMETHROWER,
    tab: Tabs.ACTION_BUILDER,
  },
  [Routes.ACTION_BUILDER_EDIT]: {
    permission: Permission.FLAMETHROWER,
    tab: Tabs.ACTION_BUILDER,
  },
  [Routes.ACTION_BUILDER_EDIT_V2]: {
    permission: Permission.FLAMETHROWER,
    tab: Tabs.ACTION_BUILDER,
  },
  [Routes.CUSTOM_ANALYTICS]: {
    permission: Permission.CUSTOM_ANALYTICS,
    tab: Tabs.CUSTOM_ANALYTICS,
  },
  [Routes.LOOKER_ANALYTICS]: {
    permission: Permission.LOOKER_ANALYTICS,
    tab: Tabs.LOOKER_ANALYTICS,
  },
  [Routes.ASSIST_AUTOMATIONS]: {
    permission: Permission.ASSIST_AUTOMATIONS,
    tab: Tabs.ASSIST_AUTOMATIONS,
  },
  [Routes.ASSIST_AUTOMATIONS_CREATE]: {
    permission: Permission.ASSIST_AUTOMATIONS,
    tab: Tabs.ASSIST_AUTOMATIONS,
  },
  [Routes.SOLVE_LITE]: {
    permission: Permission.SOLVE_LITE,
    tab: Tabs.SOLVE_LITE,
  },
  [Routes.SOLVE_PREVIEW]: {
    permission: Permission.SOLVE_PREVIEW,
    tab: Tabs.SOLVE_PREVIEW,
  },
  [Routes.SOLVE_CONFIGURATION]: {
    permission: Permission.WORKFLOW_BUILDER,
    tab: Tabs.SOLVE_CONFIGURATION,
  },
  [Routes.SOLVE_API_CONFIGURATION]: {
    permission: Permission.SOLVE_API,
    tab: Tabs.SOLVE_API_CONFIGURATION,
  },
  [Routes.PREDICT_INTENTS]: {
    permission: Permission.PREDICT_INTENTS,
    tab: Tabs.PREDICT_INTENTS,
  },
  [Routes.SOLVE_INSIGHTS_MAIN]: {
    navItem: Routes.SOLVE_INSIGHTS_MAIN,
    permission: Permission.FLAMETHROWER,
    tab: Tabs.SOLVE_INSIGHT,
  },
  [Routes.LOG]: {
    navItem: Routes.LOG,
    permission: Permission.LOG,
    tab: Tabs.LOG,
  },
};
