import { useState } from 'react';
import { Formik } from 'formik';
import { useNavigate } from 'react-router';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

import {
  Button,
  TextField,
  Typography,
} from '@forethought-technologies/forethought-elements';
import {
  createModelFields,
  createModelFormValue,
} from '../../triage-models-overview-page/constants';
import HelpDesk from '../../triage-models-overview-page/HelpDesk';
import { getTriageModelsPageUrl } from '../../triage-models-overview-page/helpers';
import { CreateModelFormValue } from '../../triage-models-overview-page/types';
import useSelfServeEvents from 'src/hooks/triage/useSelfServeEvents';
import { VersionedTriageModel } from 'src/reducers/triageSettingsReducer/types';
import {
  useCreateModelMutation,
  usePatchSelfServeTriageModelForAllVersionsMutation,
} from 'src/services/triage/triageApi';
import { TRIAGE_LLM_TRACKING_EVENTS } from 'src/utils/constants';

interface ManageModelFormProps {
  initialValues?: CreateModelFormValue;
  isEdit?: boolean;
  modelId?: string;
  onClose?: () => void;
  versionId?: string;
}

const ManageModelForm: React.FC<ManageModelFormProps> = ({
  initialValues = createModelFormValue,
  isEdit = false,
  modelId,
  onClose,
}: ManageModelFormProps) => {
  const { palette } = useTheme();
  const greyColor = palette.colors.grey[600];
  const lightColor = palette.colors.grey[400];
  const redColor = palette.colors.red[500];
  const emitTrackingEventCallback = useSelfServeEvents({});
  const [createModel, { isLoading: isCreating }] = useCreateModelMutation();
  const [patchModel, { isLoading: isPatching }] =
    usePatchSelfServeTriageModelForAllVersionsMutation();
  const navigate = useNavigate();

  const [initialFormValues] = useState(initialValues);

  const handleSubmit = (values: CreateModelFormValue) => {
    if (isEdit && modelId) {
      const patchBody: Partial<VersionedTriageModel> = {};
      if (values.displayName !== initialFormValues.displayName) {
        patchBody.model_name = values.displayName;
      }
      if (values.description !== initialFormValues.description) {
        patchBody.model_description = values.description;
      }

      if (Object.keys(patchBody).length === 0) {
        return;
      }

      // Edit Model
      patchModel({
        body: patchBody,
        modelId,
      })
        .unwrap()
        .then(() => {
          emitTrackingEventCallback(
            TRIAGE_LLM_TRACKING_EVENTS.EDIT_MODEL_SUCCESS,
          );
          if (onClose) {
            onClose();
          }
        })
        .catch(console.error);
    } else {
      // Create Model
      createModel({
        model_description: values.description,
        model_name: values.displayName,
      })
        .unwrap()
        .then(payload => {
          emitTrackingEventCallback(
            TRIAGE_LLM_TRACKING_EVENTS.CREATE_MODEL_SUCCESS,
            {
              model_id: payload.model_id,
              model_name: payload.model_name,
            },
          );
          navigate(getTriageModelsPageUrl(payload.model_id));
        })
        .catch(console.error);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validate={values => {
        const errors: Partial<CreateModelFormValue> = {};

        if (!values.displayName.trim()) {
          errors.displayName = 'Model name is required';
        }

        if (!values.description.trim()) {
          errors.description = 'Model description is required';
        }

        return errors;
      }}
      validateOnChange={true}
    >
      {({ errors, handleSubmit, setFieldValue, values }) => {
        const hasChanges =
          values.displayName !== initialFormValues.displayName ||
          values.description !== initialFormValues.description;

        return (
          <Form
            noValidate
            onSubmit={event => {
              event.preventDefault();
              handleSubmit();
            }}
          >
            <Box display='flex' flexDirection='column' rowGap='32px'>
              <Typography variant='font24'>
                {isEdit ? 'Edit Model' : 'Create Model'}
              </Typography>
              {createModelFields.map(item => (
                <Box
                  display='flex'
                  flexDirection='column'
                  key={item.key}
                  rowGap={1}
                >
                  <Typography variant='font16Bold'>{item.label}</Typography>
                  <Label htmlFor={item.key}>{item.description}</Label>
                  <TextField
                    aria-label={item.label}
                    disabled={isCreating || isPatching}
                    error={Boolean(errors[item.key])}
                    maxLength={item.maxLength ?? 100}
                    multiline={Boolean(item.rows)}
                    onChange={e => {
                      setFieldValue(item.key, e.target.value);
                    }}
                    placeholder={item.placeholder}
                    rows={item.rows}
                    value={values[item.key]}
                  />
                  {Boolean(item.info) && (
                    <Typography color={lightColor} variant='font12'>
                      {item.info}
                    </Typography>
                  )}
                  {Boolean(errors[item.key]) && (
                    <Typography color={redColor} variant='font12'>
                      {errors[item.key]}
                    </Typography>
                  )}
                </Box>
              ))}
              <Box display='flex' flexDirection='column' rowGap={1}>
                <Typography variant='font16Bold'>
                  Helpdesk Integration
                </Typography>
                <HelpDesk />
              </Box>
              <Typography color={greyColor} variant='font12'>
                After creating the model, configure it to the Helpdesk output
                field in the ‘Configuration’ tab.
              </Typography>
            </Box>
            <Button
              color='primary'
              disabled={isEdit && !hasChanges}
              isLoading={isPatching || isCreating}
              type='submit'
              variant='main'
            >
              {isEdit ? 'Save Changes' : 'Create'}
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
};

const Form = styled('form')`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  padding: 32px;
`;

const Label = styled('label')(({ theme }) => ({
  color: theme.palette.colors.grey[600],
  fontSize: '12px',
}));

export default ManageModelForm;
