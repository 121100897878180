import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FieldArray, useField, useFormikContext } from 'formik';
import { Box, useTheme } from '@mui/material';
import Grid from '@mui/material/Grid';
import { IconTrash } from '@tabler/icons-react';

import {
  IconButton,
  Skeleton,
  Typography,
} from '@forethought-technologies/forethought-elements';
import ActionBuilderFormTextField from './ActionBuilderFormTextField';
import Check from './Check';
import DetailsForm from './details-form';
import { DynamicListItem } from './dynamic-list-item';
import EndpointForm from './endpoint-form';
import FormAccordion from './FormAccordion';
import TestValuesModal from './TestValuesModal';
import isEmpty from 'lodash/isEmpty';
import { clearOutputParametersModalActionData } from 'src/actions/action-builder-actions/actionBuilderActions';
import ContextVariableSelectDropdown from 'src/components/context-variable-select-dropdown';
import { useGetContextVariables } from 'src/hooks/useGetContextVariables';
import { useGetActionBuilderDetailFormIsActive } from 'src/pages/action-builder/hooks';
import ResponseView from 'src/pages/action-builder/response-view';
import { useIsDynamicListFeatureFlagEnabled } from 'src/pages/action-builder/response-view/hooks';
import { useAppDispatch } from 'src/store/hooks';
import {
  ContextVariable,
  type OutputValues,
  SelectedActionDetail,
} from 'src/types/actionBuilderApiTypes';
import {
  flattenCvs,
  isDynamicListKvPairs,
} from 'src/utils/actionBuilder/helpers';
import { extractCvIdsFromFieldValue } from 'src/utils/cleanStr';

interface ActionBuilderFormProps {
  isError: boolean | string;
  isLoading: boolean;
  jmespathQueryTestCvs: Partial<Record<string, string>>;
  onTestConnection: (
    values: SelectedActionDetail,
    idTestValueDict?: Record<string, string>,
  ) => Promise<unknown>;
  responseData: unknown;
  setJmespathQueryTestCvs: (
    jmespathQueryTestCvs: Partial<Record<string, string>>,
  ) => void;
}

const ActionBuilderForm = ({
  isError,
  isLoading,
  jmespathQueryTestCvs,
  onTestConnection,
  responseData,
  setJmespathQueryTestCvs,
}: ActionBuilderFormProps) => {
  return (
    <>
      <DetailsAndEndpoint handleTestConnection={onTestConnection} />
      <ResponseAndOutput
        isError={isError}
        isLoading={isLoading}
        jmespathQueryTestCvs={jmespathQueryTestCvs}
        responseData={responseData}
        setJmespathQueryTestCvs={setJmespathQueryTestCvs}
      />
    </>
  );
};

interface DetailsAndEndpointProps {
  handleTestConnection: (
    values: SelectedActionDetail,
    idTestValueDict?: Record<string, string>,
  ) => Promise<unknown>;
}

const DetailsAndEndpoint = ({
  handleTestConnection,
}: DetailsAndEndpointProps) => {
  const [isTestValuesModalOpen, setIsTestValuesModalOpen] = useState(false);

  const { palette } = useTheme();
  const { values } = useFormikContext<SelectedActionDetail>();
  const { contextVariables } = useGetContextVariables({
    shouldIncludeTemplateContextVariables: true,
  });

  const { body, headers, params, url } = values.fields;

  const inputParams = useMemo(() => {
    const flattenedCvs = flattenCvs(contextVariables);

    const nonUnique = [
      ...params.flatMap(({ value }) => {
        const variableIDs = extractCvIdsFromFieldValue(value);
        return flattenedCvs.filter(({ context_variable_id: id }) =>
          variableIDs?.includes(id ?? ''),
        );
      }),
      ...headers.flatMap(({ value }) => {
        const variableIDs = extractCvIdsFromFieldValue(value);
        return flattenedCvs.filter(({ context_variable_id: id }) =>
          variableIDs?.includes(id ?? ''),
        );
      }),
      ...(extractCvIdsFromFieldValue(JSON.stringify(body))?.map(variableId =>
        flattenedCvs.find(({ context_variable_id: id }) => id === variableId),
      ) ?? []),
      ...(extractCvIdsFromFieldValue(url)?.map(variableId =>
        flattenedCvs.find(({ context_variable_id: id }) => id === variableId),
      ) ?? []),
    ].filter((param): param is ContextVariable => param !== undefined);

    return Array.from(new Set(nonUnique));
  }, [body, contextVariables, headers, params, url]);

  const onCloseTestValuesModal = () => {
    setIsTestValuesModalOpen(false);
  };

  const onClickSend = () => {
    if (inputParams.length) {
      setIsTestValuesModalOpen(true);
      return;
    }

    handleTestConnection(values);
  };

  return (
    <>
      <FormAccordion
        header={
          <Grid container spacing={3}>
            <Grid display='flex' item xs={8}>
              <Check errorsToCheck={['fields']} />
              <Typography variant='font16Bold'>Endpoint Url</Typography>
            </Grid>
            <Grid display='flex' item xs={4}>
              <Check errorsToCheck={['name', 'description']} />
              <Typography variant='font16Bold'>
                Action name and description
              </Typography>
            </Grid>
          </Grid>
        }
      >
        <Grid container flex={1} spacing={3}>
          <Grid item pr={3} xs={8}>
            <EndpointForm onClickSend={onClickSend} />
          </Grid>
          <Grid
            borderLeft={`1px solid ${palette.colors.grey[100]}`}
            item
            xs={4}
          >
            <DetailsForm inputParams={inputParams} />
          </Grid>
        </Grid>
      </FormAccordion>
      <TestValuesModal
        handleTestConnection={handleTestConnection}
        inputParams={inputParams}
        isOpen={isTestValuesModalOpen}
        onClose={onCloseTestValuesModal}
      />
    </>
  );
};

interface ResponseAndOutputProps {
  isError: boolean | string;
  isLoading: boolean;
  jmespathQueryTestCvs: Partial<Record<string, string>>;
  responseData: unknown;
  setJmespathQueryTestCvs: (
    jmespathQueryTestCvs: Partial<Record<string, string>>,
  ) => void;
}

const CurrentViewContainer = ({ children }: { children: React.ReactNode }) => {
  const { palette } = useTheme();

  return (
    <Box
      alignItems='center'
      display='flex'
      justifyContent='center'
      mt={3}
      whiteSpace='normal'
    >
      <Typography color={palette.text.secondary} variant='font14'>
        {children}
      </Typography>
    </Box>
  );
};

const ResponseAndOutput = ({
  isError,
  isLoading,
  jmespathQueryTestCvs,
  responseData,
  setJmespathQueryTestCvs,
}: ResponseAndOutputProps) => {
  const dispatch = useAppDispatch();

  const ref = useObserveAndSetJsonTableHeight();

  const [{ value }, , { setValue }] = useField<OutputValues[]>({
    name: 'outputValues',
  });
  const isActive = useGetActionBuilderDetailFormIsActive();

  const onAddNewParam = useCallback(
    (outputValues: OutputValues) => setValue([...value, outputValues]),
    [setValue, value],
  );

  const clearOutputParameters = useCallback(() => {
    setValue([]);
    dispatch(clearOutputParametersModalActionData());
  }, [setValue, dispatch]);

  const isResponseEmpty = isEmpty(responseData);

  const getCurrentViewComponent = () => {
    if (isResponseEmpty && responseData !== null) {
      return <CurrentViewContainer>Response is empty</CurrentViewContainer>;
    }

    if (isError) {
      return (
        <CurrentViewContainer>
          {typeof isError === 'string'
            ? isError.slice(0, 600) + '...'
            : 'Request Failed. Please try again.'}
        </CurrentViewContainer>
      );
    }

    if (isLoading) {
      return (
        <Box height='100%'>
          <Skeleton height='40px' />
          <Skeleton height='40px' />
          <Skeleton height='40px' />
          <Skeleton height='40px' />
        </Box>
      );
    }

    if (responseData === null) {
      return (
        <CurrentViewContainer>
          Enter the URL and click Send to get a response
        </CurrentViewContainer>
      );
    }

    return (
      <ResponseView
        clearOutputParameters={clearOutputParameters}
        isActive={isActive}
        jmespathQueryTestCvs={jmespathQueryTestCvs}
        jsonData={responseData}
        onAddNewParam={onAddNewParam}
        setJmespathQueryTestCvs={setJmespathQueryTestCvs}
      />
    );
  };

  const isDynamicListFeatureFlagEnabled = useIsDynamicListFeatureFlagEnabled();

  return (
    <FormAccordion
      header={
        <Grid container spacing={3}>
          <Grid item xs={8}>
            <Typography variant='font16Bold'>Response</Typography>
            {!isDynamicListFeatureFlagEnabled && (
              <Box display='inline' marginLeft='15px'>
                <Typography variant='font12Medium'>
                  Utilize &apos;Advanced Filter&apos; to refine the response or
                  employ keyword search
                </Typography>
              </Box>
            )}
          </Grid>
          <Grid display='flex' item xs={4}>
            <Check errorsToCheck={['outputValues']} />
            <Typography variant='font16Bold'>Output parameters</Typography>
          </Grid>
        </Grid>
      }
    >
      <Grid container flex={1} height='100%' spacing={3}>
        <Grid item p={3} xs={8}>
          {isDynamicListFeatureFlagEnabled && (
            <Box
              sx={theme => ({
                '.MuiTypography-root': {
                  background: `linear-gradient(270deg, ${theme.palette.colors.brand.magenta} 5.08%, ${theme.palette.colors.brand.deepBlue} 94.92%)`,
                  fontStyle: 'italic',

                  WebkitBackgroundClip: 'text',
                  WebkitTextFillColor: 'transparent',
                },
                lineHeight: '18px',
                mb: 2,
              })}
            >
              <Typography variant='font12Medium'>
                Select an array [] to generate a dynamic list of CVs, or select
                an individual entity for a single CV. Utilize &apos;Advanced
                Filter&apos; with JMESPath for precise filtering.
              </Typography>
            </Box>
          )}
          {getCurrentViewComponent()}
        </Grid>
        <Grid item xs={4}>
          <div ref={ref}>
            <OutputValues />
          </div>
        </Grid>
      </Grid>
    </FormAccordion>
  );
};

const OutputValues = () => {
  const [{ value }] = useField<OutputValues[]>({
    name: 'outputValues',
  });

  if (!value.length) {
    return (
      <CurrentViewContainer>
        Click on Response to add Output parameters
      </CurrentViewContainer>
    );
  }

  return (
    <FieldArray
      name='outputValues'
      render={arrayHelpers =>
        value.map((_, index) => (
          <OutputValue
            index={index}
            key={index}
            onRemove={() => arrayHelpers.remove(index)}
          />
        ))
      }
    />
  );
};

interface OutputValueProps {
  index: number;
  onRemove: () => void;
}

const OutputValue = ({ index, onRemove }: OutputValueProps) => {
  const [{ value: outputValues }, meta, { setValue: setOutputValues }] =
    useField<OutputValues>({
      name: `outputValues.${index}`,
    });
  const isActive = useGetActionBuilderDetailFormIsActive();
  const ref = useRef<HTMLDivElement>(null);

  const { value } = outputValues ?? {};

  useEffect(() => {
    const target = ref.current;
    const smoothBehaviorScrollOptions = { behavior: 'smooth' } as const;
    // If the field is new we will scroll into view.
    // We can expect a new field to have a falsy value
    if (!target || value) {
      return;
    }

    const scrollIntoViewIfNeeded = () => {
      // Target is outside the viewport from the bottom
      if (target.getBoundingClientRect().bottom > window.innerHeight) {
        target.scrollIntoView(smoothBehaviorScrollOptions);
      }

      // Target is outside the view from the top
      if (target.getBoundingClientRect().top < 0) {
        target.scrollIntoView(smoothBehaviorScrollOptions);
      }
    };

    scrollIntoViewIfNeeded();
  }, [value]);

  const setValue = useCallback(
    (value: string) => {
      setOutputValues({ ...outputValues, value });
    },
    [outputValues, setOutputValues],
  );

  const isDynamicListOutputValue = isDynamicListKvPairs(outputValues);

  return (
    <Box
      display='flex'
      flexDirection='column'
      gap={1}
      key={index}
      mb={2}
      ref={ref}
    >
      {isDynamicListOutputValue ? (
        <DynamicListItem>
          <ActionBuilderFormTextField
            disabled
            label='Array[] value for dynamic list'
            name={`outputValues.${index}.key`}
            required
          />
          <Box alignItems='center' display='flex' gap={1}>
            <ActionBuilderFormTextField
              disabled={Boolean(value)}
              label='Name for dynamic cv list'
              name={`outputValues.${index}.newContextVariableName`}
              placeholder='Give it a name'
              required
            />
            <IconButton
              aria-label='Remove Value'
              disabled={isActive}
              onClick={onRemove}
              variant='ghost'
            >
              <IconTrash size={20} />
            </IconButton>
          </Box>
        </DynamicListItem>
      ) : (
        <>
          <ActionBuilderFormTextField
            disabled
            label='Key'
            name={`outputValues.${index}.key`}
            required
          />
          <Box alignItems='center' display='flex' gap={1}>
            <Box flex={1}>
              <ContextVariableSelectDropdown
                disabled={isActive}
                error={meta.error}
                onChange={setValue}
                shouldIncludeDynamicListContextVariables={false}
                shouldIncludeTemplateContextVariables={isActive}
                value={value}
              />
            </Box>
            <IconButton
              aria-label='Remove Value'
              disabled={isActive}
              onClick={onRemove}
              variant='ghost'
            >
              <IconTrash size={20} />
            </IconButton>
          </Box>
        </>
      )}
    </Box>
  );
};

const useObserveAndSetJsonTableHeight = () => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new ResizeObserver(resizeEntries => {
      const [entry] = resizeEntries;

      document.documentElement.style.setProperty(
        '--json-editor-table-height',
        `${entry.contentRect.height}px`,
      );
    });

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  return ref;
};

export default ActionBuilderForm;
