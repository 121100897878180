import { Theme } from '@mui/material';

import { percentageValueAsString } from '../helpers';

const GraphCenterElement = ({
  theme,
  value,
}: {
  theme: Theme;
  value?: number | null;
}) => {
  if (typeof value !== 'number') {
    return undefined;
  }
  return (
    <p
      style={{
        color:
          value >= 0
            ? theme.palette.colors.green[500]
            : theme.palette.colors.red[500],
        fontFamily: theme.typography.fontFamily,
        fontSize: 14,
        fontWeight: 500,
        lineHeight: '20px',
        margin: 0,
        padding: 0,
      }}
    >
      {percentageValueAsString(value)}
    </p>
  );
};

export default GraphCenterElement;
