import { PropsWithChildren } from 'react';
import Box from '@mui/material/Box';

interface TableOverflowCellProps {
  clamp?: number;
}

const TableOverflowCell = ({
  children,
  clamp = 3,
}: PropsWithChildren<TableOverflowCellProps>) => {
  return (
    <Box
      sx={{
        display: '-webkit-box',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: clamp,
        width: '100%',
      }}
    >
      {children}
    </Box>
  );
};

export default TableOverflowCell;
