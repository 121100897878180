import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';

import { Typography } from '@forethought-technologies/forethought-elements';
import { normalizeTableFloat } from '../normalizeTableFloat';
import DiscoverSentimentEmoji from 'src/components/discover-sentiment-emoji';
import { DiscoverTopicAggregatedMetricType } from 'src/reducers/discoverReducer/types';
import {
  getNumberIndicator,
  getSentimentEmojiVariantFromValue,
} from 'src/utils/discover/helpers';

export const PercentChangeRendererComponent = ({
  isHidden,
  metricFilter,
  value,
}: {
  isHidden: boolean;
  metricFilter: DiscoverTopicAggregatedMetricType | '';
  value: number | null;
}) => {
  const { palette } = useTheme();
  if (value === null) {
    return (
      <Typography
        color={isHidden ? palette.colors.grey[600] : palette.grey[800]}
        variant='font14'
      >
        <i>N/A</i>
      </Typography>
    );
  }

  return (
    <Box alignItems='center' display='flex'>
      <Typography
        color={isHidden ? palette.colors.grey[600] : palette.colors.black}
        variant='font14'
      >
        {metricFilter === 'sentiment'
          ? value
          : `${getNumberIndicator(value)}${normalizeTableFloat(value)}%`}
      </Typography>
      {metricFilter === 'sentiment' && (
        <DiscoverSentimentEmoji
          ml={1}
          size='medium'
          variant={getSentimentEmojiVariantFromValue(value)}
        />
      )}
    </Box>
  );
};
