import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Box, Snackbar, SnackbarContent, useTheme } from '@mui/material';
import { IconCopy } from '@tabler/icons-react';

import { Typography } from '@forethought-technologies/forethought-elements';
import {
  selectGlobalCopyToastOptions,
  setGlobalCopyToastOptions,
} from 'src/slices/ui/uiSlice';
import { useAppDispatch } from 'src/store/hooks';

export default function GlobalCopyToast() {
  const { palette } = useTheme();
  const dispatch = useAppDispatch();
  const toastOptions = useSelector(selectGlobalCopyToastOptions);
  const location = useLocation();

  const isOpen = Boolean(toastOptions);

  const handleOnClose = useCallback(() => {
    dispatch(setGlobalCopyToastOptions(null));
  }, [dispatch]);

  useEffect(() => {
    if (!location.pathname.includes('workflow-builder')) {
      handleOnClose();
    }
  }, [location.pathname, handleOnClose]);

  if (!toastOptions) {
    return null;
  }

  return (
    <Snackbar
      anchorOrigin={{
        horizontal: 'center',
        vertical: 'top',
      }}
      open={isOpen}
      sx={{
        '& .MuiSnackbarContent-message': {
          margin: 0,
          padding: 0,
        },
        '@media (min-width: 600px)': {
          top: '120px',
        },
      }}
    >
      <SnackbarContent
        message={
          <Box
            alignItems='center'
            display='flex'
            gap={1}
            sx={{
              '.MuiTypography-root': {
                '&::selection': {
                  background: palette.colors.grey[600],
                },
              },
            }}
          >
            <IconCopy
              color={palette.colors.white}
              size={20}
              style={{ paddingRight: '12px', transform: 'scaleX(-1)' }}
            />
            <Typography color={palette.colors.white} variant='font12'>
              {toastOptions.title}
            </Typography>
            <Box
              onClick={handleOnClose}
              sx={{
                borderLeft: `1px solid ${palette.colors.grey[700]}`,
                cursor: 'pointer',
                padding: '8px 12px',
              }}
            >
              <Typography color={palette.colors.white} variant='font14Bold'>
                Hide
              </Typography>
            </Box>
          </Box>
        }
        style={{
          backgroundColor: palette.colors.grey[800],
          margin: 0,
          padding: 0,
        }}
      />
    </Snackbar>
  );
}
