import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';

import { theme } from '@forethought-technologies/forethought-elements';
import Spinner from '../../../spinner';
import {
  getIsModelPremium,
  prepareTriageFieldsByHelpdesk,
  TOAST_TIMEOUT,
} from '../helpers';
import { useGetCurrentDataSourcePredictedField } from '../hooks/useGetCurrentDataSourcePredictedField';
import { useGetCurrentFieldPredictorOrigins } from '../hooks/useGetCurrentFieldPredictorOrigins';
import { useGetDataSourcePredictedFields } from '../hooks/useGetDataSourcePredictedFields';
import { OutputFieldInputType } from '../types';
import TriageConfigDetailSettingAlert from './TriageConfigDetailSettingAlert';
import TriageConfigDetailSettingsEdit from './TriageConfigDetailSettingEdit';
import TriageConfigDetailSettingEmpty from './TriageConfigDetailSettingEmpty';
import TriageConfigDetailSettingHeader from './TriageConfigDetailSettingHeader';
import TriageConfigDetailSettingRead from './TriageConfigDetailSettingRead';
import {
  getPredictedFields,
  updatePredictedFieldOfTriageModelByModelName,
} from 'src/actions/triageSettings/triageSettings';
import { Helpdesk } from 'src/components/app/types';
import { useEmitTrackingEventCallback } from 'src/hooks/hooks';
import {
  selectCreatedHelpdeskConnectors,
  selectHelpdesk,
  selectIntegrationSelectedState,
  selectIsHelpdeskNotConfigured,
  selectIsHelpdeskSupported,
  selectSupportedHelpdesks,
} from 'src/reducers/triageSettingsReducer/triageSettingsReducer';
import {
  SelfServeFormInput,
  TriageModelDetail,
} from 'src/reducers/triageSettingsReducer/types';
import { setGlobalToastOptions } from 'src/slices/ui/uiSlice';
import { useAppDispatch } from 'src/store/hooks';

interface TriageConfigDetailSettingProps {
  triageModel: TriageModelDetail;
}

const TriageConfigDetailSetting = ({
  triageModel,
}: TriageConfigDetailSettingProps) => {
  const dispatch = useAppDispatch();
  const helpdesk = useSelector(selectHelpdesk);
  const integrationSelectedState = useSelector(selectIntegrationSelectedState);
  const isHelpdeskSupported = useSelector(selectIsHelpdeskSupported);
  const isHelpdeskNotConfigured = useSelector(selectIsHelpdeskNotConfigured);
  const createdHelpdeskConnectors = useSelector(
    selectCreatedHelpdeskConnectors,
  );
  const supportedHelpdesks = useSelector(selectSupportedHelpdesks);
  const emitTrackingEventCallback = useEmitTrackingEventCallback();

  // Dropdown
  const {
    origins,
    outputFields: predictedFieldsDropdownOptions,
    permissions,
    preselectedOrigins,
  } = useGetDataSourcePredictedFields();
  const {
    predictedFieldValue,
    selectedDataSourcePredictedField,
    setPredictedFieldValue,
  } = useGetCurrentDataSourcePredictedField();

  const {
    hasUpdatedOrigins,
    originFieldValue,
    selectedOrigins,
    setOriginFieldValue,
  } = useGetCurrentFieldPredictorOrigins(origins, preselectedOrigins);

  const [isEditing, setIsEditing] = useState(false);
  /**
   * choice - state where user needs to choose between new field vs existing field
   * new - state to manage new field flow (textfield)
   * exist - state to manage exist field flow (dropdown)
   */
  const [outputFieldInputType, setOutputFieldInputType] =
    useState<OutputFieldInputType>('picking-field-choice');

  const [helpdeskDataSourceValue, setHelpdeskDataSourceValue] = useState<
    Helpdesk | ''
  >(helpdesk?.name ?? '');
  const [objectFieldValue, setObjectFieldValue] = useState('Case');

  // Textfield
  const [outputFieldTextInputValue, setOutputFieldTextInputValue] = useState(
    (triageModel?.name.split('-')[0] ?? '') + '_FT',
  );
  // Dialog
  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);

  const selectedHelpdesk = useMemo(() => {
    return createdHelpdeskConnectors.find(
      ({ name }) => name === helpdeskDataSourceValue,
    );
  }, [helpdeskDataSourceValue, createdHelpdeskConnectors]);

  const selectedPredictedField = useMemo(() => {
    return predictedFieldsDropdownOptions.find(
      ({ value }) => value === predictedFieldValue,
    );
  }, [predictedFieldValue, predictedFieldsDropdownOptions]);

  // User flow flags
  const isPickingFieldChoiceFlow =
    outputFieldInputType === 'picking-field-choice';
  const isNewFieldFlow = outputFieldInputType === 'new-field';
  const isChoosingExistingFieldFlow = outputFieldInputType === 'existing-field';
  const isIntegrationFieldError = integrationSelectedState === 'error';

  useEffect(() => {
    if (!selectedDataSourcePredictedField?.value && !isIntegrationFieldError) {
      return;
    }
    setOutputFieldInputType('existing-field');
  }, [selectedDataSourcePredictedField?.value, isIntegrationFieldError]);

  const isPremium = getIsModelPremium(triageModel.model_paid_plan);

  const isIntegrationNotSelected = integrationSelectedState === 'not-selected';
  const isIntegrationLoading = integrationSelectedState === 'loading';

  const isDropdownPredictedFieldEmpty =
    isChoosingExistingFieldFlow && predictedFieldValue === '';
  const hasUpdatedDropdownPredictedField =
    isChoosingExistingFieldFlow &&
    predictedFieldValue !== selectedDataSourcePredictedField?.value;
  const isTextPredictedFieldEmpty =
    isNewFieldFlow && outputFieldTextInputValue.trim().length === 0;
  const isTextPredictedFieldUnique =
    isNewFieldFlow &&
    predictedFieldsDropdownOptions.every(
      item => item.label !== outputFieldTextInputValue.trim(),
    );

  const hasUpdatedField =
    integrationSelectedState === 'selected'
      ? hasUpdatedOrigins ||
        hasUpdatedDropdownPredictedField ||
        isTextPredictedFieldUnique
      : hasUpdatedDropdownPredictedField || isTextPredictedFieldUnique;

  const hasOnlyUpdatedOrigins =
    hasUpdatedOrigins && !hasUpdatedDropdownPredictedField;
  const hasNoOrigins =
    helpdesk?.name === 'salesforce' && !originFieldValue.length;

  const isEditSaveButtonDisabled =
    isDropdownPredictedFieldEmpty ||
    isTextPredictedFieldEmpty ||
    isPickingFieldChoiceFlow ||
    !hasUpdatedField ||
    hasNoOrigins;

  const dialogSaveText = `The model will output to the existing ${helpdesk?.display_name} field “${selectedPredictedField?.label}”. It will overwrite existing values. \n\nAdd a new field to avoid overwriting existing values.`;
  const toastSaveText = isChoosingExistingFieldFlow
    ? `Integration saved. Going forward, the model will output to the new field “${selectedPredictedField?.label}”.`
    : 'Integration saved';

  const handleUpdatePredictedField = () => {
    const helpdeskName = helpdesk?.name ?? 'other';
    const body: SelfServeFormInput = {
      helpdesk_data_source: helpdeskName,
      output_field_config: {
        case_origins: selectedOrigins,
        is_create_new_field: isNewFieldFlow,
        new_output_field: prepareTriageFieldsByHelpdesk({
          helpdesk: helpdeskName,
          isChoosingExistingFieldFlow,
          outputFieldTextInputValue,
          selectedPredictedField,
        }),
      },
    };

    return dispatch(
      updatePredictedFieldOfTriageModelByModelName({
        body,
        modelName: triageModel.name,
      }),
    ).unwrap();
  };

  const handleOnSaveToApi = async () => {
    emitTrackingEventCallback('triage-clicked-save-prediction-config-button', {
      model_name: triageModel?.name,
      scope: 'self-serve',
    });

    // unwrap can throw. We need try catch to reach `setIsSaveModalOpen(false);` logic
    try {
      await handleUpdatePredictedField();
      await dispatch(
        getPredictedFields({
          helpdesk,
          isHelpdeskNotConfigured,
        }),
      ).unwrap();
      dispatch(
        setGlobalToastOptions({
          autoHideDuration: TOAST_TIMEOUT,
          title: toastSaveText,
          variant: 'main',
        }),
      );
    } catch (error) {
      console.error(error);
    }

    setIsEditing(false);
    setIsSaveModalOpen(false);
  };

  const handleOnSaveToUi = () => {
    // When choosing new field immediately saves
    if (isNewFieldFlow || hasOnlyUpdatedOrigins) {
      handleOnSaveToApi();
      return;
    }
    // Verify modal for overwriting
    if (isChoosingExistingFieldFlow) {
      setIsSaveModalOpen(true);
    }
  };

  const handleCancelOnEditMode = () => {
    setIsEditing(false);
    // Reset user inputs
    setOutputFieldTextInputValue('');
    if (selectedDataSourcePredictedField) {
      setPredictedFieldValue(selectedDataSourcePredictedField.value);
    }

    const fieldTypeOnReset =
      selectedDataSourcePredictedField || isIntegrationFieldError
        ? 'existing-field'
        : 'picking-field-choice';
    setOutputFieldInputType(fieldTypeOnReset);
  };

  const renderTableComponent = () => {
    if (isIntegrationLoading && !isSaveModalOpen) {
      return <Spinner />;
    }

    if (
      isHelpdeskNotConfigured ||
      permissions?.has_minimal_permission === false
    ) {
      return <TriageConfigDetailSettingEmpty />;
    }

    if (isEditing) {
      return (
        <TriageConfigDetailSettingsEdit
          helpdeskDataSourceDropdownOptions={createdHelpdeskConnectors.map(
            ({ avatar, display_name, name }) => ({
              disabled: supportedHelpdesks.every(helpdesk => helpdesk !== name),
              label: display_name,
              optionStartAdornment: <AvatarIcon src={avatar} />,
              value: name,
            }),
          )}
          helpdeskDataSourceValue={helpdeskDataSourceValue}
          isTextPredictedFieldUnique={isTextPredictedFieldUnique}
          objectFieldValue={objectFieldValue}
          originFieldValue={originFieldValue}
          originsDropdownOptions={origins}
          outputFieldInputType={outputFieldInputType}
          outputFieldTextInputValue={outputFieldTextInputValue}
          permissions={permissions}
          predictedFieldDropdownOptions={predictedFieldsDropdownOptions}
          predictedFieldValue={predictedFieldValue}
          selectedHelpdesk={selectedHelpdesk}
          setHelpdeskDataSourceValue={setHelpdeskDataSourceValue}
          setObjectFieldValue={setObjectFieldValue}
          setOriginFieldValue={setOriginFieldValue}
          setOutputFieldInputType={setOutputFieldInputType}
          setOutputFieldTextInputValue={setOutputFieldTextInputValue}
          setPredictedFieldValue={setPredictedFieldValue}
        />
      );
    }

    if (isIntegrationNotSelected || !isHelpdeskSupported) {
      return <TriageConfigDetailSettingEmpty setIsEditing={setIsEditing} />;
    }

    return (
      <TriageConfigDetailSettingRead
        helpdeskDataSourceValue={helpdeskDataSourceValue}
        isPremium={isPremium}
        selectedHelpdesk={selectedHelpdesk}
      />
    );
  };

  return (
    <>
      <TriageConfigDetailSettingHeader
        handleCancelOnEditMode={handleCancelOnEditMode}
        handleOnSaveToUi={handleOnSaveToUi}
        isDisabled={isEditSaveButtonDisabled}
        isEditing={isEditing}
        setIsEditing={setIsEditing}
      />
      <SettingTableContainer>{renderTableComponent()}</SettingTableContainer>
      <TriageConfigDetailSettingAlert
        dialogSaveText={dialogSaveText}
        handleOnSaveToApi={handleOnSaveToApi}
        isSaveModalOpen={isSaveModalOpen}
        setIsSaveModalOpen={setIsSaveModalOpen}
      />
    </>
  );
};

const SettingTableContainer = styled('div')`
  border-top: 1px solid ${theme.palette.colors.slate[200]};
`;

export const AvatarIcon = styled('img')`
  height: 20px;
  width: 20px;
  margin-right: 6px;
`;

export default TriageConfigDetailSetting;
