import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { MRT_Updater } from 'material-react-table';
import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { IconAdjustmentsHorizontal } from '@tabler/icons-react';
import { VisibilityState } from '@tanstack/react-table';

import {
  Button,
  Checkbox,
  DateRangeFilterButton,
  MultiSelectFilter,
  SearchBar,
  SearchWithDropdown,
  Typography,
} from '@forethought-technologies/forethought-elements';
import DiscoverLastUpdatedAt from '../common/discover-last-updated-at';
import { DateRange } from '../discover-dashboard-page/types';
import { getMetricFromMetricsParam } from './AllTopicsTableV3/helpers';
import AllTopicsMetricRow from './components/AllTopicsMetricRow';
import AllTopicsTable from './AllTopicsTable';
import AllTopicsTableV3 from './AllTopicsTableV3';
import { SEARCH_ERROR_TEXT } from './constants';
import {
  HistogramFilters,
  MetricMultiFilterValue,
  SearchType,
  SelectedHistogramFilter,
} from './types';
import isEqual from 'lodash/fp/isEqual';
import AnalyticsFilter from 'src/components/analytic-filter';
import { GroupedMultiSelectFilterOptions } from 'src/components/app/types';
import DiscoverErrorPage from 'src/components/discover-error-page';
import FilterButtonIcon from 'src/components/discover-filter-button-icon/FilterButtonIcon';
import {
  datePickerRangeOptions,
  DISCOVER_SHARED_PARAM_NAMES,
  groupedMultiSelectFilterOptions,
  initialColumnVisibility,
  initialDateRangeValue,
  initialGroupFilters,
  initialMetricFiltersDropdownValue,
  initialMetricFiltersDropdownValueV2,
  initialSearchTextValue,
  initialSearchType,
  initialShowPercentChanged,
  initialTimeFilterDropdownValue,
  timeFilterOptions,
} from 'src/constants/discover';
import { useDataFilter } from 'src/hooks/discover/useDataFilter';
import { useDebouncedTrackingEventOnSearchQueryChange } from 'src/hooks/discover/useDebouncedTrackingEventOnSearchQueryChange';
import { useGetAvailableMetricFilterOptions } from 'src/hooks/discover/useGetAvailableMetricFilterOptions';
import { useGetSearchTopicText } from 'src/hooks/discover/useGetSearchTopicText';
import useOrgConfig from 'src/hooks/discover/useOrgConfig';
import { usePersistSearchParams } from 'src/hooks/discover/usePersistSearchParams';
import {
  useEmitTrackingEvent,
  useEmitTrackingEventCallback,
  useStateParams,
} from 'src/hooks/hooks';
import { DiscoverTopicAggregatedMetricType } from 'src/reducers/discoverReducer/types';
import { useGetAllTopicsQuery } from 'src/services/discover/discoverApi';
import { getAppCuesId } from 'src/utils/appCuesUtil';
import {
  dateRangeDeserialize,
  dateRangeSerialize,
  dateRangeToTimeFilter,
  deriveTableMetricFilters,
  groupFiltersValidator,
  listDeserialize,
  listSerialize,
  metricFiltersValidator,
  metricFiltersValidatorV2,
  shouldShowDataAnalyticFilter,
  timeFilterParameterValidator,
} from 'src/utils/discover/helpers';
import { last30DaysTimeRange } from 'src/utils/timeRangeHelpers';

const MemoizedDateRangeFilterButton = memo(DateRangeFilterButton);
const MemoizedMultiSelectFilter = memo(MultiSelectFilter);
const MemoizedAnalyticsFilter = memo(AnalyticsFilter);

const DiscoverAllTopicsPage = () => {
  usePersistSearchParams();

  const { is_ticket_keyword_search_enabled, taxonomyVersion } = useOrgConfig();
  const theme = useTheme();
  const [dateRange, setDateRange] = useStateParams({
    deserialize: dateRangeDeserialize,
    initialState: datePickerRangeOptions[2].value,
    paramsName: DISCOVER_SHARED_PARAM_NAMES.TIME_FILTER,
    serialize: dateRangeSerialize,
    validator: timeFilterParameterValidator(timeFilterOptions),
  });
  const [metricFilters, setMetricFilters] = useStateParams<
    MetricMultiFilterValue[]
  >({
    deserialize: listDeserialize,
    initialState: initialMetricFiltersDropdownValue,
    paramsName: DISCOVER_SHARED_PARAM_NAMES.METRIC_FILTERS,
    serialize: listSerialize,
    validator: metricFiltersValidator(),
  });
  const metricFromMetricsParam = getMetricFromMetricsParam(metricFilters);
  const {
    dataFilterQuery,
    isLoading: isFilterLoading,
    queryFilterOptions,
    queryFilters,
    setQueryFilters,
  } = useDataFilter();

  const [selectedHistogramFilter, setSelectedHistogramFilter] =
    useState<SelectedHistogramFilter>({
      metricType: null,
      type: null,
      value: [],
    });
  const [histogramFilters, setHistogramFilters] = useState<HistogramFilters>(
    {},
  );
  const { searchText, setSearchText, setUiSearchText, uiSearchText } =
    useGetSearchTopicText();
  const [groupFilters, setGroupFilters] = useStateParams<
    GroupedMultiSelectFilterOptions[]
  >({
    deserialize: listDeserialize,
    initialState: initialGroupFilters,
    paramsName: DISCOVER_SHARED_PARAM_NAMES.GROUP_FILTERS,
    serialize: listSerialize,
    validator: groupFiltersValidator(),
  });
  const [showPercentChanged, setShowPercentChanged] = useStateParams<boolean>({
    deserialize: (value: string) => value === 'true',
    initialState: initialShowPercentChanged,
    paramsName: DISCOVER_SHARED_PARAM_NAMES.SHOW_PERCENT_CHANGED,
    serialize: (value: boolean) => value.toString(),
    validator: (param: string) => param === 'true' || param === 'false',
  });
  const [metricFiltersV2, setMetricFiltersV2] = useStateParams<
    DiscoverTopicAggregatedMetricType[]
  >({
    deserialize: listDeserialize,
    initialState: initialMetricFiltersDropdownValueV2,
    paramsName: DISCOVER_SHARED_PARAM_NAMES.METRIC_FILTERS_V2,
    serialize: listSerialize,
    validator: metricFiltersValidatorV2(),
  });
  const [isTopicGrouped, setIsTopicGrouped] = useStateParams<boolean>({
    deserialize: (value: string) => value === 'true',
    initialState: true,
    paramsName: DISCOVER_SHARED_PARAM_NAMES.GROUP_TOPIC,
    serialize: (value: boolean) => value.toString(),
    validator: (param: string) => param === 'true' || param === 'false',
  });
  const [searchType, setSearchType] = useStateParams<SearchType>({
    deserialize: item =>
      item !== 'topic' && item !== 'ticket' ? 'topic' : item,
    initialState: initialSearchType,
    paramsName: DISCOVER_SHARED_PARAM_NAMES.SEARCH_TYPE,
    serialize: item => item,
  });

  const firstSelectedIcon = useMemo(() => {
    return groupedMultiSelectFilterOptions.find(
      option => option.value === groupFilters[0],
    )?.optionStartAdornment;
  }, [groupFilters]);

  const timeFilter = dateRangeToTimeFilter(dateRange);

  useDebouncedTrackingEventOnSearchQueryChange({
    metricFilters,
    searchQuery: searchText,
    timeFilter,
  });

  const { data, isError, isFetching, isLoading, refetch } =
    useGetAllTopicsQuery({
      dataFilterQuery,
      keyword_search:
        searchType === 'ticket' && searchText ? searchText : undefined,
      timeFilter,
    });

  const [columnVisibility, setColumnVisibility] =
    useState<VisibilityState | null>(null);

  const loading = isLoading || isFetching;
  const availableMetricFilterOptions = useGetAvailableMetricFilterOptions();
  const metricFiltersOptions = useMemo(
    () => deriveTableMetricFilters(availableMetricFilterOptions),
    [availableMetricFilterOptions],
  );

  useEffect(() => {
    if (columnVisibility === null) {
      setColumnVisibility(
        metricFiltersV2.reduce((prev, current) => {
          return {
            ...prev,
            [current]: true,
          };
        }, {}),
      );
    }
  }, [metricFiltersV2, setColumnVisibility, columnVisibility]);

  useEffect(() => {
    if (searchType === 'ticket' && searchText) {
      refetch();
    }
  }, [searchType, searchText, refetch]);

  useEmitTrackingEvent('discover-all-topics-viewed');

  const emitTrackingEventCallback = useEmitTrackingEventCallback();

  const handleHistogramFilterReset = useCallback(() => {
    setHistogramFilters({});
  }, [setHistogramFilters]);

  const handleDateRangeOnChange = useCallback(
    (dateRange: DateRange) => {
      const timeFilter = dateRangeToTimeFilter(dateRange);
      setDateRange(dateRange);
      handleHistogramFilterReset();
      emitTrackingEventCallback('discover-time-frame-selected', {
        page: 'All Topics',
        'time-period-selected': timeFilter.isCustom ? 'custom' : timeFilter.key,
      });
    },
    [setDateRange, handleHistogramFilterReset, emitTrackingEventCallback],
  );

  const checkFilterUsed = () => {
    const isGeneralFilterUsed =
      searchText !== initialSearchTextValue ||
      timeFilter.key !== initialTimeFilterDropdownValue ||
      !isEqual(groupFilters, initialGroupFilters);

    const isHistogramFilterUsed = Boolean(Object.keys(histogramFilters).length);
    const isMetricFiltersUsed = !isEqual(
      metricFilters,
      initialMetricFiltersDropdownValue,
    );
    const isMetricFiltersUsedV2 = !isEqual(
      metricFiltersV2,
      initialMetricFiltersDropdownValueV2,
    );
    const isPercentageChangedUsed =
      showPercentChanged !== initialShowPercentChanged;

    const dataFilterUser = dataFilterQuery.length !== 0;
    return (
      isPercentageChangedUsed ||
      isMetricFiltersUsedV2 ||
      isGeneralFilterUsed ||
      isHistogramFilterUsed ||
      dataFilterUser ||
      isMetricFiltersUsed ||
      isTopicGrouped === false
    );
  };

  const handleFilterReset = useCallback(() => {
    setShowPercentChanged(initialShowPercentChanged, () =>
      setSearchText('', () =>
        setDateRange(initialDateRangeValue, () =>
          setMetricFilters(initialMetricFiltersDropdownValue, () =>
            setGroupFilters(initialGroupFilters, () =>
              setIsTopicGrouped(true, () => setQueryFilters([])),
            ),
          ),
        ),
      ),
    );

    setColumnVisibility(initialColumnVisibility);
    handleHistogramFilterReset();
    setUiSearchText('');
  }, [
    handleHistogramFilterReset,
    setColumnVisibility,
    setDateRange,
    setGroupFilters,
    setIsTopicGrouped,
    setMetricFilters,
    setQueryFilters,
    setSearchText,
    setShowPercentChanged,
    setUiSearchText,
  ]);

  useEffect(() => {
    const savedVisibility = localStorage.getItem('columnVisibility');
    if (savedVisibility) {
      setColumnVisibility(JSON.parse(savedVisibility));
    }
  }, [setColumnVisibility]);

  useEffect(() => {
    if (columnVisibility) {
      localStorage.setItem(
        'columnVisibility',
        JSON.stringify(columnVisibility),
      );

      const value = Object.entries(columnVisibility).reduce(
        (prev, [key, isVisible]) => {
          if (isVisible) {
            return prev.concat(key as DiscoverTopicAggregatedMetricType);
          }
          return prev;
        },
        [] as DiscoverTopicAggregatedMetricType[],
      );

      setMetricFiltersV2(value);
    }
  }, [columnVisibility, setMetricFiltersV2]);

  const handleColumnVisibilityChange = useCallback(
    (updater: MRT_Updater<VisibilityState>) => {
      setColumnVisibility(prevValue =>
        updater instanceof Function ? updater(prevValue || {}) : updater,
      );
    },
    [setColumnVisibility],
  );

  const isFilterUsed = checkFilterUsed();
  const lastUpdateAt = null;

  const handleSearch = useCallback(
    ({ target }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setUiSearchText(target.value);
    },
    [setUiSearchText],
  );
  const handleClear = useCallback(() => setUiSearchText(''), [setUiSearchText]);

  const TopToolbarCustomActions = useMemo(
    () => (
      <Box alignItems='center' display='flex' flexWrap='wrap' gap={1}>
        <Box minWidth='200px'>
          {is_ticket_keyword_search_enabled ? (
            <SearchWithDropdown
              disabled={loading}
              onChange={handleSearch}
              onClear={handleClear}
              options={[
                { text: 'Topic', value: 'topic' },
                { text: 'Ticket', value: 'ticket' },
              ]}
              placeholder='Search for a topic'
              selectChange={value => {
                setSearchType(value as 'topic' | 'ticket');
              }}
              selectedOption={searchType}
              value={uiSearchText}
            />
          ) : (
            <SearchBar
              onChange={handleSearch}
              onClear={handleClear}
              placeholder='Search for a topic'
              size='small'
              value={uiSearchText}
            />
          )}
        </Box>
        {taxonomyVersion !== 'V2' && (
          <MemoizedMultiSelectFilter
            aria-label='metric filter'
            data-appcues-target={getAppCuesId({
              componentType: 'button',
              featureName: 'topictable',
              pageName: 'alltopics',
              subType: 'metricfilter',
            })}
            defaultValue={initialMetricFiltersDropdownValue}
            icon={<FilterButtonIcon dropdownType='metric' />}
            onChange={value => {
              emitTrackingEventCallback('discover-metrics-selected', {
                'metrics-selected': value,
                page: 'All Topics',
                'time-period-selected': timeFilter.isCustom
                  ? 'custom'
                  : timeFilter.key,
              });

              setMetricFilters(value as MetricMultiFilterValue[]);
              handleHistogramFilterReset();
            }}
            options={metricFiltersOptions}
            placeholder='Select metric columns'
            value={metricFilters}
            variant='secondary'
          />
        )}
        <MemoizedDateRangeFilterButton
          data-appcues-target={getAppCuesId({
            componentType: 'button',
            featureName: 'topictable',
            pageName: 'alltopics',
            subType: 'daterange',
          })}
          initialValue={last30DaysTimeRange}
          onChange={dateRange => handleDateRangeOnChange(dateRange)}
          options={datePickerRangeOptions}
          value={timeFilter.value}
        />
        <MemoizedMultiSelectFilter
          icon={
            firstSelectedIcon ?? (
              <IconAdjustmentsHorizontal
                color={theme.palette.grey[800]}
                size={20}
              />
            )
          }
          isMenuSearchable={false}
          onChange={value => {
            setGroupFilters(value as GroupedMultiSelectFilterOptions[]);
            emitTrackingEventCallback('discover-group-filter-selected', {
              'group-selected': value,
              page: 'All Topics',
            });
          }}
          options={groupedMultiSelectFilterOptions}
          placeholder='Show only'
          value={groupFilters}
          variant='secondary'
        />
        {shouldShowDataAnalyticFilter(queryFilterOptions) && (
          <MemoizedAnalyticsFilter
            filters={queryFilters}
            handleFilterUpdate={setQueryFilters}
            isLoading={isFilterLoading}
            options={queryFilterOptions}
            page='allTopics'
          />
        )}

        {taxonomyVersion === 'V2' && (
          <Checkbox
            checked={showPercentChanged}
            label='Show % change'
            onChange={event => {
              setShowPercentChanged(event.target.checked);
            }}
          />
        )}
        <Checkbox
          checked={isTopicGrouped}
          label='Group Topics'
          onChange={event => {
            setIsTopicGrouped(event.target.checked);
          }}
        />
        {isFilterUsed && (
          <Button onClick={handleFilterReset} size='medium' variant='ghost'>
            <Typography variant='font14Medium'>Reset filters</Typography>
          </Button>
        )}
        {lastUpdateAt && <DiscoverLastUpdatedAt lastUpdateAt={lastUpdateAt} />}
      </Box>
    ),
    [
      emitTrackingEventCallback,
      firstSelectedIcon,
      groupFilters,
      handleClear,
      handleDateRangeOnChange,
      handleFilterReset,
      handleHistogramFilterReset,
      handleSearch,
      is_ticket_keyword_search_enabled,
      isFilterLoading,
      isFilterUsed,
      isTopicGrouped,
      loading,
      metricFilters,
      metricFiltersOptions,
      queryFilterOptions,
      queryFilters,
      searchType,
      setGroupFilters,
      setIsTopicGrouped,
      setMetricFilters,
      setQueryFilters,
      setSearchType,
      setShowPercentChanged,
      showPercentChanged,
      taxonomyVersion,
      theme.palette.grey,
      timeFilter.isCustom,
      timeFilter.key,
      timeFilter.value,
      uiSearchText,
    ],
  );

  const tableFilters = useMemo(
    () => ({
      groupFilters,
      metricFilters: metricFiltersV2,
      searchText,
      showPercentChanged,
      timeFilter,
    }),
    [groupFilters, metricFiltersV2, searchText, showPercentChanged, timeFilter],
  );

  const histogram = useMemo(
    () => ({
      histogramFilters,
      selectedHistogramFilter,
      setHistogramFilters,
      setSelectedHistogramFilter,
    }),
    [
      histogramFilters,
      selectedHistogramFilter,
      setHistogramFilters,
      setSelectedHistogramFilter,
    ],
  );

  if (isError) {
    return <DiscoverErrorPage errorType={SEARCH_ERROR_TEXT} />;
  }

  return (
    <Box
      bgcolor={theme.palette.colors.white}
      display='flex'
      flexDirection='column'
      minHeight='calc(100vh - 69px)'
      px={5}
      sx={{
        label: {
          marginRight: 0,
        },
      }}
    >
      <Box
        bgcolor={theme.palette.colors.white}
        display='flex'
        flexDirection='column'
        mb={1.25}
      >
        {taxonomyVersion === 'V2' && (
          <Box display='flex' flexDirection='column' mb={3}>
            <AllTopicsMetricRow
              dateLabel={timeFilter.label}
              isLoading={loading}
              metrics={data?.aggregate.metrics}
            />
          </Box>
        )}
        {taxonomyVersion === 'V1' && TopToolbarCustomActions}
      </Box>
      <Box
        id={'all-topics-table-container'}
        sx={{
          flex: 1,
          overflowX: 'auto',
          overflowY: 'hidden',
        }}
      >
        {taxonomyVersion === 'V2' && (
          <AllTopicsTableV3
            data={data}
            filters={tableFilters}
            histogram={histogram}
            isLoading={loading}
            isTopicGrouped={isTopicGrouped}
            metricFromMetricsParam={metricFromMetricsParam}
            onColumnVisibilityChange={handleColumnVisibilityChange}
            searchType={searchType}
            TopToolbarCustomActions={TopToolbarCustomActions}
          />
        )}
        {taxonomyVersion === 'V1' && (
          <AllTopicsTable
            data={data}
            groupFilters={groupFilters}
            histogramFilters={histogramFilters}
            isLoading={loading}
            metricFilters={metricFilters}
            searchText={searchText.toLowerCase()}
            selectedHistogramFilter={selectedHistogramFilter}
            setHistogramFilters={setHistogramFilters}
            setSelectedHistogramFilter={setSelectedHistogramFilter}
            timeFilter={timeFilter}
          />
        )}
      </Box>
    </Box>
  );
};

export default DiscoverAllTopicsPage;
