import { useMemo } from 'react';

import { useSolveMetricsQueryParams } from './useSolveMetricsQueryParams';
import keyBy from 'lodash/fp/keyBy';
import { useGetWidgetBreakdownMetricsQuery } from 'src/services/workflow-builder-metrics';
import { BreakdownData } from 'src/types/workflowBuilderAPITypes';

export function useWidgetMetricBreakdownById(tags?: string[]) {
  const { channel, from, to } = useSolveMetricsQueryParams();

  const { data, isFetching } = useGetWidgetBreakdownMetricsQuery({
    channel,
    from,
    tags: tags?.length ? tags : undefined,
    to,
  });

  return useMemo(() => {
    const breakdownById = keyBy<BreakdownData>('id')(data?.breakdown);

    return { breakdownById, isFetching };
  }, [data, isFetching]);
}
