import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { styled } from '@mui/material';

import { theme } from '@forethought-technologies/forethought-elements';
import ChatList from '../chat-list/ChatList';
import { useDropAction } from '../hooks/useDropAction';
import { CanvasModes } from 'src/pages/workflow-builder-edit/constants';
import { ForethoughtCanvasProps } from 'src/pages/workflow-builder-edit/types/canvasComponentTypes';
import {
  selectCanvasWorkflowBuilder,
  selectEditingStepId,
  selectIsPasteAvailable,
  selectMode,
} from 'src/reducers/workflowBuilderReducer/workflowBuilderReducer';
import {
  CanvasWorkflowBuilderState,
  selectIsSqueezingStep,
} from 'src/slices/canvas-workflow-builder/workflowBuilderSlice';

const ForethoughtCanvas: React.FC<
  React.PropsWithChildren<ForethoughtCanvasProps>
> = ({
  actionPanelVisibilityParameters,
  editorRef,
  hasWorkflowConflict,
  setActionPanelVisibilityParameters,
  shouldSqueezeIntoEntry,
  squeezeStepParentId,
}) => {
  const editingStepId = useSelector(selectEditingStepId);
  const canvasData: CanvasWorkflowBuilderState = useSelector(
    selectCanvasWorkflowBuilder,
  );
  const mode = useSelector(selectMode);
  const isPasteAvailable = useSelector(selectIsPasteAvailable);
  const isSqueezingStep = useSelector(selectIsSqueezingStep);

  const { intent_workflow_id } = canvasData;

  const isEditMode = editingStepId !== '';
  const isCanvasDisabled =
    [
      CanvasModes.ACTION,
      CanvasModes.CONDITION,
      CanvasModes.GO_TO_INTENT,
      CanvasModes.ARTICLE_PICKER,
      CanvasModes.ACTION_EDIT,
    ].includes(mode) ||
    isEditMode ||
    isSqueezingStep ||
    canvasData.loading;

  const canvasRef = useRef<HTMLDivElement>(null);
  const actionOutlineContainerRef = useRef<HTMLDivElement>(null);

  const { drop, isOver } = useDropAction({
    intentWorkflowId: intent_workflow_id,
  });

  useEffect(() => {
    if (mode === CanvasModes.ACTION) {
      actionOutlineContainerRef.current?.scrollIntoView({
        behavior: 'smooth',
      });
    }
  }, [mode]);

  return (
    <CanvasContainer aria-label='canvas' ref={drop} role='group'>
      <CanvasScroll ref={canvasRef}>
        <Maskable
          isOver={
            isOver ||
            isCanvasDisabled ||
            isSqueezingStep ||
            actionPanelVisibilityParameters !== 'hidden'
          }
        >
          <ChatList
            actionPanelVisibilityParameters={actionPanelVisibilityParameters}
            editorRef={editorRef}
            hasWorkflowConflict={hasWorkflowConflict}
            isCanvasDisabled={isCanvasDisabled}
            isOver={isOver}
            isPasteAvailable={isPasteAvailable}
            setActionPanelVisibilityParameters={
              setActionPanelVisibilityParameters
            }
            shouldSqueezeIntoEntry={shouldSqueezeIntoEntry}
            squeezeStepParentId={squeezeStepParentId}
          />
        </Maskable>
      </CanvasScroll>
    </CanvasContainer>
  );
};

const CanvasContainer = styled('div')`
  height: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const CanvasScroll = styled('div')`
  display: flex;
  flex-flow: column;
  position: relative;
  padding: 0;
  flex: 1;
`;

const Maskable = styled('div')<{ isOver: boolean }>`
  .step-container:not(.editing),
  .revamped-action-container:not(.editing) {
    opacity: ${props => (props.isOver ? 0.4 : 1)};
  }
  border: none;
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const ChatMessageContainer = styled('div')`
  display: flex;
  align-items: flex-start;
  gap: 6px;
  width: 100%;
`;

export const ChatMessage = styled('div')<{
  isBeingEdited?: boolean;
}>`
  background: ${theme.palette.colors.slate[100]};
  border-radius: 8px;
  ${props =>
    props.isBeingEdited
      ? `border: 2px solid ${theme.palette.colors.purple[500]};`
      : `border: 1px solid ${theme.palette.colors.slate[200]};`}
  padding: 12px 16px;
  width: fit-content;
  word-wrap: break-word;
  word-break: break-word;
  display: flex;
  align-items: center;

  & p:first-child {
    margin-top: 0;
  }
  & p:last-child {
    margin-bottom: 0;
  }
  padding: 14px 16px;
  border: 1px solid transparent;
  width: 478px;
`;

export const ChatMessageRight = styled('div')`
  background: ${theme.palette.colors.purple[500]};
  border-radius: 8px;
  padding: 12px 16px;
  font-size: var(--font-size-base);
  line-height: 20px;
  max-width: 250px;
  word-wrap: break-word;
  display: inline-block;
  color: ${theme.palette.colors.white};
  margin-top: 10px;
  right: 12px;
  position: relative;
`;

export default ForethoughtCanvas;
