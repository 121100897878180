import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { SOLVE_SLACK_CONFIG_TABS } from '../constants';
import { getSolveApiConfigTabs, getSolveWidgetConfigTabs } from '../utils';
import { selectIsSolveLiteEnabled } from 'src/reducers/userReducer/userReducer';
import { useGetFeatureFlagsQuery } from 'src/services/dashboard-api';

export const useGetWidgetTabIndex = () => {
  const location = useLocation();
  const isSolveLiteEnabled = useSelector(selectIsSolveLiteEnabled);

  const { data: featureFlagsData } = useGetFeatureFlagsQuery();
  const { feature_flags: featureFlags = [] } = featureFlagsData ?? {};
  const isSolveKnowledgeConfigRevamp = featureFlags.includes(
    'knowledge_article_workflow',
  );
  const isHandoffRevampEnabled = featureFlags.includes('handoff_revamp');
  const isPrivacyConsentEnabled = featureFlags.includes('privacy_consent');
  const isBannerImageEnabled = featureFlags.includes('banner_image');
  const isCsatConfigRevampEnabled = featureFlags.includes('csat_config_revamp');
  const isFallbackWorkflowEnabled = featureFlags.includes('fallback_workflow');
  const isQuickFeedbackEnabled = featureFlags.includes('quick_feedback');
  const isToneOfVoiceEnabled = featureFlags.includes('tone_of_voice');

  const getTabIndex = () => {
    // Get index based of query param if it exists on initial load
    const params = new URLSearchParams(location.search);
    const query = params.get('tab') ?? '';
    const updatedQuery = query === 'quick feedback' ? 'csat' : query;
    const tabsFiltered = getSolveWidgetConfigTabs({
      isBannerImageEnabled,
      isCsatConfigRevampEnabled,
      isFallbackWorkflowEnabled,
      isHandoffRevampEnabled,
      isPrivacyConsentEnabled,
      isQuickFeedbackEnabled,
      isSolveKnowledgeConfigRevamp,
      isSolveLiteEnabled,
      isToneOfVoiceEnabled,
    });
    const initialTabIndex = tabsFiltered.findIndex(
      tab => tab.label.toLowerCase() === updatedQuery,
    );
    if (initialTabIndex >= 0) {
      return initialTabIndex;
    }
    return null;
  };

  return getTabIndex();
};

export const useGetApiTabIndex = () => {
  const location = useLocation();

  const { data: featureFlagsData } = useGetFeatureFlagsQuery();
  const { feature_flags: featureFlags = [] } = featureFlagsData ?? {};
  const isSolveKnowledgeConfigRevamp = featureFlags.includes(
    'knowledge_article_workflow',
  );

  const getTabIndex = () => {
    // Get index based of query param if it exists on initial load
    const params = new URLSearchParams(location.search);
    const query = params.get('tab') ?? '';
    const tabsFiltered = getSolveApiConfigTabs({
      isSolveKnowledgeConfigRevamp,
    });
    const initialTabIndex = tabsFiltered.findIndex(tab => tab.id === query);
    if (initialTabIndex >= 0) {
      return initialTabIndex;
    }
    return null;
  };

  return getTabIndex();
};

export const useGetSlackTabIndex = () => {
  const location = useLocation();

  const getTabIndex = () => {
    // Get index based of query param if it exists on initial load
    const params = new URLSearchParams(location.search);
    const query = params.get('tab') ?? '';
    const initialTabIndex = SOLVE_SLACK_CONFIG_TABS.findIndex(
      tab => tab.id === query,
    );
    if (initialTabIndex >= 0) {
      return initialTabIndex;
    }
    return null;
  };

  return getTabIndex();
};
