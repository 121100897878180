import { format } from 'date-fns';

import { ALL_INTENTS, NA } from './constants';
import {
  AllConversationChannel,
  ConversationChannel,
  IntentData,
} from 'src/types/workflowBuilderAPITypes';
import {
  constructTimeRangeQueryParamsRevamped,
  last30DaysTimeRange,
} from 'src/utils/timeRangeHelpers';

export function formatCSAT(score?: number | null) {
  if (typeof score !== 'number') {
    return NA;
  }
  return score.toFixed(1);
}

export function openTab(url: string) {
  window.open(url, '_blank');
}

/**
 * create a url to be opened in a new tab on workflow builder landing page with initialPhrase q param
 */
export function getCreateIntentUrl(
  initialIntentPhrase: string,
  channel?: ConversationChannel,
) {
  const searchParams = new URLSearchParams(window.location.search);
  let currParams = Object.fromEntries(searchParams);

  // current search params doesn't contain the needed params, use the default params
  if (
    !searchParams.has('start') ||
    !searchParams.has('end') ||
    !searchParams.has('channel')
  ) {
    currParams = Object.fromEntries(
      constructTimeRangeQueryParamsRevamped(last30DaysTimeRange, 'widget'),
    );
  }
  const qString = new URLSearchParams({
    ...currParams,
    header: 'workflows',
    initialIntentPhrase,
  });
  if (channel) {
    qString.set('channel', channel);
  }

  return `${window.location.origin}/workflow-builder?${qString}`;
}

/**
 * create a url to be opened in a new tab on workflow builder edit page with initialPhrase q param
 */
export function getWorkflowBuilderUrl({
  channel,
  initialIntentPhrase,
  intentDefinitionId,
  intentWorkflowId,
  mode,
  stepId,
}: {
  channel?: AllConversationChannel;
  initialIntentPhrase?: string;
  intentDefinitionId: string;
  intentWorkflowId: string;
  mode?: string;
  stepId?: string;
}) {
  const qString = new URLSearchParams({
    intentId: intentDefinitionId,
    workflowId: intentWorkflowId,
  });
  if (channel) {
    if (channel === 'sunshine' || channel === 'preview') {
      qString.set('view', 'widget');
    } else {
      qString.set('view', channel);
    }
  }
  if (initialIntentPhrase) {
    qString.set('initialIntentPhrase', initialIntentPhrase);
  }
  if (mode) {
    qString.set('mode', mode);
  }
  if (stepId) {
    qString.set('stepId', stepId);
  }

  return `${window.location.origin}/workflow-builder-edit?${qString}`;
}

export function getIntentOptions(intents: IntentData[]) {
  const allIntentsOption = { label: 'All intents', value: ALL_INTENTS };
  const intentOptions = intents.map(intent => ({
    label: intent.intent_name,
    value: intent.intent_definition_id,
  }));

  return [allIntentsOption, ...intentOptions];
}

export const multiIntentOption = (intents: IntentData[]) => {
  return {
    label: 'All workflows',
    options: intents.map(intent => ({
      label: intent.intent_name,
      value: `intent_ids.${intent.intent_definition_id}`,
    })),
    value: 'intent_ids',
  };
};

// Formats timestamp as date like 'Jun 25, 2024 3:14:02 pm'
export const formatTimestamp = (timestamp: string) => {
  return format(new Date(timestamp), 'MMM dd, yyyy h:mm:ss aaa');
};
