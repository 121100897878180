/**
 * Copied from the library here: https://github.com/remirror/remirror/blob/e6fd529cb5791614b59a278371f4a6613feeef81/packages/remirror__react-components/src/popups/mention-atom-popup-component.tsx
 * Modified to format indented lists and follow design system.
 * */
import React, { ComponentType, FC, useEffect, useRef } from 'react';
import { MenuList } from '@mui/material';
import { cx, isEmptyArray } from '@remirror/core';
import { ReactComponentMessages as Messages } from '@remirror/messages';
import { FloatingWrapper } from '@remirror/react';
import { useCommands, useI18n } from '@remirror/react-core';
import {
  MentionAtomNodeAttributes,
  MentionAtomState,
  useMentionAtom,
  UseMentionAtomProps,
  UseMentionAtomReturn,
} from '@remirror/react-hooks';
import { ExtensionMentionAtomTheme as Theme } from '@remirror/theme';

import { IndentedMenuItem } from '../context-variable-indented-menu-items/ContextVariableIndentedMenuItems';
import { ContextVariableOptionWithIndent } from 'src/utils/solve/dynamicContextVariableUtils';

interface MentionAtomPopupComponentProps<
  Data extends MentionAtomNodeAttributes = MentionAtomNodeAttributes,
> extends UseMentionAtomProps<
    Data & (ContextVariableOptionWithIndent | MentionAtomNodeAttributes)
  > {
  /**
   * Called whenever the query state changes.
   */
  onChange: (
    mentionAtomState: MentionAtomState<
      Data & ContextVariableOptionWithIndent
    > | null,
  ) => void;

  /**
   * The message that is displayed when there are no items to display.
   */
  ZeroItemsComponent?: ComponentType<React.PropsWithChildren<object>>;
}

interface UseMentionAtomChangeHandlerProps<
  Data extends MentionAtomNodeAttributes = MentionAtomNodeAttributes,
> {
  onChange: MentionAtomPopupComponentProps<Data>['onChange'];
  state: UseMentionAtomReturn<Data>['state'];
}

function useMentionAtomChangeHandler<
  Data extends MentionAtomNodeAttributes = MentionAtomNodeAttributes,
>(props: UseMentionAtomChangeHandlerProps<Data>) {
  const { onChange, state } = props;

  useEffect(() => {
    onChange(state);
  }, [state, onChange]);
}

/**
 * This component renders the emoji suggestion dropdown for the user.
 */
export function MentionAtomPopupComponent<
  Data extends MentionAtomNodeAttributes = MentionAtomNodeAttributes,
>(props: MentionAtomPopupComponentProps<Data>): JSX.Element {
  const { focus } = useCommands();
  const {
    onChange,
    ZeroItemsComponent = DefaultZeroItemsComponent,
    ...hookProps
  } = props;
  const { getMenuProps, index, indexIsHovered, indexIsSelected, state } =
    useMentionAtom(hookProps);
  useMentionAtomChangeHandler({ onChange, state });
  const selectedItemRef = useRef<HTMLLIElement | null>(null);

  // Scroll into view when selectedItem changes
  useEffect(() => {
    if (index !== null && selectedItemRef.current) {
      selectedItemRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      });
    }
  }, [index]);

  return (
    <FloatingWrapper
      enabled={!!state}
      placement='auto-end'
      positioner='cursor'
      renderOutsideEditor
    >
      <MenuList
        {...getMenuProps()}
        className={cx(Theme.MENTION_ATOM_POPUP_WRAPPER)}
      >
        {!!state && isEmptyArray(hookProps.items) ? (
          <ZeroItemsComponent />
        ) : (
          hookProps.items.map((item, index) => {
            const isHighlighted = indexIsSelected(index);
            const isHovered = indexIsHovered(index);
            const onClick = () => {
              state?.command(item);
              focus();
            };
            return (
              <IndentedMenuItem
                focused={!item.disabled && (isHovered || isHighlighted)}
                key={item.id}
                onClick={!item.disabled ? onClick : () => undefined}
                option={item}
                ref={isHighlighted ? selectedItemRef : null}
              />
            );
          })
        )}
      </MenuList>
    </FloatingWrapper>
  );
}

const DefaultZeroItemsComponent: FC<React.PropsWithChildren<unknown>> = () => {
  const { t } = useI18n();
  return (
    <span className={Theme.MENTION_ATOM_ZERO_ITEMS}>
      {t(Messages.NO_ITEMS_AVAILABLE)}
    </span>
  );
};
