import { ListOption } from '@forethought-technologies/forethought-elements';
import { QuickstartFieldType } from './types';
import { Helpdesk } from 'src/components/app/types';
import { isSalesforceTicketFields } from 'src/reducers/triageSettingsReducer/helpers';
import {
  ModelPaidPlan,
  SalesforceTicketField,
  TriageField,
  TriageHelpdeskTicketFields,
  TriageTypedErrors,
  ZendeskTicketField,
} from 'src/reducers/triageSettingsReducer/types';

export const TOAST_TIMEOUT = 3000;

export const premiumDisabledTooltipText =
  'Contact your CSM to change the status of premium models';

export const getDisabledModelMessage = (
  isHelpdeskNotConfigured: boolean,
  isHelpdeskSupported: boolean,
) => {
  if (isHelpdeskNotConfigured || !isHelpdeskSupported) {
    return `A helpdesk integration must be ${
      !isHelpdeskSupported ? 'selected and ' : ''
    }configured to activate the model`;
  }
  return 'A helpdesk integration must be set up for this model to activate it';
};

export const getIsModelPremium = (modelPaidPlan: ModelPaidPlan | undefined) =>
  modelPaidPlan === 'premium';

export const getTriageConfigDetailUiErrorMessage = (
  error: TriageTypedErrors,
) => {
  if (
    error === 'DUPLICATE_CUSTOM_FIELD_PARAMS' ||
    error === 'INVALID_FIELD_SELECTED'
  ) {
    return 'The model can not save. A Forethought team member will reach out to help soon.';
  }

  return 'The model can not save. Please try again later.';
};

export const getFieldTypesByHelpdeskAndStage = (
  helpdesk: Helpdesk | '',
): QuickstartFieldType[] => {
  switch (helpdesk) {
    case 'zendesk':
      return ['Output field'];
    case 'salesforce':
      return ['Object', 'Origins', 'Output field'];
    default:
      throw new Error(`Helpdesk ${helpdesk} not supported yet`);
  }
};

const formatSalesforceOutputFieldsAsListItem = (
  item: SalesforceTicketField,
): ListOption => {
  return { label: item.label, value: item.name };
};

const formatZendeskOutputFieldsAsListItem = (
  item: ZendeskTicketField,
): ListOption => {
  return { label: item.name, value: item.id.toString() };
};

export const formatTicketFields = (
  ticketFields: TriageHelpdeskTicketFields | undefined,
) => {
  if (isSalesforceTicketFields(ticketFields)) {
    const {
      case_origins: origins,
      output_fields: outputFields,
      permissions,
      preselected_origins: preselectedOrigins,
    } = ticketFields;

    return {
      origins,
      outputFields: outputFields.map(formatSalesforceOutputFieldsAsListItem),
      permissions,
      preselectedOrigins,
    };
  }

  return {
    origins: [],
    outputFields:
      ticketFields?.output_fields.map(formatZendeskOutputFieldsAsListItem) ??
      [],
  };
};

export const prepareTriageFieldsByHelpdesk = ({
  helpdesk,
  isChoosingExistingFieldFlow,
  outputFieldTextInputValue,
  selectedPredictedField,
}: {
  helpdesk: Helpdesk;
  isChoosingExistingFieldFlow: boolean;
  outputFieldTextInputValue: string;
  selectedPredictedField: ListOption | undefined;
}): TriageField => {
  if (!isChoosingExistingFieldFlow) {
    return { id: outputFieldTextInputValue, name: outputFieldTextInputValue };
  }

  switch (helpdesk) {
    case 'zendesk':
      return {
        id: selectedPredictedField?.value,
        name: selectedPredictedField?.label ?? '',
      };
    case 'salesforce':
      return {
        id: selectedPredictedField?.value,
        name: selectedPredictedField?.value ?? '',
      };
    default:
      throw new Error(`Helpdesk ${helpdesk} not supported yet`);
  }
};
