import React, { useMemo } from 'react';
import { useTheme } from '@mui/material';
import Box, { BoxProps } from '@mui/material/Box';

import { Typography } from '@forethought-technologies/forethought-elements';
import { useGetBuilderQueryParams } from '../../hooks';
import HtmlComponent from 'src/components/html-component/HtmlComponent';
import { useGetContextVariables } from 'src/hooks/useGetContextVariables';
import { useGetProductMentionFieldsQuery } from 'src/services/dashboard-api';
import { contextVariableAliasHtml } from 'src/utils/cleanStr';
import { CHANNEL_TO_PRODUCT_MAP } from 'src/utils/constants';
import { flattenDynamicContextVariables } from 'src/utils/solve/dynamicContextVariableUtils';

const useGetBorderAndBackground = (
  variant: 'dark' | 'light',
  isSelected: boolean,
) => {
  const { palette } = useTheme();
  if (isSelected) {
    return {
      bgcolor: palette.colors.slate[100],
      border: '1px solid ' + palette.colors.purple[500],
    };
  }

  const bgcolor =
    variant === 'light' ? palette.common.white : palette.colors.slate[100];
  const border =
    variant === 'light' ? '1px solid' + palette.colors.slate[200] : undefined;

  return { bgcolor, border };
};

interface TextBoxProps extends BoxProps {
  index?: number;
  isRichText?: boolean;
  isSelected?: boolean;
  placeholder?: string;
  undefinedContextVariableIds?: string[];
  validIndicator?: React.ReactNode;
  value?: string;
  variant?: 'dark' | 'light';
}

const TextBox = ({
  index,
  isRichText,
  isSelected = false,
  onClick,
  placeholder = '',
  undefinedContextVariableIds = [],
  validIndicator,
  value = '',
  variant = 'light',
  ...boxProps
}: TextBoxProps) => {
  const { view } = useGetBuilderQueryParams();
  const { data = [] } = useGetProductMentionFieldsQuery({
    product: CHANNEL_TO_PRODUCT_MAP[view],
  });
  const { contextVariables } = useGetContextVariables({
    shouldIncludeSystemContextVariables: true,
  });
  const allCVs = useMemo(() => {
    // flatten dynamic cvs to include nested values
    const flattenedContextVariableOptions =
      flattenDynamicContextVariables(contextVariables);
    // format nested dynamic list cv options so they can be used in contextVariableAliasHtml
    return flattenedContextVariableOptions.map(option => {
      return {
        context_variable_id: option.id,
        context_variable_name: option.fullLabel || option.label,
      };
    });
  }, [contextVariables]);
  const content = useMemo(() => {
    return contextVariableAliasHtml({
      additionalMentions: data,
      contextVariables: allCVs,
      html: value,
      undefinedContextVariableIds,
    });
  }, [allCVs, undefinedContextVariableIds, value, data]);
  const { palette } = useTheme();
  const { bgcolor, border } = useGetBorderAndBackground(variant, isSelected);

  const renderedValue = isRichText ? (
    <HtmlComponent content={content} />
  ) : (
    <Typography variant='font14Bold'>{value}</Typography>
  );

  const displayableIndex = typeof index === 'number' ? index + 1 : undefined;

  return (
    <Box
      alignItems='center'
      bgcolor={bgcolor}
      border={border}
      borderRadius='8px'
      display='flex'
      onClick={onClick}
      padding='10px 16px'
      sx={{
        '& p': { fontSize: '14px', marginBlockEnd: 0, marginBlockStart: 0 },
        '&:hover': {
          borderColor:
            onClick && !isSelected ? palette.colors.grey[300] : undefined,
        },
        cursor: onClick ? 'pointer' : 'default',
        fontSize: '14px',
        wordBreak: 'break-word',
      }}
      {...boxProps}
    >
      {displayableIndex && (
        <Typography variant='font14Bold'>{displayableIndex}. </Typography>
      )}
      <Box flex={1}>
        {value ? (
          renderedValue
        ) : (
          <Typography color={palette.text.secondary} variant='font14'>
            {placeholder}
          </Typography>
        )}
      </Box>
      {validIndicator}
    </Box>
  );
};

export default TextBox;
