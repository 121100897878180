import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material';

import AutonomousAgentTab from '../workflow-builder-edit/autonomous-agent';
import EmailBuilderPage from '../workflow-builder-edit/email-builder-page/EmailBuilderPage';
import {
  useConstructUrlOnIntetWorkflowCreation,
  useEmailMode,
  useGetBuilderQueryParams,
  useInitCanvas,
  useInitPusher,
  useWorkflowBuilderMode,
} from '../workflow-builder-edit/hooks';
import {
  BuilderView,
  WorkflowBuilderProps,
} from '../workflow-builder-edit/types';
import { MemoizedWorkflowBuilder } from '../workflow-builder-edit/workflow-builder/WorkflowBuilder';
import Spinner from 'src/components/spinner';
import { useGetProductMentionFieldsQuery } from 'src/services/dashboard-api';
import { useGetWorkflowsForIntentQuery } from 'src/services/workflow-builder-canvas/workflowBuilderCanvasApi';
import { selectIntent } from 'src/slices/canvas-workflow-builder/workflowBuilderSlice';
import { CHANNEL_TO_PRODUCT_MAP } from 'src/utils/constants';
import { CommonIntentWorkflowType, Routes } from 'src/utils/enums';

const BuilderParentPage: React.FC<React.PropsWithChildren<unknown>> = () => {
  const navigate = useNavigate();
  const intentDefinition = useSelector(selectIntent);
  const intentDefinitionExists =
    !!intentDefinition && Object.keys(intentDefinition).length > 0;
  const [mode] = useWorkflowBuilderMode();

  const {
    intentId,
    view: currentView,
    workflowId,
  } = useGetBuilderQueryParams();
  const { emailMode } = useEmailMode();

  const { data: workflowsForIntent, isLoading } = useGetWorkflowsForIntentQuery(
    {
      intentDefinitionId: intentId,
    },
    {
      skip: !intentId || !intentDefinitionExists,
    },
  );
  const { isLoading: isLoadingMentions } = useGetProductMentionFieldsQuery(
    {
      product: CHANNEL_TO_PRODUCT_MAP[currentView],
    },
    { skip: currentView !== 'slack' },
  );

  useConstructUrlOnIntetWorkflowCreation();
  useInitPusher();

  if (isLoading || isLoadingMentions) {
    return <Spinner />;
  }

  const updateUrlWithSelectedView = (view: BuilderView) => {
    const getWorkflowId = () => {
      if (view === 'widget' || view === 'email') {
        return workflowsForIntent?.widget_workflow_id;
      }

      if (view === 'api') {
        return workflowsForIntent?.api_workflow_id;
      }
      if (view === 'slack') {
        return workflowsForIntent?.slack_workflow_id;
      }

      return workflowId;
    };
    const queryParams = {
      intentId,
      mode,
      view,
      workflowId: getWorkflowId() ?? workflowId,
      ...(intentId === CommonIntentWorkflowType.FALLBACK && view === 'email'
        ? { emailMode: 'Interactive' }
        : {}),
    };

    navigate({
      pathname: Routes.WORKFLOW_BUILDER_EDIT,
      search: `?${new URLSearchParams(queryParams).toString()}`,
    });
  };

  const props = {
    emailMode,
    onTabClick: updateUrlWithSelectedView,
    view: currentView,
  };

  const getCurrentViewComponent = () => {
    switch (currentView) {
      case 'widget':
        return WidgetContainer;
      case 'api':
        return WidgetContainer;
      case 'slack':
        return WidgetContainer;
      case 'email':
        if (emailMode === 'Interactive') {
          return WidgetContainer;
        }
        return EmailBuilderPage;

      default:
        console.error(`currentView ${currentView} does not exist`);
        return React.Fragment;
    }
  };

  const CurrentViewComponent = getCurrentViewComponent();

  return (
    <Container>
      <Content>
        <CurrentViewComponent {...props} />
      </Content>
    </Container>
  );
};

const WidgetContainer = (props: WorkflowBuilderProps) => {
  const [mode] = useWorkflowBuilderMode();
  const isReady = useInitCanvas(props.emailMode);

  if (!isReady) {
    return <Spinner />;
  }

  if (mode === 'autoflow') {
    return <AutonomousAgentTab {...props} />;
  }

  return <MemoizedWorkflowBuilder {...props} />;
};

export default BuilderParentPage;

const Container = styled('div')`
  width: 100%;
  height: calc(100% - 48px);
`;

const Content = styled('div')`
  width: 100%;
  height: 100%;
`;
