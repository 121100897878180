import Box from '@mui/material/Box';

import EmptyLabelsTable from '../triage-model-detail-page/label-tab/EmptyLabelsTable';
import NotFound from '../triage-version-control-detail/NotFound';
import SimulationLabelsOverview from './SimulationLabelsOverview';
import SimulationLabelsTable from './SimulationLabelsTable';
import Spinner from 'src/components/spinner';
import { VersionedTriageModel } from 'src/reducers/triageSettingsReducer/types';
import { useGetPredictionTestResultsQuery } from 'src/services/triage/triageApi';

const SimulationLabelsTab = ({ model }: { model: VersionedTriageModel }) => {
  const predictionTestId = window.location.pathname.split('/')[2];
  const { data: tableData, isLoading } =
    useGetPredictionTestResultsQuery(predictionTestId);
  const { labels_metrics: labelTableData = [] } = tableData || {};

  if (isLoading) {
    return <Spinner />;
  }

  if (!model || !tableData?.label_overall_metrics) {
    return <NotFound />;
  }

  const isEmpty = !labelTableData.length;
  const grid = isEmpty ? 'auto 110px auto' : 'auto minmax(350px, auto)';

  return (
    <Box display='grid' gridTemplateRows={grid} rowGap={5}>
      <SimulationLabelsOverview data={tableData.label_overall_metrics} />
      <SimulationLabelsTable data={labelTableData} isDataLoading={isLoading} />
      {isEmpty && <EmptyLabelsTable />}
    </Box>
  );
};

export default SimulationLabelsTab;
