import React, { FC, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, styled, Typography, useTheme } from '@mui/material';
import { IconChevronDown, IconChevronUp } from '@tabler/icons-react';

// Components
import {
  Button,
  Checkbox,
  Drawer,
  TextField,
  theme,
} from '@forethought-technologies/forethought-elements';
import { useSolveMetricsQueryParams } from '../intent-workflows-table/hooks/useSolveMetricsQueryParams';
// Redux
import {
  createIntentWorkflow,
  setIsOverlayVisible,
} from 'src/actions/workflow-builder/workflowBuilderActions';
import { useGetIntentsQueryWithProduct } from 'src/hooks/hooks';
import IntentBadge from 'src/pages/intent-conversation-analytics/IntentBadge';
import { FALLBACK_INTENT_DEF_ID } from 'src/pages/solve-config/constants';
import { setDefaultHandoffAPI } from 'src/services/workflow-builder/workflowBuilderApi';
import { useGetGoToIntentUsagesQuery } from 'src/services/workflow-builder-metrics';
import { updateIntent } from 'src/slices/canvas-workflow-builder/workflowBuilderSlice.thunks';
import { selectGlobalHandoffDrawerOptions } from 'src/slices/ui/uiSlice';
// Hooks
import { useAppDispatch } from 'src/store/hooks';
import { CHANNEL_TO_PRODUCT_MAP } from 'src/utils/constants';
import { isIntentActiveForChannel } from 'src/utils/solve/intentUtils';

interface CustomHandoffFormProps {
  isEditing: boolean;
  isOpen: boolean;
  onClose: () => void;
  onPostSave?: (newWorkflowId?: string, intentId?: string) => void;
}

const validateTitle = (handoffName: string) => {
  if (handoffName.trim().length === 0) {
    return "Handoff name can't be empty";
  } else if (handoffName.length > 80) {
    return "Handoff name can't be more than 80 characters";
  }
  return undefined;
};

const useIsFallbackActiveForChannel = () => {
  const { channel } = useSolveMetricsQueryParams();

  const { data: intentsResponse } = useGetIntentsQueryWithProduct();

  return useMemo(() => {
    const fallback = intentsResponse?.intents.find(
      intent => intent.intent_definition_id === FALLBACK_INTENT_DEF_ID,
    );

    return isIntentActiveForChannel(
      fallback?.active_workflow_types ?? [],
      channel,
    );
  }, [intentsResponse?.intents, channel]);
};

export const CustomHandoffForm: FC<
  React.PropsWithChildren<CustomHandoffFormProps>
> = ({ isEditing, isOpen, onClose, onPostSave }) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { handoffToEdit } = useSelector(selectGlobalHandoffDrawerOptions);
  const { data } = useGetGoToIntentUsagesQuery();
  const { usages: goToIntentUsages = [] } = data ?? {};

  const hasActiveFallbackForChannel = useIsFallbackActiveForChannel();
  const { channel } = useSolveMetricsQueryParams();

  const intentWorkflowsUsingIntentWorkflow = useMemo(() => {
    return (
      goToIntentUsages.find(
        usage => usage.intent_workflow_id === handoffToEdit?.intentWorkflowId,
      )?.intent_workflow_ids_using_intent_workflow ?? []
    );
  }, [goToIntentUsages, handoffToEdit?.intentWorkflowId]);

  // states
  const [handoffName, setHandoffName] = useState<string>(
    handoffToEdit?.handoffName ?? '',
  );
  const [shouldValidate, setShouldValidate] = useState(false);
  const [isPendingRequest, setIsPendingRequest] = useState(false);
  const [isDefaultHandoffChecked, setIsDefaultHandoffChecked] = useState(false);
  const [isDescriptionExpanded, setIsDescriptionExpanded] = useState(
    !isEditing,
  );
  const [isDefaultExpanded, setIsDefaultExpanded] = useState(true);

  const headerErrorMsg = validateTitle(handoffName);

  useEffect(() => {
    if (handoffToEdit) {
      setIsDescriptionExpanded(!isEditing);
      setHandoffName(handoffToEdit.handoffName);
      setIsDefaultHandoffChecked(handoffToEdit.isDefaultHandoff);
    }
  }, [handoffToEdit, isEditing]);

  const resetForm = () => {
    dispatch(setIsOverlayVisible(false));
    setHandoffName('');
    setShouldValidate(false);
    setIsDefaultHandoffChecked(false);
  };

  const closeAndResetForm = () => {
    setTimeout(resetForm, 200);
    onClose();
  };

  const handleCreateHandoff = async () => {
    setIsPendingRequest(true);
    const data = await dispatch(
      createIntentWorkflow({
        handoff_configuration: {
          is_default_handoff: isDefaultHandoffChecked,
          is_handoff: true,
        },
        name: handoffName,
        phrases: [],
      }),
    ).unwrap();
    if (data) {
      const {
        api_workflow_id: apiWorkflowId,
        intent_id: intentId,
        intent_workflow_id: intentWorkflowId,
      } = data.canvas;
      setIsPendingRequest(false);
      closeAndResetForm();
      onPostSave &&
        onPostSave(
          (channel === 'api' ? apiWorkflowId : intentWorkflowId) || '',
          intentId,
        );
    } else {
      setIsPendingRequest(false);
      closeAndResetForm();
    }
  };

  const handleEditHandoff = async () => {
    setIsPendingRequest(true);

    await dispatch(
      updateIntent({
        intent_id: handoffToEdit?.intentId ?? '',
        name: handoffName,
        phrases: [],
      }),
    );

    if (isDefaultHandoffChecked && !handoffToEdit?.isDefaultHandoff) {
      await setDefaultHandoffAPI(
        handoffToEdit?.intentId ?? '',
        CHANNEL_TO_PRODUCT_MAP[channel],
      );
    }

    onPostSave && onPostSave();
    setIsPendingRequest(false);
    closeAndResetForm();
  };

  return (
    <Drawer
      isOpen={isOpen}
      onClose={() => {
        closeAndResetForm();
      }}
    >
      <Content>
        <Typography variant='font24'>
          {isEditing ? 'Edit Handoff' : 'New Custom Handoff'}
        </Typography>
        <Box
          display='flex'
          flexDirection='column'
          gap='32px'
          height='calc(100% - 113px)'
        >
          <Box display='flex' flexDirection='column' gap='8px'>
            <Typography variant='font16Bold'>Handoff name</Typography>
            <TextField
              aria-label='Input handoff name'
              error={shouldValidate && headerErrorMsg}
              label=''
              onBlur={() => setShouldValidate(true)}
              onChange={e => {
                setHandoffName(e.target.value);
              }}
              placeholder='Give a name to this handoff'
              required
              showRequiredLabel
              value={handoffName}
            />
          </Box>
          <Separator />
          <Box display='flex' flexDirection='column' gap='24px'>
            <Box
              display='flex'
              justifyContent='space-between'
              onClick={() => {
                setIsDescriptionExpanded(prev => !prev);
              }}
              sx={{ cursor: 'pointer' }}
            >
              <Typography variant='font16Bold'>What is a Handoff?</Typography>
              {isDescriptionExpanded ? (
                <IconChevronDown size={20} />
              ) : (
                <IconChevronUp size={20} />
              )}
            </Box>
            {isDescriptionExpanded && (
              <>
                <Typography variant='font14'>
                  A handoff occurs when Solve AI can&apos;t handle a
                  customer&apos;s query or when the customer requests to speak
                  with an agent. This decreases self-serve rates as customers
                  prefer finding solutions on their own.
                </Typography>
                <Typography variant='font14'>
                  Provide handoff options to redirect your customers to
                  additional resources or support channels for further
                  assistance.
                </Typography>
              </>
            )}
          </Box>
          {!hasActiveFallbackForChannel && (
            <>
              <Separator />
              <Box display='flex' flexDirection='column' gap='24px'>
                <Box
                  display='flex'
                  justifyContent='space-between'
                  onClick={() => {
                    setIsDefaultExpanded(prev => !prev);
                  }}
                  sx={{ cursor: 'pointer' }}
                >
                  <Typography variant='font16Bold'>Default Handoff</Typography>
                  {isDefaultExpanded ? (
                    <IconChevronDown size={20} />
                  ) : (
                    <IconChevronUp size={20} />
                  )}
                </Box>
                {isDefaultExpanded && (
                  <>
                    <Typography variant='font14'>
                      Setting a default handoff ensures consistent support with
                      personalized assistance, solving complex issues, and a
                      smooth customer experience. It applies to all workflows,
                      but you can always change the preference for individual
                      workflows based on specific needs.
                    </Typography>
                    {isEditing ? (
                      <Box display='flex' flexDirection='column'>
                        <Checkbox
                          checked={isDefaultHandoffChecked}
                          disabled={handoffToEdit?.isDefaultHandoff ?? false}
                          label='Set this as the Default Handoff'
                          onChange={() => {
                            setIsDefaultHandoffChecked(prevValue => !prevValue);
                          }}
                        />
                        <Typography
                          color={theme.palette.colors.grey[600]}
                          variant='font12'
                        >
                          {handoffToEdit?.isDefaultHandoff
                            ? 'You can change the default by making another handoff as the Default Handoff.'
                            : 'This will replace the current default handoff.'}
                        </Typography>
                      </Box>
                    ) : (
                      <Typography
                        color={theme.palette.colors.grey[600]}
                        variant='font14'
                      >
                        You can set this as the Default Handoff after creation
                      </Typography>
                    )}
                  </>
                )}
              </Box>
            </>
          )}
          {isEditing && intentWorkflowsUsingIntentWorkflow !== undefined && (
            <>
              <Separator />
              <Box display='flex' flexDirection='column' gap='16px'>
                <Typography variant='font16Bold'>Applied Workflows</Typography>
                {!isEditing ||
                intentWorkflowsUsingIntentWorkflow.length === 0 ? (
                  <Typography
                    color={theme.palette.colors.grey[600]}
                    variant='font14'
                  >
                    This handoff has no workflow applied
                  </Typography>
                ) : (
                  <Typography variant='font14'>
                    This handoff is applied to a total of{' '}
                    {intentWorkflowsUsingIntentWorkflow.length} workflow
                    {intentWorkflowsUsingIntentWorkflow.length === 1
                      ? ''
                      : 's'}{' '}
                    in the Solve Widget
                  </Typography>
                )}
              </Box>
              {isEditing !== undefined && (
                <Box display='flex' flexDirection='column' gap='8px'>
                  {intentWorkflowsUsingIntentWorkflow.map(usage => (
                    <IntentBadge intentWorkflowId={usage} key={usage} />
                  ))}
                </Box>
              )}
            </>
          )}
        </Box>
        <Box marginTop='auto'>
          <Button
            disabled={isPendingRequest}
            fullWidth
            isLoading={isPendingRequest}
            onClick={() =>
              isEditing ? handleEditHandoff() : handleCreateHandoff()
            }
            size='large'
            variant='main'
          >
            {isEditing ? 'Save' : 'Create'} Handoff
          </Button>
        </Box>
      </Content>
    </Drawer>
  );
};

const Content = styled('div')`
  display: flex;
  flex-direction: column;
  padding: 45px 40px 24px 40px;
  gap: 32px;
  min-height: 100%;
`;

const Separator = styled('div')`
  width: 100%;
  height: 1px;
  background: ${theme.palette.colors.grey[100]};
`;
