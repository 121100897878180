import React, { FunctionComponent, memo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { styled } from '@mui/material/styles';

import { isEmitTrackingEventRoute, trackingEventRouteMapping } from './helpers';
import upperFirst from 'lodash/fp/upperFirst';
import { setTimeRange } from 'src/actions/pageSettings/pageSettings';
import { updateQueryString } from 'src/actions/search';
import { setCurrentApplication } from 'src/actions/user';
import { LabelBadge } from 'src/components/label-badge';
import { useEmitTrackingEventCallback } from 'src/hooks/hooks';
import { useGenerateSidebarLinkWithSearch } from 'src/hooks/useGenerateSidebarLinkWithSearch';
import { useGetFeatureFlagsQuery } from 'src/services/dashboard-api';
import { getAppCuesId } from 'src/utils/appCuesUtil';
import { Apps, Routes } from 'src/utils/enums';
import {
  constructTimeRangeQueryParamsRevamped,
  last30DaysTimeRange,
} from 'src/utils/timeRangeHelpers';

interface NavMenuItemProps {
  badgeLabel?: string | null;
  childHasBadgeLabel?: boolean;
  hideLabel?: boolean;
  icon?: string;
  name: string;
  onClick?: (e: React.MouseEvent) => void;
  path?: string;
  search?: string;
}

interface NavItemProps {
  selected?: boolean;
}

const NavMenuItem: FunctionComponent<
  React.PropsWithChildren<NavMenuItemProps>
> = ({
  badgeLabel,
  childHasBadgeLabel,
  hideLabel = false,
  icon,
  name,
  onClick,
  path,
  search = '',
}) => {
  const dispatch = useDispatch();
  const pathWithSearch = useGenerateSidebarLinkWithSearch({
    path,
    search,
  });
  const emitTrackingEventCallback = useEmitTrackingEventCallback();
  const { data: featureFlagsData } = useGetFeatureFlagsQuery();
  const { feature_flags: featureFlags = [] } = featureFlagsData ?? {};
  const isSolveWidgetEnabled = featureFlags.includes('solve_widget_enabled');

  const handleLinkClick = useCallback(
    (e: React.MouseEvent) => {
      if (!path) {
        onClick?.(e);
        return;
      }

      dispatch(setTimeRange(last30DaysTimeRange));

      dispatch(setCurrentApplication(Apps.INSIGHTS, name));
      dispatch(updateQueryString(''));

      isEmitTrackingEventRoute(path) &&
        emitTrackingEventCallback(trackingEventRouteMapping[path ?? '']);
    },
    [path, dispatch, name, emitTrackingEventCallback, onClick],
  );

  const RenderedNode = (
    path ? NavItem : NavItemWithoutLink
  ) as React.ElementType;

  const isWorkflowBuilder = path === Routes.WORKFLOW_BUILDER;
  const defaultSolveChannel = isSolveWidgetEnabled ? 'widget' : 'email';
  const timeStampQueryParams = constructTimeRangeQueryParamsRevamped(
    last30DaysTimeRange,
    defaultSolveChannel,
  );

  const redirectTo = isWorkflowBuilder
    ? `${path}?${timeStampQueryParams}`
    : `${pathWithSearch}`;

  return (
    <RenderedNode onClick={handleLinkClick} to={redirectTo}>
      {icon && hideLabel && !!(badgeLabel || childHasBadgeLabel) && <NewDot />}
      {icon && <img alt={hideLabel ? name : ''} height='24' src={icon} />}
      <Label
        aria-hidden={hideLabel}
        data-appcues-target={getAppCuesId({
          componentType: 'label',
          featureName: 'sidebar',
          pageName: 'navigation',
          subType: name,
        })}
        hidden={hideLabel}
      >
        {name.split(' ').map(upperFirst).join(' ')}
      </Label>
      {badgeLabel && !hideLabel && <LabelBadge badgeLabel={badgeLabel} />}
    </RenderedNode>
  );
};

const styledBase = `
  --navbar-item-height: 24px;
  display: flex;
  text-decoration: none;
  align-items: center;
  color: rgba(255, 255, 255, 0.7);
  transition-duration: var(--duration-fast);
  min-height: var(--navbar-item-height);
  cursor: pointer;

  &:hover {
    color: var(--color-solid-white);
    background-color: rgba(255, 255, 255, 0.08);
  }

  border-radius: 0;
  padding: 12px 16px;
  position: relative;

  img {
    position: absolute;
  }

  box-sizing: content-box;
`;
// content-box is needed her because of img {position: absolute;}

const NewDot = styled('div')`
  height: 9px;
  width: 9px;
  border-radius: 100px;
  position: relative;
  top: -8px;
  right: -16px;
  background: #ec4a77;
  z-index: 100;
`;

const NavItemWithoutLink = styled('div')<NavItemProps>`
  ${styledBase};
  color: var(--color-solid-white);
`;

const NavItem = styled(NavLink)<NavItemProps>`
  ${styledBase};
  color: var(--color-grey-300);
  display: flex;
  justify-content: space-between;
  &.active {
    color: var(--color-solid-white);
    font-weight: var(--font-weight-medium);
    background-color: rgba(255, 255, 255, 0.15);
  }
`;

const Label = styled('label')`
  display: ${props => (props.hidden ? 'none' : 'block')};
  padding-left: 40px;
  cursor: pointer;
  white-space: nowrap;
`;

const MemoizedNavMenuItem = memo(NavMenuItem);

export default MemoizedNavMenuItem;
