import React, { FunctionComponent, memo, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';

import { theme, Tooltip } from '@forethought-technologies/forethought-elements';
import { UnstyledUnorderedList } from '../navbar/common/resetUI';
import {
  insertDynamicAnalyticsTab,
  isRouteExperimentEnabled,
  isUsageMeterRoute,
  processBadgedNavItems,
  processBadges,
} from './helpers';
import navData from './navData';
import NavGroup from './navGroup';
import NavMenuItem from './navMenuItem';
import cloneDeep from 'lodash/fp/cloneDeep';
import logoMini from 'src/assets/images/logo-v2-forethought.svg';
import logo from 'src/assets/images/logo-v3-forethought.png';
import ToggleCollapseIcon from 'src/assets/images/sidebar-toggle-collapse.svg';
import ToggleExpandIcon from 'src/assets/images/sidebar-toggle-expand.svg';
import UsageMeterWidget from 'src/components/usage-meter-widget';
import { useBadgedRoutes } from 'src/hooks/useBadgedRoutes';
import useEmbeddedDashboardSettings from 'src/hooks/useEmbeddedDashboardSettings';
import {
  selectUserEmail,
  selectUserRole,
} from 'src/reducers/userReducer/userReducer';
import {
  useGetFeatureFlagsQuery,
  useGetPaidPlanDetailsQuery,
  useGetPermissionsByRoleQuery,
} from 'src/services/dashboard-api';
import { useGetEmbeddedAnalyticsSettingsQuery } from 'src/services/embedded-analytics/embeddedAnalyticsApi';
import { useGetIsPlanPageEnabledQuery } from 'src/services/subscription-plan/subscriptionPageApi';
import { selectExperimentTreatments } from 'src/slices/experiments/experimentsSlice';
import { Permission, Tabs, UserRoles } from 'src/utils/enums';

type SidebarProps = {
  isFullWidth?: boolean;
  isMobile: boolean;
  isSidebarExpandedToggle: boolean;
  permissions: Set<string>;
  toggleSidebar: () => void;
  toggleSidebarFlag: () => void;
};

const Sidebar: FunctionComponent<React.PropsWithChildren<SidebarProps>> = ({
  isFullWidth = true,
  isMobile,
  isSidebarExpandedToggle,
  permissions,
  toggleSidebar,
  toggleSidebarFlag,
}) => {
  const userRole = useSelector(selectUserRole);
  const userEmail = useSelector(selectUserEmail);
  const { pathname } = useLocation();
  const treatments = useSelector(selectExperimentTreatments);
  const { activeBadgedRoutes } = useBadgedRoutes(pathname);
  const embeddedDashboardSettings = useEmbeddedDashboardSettings();
  const { data: featureFlagsData } = useGetFeatureFlagsQuery();
  const { feature_flags: featureFlags = [] } = featureFlagsData ?? {};
  const { data: permissionsByRole = {} } = useGetPermissionsByRoleQuery();
  const { data: isPlanPageEnabled = false } = useGetIsPlanPageEnabledQuery();
  const { data: paidPlansData } = useGetPaidPlanDetailsQuery();
  const { enablement_info } = paidPlansData || {};
  const isDiscoverEnabled = enablement_info && enablement_info.discover;
  const { data: embeddedAnalyticsUserSettings } =
    useGetEmbeddedAnalyticsSettingsQuery();

  const navItems = useMemo(() => {
    const filteredItems = cloneDeep(navData)
      .filter(navItem => {
        if (navItem.name === Tabs.DISCOVER && !isDiscoverEnabled) {
          return false;
        }
        if (navItem.children) {
          const filteredChildren = navItem.children
            .filter(childNavItem =>
              isRouteExperimentEnabled(childNavItem.route?.path, treatments),
            )
            .filter(childNavItem => {
              if (
                userRole === UserRoles.ROLE_SUPER_ADMIN &&
                childNavItem.route?.ignorePermissionForSuperAdmin
              ) {
                return true;
              }

              if (childNavItem.name === Tabs.PLAN && !isPlanPageEnabled) {
                return false;
              }

              if (childNavItem.route?.permissionKey) {
                return permissions.has(childNavItem.route.permissionKey);
              }

              if (childNavItem.route?.roles) {
                return childNavItem.route.roles.has(userRole);
              }
            })
            .filter(childNavItem =>
              childNavItem.route?.featureFlags
                ? childNavItem.route?.featureFlags.every(flag =>
                    featureFlags.includes(flag),
                  )
                : true,
            );
          if (filteredChildren.length) {
            navItem.children = filteredChildren;
          }

          return filteredChildren.length;
        }

        if (!navItem.route && navItem.children) {
          // static text, no route
          return true;
        }

        if (navItem.route?.permissionKey) {
          return permissions.has(navItem.route.permissionKey);
        }

        if (navItem.route?.roles) {
          return navItem.route.roles.has(userRole);
        }
      })
      .map(processBadges(permissionsByRole, userRole))
      .map(processBadgedNavItems(activeBadgedRoutes));

    const orgHasLookerPermissions = permissions.has(
      Permission.LOOKER_ANALYTICS,
    );
    const orgHasDashboardsConfigured = embeddedDashboardSettings?.length;
    const userHasLookerPermissions =
      userRole === UserRoles.ROLE_SUPER_ADMIN ||
      (userEmail &&
        embeddedAnalyticsUserSettings?.allowed_emails.includes(userEmail));
    if (
      orgHasLookerPermissions &&
      orgHasDashboardsConfigured &&
      userHasLookerPermissions
    ) {
      return insertDynamicAnalyticsTab(
        filteredItems,
        embeddedDashboardSettings,
      );
    }

    return filteredItems;
  }, [
    permissionsByRole,
    userRole,
    activeBadgedRoutes,
    permissions,
    embeddedDashboardSettings,
    isDiscoverEnabled,
    treatments,
    isPlanPageEnabled,
    featureFlags,
    embeddedAnalyticsUserSettings,
    userEmail,
  ]);

  const minimizedNavMenu = useMemo(() => {
    return (
      <UnstyledUnorderedList>
        {navItems.map(({ badgeLabel, children, icon, name, route }) => (
          <li key={name}>
            <NavMenuItem
              badgeLabel={badgeLabel}
              childHasBadgeLabel={children?.some(child => !!child.badgeLabel)}
              hideLabel
              icon={icon}
              name={name}
              path={route?.path}
              search={route?.search}
            />
          </li>
        ))}
      </UnstyledUnorderedList>
    );
  }, [navItems]);

  const navMenu = useMemo(() => {
    return (
      <UnstyledUnorderedList>
        {navItems.map(({ badgeLabel, children, icon, name, route }) => (
          <NavGroup
            badgeLabel={badgeLabel}
            icon={icon}
            key={name}
            name={name}
            path={route?.path}
            search={route?.search}
          >
            {children}
          </NavGroup>
        ))}
      </UnstyledUnorderedList>
    );
  }, [navItems]);

  const handleHeaderRender = () => {
    if (isMobile) {
      return (
        <ToggleContainer>
          <CloseIcon
            onClick={toggleSidebar}
            sx={{ color: theme.palette.colors.white }}
          />
        </ToggleContainer>
      );
    }

    if (isFullWidth) {
      return (
        <ToggleContainer onClick={toggleSidebarFlag}>
          <Tooltip
            tooltipContent={isSidebarExpandedToggle ? 'Collapse' : 'Keep open'}
          >
            <img
              src={
                isSidebarExpandedToggle ? ToggleExpandIcon : ToggleCollapseIcon
              }
            />
          </Tooltip>
        </ToggleContainer>
      );
    }
  };

  return (
    <>
      <SidebarContainer>
        <SidebarSection>
          <SidebarHeader>
            <img
              alt='Forethought'
              height='28px'
              src={isFullWidth ? logo : logoMini}
              style={{ marginBottom: isFullWidth ? 0 : '8px' }}
            />
            {handleHeaderRender()}
          </SidebarHeader>
          {isFullWidth ? navMenu : minimizedNavMenu}
        </SidebarSection>
      </SidebarContainer>
      {isUsageMeterRoute(pathname) && (
        <UsageMeterWidget isFullWidth={isFullWidth} pathname={pathname} />
      )}
    </>
  );
};

const SidebarContainer = styled('nav')`
  background-color: ${theme.palette.colors.slate[800]};
  padding: 0;
  ${props => (props.onClick ? 'cursor: pointer;' : undefined)}
  display: flex;
  overflow-y: auto;
  overflow-x: hidden;
`;

const ToggleContainer = styled('div')`
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background-color: ${theme.palette.colors.slate[700]};
  }
  display: flex;
  align-items: center;
  padding: 6px;
`;

const SidebarHeader = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 18px 6px 16px 16px;
  border: none;
  background: transparent;
`;

const SidebarSection = styled('section')`
  flex: 1;
`;

const MemoizedSidebar = memo(Sidebar);

export default MemoizedSidebar;
