import { type ContextVariablesRef, type MentionComponentProps } from '../types';
import { ProductMentionField } from 'src/types/workflowBuilderAPITypes';
import { contextVariableAliasHtml } from 'src/utils/cleanStr';

export function replaceContextVariableIdsWithMarkup(
  html = '',
  contextVariables: MentionComponentProps['contextVariables'],
  additionalMentions: ProductMentionField[] = [],
): string {
  const undefinedContextVariableIds = contextVariables
    .filter(contextVariable => !contextVariable.isDefined)
    .map(contextVariable => contextVariable.id);
  return contextVariableAliasHtml({
    additionalMentions,
    contextVariables: contextVariables.map(contextVariable => ({
      context_variable_id: contextVariable.id,
      context_variable_name: contextVariable.label,
    })),
    html,
    replaceValueFn({ id, isDefined, mentionName, name }) {
      return (
        '<span' +
        ` class="remirror-mention-atom remirror-mention-atom-${mentionName}"` +
        ` data-mention-atom-id="${id}"` +
        ` data-mention-atom-name="${mentionName}"` +
        ` data-is-defined="${isDefined}"` +
        `>${name}</span>`
      );
    },
    undefinedContextVariableIds,
  });
}

export function replaceContextVariableIdsWithMarkupRef(
  html = '',
  contextVariablesRef: ContextVariablesRef,
  additionalMentions: ProductMentionField[] = [],
): string {
  return replaceContextVariableIdsWithMarkup(
    html,
    contextVariablesRef.current || [],
    additionalMentions,
  );
}

export function replaceNewlinesWithBR(html = ''): string {
  return html.replaceAll('\n', '<br />');
}
