import { useMemo } from 'react';

import {
  IntentDataWithTopics,
  MetadataByConversationChannel,
  UsageByConversationChannel,
} from '../types';
import { useSolveMetricsQueryParams } from './useSolveMetricsQueryParams';
import { safeConvertToNumber } from 'src/components/app/utils';
import { useGetIntentsQueryWithProduct } from 'src/hooks/hooks';
import { useGetActionUsagesByEntityId } from 'src/pages/action-builder/hooks';
import {
  apiWorkflowTypes,
  slackWorkflowTypes,
  widgetWorkflowTypes,
} from 'src/pages/workflow-builder-edit/constants';
import { WorkflowTypes } from 'src/pages/workflow-builder-edit/types';
import { useGetContextVariableUsagesQuery } from 'src/services/action-builder/actionBuilderApi';
import {
  useGetPaidPlanDetailsQuery,
  useGetPermissionsQuery,
} from 'src/services/dashboard-api';
import { useGetAutomationsQuery } from 'src/services/discover/api';
import {
  useGetGoToIntentUsagesQuery,
  useGetIntentWorkflowMetadataQuery,
  useInitWorkflowBuilderSettingsQuery,
} from 'src/services/workflow-builder-metrics';
import { ContextVariableUsagesResponse } from 'src/types/actionBuilderApiTypes';
import { DiscoverAutomationsByTypeResponse } from 'src/types/DiscoverTypes';
import {
  IntentData,
  IntentWorkflowMetadata,
  IntentWorkflowMetadataResponse,
} from 'src/types/workflowBuilderAPITypes';
import { Permission } from 'src/utils/enums';

function flattenContextVariableIds(
  obj: Record<
    string,
    {
      context_variable_id: string;
    }[]
  > = {},
) {
  const flattenedObj: Record<string, string[]> = {};

  // Iterate through each key in the object
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      // Access the array associated with the key
      const valueArray = obj[key];
      const valueSet = new Set(
        valueArray.map(item => item.context_variable_id),
      );

      // Extract context_variable_ids and store in an array under the same key
      flattenedObj[key] = [...valueSet].toSorted((a, b) => a.localeCompare(b));
    }
  }

  return flattenedObj;
}

const buildUsageByConversationChannel = (
  intent: IntentData,
  usages: Record<string, string[]>,
): UsageByConversationChannel => {
  return {
    api: usages[intent.api_workflow_id ?? ''] || [],
    email: [],
    slack: usages[intent.slack_workflow_id ?? ''] || [],
    widget: usages[intent.intent_workflow_id] || [],
  };
};

const buildMetadataByConversationChannel = (
  intent: IntentData,
  metadata?: Record<string, IntentWorkflowMetadata>,
): MetadataByConversationChannel => {
  return {
    api: metadata?.[intent.api_workflow_id ?? ''] ?? {},
    email: {},
    slack: metadata?.[intent.slack_workflow_id ?? ''] ?? {},
    widget: metadata?.[intent.intent_workflow_id] ?? {},
  };
};

function getIntentsWithTopics(
  intents: IntentData[],
  automationsResponse: DiscoverAutomationsByTypeResponse[] = [],
  contextVariableUsages: ContextVariableUsagesResponse | undefined = undefined,
  actionUsages: Record<string, string[]>,
  metadata: IntentWorkflowMetadataResponse | undefined,
): IntentDataWithTopics[] {
  const solveWorkflowAutomations = automationsResponse.find(
    ({ type }) => type === 'solve_workflow',
  );

  return intents.map(intent => {
    const topics = solveWorkflowAutomations?.automations
      .filter(
        ({ automated_object }) =>
          automated_object.automated_object_id === intent.intent_workflow_id,
      )
      .flatMap(({ topics }) => topics);

    return {
      ...intent,
      actions: buildUsageByConversationChannel(intent, actionUsages),
      contextVariables: buildUsageByConversationChannel(
        intent,
        flattenContextVariableIds(
          contextVariableUsages?.context_variable_usage_by_entity_id,
        ),
      ),
      metadata: buildMetadataByConversationChannel(
        intent,
        intent.is_autoflow ? metadata?.autoflow : metadata?.classic,
      ),
      topics: topics || [],
    };
  });
}

export function useIntents() {
  const { from, to } = useSolveMetricsQueryParams();
  const { data: paidPlanData } = useGetPaidPlanDetailsQuery();
  const { data: contextVariableUsages } = useGetContextVariableUsagesQuery();
  const { usages: actionUsages } = useGetActionUsagesByEntityId();
  const { data: permissions = {}, isSuccess: isPermissionsSuccess } =
    useGetPermissionsQuery();

  const isSolveLiteEnabled = Boolean(permissions[Permission.SOLVE_LITE]);
  const isWorkflowBuilderEnabled = Boolean(
    permissions[Permission.FLAMETHROWER],
  );

  const {
    isLoading: isInitWorkflowBuilderSettingsLoading,
    isSuccess: isInitWorkflowBuilderSettingsSuccess,
  } = useInitWorkflowBuilderSettingsQuery(undefined, {
    skip:
      !isPermissionsSuccess || isSolveLiteEnabled || !isWorkflowBuilderEnabled,
  });

  const { data: intentsResponse, isLoading: isLoadingIntents } =
    useGetIntentsQueryWithProduct(
      {
        end_timestamp: safeConvertToNumber(to),
        include_inquiry_counts: true,
        start_timestamp: safeConvertToNumber(from),
      },
      {
        skip: !isInitWorkflowBuilderSettingsSuccess,
      },
    );

  const { data: goToIntentsUsagesData } = useGetGoToIntentUsagesQuery();
  const { usages: goToIntentUsages = [] } = goToIntentsUsagesData ?? {};
  const isDiscoverEnabled = Boolean(paidPlanData?.enablement_info.discover);
  const { data: discoverAutomationData } = useGetAutomationsQuery(
    { includeAutomationValue: false },
    { refetchOnMountOrArgChange: true, skip: !isDiscoverEnabled },
  );
  const { data: intentWorkflowMetadata } = useGetIntentWorkflowMetadataQuery(
    undefined,
    { refetchOnMountOrArgChange: true },
  );

  const intents = useMemo(() => {
    if (!intentsResponse) {
      return [];
    }

    const usageSet = new Set(
      goToIntentUsages.map(usage => usage.intent_workflow_id),
    );

    const labelledIntents = intentsResponse.intents.map(intent => {
      const isHandoffUsedWidget =
        intent.is_handoff && usageSet.has(intent.intent_workflow_id ?? '');

      const isHandoffUsedApi =
        intent.api_is_handoff && usageSet.has(intent.api_workflow_id ?? '');

      const isHandoffUsedSlack =
        intent.slack_is_handoff && usageSet.has(intent.slack_workflow_id ?? '');

      if (intent.is_handoff) {
        const activeWorkflowTypes: WorkflowTypes[] = [];
        if (!isHandoffUsedWidget && !isHandoffUsedApi && !isHandoffUsedSlack) {
          return {
            ...intent,
            active_workflow_types: [],
          };
        }

        if (isHandoffUsedApi) {
          activeWorkflowTypes.push(...apiWorkflowTypes);
        }

        if (isHandoffUsedWidget) {
          activeWorkflowTypes.push(...widgetWorkflowTypes);
        }

        if (isHandoffUsedSlack) {
          activeWorkflowTypes.push(...slackWorkflowTypes);
        }

        return {
          ...intent,
          active_workflow_types: activeWorkflowTypes,
        };
      }

      return intent;
    });

    return getIntentsWithTopics(
      labelledIntents,
      discoverAutomationData,
      contextVariableUsages,
      actionUsages,
      intentWorkflowMetadata,
    );
  }, [
    intentsResponse,
    goToIntentUsages,
    discoverAutomationData,
    contextVariableUsages,
    actionUsages,
    intentWorkflowMetadata,
  ]);

  return {
    intents,
    loading: isLoadingIntents || isInitWorkflowBuilderSettingsLoading,
  };
}
