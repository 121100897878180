import { automationCallToActionCellRenderer } from './components/automationCallToActionCellRenderer';
import { averageCellRenderer } from './components/averageCellRenderer';
import { bookmarkedCellRenderer } from './components/bookmarkedCellRenderer';
import { devianceCellRenderer } from './components/devianceCellRenderer';
import { percentChangeCellRenderer } from './components/percentChangeCellRenderer';
import { topicCellRenderer } from './components/topicCellRenderer';
import { MetricUnit } from './types';
import { normalizedSentimentMetricToSentimentMetricMap } from 'src/constants/discover';
import { DiscoverTopicAggregatedMetricType } from 'src/reducers/discoverReducer/types';

export const getCellRendererById = (
  id: 'automationCallToAction' | 'isBookmarked' | 'name',
  options: {
    metricFilter?: DiscoverTopicAggregatedMetricType;
    percentageOfTicketVolume?: string;
    searchText?: string;
    trackingEventMetadata?: Record<string, string | string[]>;
  } = {},
) => {
  switch (id) {
    case 'isBookmarked':
      return bookmarkedCellRenderer(options.trackingEventMetadata);
    case 'name':
      return topicCellRenderer(
        options.searchText ?? '',
        options.percentageOfTicketVolume ?? '',
      );
    case 'automationCallToAction':
      return automationCallToActionCellRenderer(options.trackingEventMetadata);
    default:
      throw new Error(`getCellRenderer received unsupported ID ${id}`);
  }
};

export const metricUnitToCellRenderer = (
  metric: DiscoverTopicAggregatedMetricType,
  metricUnit: MetricUnit,
) => {
  switch (metricUnit) {
    case 'value':
      return averageCellRenderer(metric);
    case 'value_changed':
      return percentChangeCellRenderer(metric);
    case 'value_deviance':
      return devianceCellRenderer(metric);
    default:
      throw new Error(
        `metricUnitToCellRenderer received unsupported unit ${metricUnit}`,
      );
  }
};

export const getTooltipKey = (
  metric: DiscoverTopicAggregatedMetricType,
  metricUnit: MetricUnit,
) => {
  if (metric === 'sentiment') {
    return normalizedSentimentMetricToSentimentMetricMap[metricUnit];
  }

  return metricUnit === 'value' ? metric : metricUnit;
};
