import { Icon, IconListNumbers, IconSettings } from '@tabler/icons-react';

import { EmailAutomationPageContainer } from '../email-automation-page/EmailAutomationPageContainer';
import { EmailSettingsPage } from '../email-settings-page/EmailSettingsPage';

interface SolveEmailConfigTab {
  component: JSX.Element;
  Icon: Icon;
  id: string;
  isFullWidth?: boolean;
  label: string;
  title?: string;
}

export const GENERAL_EMAIL_SETTINGS_TAB = {
  component: <EmailSettingsPage />,
  Icon: IconSettings,
  id: 'general',
  isFullWidth: true,
  label: 'General settings',
};

export const EMAIL_TABS: SolveEmailConfigTab[] = [
  {
    component: <EmailAutomationPageContainer />,
    Icon: IconListNumbers,
    id: 'automations',
    isFullWidth: true,
    label: 'Automation rules',
  },
];
