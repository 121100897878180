import { SOLVE_AUTOFLOW_BASE_URL } from '../constants';
import { dashboardApi } from '../dashboard-api';
import { SolveWidgetProduct } from 'src/types/types';
import {
  AddAutoflowToolRequest,
  AutoflowIntentWorkflowResponse,
  AutoflowToolResponse,
  AutoflowVersionHistory,
  DeleteAutoflowToolRequest,
  PatchAutoflowCVRequest,
  PatchAutoflowVersionHistoryRequest,
  TemplateAutoflowIntentWorkflow,
} from 'src/types/workflowBuilderAPITypes';

export const workflowBuilderAutoflowApi = dashboardApi.injectEndpoints({
  endpoints: builder => ({
    addAutoflowTool: builder.mutation<
      AutoflowIntentWorkflowResponse,
      { body: AddAutoflowToolRequest; intentWorkflowId: string }
    >({
      invalidatesTags: [
        'AutoflowWorkflow',
        'Actions',
        'IntentUsages',
        'ActionUsages',
      ],
      query: ({ body, intentWorkflowId }) => ({
        body,
        method: 'POST',
        url:
          SOLVE_AUTOFLOW_BASE_URL +
          `/workflow-builder/${intentWorkflowId}/tool`,
      }),
    }),
    changeWorkflowPublishStatus: builder.mutation<
      AutoflowIntentWorkflowResponse,
      { intentWorkflowId: string; status: 'enabled' | 'disabled' }
    >({
      invalidatesTags: ['AutoflowWorkflow', 'Tools', 'Intents'],
      query: ({ intentWorkflowId, status }) => ({
        method: 'POST',
        params: { status },
        url:
          SOLVE_AUTOFLOW_BASE_URL +
          `/workflow-builder/${intentWorkflowId}/change-publish-status`,
      }),
    }),
    customizeAutoflowTemplate: builder.mutation<
      AutoflowIntentWorkflowResponse,
      string
    >({
      invalidatesTags: ['Intents'],
      query: intentWorkflowId => ({
        method: 'POST',
        url:
          SOLVE_AUTOFLOW_BASE_URL + `/templates/${intentWorkflowId}/customize`,
      }),
    }),
    deleteAutoflowTool: builder.mutation<
      AutoflowIntentWorkflowResponse,
      { body: DeleteAutoflowToolRequest; intentWorkflowId: string }
    >({
      invalidatesTags: [
        'AutoflowWorkflow',
        'Actions',
        'IntentUsages',
        'ActionUsages',
      ],
      query: ({ body, intentWorkflowId }) => ({
        body,
        method: 'DELETE',
        url:
          SOLVE_AUTOFLOW_BASE_URL +
          `/workflow-builder/${intentWorkflowId}/tool`,
      }),
    }),
    discardAutoflowWorkflowChanges: builder.mutation<
      AutoflowIntentWorkflowResponse,
      string
    >({
      invalidatesTags: ['AutoflowWorkflow'],
      query: intentWorkflowId => ({
        method: 'DELETE',
        url: SOLVE_AUTOFLOW_BASE_URL + `/workflow-builder/${intentWorkflowId}`,
      }),
    }),
    getAutoflowTemplates: builder.query<TemplateAutoflowIntentWorkflow[], void>(
      {
        query: () => ({
          method: 'GET',
          url: SOLVE_AUTOFLOW_BASE_URL + '/templates',
        }),
      },
    ),
    getAutoflowTools: builder.query<
      AutoflowToolResponse,
      { product: SolveWidgetProduct }
    >({
      providesTags: ['Tools'],
      query: ({ product }) => ({
        method: 'GET',
        params: { product },
        url: SOLVE_AUTOFLOW_BASE_URL + '/workflow-builder/tools',
      }),
    }),
    getAutoflowVersionHistory: builder.query<AutoflowVersionHistory[], string>({
      providesTags: ['AutoflowWorkflow'],
      query: intentWorkflowId => ({
        method: 'GET',
        url:
          SOLVE_AUTOFLOW_BASE_URL +
          `/workflow-builder/${intentWorkflowId}/version-history`,
      }),
    }),
    getAutoflowWorkflow: builder.query<AutoflowIntentWorkflowResponse, string>({
      providesTags: ['AutoflowWorkflow'],
      query: intentWorkflowId => ({
        method: 'GET',
        url: SOLVE_AUTOFLOW_BASE_URL + `/workflow-builder/${intentWorkflowId}`,
      }),
    }),
    patchAutoflowCVs: builder.mutation<
      AutoflowIntentWorkflowResponse,
      { body: PatchAutoflowCVRequest; intentWorkflowId: string }
    >({
      invalidatesTags: ['AutoflowWorkflow'],
      query: ({ body, intentWorkflowId }) => ({
        body: body,
        method: 'PATCH',
        url:
          SOLVE_AUTOFLOW_BASE_URL +
          `/workflow-builder/${intentWorkflowId}/context-variable`,
      }),
    }),
    patchVersionHistory: builder.mutation<
      AutoflowVersionHistory[],
      {
        body: PatchAutoflowVersionHistoryRequest;
        intentWorkflowId: string;
        version: number;
      }
    >({
      invalidatesTags: ['AutoflowWorkflow'],
      query: ({ body, intentWorkflowId, version }) => ({
        body: body,
        method: 'PATCH',
        url:
          SOLVE_AUTOFLOW_BASE_URL +
          `/workflow-builder/${intentWorkflowId}/version-history/${version}`,
      }),
    }),
    updateAutoflowToolConfigurationFields: builder.mutation<
      AutoflowIntentWorkflowResponse,
      {
        body: {
          configuration_fields: Record<string, unknown>;
          tool_id: string;
        };
        intentWorkflowId: string;
      }
    >({
      invalidatesTags: ['AutoflowWorkflow'],
      query: ({ body, intentWorkflowId }) => ({
        body,
        method: 'PUT',
        url:
          SOLVE_AUTOFLOW_BASE_URL +
          `/workflow-builder/${intentWorkflowId}/tool/configuration-fields`,
      }),
    }),
    updatePolicyDescription: builder.mutation<
      AutoflowIntentWorkflowResponse,
      { body: { policy_description: string }; intentWorkflowId: string }
    >({
      invalidatesTags: ['AutoflowWorkflow'],
      query: ({ body, intentWorkflowId }) => ({
        body,
        method: 'PUT',
        url:
          SOLVE_AUTOFLOW_BASE_URL +
          `/workflow-builder/${intentWorkflowId}/policy-description`,
      }),
    }),
    updateWorkflow: builder.mutation<AutoflowIntentWorkflowResponse, string>({
      invalidatesTags: ['AutoflowWorkflow'],
      query: intentWorkflowId => ({
        method: 'POST',
        url:
          SOLVE_AUTOFLOW_BASE_URL +
          `/workflow-builder/${intentWorkflowId}/update`,
      }),
    }),
  }),
});

export const {
  useAddAutoflowToolMutation,
  useChangeWorkflowPublishStatusMutation,
  useCustomizeAutoflowTemplateMutation,
  useDeleteAutoflowToolMutation,
  useDiscardAutoflowWorkflowChangesMutation,
  useGetAutoflowTemplatesQuery,
  useGetAutoflowToolsQuery,
  useGetAutoflowVersionHistoryQuery,
  useGetAutoflowWorkflowQuery,
  usePatchAutoflowCVsMutation,
  usePatchVersionHistoryMutation,
  useUpdateAutoflowToolConfigurationFieldsMutation,
  useUpdatePolicyDescriptionMutation,
  useUpdateWorkflowMutation,
} = workflowBuilderAutoflowApi;
