import { UserAction, UserState } from './types';
import {
  APPS_AVAILABLE,
  SET_CURRENT_APPLICATION,
  SET_DASHBOARD_APPLICATIONS_BY_ROLE,
  SET_ORG_CODE,
  UPDATE_ROLE_INFO,
  UPDATE_USER_INFO,
} from 'src/actions/actionTypes';
import { rolePermissions } from 'src/components/app/rolePermissions';
import { AvailablePermissions, Roles } from 'src/components/app/types';
import type { RootState } from 'src/store/rootReducer';
import { Permission, UserRoles } from 'src/utils/enums';

export const initialState: UserState = {
  applications: [],
  avatarColor: '',
  currentApplication: '',
  currentTab: '',
  dashboardApplicationsByRole: {},
  insightsTabs: [],
  orgCode: '',
  role: '',
  user: {},
};

export default function userReducer(state = initialState, action: UserAction) {
  if (action.type === UPDATE_USER_INFO) {
    return Object.assign({}, state, {
      avatarColor: action.avatarColor,
      user: action.user,
    });
  } else if (action.type === UPDATE_ROLE_INFO) {
    return Object.assign({}, state, {
      role: action.role,
    });
  } else if (action.type === APPS_AVAILABLE) {
    return Object.assign({}, state, {
      applications: action.applications,
      insightsTabs: action.insightsTabs,
    });
  } else if (action.type === SET_CURRENT_APPLICATION) {
    return Object.assign({}, state, {
      currentApplication: action.currentApplication,
      currentTab: action.currentTab,
    });
  } else if (action.type === SET_ORG_CODE) {
    return Object.assign({}, state, {
      orgCode: action.payload,
    });
  } else if (action.type === SET_DASHBOARD_APPLICATIONS_BY_ROLE) {
    return Object.assign({}, state, {
      dashboardApplicationsByRole: action.payload,
    });
  }

  return state;
}

// Selectors
export const selectCurrentUser = (state: RootState): UserState => state.user;

export const selectCurrentTab = (state: RootState): UserState['currentTab'] =>
  state.user.currentTab;

export const selectUserRole = (state: RootState): UserState['role'] =>
  state.user.role;

export const selectIsUserSuperAdmin = (state: RootState): boolean =>
  state.user.role === UserRoles.ROLE_SUPER_ADMIN;

export const selectIsUserSuperAdminOrAdmin = (state: RootState): boolean =>
  selectIsUserSuperAdmin(state) || state.user.role === UserRoles.ROLE_ADMIN;

export const tabsAvailable = (state: RootState): UserState['insightsTabs'] =>
  state.user.insightsTabs;

export const selectUser = (state: RootState): UserState['user'] =>
  state.user.user;

export const selectUserEmail = (state: RootState): string | undefined =>
  state.user.user.user?.email;

export const selectUserCan =
  (permission: AvailablePermissions) =>
  (state: RootState): boolean => {
    const userRole = state.user.role as Roles;
    return userRole ? rolePermissions[userRole].includes(permission) : true;
  };

export const selectIsSolveLiteEnabled = (state: RootState): boolean =>
  !!state.user.dashboardApplicationsByRole[Permission.SOLVE_LITE];

export const selectDashboardApplicationsByRole = (
  state: RootState,
): UserState['dashboardApplicationsByRole'] =>
  state.user.dashboardApplicationsByRole;
