import React, { useMemo } from 'react';
import { alpha, Box, styled, useTheme } from '@mui/material';

import { Typography } from '@forethought-technologies/forethought-elements';
import { getWorkflowIdByChannel } from '../workflow-builder/intent-workflows-table/helper';
import { getWorkflowBuilderUrl, openTab } from './helpers';
import { useGetIntentsQueryWithProduct } from 'src/hooks/hooks';
import {
  ConversationChannel,
  IntentData,
} from 'src/types/workflowBuilderAPITypes';
import { isIntentActiveForChannel } from 'src/utils/solve/intentUtils';
type IntentState = 'active' | 'not-active' | 'error';

const useGetColorFromIntentState = (intentState: IntentState) => {
  const { palette } = useTheme();

  switch (intentState) {
    case 'not-active':
      return palette.colors.grey[400];
    case 'active':
      return palette.colors.green[500];
    case 'error':
      return palette.colors.red[500];
    default:
      return palette.colors.red[500];
  }
};

const getIntentState = (
  intentData: IntentData | undefined,
  channel: ConversationChannel,
): IntentState => {
  if (!intentData) {
    return 'error';
  }

  return isIntentActiveForChannel(intentData.active_workflow_types, channel)
    ? 'active'
    : 'not-active';
};

export default function IntentBadge({
  channel = 'widget',
  intentDefinitionId,
  intentName,
  intentNameSuffix,
  intentWorkflowId,
  isOnHeader = false,
  onClick,
}: {
  channel?: ConversationChannel;
  intentDefinitionId?: string | null;
  intentName?: string | null;
  intentNameSuffix?: React.ReactNode;
  intentWorkflowId?: string;
  isOnHeader?: boolean;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}) {
  const { palette } = useTheme();
  const { data: intentsResponse } = useGetIntentsQueryWithProduct();

  const intentDetails = useMemo(() => {
    return intentsResponse?.intents.find(
      intent =>
        intent.intent_definition_id === intentDefinitionId ||
        getWorkflowIdByChannel(channel, intent) === intentWorkflowId,
    );
  }, [intentsResponse?.intents, intentDefinitionId, intentWorkflowId, channel]);

  const shouldShowLink = Boolean(intentDetails);
  const usableIntentName = intentName || intentDetails?.intent_name || '';

  const statusColor = useGetColorFromIntentState(
    getIntentState(intentDetails, channel),
  );

  const bgcolor = usableIntentName
    ? palette.colors.slate[100]
    : alpha('#FFD8CC', 0.3);

  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!intentDetails) {
      return;
    }
    e.stopPropagation();
    const { intent_definition_id } = intentDetails;
    const url = getWorkflowBuilderUrl({
      channel,
      intentDefinitionId: intent_definition_id,
      intentWorkflowId: getWorkflowIdByChannel(channel, intentDetails) ?? '',
    });
    openTab(url);
  };

  return (
    <Box
      alignItems='center'
      bgcolor={bgcolor}
      borderRadius='4px'
      component='div'
      display='flex'
      gap={isOnHeader ? 1 : 0.5}
      maxWidth='100%'
      onClick={shouldShowLink ? onClick ?? handleClick : undefined}
      overflow='hidden'
      padding={isOnHeader ? '6.25px 12px' : '4px 8px'}
      sx={
        shouldShowLink
          ? {
              '.MuiTypography-root': {
                lineHeight: 'initial',
                textDecoration: 'underline',
                textDecorationThickness: '1px',
              },
              ':hover': {
                bgcolor: palette.colors.slate[200],
                cursor: 'pointer',
              },
            }
          : {}
      }
      width='fit-content'
    >
      <Box
        alignItems='center'
        display='flex'
        height={isOnHeader ? '21px' : '18px'}
      >
        <StatusIcon color={statusColor} />
      </Box>

      <Typography
        color={palette.colors.grey[700]}
        noWrap
        variant={isOnHeader ? 'font20' : 'font12Medium'}
      >
        {usableIntentName || 'No intent detected'}
      </Typography>
      {intentNameSuffix && intentNameSuffix}
    </Box>
  );
}

const StatusIcon = styled('div')<{ color: string }>`
  height: 8px;
  width: 8px;
  flex: 0 0 8px;
  border-radius: 50%;
  background-color: ${props => props.color};
`;
