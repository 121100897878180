import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { Button } from '@forethought-technologies/forethought-elements';
import { useGetBuilderQueryParams } from '../hooks';
import { BuilderView } from '../types';
import { WorkflowBuilderBanner } from './WorkflowBuilderBanner';
import { acceptProductWorkflowSuggestion } from 'src/actions/workflow-builder/workflowBuilderActions';
import { conversationChannelToProductMap } from 'src/constants/solve';
import { selectIsDuplicatedPendingCustomization } from 'src/slices/canvas-workflow-builder/workflowBuilderSlice';
import { useAppDispatch } from 'src/store/hooks';

export const ProductBanner = ({
  onStartFromScratch,
  width,
}: {
  onStartFromScratch?: () => void;
  width?: string | number;
}) => {
  const isDuplicatedPendingCustomization = useSelector(
    selectIsDuplicatedPendingCustomization,
  );
  const { intentId, view } = useGetBuilderQueryParams();
  const dispatch = useAppDispatch();

  return (
    <WorkflowBuilderBanner width={width}>
      <Message view={view} />
      {isDuplicatedPendingCustomization && (
        <Box display='flex' gap={1} mt={1}>
          <Button
            onClick={() => {
              dispatch(
                acceptProductWorkflowSuggestion({
                  intentId,
                  product: conversationChannelToProductMap[view],
                  shouldStartFromScratch: true,
                }),
              );
              onStartFromScratch?.();
            }}
            variant='secondary'
          >
            Start from scratch
          </Button>
          <Button
            onClick={() =>
              dispatch(
                acceptProductWorkflowSuggestion({
                  intentId,
                  product: conversationChannelToProductMap[view],
                  shouldStartFromScratch: false,
                }),
              )
            }
            variant='main'
          >
            Start from existing workflow
          </Button>
        </Box>
      )}
    </WorkflowBuilderBanner>
  );
};

const Message = ({ view }: { view: BuilderView }) => {
  switch (view) {
    case 'slack':
      return (
        <Typography
          color={theme => theme.palette.text.secondary}
          variant='font14'
        >
          This is the{' '}
          <Typography
            color={theme => theme.palette.text.primary}
            variant='font14Bold'
          >
            Slack channel
          </Typography>
          . Configure workflows for Slack.
        </Typography>
      );
    case 'api':
      return (
        <Typography
          color={theme => theme.palette.text.secondary}
          variant='font14'
        >
          This is the{' '}
          <Typography
            color={theme => theme.palette.text.primary}
            variant='font14Bold'
          >
            API Channel
          </Typography>
          . Configure workflows for API ingestion.
        </Typography>
      );
    default:
      return (
        <Typography
          color={theme => theme.palette.text.secondary}
          variant='font14'
        >
          This is the{' '}
          <Typography
            color={theme => theme.palette.text.primary}
            variant='font14Bold'
          >
            Interactive Email Widget
          </Typography>
          . Enable chat with customer email data context.
        </Typography>
      );
  }
};
